import { LoadingOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col, Divider,
  Image,
  Modal,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IAlertInfo, ICustomerSettings } from "../../AppModels";
import { WEBAPP_VERSION } from "../../Constants";
import { API } from "../../services/LookerAPI/LookerAPI";
import {
  getLastDataRefreshDateTime,
  getLastEtlCompletedDateTime
} from "../../services/NotificationsManagement/NotificationsManagement";
import { IApplicationState } from "../../store/Store";
import "./AboutModal.scss";

const { Text } = Typography;

interface IProps {
  closeModal(): void;
}

const AboutModal: React.FC<IProps> = ({ closeModal }) => {
  const customerSettings: ICustomerSettings = useSelector<IApplicationState>(
    (state) => state.settings.customerSettings
  ) as ICustomerSettings;
  const [alertInfo, setAlertInfo] = React.useState<IAlertInfo>({
    visible: false,
    message: "",
    type: "error",
    description: "",
  });
  const [patchVersion, setPatchVersion] = React.useState<string>(null);
  const [dataLastRefreshDateTime, setDataLastRefreshDateTime] =
    React.useState<string>(null);
  const [lastETLRunCompletedDateTime, setLastETLRunCompletedDateTime] =
    React.useState<string>(null);

  useEffect(() => {
    API.getApplicationVersion(
      (result: any) => setPatchVersion(result["patch_version"]),
      () => {}
    );

    getLastDataRefreshDateTime().then(
      (result) => {
        setDataLastRefreshDateTime(result);
      },
      (error) => {
        setDataLastRefreshDateTime(error);
      }
    );

    getLastEtlCompletedDateTime().then(
      (result) => {
        setLastETLRunCompletedDateTime(result);
      },
      (error) => {
        setLastETLRunCompletedDateTime(error);
      }
    );
  }, []);

  return (
    <Modal
      title="About"
      visible
      width={800}
      onCancel={closeModal}
      footer={
        <Button type="primary" onClick={closeModal}>
          Close
        </Button>
      }
    >
      {alertInfo.visible && (
        <Alert
          className="about-alert"
          message={alertInfo.message}
          description={alertInfo.description}
          type={alertInfo.type}
          onClose={() => setAlertInfo({ visible: false, message: "" })}
        />
      )}
      <Row>
        <Col span={7}>
          <Image
            width={200}
            preview={false}
            className="about-img"
            src="/McKesson_Icon_Blue.png"
          />
        </Col>
        <Col span={17}>
          <h3 className="about-h3">EnterpriseRx Data Insights™</h3>
          <h6 className="about-h6">
            {WEBAPP_VERSION + (patchVersion == null ? "" : "." + patchVersion)}
          </h6>
          <Divider className="about-divider" />
          <p className="about-para">
            EnterpriseRx Data Insights is a simple, easy to use front end
            reporting tool with interactive dashboard capabilities. Users will
            experience the benefit of summary level analysis of data brought to
            life through visualizations to make quick, on the spot decisions.
          </p>
          <br />
          <Space direction="vertical">
            <Text style={{ paddingLeft: "20px" }}>
              <Tooltip
                title="Displays the most recent time the process to update the available data in Data Insights ran."
                overlayInnerStyle={{ width: "400px" }}
              >
                Last update completed:{" "}
              </Tooltip>
              {lastETLRunCompletedDateTime ?? <LoadingOutlined />}
            </Text>
            <Text style={{ paddingLeft: "20px" }}>
              <Tooltip
                title="Displays the time of the most recently added data just before the last update process ran."
                overlayInnerStyle={{ width: "400px" }}
              >
                Data valid as of:{" "}
              </Tooltip>
              {dataLastRefreshDateTime ?? <LoadingOutlined />}
            </Text>
          </Space>
          {customerSettings == null && !alertInfo.visible && (
            <Spin style={{ paddingLeft: "20px", paddingTop: "20px" }} />
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default AboutModal;
