import { Reducer } from 'redux'

import {
    IEditGroupsState,
    EditGroupsActions,
    EditGroupsActionTypes,
} from '../actions/EditGroupsTypes'

const initialEditGroupsState: IEditGroupsState = {
    customGroupDetails: [],
    licensedUsers: [],
};

export const editGroupsReducer: Reducer<
    IEditGroupsState,
    EditGroupsActions
> = (state = initialEditGroupsState, action): IEditGroupsState => {
    switch (action.type) {
        case EditGroupsActionTypes.GET_CUSTOM_GROUP_DETAILS: {
            return {
                ...state,
                customGroupDetails: action.customGroupDetails
            }
        }
        case EditGroupsActionTypes.GET_LICENSED_USERS: {
            return {
                ...state,
                licensedUsers: action.licensedUsers
            }
        }
        default: {
            return state;
        }
    }
};