import { DATA_MART } from "../../store/actions/UserAuthTypes";
import { Role, Permission, permissionMapping, realmPermissionMapping } from "./RoleModels";

export const isAuthorized = (roleMapping : Map<Role, boolean>, permission: Permission): boolean => {
    let role;
    let authorized = false;
    let allowedRoles = permissionMapping.get(permission);

    for(role of allowedRoles){
        if(roleMapping.get(role)){
            authorized = true;
            break;
        }
    }

    return authorized;
}

export const hasDataMart = (dataMarts: string[], dataMart: DATA_MART): boolean => {
    if (dataMarts && dataMarts.filter(value => value === dataMart).length > 0) return true
    else return false
}

export const isRealmAuthorized = (realm: string, permission: Permission): boolean => {
    let authorized = false;
    let allowedRealms = realmPermissionMapping.get(permission);

    authorized = allowedRealms.some((val) => val === realm);
    return authorized;
}