/* eslint-disable */
import { MoreOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { IDeleteSource, IDisplayFolderItem, IDisplayFolderItemType, IFolderInfo, IMoveSource, IRootFolderIds, ReportActionTypes, ReportsAction, IRenameSource, IContentDisplayTypes } from "../../../services/FolderManagement/FolderModels";
import { isAuthorized } from "../../../services/RoleManagement/RoleManagement";
import { Permission, Role } from "../../../services/RoleManagement/RoleModels";
import { getFolderPermissions } from "../../../store/actions/EditFolderPermissionsActions"
import { setMoveStartFolder } from "../../../store/actions/FolderActions"
import { IApplicationState } from "../../../store/Store";
import EditFolderPermissions from '../../EditFolderPermissions/EditFolderPermissions'
import "./ReportsFolderItemOptionsMenu.scss";

interface IReportsFolderItemOptionsMenu {
    item: IDisplayFolderItem;
    folderInfo?: IFolderInfo; //only needed if item is folder
    setAction: (action: ReportsAction) => void;
    rootFolders: IRootFolderIds;
    roleMapping: Map<Role, boolean>;
    showMove?: boolean;
    userCanDeleteFolder?: boolean; // only needed if item is folder and user is not allowed to delete
}

const ReportsFolderItemOptionsMenu: React.FC<IReportsFolderItemOptionsMenu> = ({item, folderInfo, setAction, rootFolders, roleMapping, showMove = true, userCanDeleteFolder}) => {

    const [permissionsVisible, setPermissionsVisible] = useState(false);
    const [permissionsLoading, setPermissionsLoading] = useState(false);

    const dispatch = useDispatch();

    const togglePermissionsModal = async () => {
      if (permissionsVisible) {
        setPermissionsVisible(false);
      } else {
        setPermissionsVisible(true);
        setPermissionsLoading(true);
        await dispatch(getFolderPermissions(item.id));
        setPermissionsLoading(false);
      }
    }

    //Top Level Element of this Menu will stop propagation of onClick
    // Because click within menu should not trigger card click
    const stopPropagation = (e) =>{
        e.stopPropagation();
    }

    const startDeleteItem = (item: IDisplayFolderItem) => {
      if(item.type === IDisplayFolderItemType.FOLDER
        && (folderInfo == null || item.id !== folderInfo.id)){
        //Assuming that the folder being deleted is the current folder
        //If that's not true, treat as error condition
        //The current UI design only allows for that case
        //If this ever changes, we need to add an API call here to get info for the folder being deleted
        notification['error']({
            message: 'Invalid Delete Request',
            description: 'Unexpected conditions on delete request.',
          });
      }
      const source: IDeleteSource ={
        type: item.type,
        id: item.id,
        name: item.name,
        folderInfo: folderInfo
      };
      setAction({
        type: ReportActionTypes.DELETE,
        deleteSource: source
      });      
    }

    const startMoveItem = async (item: IDisplayFolderItem) => {

      let moveFromFolderId;
      if(item.type === IDisplayFolderItemType.FOLDER) {
        moveFromFolderId = folderInfo.parent_id;
      } else {
        moveFromFolderId = folderInfo.id;
      }
      console.log(item)
      const source: IMoveSource = {
        moveType: item.type,
        moveItemId: "" + item.id,
        moveItemName: item.name,
        moveFromFolderId: moveFromFolderId,
        startFolderId: rootFolders.teamRootId,
        startFolderName: "Shared Root Folder",
      }
      setAction({
        type: ReportActionTypes.MOVE,
        moveSource: source
      });
    }

    const startRenameItem = (item: IDisplayFolderItem) =>{
      const source: IRenameSource = {
        type: item.type,
        folderInfo: folderInfo
      };
      setAction({
        type: ReportActionTypes.RENAME,
        renameSource: source
      });
    }

    const startPermissionsModal = (item: IDisplayFolderItem) => {
      togglePermissionsModal();
    }

    const startCopyItem = (item: IDisplayFolderItem) => {
      let moveFromFolderId;
      if(item.type === IDisplayFolderItemType.FOLDER) {
        //Folder Copy Not possible
        throw new Error("Folder Copy Not Supported");
      } else {
        moveFromFolderId = folderInfo.id;
      }
      const source: IMoveSource = {
        moveType: item.type,
        moveItemId: "" + item.id,
        moveItemName: item.name,
        moveFromFolderId: moveFromFolderId,
        startFolderId: rootFolders.teamRootId,
        startFolderName: 'Shared Root Folder',
      }
      setAction({
        type: ReportActionTypes.COPY,
        copySource: source
      });
    }
    
    const itemMoreOptions = (item: IDisplayFolderItem) => (
        <Menu className="report-content-options-menu">
          {
            showMove &&
            <Menu.Item 
              onClick={(event) =>{
                  startMoveItem(item);
                }
              }>
              Move...
            </Menu.Item>
          }
          {
            item.type !== IDisplayFolderItemType.FOLDER &&
            <Menu.Item 
              onClick={(event) =>{
                  startCopyItem(item);
                }
              }>
              Copy...
            </Menu.Item>
          }
          {
            // displays an option to edit permissions if the user has access, the item is a folder, and its parent is root
            isAuthorized(roleMapping, Permission.MANAGE_FOLDER_PERMISSIONS) &&
            item.type === IDisplayFolderItemType.FOLDER &&
            folderInfo.parent_id === rootFolders.teamRootId &&
            <Menu.Item
              onClick={(event) => {
                startPermissionsModal(item);
              }}>
              Manage Access...
            </Menu.Item>
          }
          { 
            item.type === IDisplayFolderItemType.FOLDER &&
            <Menu.Item 
              onClick={(event) =>{
                  startRenameItem(item);
                }
              }>
              Rename...
            </Menu.Item>
          } 
          { (userCanDeleteFolder ||
            item.type !== IDisplayFolderItemType.FOLDER) &&
            <>
              <Menu.Divider className="reports-menu-divider"/>
              <Menu.Item 
                onClick={(event) =>{
                  startDeleteItem(item);
                }
              }>
                Delete
              </Menu.Item>
            </>
          }

        </Menu>
    );

  return (
    <div onClick={stopPropagation} style={{ display:"inline"}}>
        <Dropdown 
            overlay={itemMoreOptions(item)} 
            trigger={['click']}
        >
        { item.type === IDisplayFolderItemType.FOLDER ? 
            <Button 
                type="link" 
                icon={<SettingOutlined style={{ fontSize: "20px", verticalAlign: "middle"}} /> } />
            :
            <Button 
                shape="circle" 
                size="large"
                type="text" 
                icon={<MoreOutlined style={{ fontSize: "30px", fontWeight: "bolder"}}  />} />          
            }
        </Dropdown>
        <EditFolderPermissions 
          isVisible={permissionsVisible} 
          toggleModal={togglePermissionsModal} 
          item={item} 
          loading={permissionsLoading}
          setLoading={setPermissionsLoading}/>
    </div>
  );
};
 
export default ReportsFolderItemOptionsMenu;
  