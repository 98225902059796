import React from "react";
import { RouteComponentProps } from "react-router";
import Looker from "../Looker/Looker";


interface MatchParams {
  dashboardId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const Dashboard: React.FC<IProps> = (props: IProps) => {

  return (
    <>
    {
      (props.match.params.dashboardId && props.match.params.dashboardId.length > 0) ?
      (
        <Looker embeddedPath={'/dashboards/' + props.match.params.dashboardId}  />
      )
      :
      (
        <p>Dashboard Id required.</p>
      )
    }
    </>
  );
};

export default Dashboard;
