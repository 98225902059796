// Importing necessary modules from React, antd library, and other custom components
import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Skeleton,
  Menu,
  Card,
  Space,
  Typography,
  Tooltip,
  Alert,
  Button,
  Empty,
  Input,
} from "antd";
import { ExclamationCircleOutlined, SelectOutlined } from "@ant-design/icons";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotificationsError,
  setNotificationStatus,
  setNotificationStatusBulk,
} from "../../../store/actions/NotificationActions";
import { IApplicationState } from "../../../store/Store";
import { INotification } from "../../../services/NotificationsManagement/NotificationsModels";
import { IAlertInfo } from "../../../AppModels";
import { NotificationActionTypes } from "../../../store/actions/NotificationActionTypes";
import "./NotificationsMenu.scss";
import Highlighter from 'react-highlight-words';
import LinkConfirmModal from "../LinkConfirmModal/LinkConfirmModal";

const { Text } = Typography;

// Defining the type of props that NotificationsMenu component receives
interface IProps {
  unreadCount: number;
  notifications: INotification[];
  handleModalOpen: (notification: INotification) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// NotificationsMenu component definition
const NotificationsMenu: React.FC<IProps> = ({
  unreadCount,
  notifications,
  handleModalOpen,
  searchText,
  setSearchText,
  setIsPopoverOpen,
}) => {
  // Initialize Redux dispatch function
  const dispatch = useDispatch();

  // Extract required values from Redux store
  const { error: notificationsError } = useSelector(
    (state: IApplicationState) => state.notifications
  );

  const [isLinkConfirmModalVisible, setIsLinkConfirmModalVisible] = useState<boolean>(false);
  const [notificationNavigatedFrom, setNotificationNavigatedFrom] = useState<INotification>(null);

  // State to hold sorted notifications
  const [sortedNotifications, setSortedNotifications] = useState<INotification[]>([]);
  
  // State for managing alert errors
  const [alertError, setAlertError] = useState<IAlertInfo>({
    visible: false,
    type: "error",
    message: "",
    description: "",
  });

  // Function to compute how long ago a certain date was relative to now
  function timeAgo(date: Date): string {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    if (seconds < 60) return "Just now";
    if (minutes < 60)
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    if (hours < 24) return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    if (days < 30) return days === 1 ? "Yesterday" : `${days} days ago`;
    if (months < 12)
      return months === 1 ? "1 month ago" : `${months} months ago`;

    const years = Math.floor(days / 365);
    return years === 1 ? "1 year ago" : `${years} years ago`;
  }

  // Function to get read status of a notification
  function getIsReadStatus(notification: INotification): boolean {
    const matchingEntry = notification.readHistory.find(
      (entry) =>
        entry.notificationId === notification.notificationId 
    );

    return matchingEntry ? matchingEntry.isRead : false;
  }

  // Function to find unread notifications
  function findUnreadNotifications(notifications: INotification[]): string[] {
    const unreadNotifications = notifications.filter((notification) => {
      const matchingEntry = notification.readHistory[0];

      // Condition to check if the notification should be ignored based on modalInfo and severity
      const shouldIgnore =
        (notification.modalInfo && notification.modalInfo.trim() !== "") ||
        notification.severity === "CRITICAL";

      // If no entry for the given realmUsername or the entry has isRead = false, and the notification should not be ignored, consider it as unread
      return (
        (!matchingEntry || (matchingEntry && !matchingEntry.isRead)) &&
        !shouldIgnore
      );
    });

    // Extract and return the notificationIds of unread notifications
    return unreadNotifications.map(
      (notification) => notification.notificationId
    );
  }

  // Function to mark all notifications as read
  function handleMarkAllAsRead(notifications: INotification[]): void {
    const unreadNotifications = findUnreadNotifications(notifications);

    // Dispatch action to bulk update notification status
    dispatch(setNotificationStatusBulk(unreadNotifications, true));
  }

  // Effect to handle notifications error
  useEffect(() => {
    if (notificationsError !== null) {
      switch (notificationsError.type) {
        case NotificationActionTypes.GET_ALL_NOTIFICATIONS: {
          setAlertError({
            visible: true,
            type: "error",
            message: "There was an error fetching notifications",
            description: "",
          });
          dispatch(clearNotificationsError());
          break;
        }
        case NotificationActionTypes.SET_NOTIFICATION_STATUS: {
          setAlertError({
            visible: true,
            type: "error",
            message: "There was an error toggling the read status",
            description: "",
          });
          dispatch(clearNotificationsError());
          break;
        }
      }
    }
  }, [notificationsError, dispatch]);

  // Define a type to track the status of notifications, which are either overflowing or expanded.
  // This is an object with notification IDs as keys and boolean values indicating their status.
  type NotificationStatus = {
    [id: string]: boolean;
  };

  // Define a type for references to notification elements.
  // This is an object with notification IDs as keys and RefObject values pointing to their corresponding HTML elements.
  type NotificationRef = {
    [id: string]: React.RefObject<HTMLParagraphElement>;
  };

  // Define a type for the local state of notifications.
  // This includes status of overflowing and expanded notifications, and their references.
  type LocalNotificationsState = {
    overflowingNotifications: NotificationStatus;
    expandedNotifications: NotificationStatus;
    notificationRefs: NotificationRef;
  };

  // Define a function to initialize the notification state.
  // It uses reduce method to create an object with notification IDs as keys and false as initial value.
  const initialNotificationState = (
    notifications: INotification[],
    currentState: NotificationStatus = {}
  ): NotificationStatus => {
    return notifications.reduce(
      (acc, notification) => {
        // Only update if it doesn't already exist in the current state
        if (!currentState[notification.notificationId]) {
          acc[notification.notificationId] = false;
        }
        return acc;
      },
      { ...currentState }
    );
  };

  // Define a function to initialize the references state.
  // It uses reduce method to create an object with notification IDs as keys and RefObject as initial value.
  const initialRefsState = (
    notifications: INotification[],
    currentState: NotificationRef = {}
  ): NotificationRef => {
    return notifications.reduce(
      (acc, notification) => {
        // Only update if it doesn't already exist in the current state
        if (!currentState[notification.notificationId]) {
          acc[notification.notificationId] =
            React.createRef<HTMLParagraphElement>();
        }
        return acc;
      },
      { ...currentState }
    );
  };

  // Use React's useState hook to manage the local state of notifications.
  const [notificationLocalState, setNotificationLocalState] =
    useState<LocalNotificationsState>({
      overflowingNotifications: initialNotificationState(notifications),
      expandedNotifications: initialNotificationState(notifications),
      notificationRefs: initialRefsState(notifications),
    });

  // Destructure the state object for easier access to its properties.
  const { overflowingNotifications, expandedNotifications, notificationRefs } =
    notificationLocalState;

  // Define a helper function to check if content overflows its container.
  // It returns true if the scroll height/width is greater than the client height/width.
  function checkOverflow(element: HTMLElement): boolean {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  // Define a function to toggle the expanded state of a notification.
  // It uses the previous state to calculate the new state.
  const toggleExpand = (notificationId: string) => {
    setNotificationLocalState((prev) => {
      const currentlyExpanded = prev.expandedNotifications[notificationId];
      return {
        ...prev,
        expandedNotifications: {
          ...prev.expandedNotifications,
          [notificationId]: !currentlyExpanded,
        },
      };
    });
  };

  // Use effect to check if notification is overflowing and not expanded.
  useEffect(() => {
    // Loop through each notification in the local state.
    Object.keys(notificationLocalState.expandedNotifications).forEach(
      (notificationId) => {
        // Check if the notification is not expanded and it exists.
        if (
          !notificationLocalState.expandedNotifications[notificationId] &&
          notificationLocalState.notificationRefs[notificationId]?.current
        ) {
          // Check if the notification is overflowing.
          const isOverflowing = checkOverflow(
            notificationLocalState.notificationRefs[notificationId].current
          );
          // If the notification is not overflowing but marked as overflowing in the state, update the state.
          if (
            !isOverflowing &&
            notificationLocalState.overflowingNotifications[notificationId]
          ) {
            setNotificationLocalState((prev) => ({
              ...prev,
              overflowingNotifications: {
                ...prev.overflowingNotifications,
                [notificationId]: false,
              },
            }));
          }
        }
      }
    );
  }, [
    notificationLocalState.expandedNotifications,
    notificationLocalState.notificationRefs,
    notificationLocalState.overflowingNotifications,
  ]);
  
    // Use React's useEffect to sort notifications once when component mounts or when notifications array changes
    useEffect(() => {
      const sortedNotifications = sortNotifications(notifications); // <-- Updated TypeScript annotations
      setSortedNotifications(sortedNotifications); // <-- New code: set sorted notifications in state
    }, [notifications]);

  // Function to sort notifications with CRITICAL severity first, then by startDate
  const sortNotifications = (notifications: INotification[]): INotification[] => { 
    return notifications.sort((a, b) => {
      if (a.severity === "CRITICAL" && b.severity !== "CRITICAL") {
        return -1; // a comes first
      } else if (b.severity === "CRITICAL" && a.severity !== "CRITICAL") {
        return 1; // b comes first
      } else {
        // Assuming startDate is a string that can be converted to a Date object
        return new Date(b.startDate).getTime() - new Date(a.startDate).getTime(); // Sort by startDate descending
      }
    });
  };

  // Function to get the class name based on whether the notification is expanded or overflowing.
  function getClassNameForNotificationSummary(notificationId: string) {
    const overflowing: boolean = overflowingNotifications[notificationId];
    const expanded: boolean = expandedNotifications[notificationId];

    if (expanded) return "overflowing expanded";
    else if (overflowing) return "overflowing";
    else return "";
  }

  // Use layout effect to initialize the references and overflowing status of notifications.
  useLayoutEffect(() => {
    // Function to get the overflowing status of notifications.
    function getOverflowingNotifications(
      notifications: INotification[]
    ): NotificationStatus {
      let overflowingNotifications: NotificationStatus = {};
      // Loop through each notification.
      for (const notification of notifications) {
        // Check if the notification reference exists.
        if (notificationRefs[notification.notificationId]?.current) {
          // Update the overflowing status of the notification.
          overflowingNotifications[notification.notificationId] = checkOverflow(
            notificationRefs[notification.notificationId].current!
          );
        }
      }
      return overflowingNotifications;
    }

    // Initialize the references and overflowing status of notifications.
    const newRefs = initialRefsState(notifications, notificationRefs);
    const newOverflowingNotifications =
      getOverflowingNotifications(notifications);

    // Update the local state with the new references and overflowing status.
    setNotificationLocalState((prev) => ({
      ...prev,
      notificationRefs: newRefs,
      overflowingNotifications: newOverflowingNotifications,
    }));
    // eslint-disable-next-line
  }, [notifications, expandedNotifications]);

  // Render the component.
  return (
    <Skeleton active loading={false}>
      {/* Renders a vertical menu */}
      {alertError.visible && (
        <Alert
          className="notification-alert"
          closable
          {...alertError}
          onClose={() =>
            setAlertError({
              visible: false,
              type: "error",
              message: "",
              description: "",
            })
          }
        />
      )}
      <Space className="space-mark-all-read" direction="horizontal">
        <Input.Search
          className="notifications-search"
          placeholder="Search Notifications..."
          onSearch={setSearchText}
          allowClear
        />
          <Tooltip title="Marks all as read, excluding critical alerts and pop-up notifications">
            <Button
              className={"btn-mark-all-read btn-mark-all-read-" + (unreadCount > 0 ? "enabled" : "disabled")}
              type="link"
              disabled={unreadCount === 0}
              onClick={() => handleMarkAllAsRead(notifications)}
            >
              Mark all as read
            </Button>
          </Tooltip>
      </Space>
      {sortedNotifications.length > 0 ? (
        <Menu mode="vertical" selectable={false} className="notifications-menu">
          {/* Loop through the notifications array and render a menu item for each notification */}
          {sortedNotifications.map((notification, index) => (
            <Menu.Item
              key={notification.notificationId}
              className="notification"
            >
              {/* Render a card for the notification */}
              <Card
                size="small"
                bordered={false}
                style={getIsReadStatus(notification) ? {} : {backgroundColor:"#DEE9F0"}}
                title={
                  // Render the header of the notification card
                  <Space
                    direction="horizontal"
                    size={0}
                    className="notification-header"
                  >
                    {/* Render the title of the notification */}
                    {/* Add an ellipsis to truncate long titles and show a tooltip with the full title */}
                    {notification.severity === "CRITICAL" && (
                      <Tooltip
                        title="This is a critical notification"
                        placement="left"
                      >
                        <ExclamationCircleOutlined className="critical-notification-icon" />
                      </Tooltip>
                    )}
                    <Text
                      className="title"
                      strong
                      ellipsis={{
                        tooltip: {
                          title: notification.title,
                          placement: "top",
                        },
                      }}
                    >
                      { searchText ? (
                          <Highlighter
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={notification.title ? notification.title : ''}
                          />
                        ) : (
                          notification.title
                        )
                      }
                    </Text>
                    {/* Render the date when the notification was created */}
                    <div className="date">
                      {timeAgo(new Date(notification.startDate))}
                    </div>
                    {/* Render a tooltip with the appropriate action based on the read status of the notification */}
                    <Tooltip
                      title={
                        (!notification.modalInfo || notification.modalInfo.length === 0) ||
                        ((notification.modalInfo && notification.modalInfo.length > 0) && getIsReadStatus(notification)) ?
                        getIsReadStatus(notification)
                          ? "Mark as unread"
                          : "Mark as read"
                        : "Open to mark as read"
                      }
                      placement="left"
                    >
                      <div className="toggle-btn-wrapper">
                        {/* Render a toggle button to mark the notification as read or unread */}
                        <ToggleButton
                          disabled={notification.modalInfo && notification.modalInfo.length > 0 && !getIsReadStatus(notification)}
                          active={!getIsReadStatus(notification)}
                          onClick={(e) => {
                            e.stopPropagation();
                            // Call the toggleReadStatus function and handle any errors
                            dispatch(
                              setNotificationStatus(
                                notification.notificationId,
                                !getIsReadStatus(notification)
                              )
                            );
                          }}
                        />
                      </div>
                    </Tooltip>
                  </Space>
                }
                onClick={() => {
                  if (notification.modalInfo) {
                    handleModalOpen(notification);
                    dispatch(
                      setNotificationStatus(
                        notification.notificationId,
                        true
                      )
                    )
                  }
                }}
                className={
                  notification.modalInfo
                    ? "notification notification-card-modal"
                    : "notification notification-card"
                }
              >
                {/* Render the summary of the notification */}
                <Space direction="vertical" size={0}>
                  {/* Assign ref and add 'overflowing' class if the content overflows */}
                  <div className="notification-summary-container">
                    <p
                      id={notification.notificationId}
                      ref={notificationRefs[notification.notificationId]}
                      className={getClassNameForNotificationSummary(
                        notification.notificationId
                      )}
                      tabIndex={
                        overflowingNotifications[notification.notificationId]
                          ? 0
                          : -1
                      }
                    >
                      <Text>
                      { searchText ? (
                          <Highlighter
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={notification.summary ? notification.summary : ''}
                          />
                        ) : (
                          notification.summary
                        )
                      }
                      </Text>
                    </p>
                    {/* Overlay button for "more" or "less" */}
                    {(overflowingNotifications[notification.notificationId] ||
                      expandedNotifications[notification.notificationId]) && (
                      <span
                        className="toggle-more-less"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleExpand(notification.notificationId);
                        }}
                      >
                        {expandedNotifications[notification.notificationId]
                          ? "less"
                          : "more"}
                      </span>
                    )}
                  </div>
                  
                  <span>
                  {notification.modalInfo && (
                      <>Click for more information.&nbsp;&nbsp;<SelectOutlined /><br /></>
                  )}   

                  {/* Render a link to an external survey if it exists */}
                  {notification.link && (
                    <Button
                    className="link-button"
                    style={{paddingLeft: 0}}
                    type="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        // Mark the notification as read when the link is clicked
                        setNotificationNavigatedFrom(notification);
                        setIsLinkConfirmModalVisible(true);
                        setIsPopoverOpen(false);
                      }}
                    >
                      Click here to open link in new tab.
                    </Button>
                  )}   

                  {!notification.modalInfo && !notification.link &&
                    <><span>&nbsp;</span></>
                  }
                  </span>              
                </Space>
              </Card>
            </Menu.Item>
          ))}
        </Menu>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={searchText ? <span>No notifications match search criteria.</span> : 
            <span>No notifications to display.</span>}
        />
      )}
      { isLinkConfirmModalVisible &&
      <LinkConfirmModal
        link={notificationNavigatedFrom?.link ?? ""}
        notification={notificationNavigatedFrom}
        exit={() => setIsLinkConfirmModalVisible(false)}
        getIsReadStatus={getIsReadStatus}
      />
      }
    </Skeleton>
  );
};

// Export the NotificationsMenu component.
export default NotificationsMenu;
