import React from "react";
import { RouteComponentProps } from "react-router";
import Looker from "../Looker/Looker";


interface MatchParams {
  lookId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const Look: React.FC<IProps> = (props: IProps) => {

  return (
    <>
    {
      (props.match.params.lookId && props.match.params.lookId.length > 0) ?
      (
        <Looker embeddedPath={'/looks/' + props.match.params.lookId} />
      )
      :
      (
        <p>Look Id required.</p>
      )
    }
    </>
  );
};

export default Look;
