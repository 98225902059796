import React, { ReactText, useEffect, useState } from "react";
import { Alert, Button, Input, Row, Col, Space, Table, message, Typography } from "antd";
import Highlighter from 'react-highlight-words';
import { ColumnsType } from 'antd/es/table'
import { IDataModelField, IDataSearchState, IExploreTile, ILookerExplore } from '../../../services/DocumentationManagement/DocumentationModels'
import { IAlertInfo } from "../../../AppModels";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv"
import scrollIntoView from 'scroll-into-view-if-needed';
import DataDocumentationSearchModal from "./DataDocumentationSearchModal/DataDocumentationSearchModal";
import './DataDocumentationView.scss'
import { FilterDropdownProps } from "antd/lib/table/interface";

interface IProps {
    fields: IDataModelField[];
    exploresInfo: IExploreTile[];
    defaultExpanded: string;
    setLoading(isLoading: boolean): void;
    setAlertInfo(alertInfo: IAlertInfo);
    loading: boolean;
}

export const SearchStateDispatch = React.createContext<React.Dispatch<Partial<IDataSearchState>>>(null);

const DataDocumentationView: React.FC<IProps> = (props: (IProps & IAlertInfo)) => {

    const [explores, setExplores] = React.useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<readonly (string | number)[]>([]);

    const [filteredData, setFilteredData] = React.useState([]);

    const [searchState, setSearchState] = React.useReducer((prev: IDataSearchState, next: Partial<IDataSearchState>) => {
      if (next.visible && next.text !== undefined) {
        const results = props.fields.filter((field) => field.fieldName.toLowerCase().includes(next.text.toLowerCase()))
        return {
          ...prev,
          ...next,
          results: results
        }
      }
      return {...prev, ...next}
    }, {visible: false, text: '', results: []})

    const { Search } = Input;
    const { Text } = Typography;

    const handleScroll = () => {
      if (document.querySelector('.scroll-row')) {
        scrollIntoView(document.querySelector('.scroll-row'), {
          scrollMode: 'always',
          block: 'start',
          inline: 'start'
        });
      }
    }

    const onAlertClose = () => {
        props.setAlertInfo({
            visible: false,
            type: 'warning',
            message: '',
            description: ''
        })
    }

    useEffect(() => {
        let catBuilder: ILookerExplore[] = [];
        for (let field of props.fields) {
            if (catBuilder.filter(cat => cat.label === field.exploreLabel).length === 0) {
                catBuilder.push({label: field.exploreLabel, name: field.exploreName, fields: props.fields.filter(fld => fld.exploreLabel === field.exploreLabel)});
            }   
        }
        catBuilder.sort((a, b) => b.label.localeCompare(a.label))
        setExplores(catBuilder);
    }, [props.fields])

    useEffect(() => {
      if (props.defaultExpanded !== '' && !expandedRowKeys.includes(props.defaultExpanded) && props.exploresInfo.length > 0) {
        let defaultExpand: readonly ReactText[] = [props.defaultExpanded];
        setExpandedRowKeys(defaultExpand);
        setTimeout(handleScroll, 250);
      }
      // eslint-disable-next-line
    }, [props.defaultExpanded, props.exploresInfo]) 

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('');

    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
    }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
          }
        },
        render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
      });
    
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    
       const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
      };

    
    
    const expandedRowRender = (item: ILookerExplore) => {

      const data = [];

      const nestedColumns: ColumnsType<IDataModelField> = [
          { 
              title: 'Field Name', 
              dataIndex: 'fieldName', 
              key: 'fieldName', 
              width: '15%',
              sortDirections: ['descend', 'ascend'],
              sorter: (a, b) => a.fieldName.localeCompare(b.fieldName),
              ...getColumnSearchProps('fieldName')
          },
          {
              title: 'Data Format',
              dataIndex: 'dataFormat',
              key: 'dataFormat',
              width: '10%',
              sortDirections: ['descend', 'ascend'],
              sorter: (a, b) => a.dataFormat.localeCompare(b.dataFormat),
              ...getColumnSearchProps('dataFormat')
          },
          {
              title: 'Description',
              dataIndex: 'fieldDescription',
              key: 'fieldDescription',
              width: '400px',
              ...getColumnSearchProps('fieldDescription')
          },
          {
            width: '10%',
            title: (
            <Button type="primary">
              <CSVLink
                filename={item && item.fields ? item.fields[0].exploreName + ".csv" : 'export.csv'}
                data={searchText ? filteredData : data}
                onClick={() => {
                  message.success("The file is downloading")
                }}
              >
                Export to CSV
              </CSVLink>
            </Button>)
          }
      ];

      let key = 0;
      for (let i of item.fields) {
          data.push({
              key: key,
              fieldName: i.fieldName,
              dataFormat: i.dataFormat,
              fieldDescription: i.fieldDescription
          });
          key++;
      }
    
      const handleChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: Array<any>[] }) => {
        setFilteredData(extra['currentDataSource'])
      }

    return (
    <div className='nested-field-table'>

      <Table 
      id='nested-table' 
      columns={nestedColumns}
      dataSource={data} 
      size='small' 
      pagination={
        {
          position: ['bottomCenter'], 
          size: 'default',
        }
      }
      onChange={handleChange}
      />
    </div>);
    };

    const columns: ColumnsType<ILookerExplore> = [
        {
            width: '1000px',
            title: 
              <Space className="data-doc-title">
                <Text>Explore Name</Text>
                <Search disabled={!props.fields || props.fields.length === 0} 
                className='data-doc-search' 
                placeholder="Search all fields..." 
                onSearch={(value) => setSearchState({visible: true, text: value})} 
                style={{float: 'right', width: '250px'}}/>
              </Space>, 
            dataIndex: 'label', 
            key:'name',
            render: (value, record, index) => (
              <div>
                <Row>
                  <Col>
                    {expandedRowKeys.filter(key => key === record.name).length > 0 ? <h5>{value}</h5> : value}
                  </Col>
                </Row>
                { expandedRowKeys.filter(key => key === record.name).length > 0 && props.exploresInfo.length > 0 &&
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <p className='explore-info-paragraph'>{props.exploresInfo.filter(explore => explore.exploreLabel === record.label)[0] ? props.exploresInfo.filter(explore => explore.exploreLabel === record.label)[0].description : null}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h5>Example Data Types</h5>
                          <p className='explore-info-paragraph'>{props.exploresInfo.filter(explore => explore.exploreLabel === record.label)[0] ? props.exploresInfo.filter(explore => explore.exploreLabel === record.label)[0].exampleDataTypes : null}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h5 style={{paddingTop:'10px'}}>{props.exploresInfo.filter(explore => explore.exploreLabel === record.label)[0] ? props.exploresInfo.filter(explore => explore.exploreLabel === record.label)[0].reportsUsingDetail : null}</h5>
                          <ul className='data-model-list'>
                          { props.exploresInfo.filter(explore => explore.exploreLabel === record.label)[0] ? props.exploresInfo.filter(explore => explore.exploreLabel === value)[0].reportsUsingNames.map( (value, index) => {
                            return (<li key={index} className='data-model-list-item'>{value}</li>)
                          }) : null}
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              </div>
            ),
        },
    ]

    return (
        <div>
          {
            props.visible &&
            <Alert
                style={{ marginBottom: "24px"  }}
                type={props.type}
                message={props.message}  
                description={props.description}
                closable 
                onClose={onAlertClose}/>
            }
            <div className='data-model-div'>
              <Table<ILookerExplore>
                  scroll={{x: 'max-content'}}
                  rowKey={record => record.name}
                  columns={columns}
                  dataSource={props.exploresInfo.length > 0 ? explores : null}
                  className='explore-table-row-select'
                  rowClassName={(record, index) => record.name === props.defaultExpanded ? 'scroll-row' : ''}
                  expandable={{ 
                    expandedRowRender,
                    onExpandedRowsChange: (expandedKeys) => {
                      const filteredKeys = expandedKeys.filter((key): key is string | number => typeof key === 'string' || typeof key === 'number');
                      setExpandedRowKeys(filteredKeys);
                    },
                    expandedRowKeys: expandedRowKeys,
                    expandRowByClick: true, 
                  }}
                  loading={(props.exploresInfo.length <= 0) && (props.fields.length <= 0)}
                  pagination={false}
              />
            </div>
            { searchState.visible &&
              <SearchStateDispatch.Provider value={setSearchState}>
                <DataDocumentationSearchModal searchState={searchState}/>
              </SearchStateDispatch.Provider>
            }
        </div>
    );
};

export default DataDocumentationView;