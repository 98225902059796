// Import React and useState hook from the 'react' library
import React, { useState } from 'react';

// Import the styles from the './ToggleButton.scss' file
import './ToggleButton.scss';

// Define the prop types for the ToggleButton component
interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    size?: number;
    active?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

// Define the ToggleButton component as a functional component
const ToggleButton: React.FC<IProps> = ({ 
    size = 24, 
    active = false, 
    onClick,
    ...btnProps
}) => {
    // Define a state variable called 'mouseDown' and a function to update it
    const [mouseDown, setMouseDown] = useState(false);

    // Render the ToggleButton component
    return (
        <button 
            {...btnProps}
            id="toggleButton" 
            className={`circle-button ${active ? 'active' : ''} ${mouseDown ? 'mouse-down' : ''}`}
            onClick={onClick}
            onMouseDown={() => setMouseDown(true)}
            onMouseUp={() => setMouseDown(false)}
            onMouseLeave={() => setMouseDown(false)} // Reset the state when mouse leaves the button
            style={{ width: `${size}px`, height: `${size}px` }}
        />
    );
}

// Export the ToggleButton component as the default export of this module
export default ToggleButton;
