export enum FilterValueType {
  STRING_VALUE = "string",
  NUMBER_VALUE = "number",
  YESNO_VALUE = "yesno",
  SELECT_LIST_VALUE = "select",
}

export interface IFilter {
  key: string;
  filterName: string;
  valueType: FilterValueType;
  filterValue: string | boolean;
  selectListChoices?: string[];
  selectListType?: SelectListType;
}

export enum SelectListType {
  PRIMARY_TP_NAME = "Primary Third Pary Name",
  PRODUCT_NAME = "Product Name",
}

export interface IDemoFilter {
  filters: IFilter[];
}

export const filterChoices: IDemoFilter = {
  filters: [
    {
      key: "0",
      filterName: "Show Patient Phone",
      valueType: FilterValueType.YESNO_VALUE,
      filterValue: true,
    },
    {
      key: "1",
      filterName: "Show Product Name",
      valueType: FilterValueType.YESNO_VALUE,
      filterValue: true,
    },
    {
      key: "2",
      filterName: "Show Third Party Paid",
      valueType: FilterValueType.YESNO_VALUE,
      filterValue: true,
    },
    {
      key: "3",
      filterName: "Show Patient Paid",
      valueType: FilterValueType.YESNO_VALUE,
      filterValue: true,
    },
    // {
    //   key: "4",
    //   filterName: "Primary TP Name",
    //   valueType: FilterValueType.STRING_VALUE,
    //   filterValue: "",
    // },
    {
      key: "5",
      filterName: "Primary TP Name",
      valueType: FilterValueType.SELECT_LIST_VALUE,
      filterValue: "",
      selectListChoices: [""],
      selectListType: SelectListType.PRIMARY_TP_NAME,
    },
    // {
    //   key: "6",
    //   filterName: "Product",
    //   valueType: FilterValueType.STRING_VALUE,
    //   filterValue: "",
    // },
    {
      key: "7",
      filterName: "Product",
      valueType: FilterValueType.SELECT_LIST_VALUE,
      filterValue: "",
      selectListChoices: [""],
      selectListType: SelectListType.PRODUCT_NAME,
    },
    {
      key: "8",
      filterName: "Days in Bin",
      valueType: FilterValueType.NUMBER_VALUE,
      filterValue: "",
    },
  ],
};
