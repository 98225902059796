import { ExclamationCircleOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFirstMessage } from "../../store/actions/MessageActions";
import { IApplicationState } from "../../store/Store";
import { IDataInsightsMessage } from "./MessageList";

interface IProps {
}

const Messages: React.FC<IProps> = () => {

  const dispatch = useDispatch();
  const messages: IDataInsightsMessage[] = useSelector<IApplicationState>(state => state.messages.messages) as IDataInsightsMessage[];

  const clearMessage = () => {
    dispatch(clearFirstMessage());
  }
 
  return (
    <>
    { messages && messages.length>0 &&
        <Modal
          title={messages[0].heading}
          visible={true}
          closable={true}
          maskClosable={true}
          onOk={clearMessage}
          onCancel={clearMessage}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={650}
        >   
          <h4>
            { messages[0].severity === 'warning' && <WarningOutlined /> }
            { messages[0].severity === 'info' && <InfoCircleOutlined />}
            { messages[0].severity === 'error' && <ExclamationCircleOutlined />}
            &nbsp;{messages[0].title}
          </h4>
          { messages[0].messages && messages[0].messages.length>0 &&
            <ul>
              {
                messages[0].messages.map((message, index)=>{
                  return <li key={"messagelist" + index}><h5>{message}</h5></li>
                })
              }
            </ul>
          }
        </Modal>
      }
   </>
  );
}

export default Messages;
