import { DownOutlined, PieChartOutlined, UpOutlined, ReloadOutlined } from "@ant-design/icons";
import { Badge, Spin, Typography, Space } from "antd";
import Menu from "antd/lib/menu";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useEffect, ReactNode } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ICustomerSettings, IStandardReportsGroupItem } from "../../../AppModels";
import { IApplicationState } from "../../../store/Store";
import { getStandardReports, getCustomerSettings } from "../../../store/actions/SettingsActions";
import { Permission, Role } from "../../../services/RoleManagement/RoleModels";
import { hasDataMart, isAuthorized } from "../../../services/RoleManagement/RoleManagement";
import { DATA_MART } from "../../../store/actions/UserAuthTypes";

const { Text } = Typography;

interface IStandardReportsProps {
  collapsed: boolean;
  dataMarts: string[];
  roleMapping: Map<Role, boolean>;
}

const StandardReportsMenu: React.FC<IStandardReportsProps> = (props) => {
  const {collapsed, roleMapping, dataMarts, ...other} = props;

  const [collapsedMenus, setCollapsedMenus] = React.useState<object>({});
  const [errorMessage, setErrorMessage] = React.useState<ReactNode>();
  const cpsPremiumEnabled: boolean = useSelector<IApplicationState>(state => state.userAuth.cpsPremiumEnabled) as boolean;
  const standardReports: IStandardReportsGroupItem[] = useSelector<IApplicationState>(state => state.settings.standardReports) as IStandardReportsGroupItem[];
  const standardReportsError: string = useSelector<IApplicationState>(state => state.settings.standardReportsError) as string;
  const customerSettings: ICustomerSettings = useSelector<IApplicationState>(state => state.settings.customerSettings) as ICustomerSettings;
  const customerSettingsError: string = useSelector<IApplicationState>(state => state.settings.customerSettingsError) as string;
  const dispatch = useDispatch();

  useEffect(() => {
    if(standardReports!=null){
      let _collapsed = {};
      standardReports.forEach((group) =>{
        if(group.menus){
          group.menus.forEach((menu) => {
            _collapsed[group.name + '/' + menu.name] = false; 
            if(menu.subMenus){
              menu.subMenus.forEach((subMenu) => {
                _collapsed[group.name + '/' + menu.name + '/' + subMenu.name] = false;
              });
            }
          })
        }
      });
      setCollapsedMenus(_collapsed);
    } 
  }, [standardReports]);

  useEffect(()=>{
    if ((standardReports == null && standardReportsError !=null) || (customerSettings == null && customerSettingsError != null)) {
      setErrorMessage(
          <div 
            onClick={() => {
              if(standardReportsError){
                dispatch(getStandardReports());
              }
              if(customerSettingsError){
                dispatch(getCustomerSettings());
              }
              setErrorMessage(null);
            }} 
            style={{ textAlign: "center"}} 
            className="clickable"
          >
              There was an error loading Standard Reports. <br />
              <u>Click to Retry <ReloadOutlined /></u>
          </div>
      )        
    } else if (standardReports != null && customerSettings!=null){
      setErrorMessage(null);
    }
  }, [standardReports, standardReportsError, customerSettings, customerSettingsError, dispatch]);

  const handleMenuTitleClick = (key: string) =>{
    let _collapsed = JSON.parse(JSON.stringify(collapsedMenus));
    _collapsed[key] = !_collapsed[key];
    setCollapsedMenus(_collapsed);
  }

  const SecondLevelWrapper = ({collapsed, group, menu, children, ...moreSecondLevelProps}) => (
    collapsed ?
    <SubMenu
      title={menu.name}
      className="custom-sidebar-submenu-group"
      {...moreSecondLevelProps}
    >
      {children}
    </SubMenu>
    :
    <Menu.ItemGroup
      title={
        <div className="clickable" onClick={() => {handleMenuTitleClick(group.name + '/' + menu.name)}}> 
          { menu.name.toUpperCase() }
          { collapsedMenus[group.name + '/' + menu.name] ? 
            <DownOutlined className="submenu-lookalike-arrow" /> : 
            <UpOutlined className="submenu-lookalike-arrow" /> }
        </div>
      }
      className="custom-sidebar-submenu-group"
      {...moreSecondLevelProps}
    >
      {children}
    </Menu.ItemGroup>
  )

  const ThirdLevelWrapper = ({collapsed, group, menu, subMenu, children, ...moreThirdLevelProps}) => (
    collapsed ?
    <SubMenu 
      title={subMenu.name}
      className="custom-sidebar-submenu2-group"
      {...moreThirdLevelProps}  
    >
      {children}
    </SubMenu>
    :
    <Menu.ItemGroup 
      title={
        <div className="clickable" onClick={() => {handleMenuTitleClick(group.name + '/' + menu.name + '/' + subMenu.name)}}> 
          { subMenu.name.toUpperCase() }
          { collapsedMenus[group.name + '/' + menu.name + '/' + subMenu.name] ? 
            <DownOutlined className="submenu-lookalike-arrow" /> : 
            <UpOutlined className="submenu-lookalike-arrow" /> }
        </div>
      }
      className="custom-sidebar-submenu2-group"
      {...moreThirdLevelProps}  
    >
      {children}
    </Menu.ItemGroup>
  )

  const ReportWrapper = ({dashboard, ...moreReportProperties}) => {
    const realTimeAllowed = customerSettings.realTimeAll || dashboard.explores==null || 
    (customerSettings.realTimeExplores && dashboard.explores.some(explore => customerSettings.realTimeExplores.has(explore)));
    if(!realTimeAllowed && dashboard.embeddedPath == null) return <></>;
    return (<div className={dashboard.explores.length > 0 ? 'standard-reports-submenu': ''}>
      <Menu.Item
      className="custom-sidebar-submenu-item"
      {...moreReportProperties}
    >
        <Link to={"/reports/" + dashboard.dashboardId}>
        <Space>
            {
              dashboard.explores.length > 0 &&
              <Badge count={'Daily'} style={{ backgroundColor: '#005A8C', padding: "0 4px", fontSize: "10px", fontWeight: 600, width: 35 }}
                offset={[5, -5]}
                size='small'
                title='Daily Data'
              />
            }
            <span>{dashboard.name}</span>
          </Space>
        </Link>
    </Menu.Item>
    </div>
  )}

  return (
    <SubMenu
      className="custom-sidebar-submenu"
      key="coreReportsSubMenu"
      title={
        <span>
          <PieChartOutlined className="sidebar-icon" />
          <span>Standard Reports</span>
        </span>
      }
      {...other}
    >
    <>
      {
        !(standardReports && customerSettings) && errorMessage != null &&
          <Menu.ItemGroup
              title={errorMessage}
          >
          </Menu.ItemGroup>
      }
      {
        !(standardReports && customerSettings) && errorMessage == null &&
          <Menu.ItemGroup
            title={<Spin/>}
          >
          </Menu.ItemGroup>
      }
      {
        standardReports && customerSettings && 
        standardReports.flatMap((group) => (
          (group.dashboards || group.menus) ?
          <SubMenu
            key={"group" + group.name}
            title={ collapsed ? group.name : 
              <Text style={{width: '160px'}} ellipsis={{ 
                tooltip: {
                  placement: 'right',
                  title: group.name,
                  overlayStyle: {paddingLeft: '30px'}
              }}}>
                &emsp;{group.name}
              </Text>}
            className={collapsed ? "custom-sidebar-submenu-group" : ""}
          >
            {
              group.dashboards && group.dashboards.map((dashboard)=>(
                <ReportWrapper 
                  key={"/reports/" + dashboard.dashboardId}
                  dashboard={dashboard}
                />
              ))
            }
            {
              group.name === 'Adherence Performance Solution' && cpsPremiumEnabled && isAuthorized(props.roleMapping, Permission.VIEW_APS_CUSTOM_LISTS) && hasDataMart(props.dataMarts, DATA_MART.APS) &&
              <Menu.Item key="/customAdherenceDrugLists" className="custom-sidebar-submenu-item">
                <Link to="/customAdherenceDrugLists">
                  <span>Custom Adherence Drug Lists</span>
                </Link>
              </Menu.Item>
            }
            {
              group.menus && group.menus.flatMap((menu) => (
                (menu.subMenus || menu.dashboards) ?
                <SecondLevelWrapper
                  key={"menu" + group.name + "/" + menu.name}
                  collapsed={collapsed}
                  group={group}
                  menu={menu}
                >
                  { (collapsed || !collapsedMenus[group.name + '/' + menu.name]) && menu.dashboards && menu.dashboards.map((dashboard) => (
                      <ReportWrapper 
                        key={"/reports/" + dashboard.dashboardId}
                        dashboard={dashboard}
                      />
                  ))}
                  { (collapsed || !collapsedMenus[group.name + '/' + menu.name]) && menu.subMenus && (
                    menu.subMenus.flatMap((subMenu) => (
                      (subMenu.dashboards) ?
                        <ThirdLevelWrapper 
                          key={"submenu" + group.name + "/" + menu.name + "/" + subMenu.name} 
                          group={group}
                          menu={menu}
                          subMenu={subMenu}
                          collapsed={collapsed}  
                        >
                            { (collapsed || !collapsedMenus[group.name + '/' + menu.name + '/' + subMenu.name]) && subMenu.dashboards &&
                              subMenu.dashboards.map(((dashboard) => (
                                <ReportWrapper 
                                  key={"/reports/" + dashboard.dashboardId}
                                  dashboard={dashboard}
                                />
                            )))}
                        </ThirdLevelWrapper>
                        :
                        []
                      ))
                  )}
              </SecondLevelWrapper>
              : 
              []
              ))
            }
          </SubMenu>
          : []
        ))    
      }
    </>
    </SubMenu>
  );
};

export default StandardReportsMenu;