import React from "react";
import { RouteComponentProps } from "react-router";
import Looker from "../Looker/Looker";
import ExploreTiles from "./ExploreTiles";


interface MatchParams {
  lookId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  
}

const Explore: React.FC<IProps> = (props: IProps) => {

  return (
    <>
    {
      (props.match.params.lookId && props.match.params.lookId.length > 0) ?
      (
        <Looker embeddedPath={'/looks/' + props.match.params.lookId + "/explore"} windowTitle="Explore" />
      )
      :
      (
        <ExploreTiles/>
      )
    }
    </>
  );
};

export default Explore;
