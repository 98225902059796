import { Alert, Layout, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import IFrame from "../../components/IFrame/IFrame";
import { API } from "../../services/LookerAPI/LookerAPI";
import { IApplicationState } from "../../store/Store";
import "../Looker/Looker.scss";



interface IProps {
  embeddedPath: string;
}

const MainDashboard: React.FC<IProps> = ({
  embeddedPath,
}) => {

  const [embeddedUrl, setEmbeddedUrl] = React.useState<string>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>(null);

  React.useEffect(() => {
    const setResult = (result: any) =>{
      setEmbeddedUrl(result.url);
    }
    const setError = (error: Error) =>{
      setErrorMessage("An error occurred retrieving the URL for the requested content from Looker.");
    }
    API.getSsoEmbeddedUrl(embeddedPath,setResult,setError);
  }, [embeddedPath]);

  React.useEffect(() => {
    document.title = 'At A Glance';
  }, []);

  return (
    <div className="full-height-minus-header">
      <Row
        style={{
          background: "white",
          height: "100%",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        {embeddedUrl ? (
          <IFrame embeddedUrl={embeddedUrl} />
        ) : errorMessage ? (
          <Layout.Content style = {{ padding: "32px 64px"  }} > 
            <Alert 
                style={{ marginBottom: "24px"  }}
                type="error"
                message={errorMessage} 
              />
          </Layout.Content>
        ) : (
          <CustomSpinner />
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);
