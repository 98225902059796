import { Button, Modal } from 'antd'
import React from 'react'
import { IDrugListHeader } from '../../../services/CustomAdherenceDrugListManagement/CustomAdherenceDrugListModels'
import './DeleteListModal.scss'

interface IDeleteListModalProps {
    list: IDrugListHeader
    deleteList: (listname: string) => string
    setDeleting: (isDeleting: boolean, list: IDrugListHeader, confirmation: boolean) => void
}

const DeleteListModal: React.FC<IDeleteListModalProps> = (props: IDeleteListModalProps) => {

    return (
        <Modal
            visible={true}
            title={`Delete List - "${props.list.drug_list_name}"`}
            onCancel={() => props.setDeleting(false, null, false)}
            footer={[
                <Button onClick={() => props.setDeleting(false, null, false)}>Cancel</Button>,
                <Button onClick={() => {
                    props.setDeleting(false, props.list, true)
                    props.deleteList(props.list.drug_list_name)
                }} type='primary' danger>Delete</Button>
            ]}>
                <h4>Are you sure you want to delete the List "{props.list.drug_list_name}"?</h4>
                <br />
                <div className="modal-list-delete-warning">
                    This action will:
                    <ul>
                        <li>Delete this List from the application</li>
                        <li>Remove any filters that use this List in any schedules</li> 
                    </ul>
                </div>
                <br />
                This <b>CANNOT</b> be undone.
        </Modal>
    )
}

export default DeleteListModal