export enum FieldType {
  DEFAULT,
  SINGLE_VALUE,
  DISCRETE_VALUES,
  RANGE,
  INTERVAL,
  RANGE_AND_INTERVAL,
  INVALID,
  UNKNOWN,
}

export enum ScheduleType {
  DAILY,
  WEEKLY,
  MONTHLY,
  EVERY_HOUR,
  HOURLY_RANGE,
  HOURLY_INTERVAL,
  BY_MINUTE,
  UNKNOWN = "Unknown Schedule Type",
}

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAY_NAMES = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export class CronFields {
  //constructor
  constructor(cron: string) {
    let _fields: string[];
    this.cron = cron;
    _fields = cron.split(" ");
    if (!(_fields.length === 5)) {
      console.log("ERROR cron string must contain 5 fields: ", cron);
      this.fields = [];
    } else {
      this.fields = _fields;
      this.minuteField = new Field(_fields[0]);
      this.hourField = new Field(_fields[1]);
      this.dayOfMonthField = new Field(_fields[2]);
      this.monthOfYearField = new Field(_fields[3]);
      this.dayOfWeekField = new Field(_fields[4]);
    }
  }

  cron: string;
  fields: string[];
  minuteField: Field | null = null;
  hourField: Field | null = null;
  dayOfMonthField: Field | null = null;
  monthOfYearField: Field | null = null;
  dayOfWeekField: Field | null = null;

  hourRangeText: string = "";
  hourIntervalText: string = "";
  hourAndMinuteText: string = "";
  dayOfMonthText: string = "";
  monthOfYearText: string = "";
  dayOfWeekText: string = "";
  fullText: string = "";

  getFullText(): string {
    this.doFindScheduleType();
    return this.fullText;
  }

  // These are schedule types:
  //   DAILY,
  //   WEEKLY,
  //   MONTHLY,
  //   EVERY_HOUR,
  //   HOURLY_RANGE,
  //   HOURLY_INTERVAL,
  //   BY_MINUTE,
  //   UNKOWN_TYPE,
  doFindScheduleType(): ScheduleType {
    let schedType: ScheduleType = ScheduleType.UNKNOWN;
    this.doMinuteAndHour() || // This call needed in most cases.
      this.doMinuteOnly(); // this call may be needed.
    if (this.doMonth()) {
      this.doDayOfMonth();
      schedType = ScheduleType.MONTHLY;
      this.fullText =
        this.monthOfYearText +
        " " +
        this.dayOfMonthText +
        " at " +
        this.hourAndMinuteText;
    } else if (this.doDayOfMonth()) {
      schedType = ScheduleType.MONTHLY;
      this.fullText =
        "Monthly " + this.dayOfMonthText + " at " + this.hourAndMinuteText;
    } else if (this.doDaysOfWeek()) {
      schedType = ScheduleType.WEEKLY;
      this.fullText = this.hourAndMinuteText + " " + this.dayOfWeekText;
    } else if (this.doByMinuteInterval()) {
      schedType = ScheduleType.BY_MINUTE;
      this.fullText = this.hourAndMinuteText + this.hourRangeText;
    } else if (this.doMinuteOnly()) {
      schedType = ScheduleType.EVERY_HOUR;
      this.fullText = this.hourAndMinuteText;
    } else if (this.doHourInterval()) {
      schedType = ScheduleType.HOURLY_INTERVAL;
      this.fullText = this.hourAndMinuteText + this.hourIntervalText;
    } else if (this.doHourRange()) {
      schedType = ScheduleType.HOURLY_RANGE;
      this.fullText = this.hourAndMinuteText + this.hourIntervalText + this.hourRangeText;
    } else if (this.doMinuteAndHour()) {
      schedType = ScheduleType.DAILY;
      this.fullText = this.hourAndMinuteText + " Daily";
    } else {
      this.fullText = "Unkown Schedule Type";
    }
    return schedType;
  }

  doByMinuteInterval(): boolean {
    // Repeat at minute interval with or without an hour range.
    if (this.minuteField?.fieldType === FieldType.INTERVAL) {
      const minuteInterval: number = this.minuteField?.repeatInterval as number;
      this.hourAndMinuteText = `Every ${minuteInterval} minutes `;
      if (this.hourField?.fieldType === FieldType.RANGE) {
        // like: { cron: "*/15 12-13 * * *", text: "Every 15 minutes from 12:00 PM to 1:45 PM" },
        const fromHour = this.hourField?.rangeMin as number;
        const toHour = this.hourField?.rangeMax as number;
        this.setMinuteIntervalTimeRange(minuteInterval, fromHour, toHour);

      } else {
        // like: { cron: "*/5 * * * *", text: "Every 5 minutes" },
      }
      return true;
    }
    return false;
  }

  setMinuteIntervalTimeRange(
    minuteInterval: number,
    fromHour: number,
    toHour: number,
    ): void {
      const lastIntervalMinutes: number = Math.floor( (60 - 1) / minuteInterval) * minuteInterval;
      const fromTime = this.formatHourAndMinute(fromHour, 0);
      const toTime = this.formatHourAndMinute(toHour, lastIntervalMinutes);
      this.hourRangeText = `from ${fromTime} to ${toTime}`;
  }

  doHourInterval(): boolean {
    // Hour Interval without range
    if (this.hourField?.fieldType === FieldType.INTERVAL) {
      // like: { cron: "0 */4 * * *", text: "On the hour every 4 hours" },
      // like: { cron: "35 */4 * * *", text: "35 minutes past the hour every 4 hours," },
      this.setSingleTimeWithinHour();

      const interval = this.hourField?.repeatInterval as number;
      this.hourIntervalText = ` every ${interval} hours`;

      return true;
    }
    return false;
  }

  doHourRange(): boolean {
    // Hour Range with or without repeating interval
    if (this.hourField?.fieldType === FieldType.RANGE_AND_INTERVAL) {
      // like: { cron: "30 12-23/4 * * *", text: "30 minutes past the hour every 4 hours from 12:30 PM to 8:30 PM" },
      this.setSingleTimeWithinHour();
      
      const interval = this.hourField?.repeatInterval as number;
      this.hourIntervalText = ` every ${interval} hours `;
      const minutes = this.minuteField?.singleValue as number;
      const from = this.hourField?.rangeMin;
      const to = this.hourField?.rangeMax;
      this.setHourRangeText(minutes, interval, from, to);

      return true;
    } else if (this.hourField?.fieldType === FieldType.RANGE) {
      // like: { cron: "25 9-15 * * *", text: "25 minutes past the hour from 9:25 AM to 3:25 PM" },
      // like: { cron: "0 19-23 * * *", text: "On the hour from 7:00 PM to 11:00 PM" },
      this.setSingleTimeWithinHour();

      this.hourIntervalText = " ";
      const interval = 1;
      const minutes = this.minuteField?.singleValue as number;
      const from = this.hourField?.rangeMin;
      const to = this.hourField?.rangeMax;
      this.setHourRangeText(minutes, interval, from, to);

      return true;
    }
    return false;
  }

  setHourRangeText(
    minutes: number,
    interval: number,
    from: number,
    to: number
    ) : void {
      this.hourRangeText = "";

      // It the repeating interval does not fit within the hour range,
      // the schedule colapses to 1 occurence per day.
      if(interval > (to - from)) {
        const hour: number = from;
        this.hourAndMinuteText = this.formatHourAndMinute(hour, minutes) + " Daily";
        this.hourIntervalText = "";
        this.hourRangeText = "";
        return;
      }
      // Otherwise at least 2 repetitions fit within the hour range.
      const repetitions = Math.floor( (to - from) / interval );
      const lastHour = from + repetitions * interval;
      const fromTime = this.formatHourAndMinute(from, minutes);
      const toTime = this.formatHourAndMinute(lastHour, minutes);
      this.hourRangeText = `from ${fromTime} to ${toTime}`;
    }

  doMinuteOnly(): boolean {
    // Hourly, every 1 hour, no time range.
    // Every hour with at most minute offset.
    if (
      this.minuteField?.fieldType === FieldType.SINGLE_VALUE &&
      this.hourField?.fieldType === FieldType.DEFAULT
    ) {
      // Hour will only default for patterns repeating every hour.
      this.setSingleTimeWithinHour();

      return true;
    }
    return false;
  }

  setSingleTimeWithinHour(): void {
    // like: { cron: "0 * * * *", text: "On the hour" },
      // like: { cron: "55 * * * *", text: "55 minutes past the hour" },
      if (this.minuteField?.singleValue === 0) {
        this.hourAndMinuteText = "On the hour";
      } else {
        this.hourAndMinuteText = `${this.minuteField?.singleValue} minutes past the hour`;
      }
  }

  doDayOfMonth(): boolean {
    if (this.dayOfMonthField?.fieldType === FieldType.SINGLE_VALUE) {
      const day = this.dayOfMonthField.singleValue;
      let dayText = "on the ";
      if (day === 1) {
        dayText += "1st";
      } else if (day === 2) {
        dayText += "2nd";
      } else if (day === 3) {
        dayText += "3rd";
      } else {
        dayText += "" + day + "th";
      }
      this.dayOfMonthText = dayText;
      return true;
    }
    return false;
  }

  doDaysOfWeek(): boolean {
    if (this.fields[4] === "6,0" || this.fields[4] === "0,6") {
      this.dayOfWeekText = "on weekends";
      return true;
    } else if (this.fields[4] === "1,2,3,4,5") {
      this.dayOfWeekText = "on weekdays";
      return true;
    }
    let values: number[] = [];
    let haveDays: boolean = false;
    if (this.dayOfWeekField?.fieldType === FieldType.SINGLE_VALUE) {
      values.push(this.dayOfWeekField.singleValue);
      haveDays = true;
    } else if (this.dayOfWeekField?.fieldType === FieldType.DISCRETE_VALUES) {
      values = this.dayOfWeekField.discreteValues;
      haveDays = true;
    }
    if (haveDays) {;
      let items: string = "every ";
      let i: number;
      for (i = 0; i < values.length; i++) {
        if (values[i] < 0 || values[i] > 6) {
          console.log("ERROR: Day number out of range = ", values[i]);
          continue;
        }
        if (i > 0) {
          if (i + 1 === values.length) {
            items += " & ";
          } else {
            items += ", ";
          }
        }
        items += DAY_NAMES[values[i]];
      }
      this.dayOfWeekText = items;
    }
    return haveDays;
  }

  doMonth(): boolean {
    let haveMonths: boolean = false;
    let monthValues: number[] = [];
    if (this.monthOfYearField?.fieldType === FieldType.SINGLE_VALUE) {
      monthValues.push(this.monthOfYearField.singleValue);
      haveMonths = true;
    } else if (this.monthOfYearField?.fieldType === FieldType.DISCRETE_VALUES) {
      monthValues = this.monthOfYearField.discreteValues;
      haveMonths = true;
    }
    if (haveMonths) {
      let months: string = "";
      let i: number;
      for (i = 0; i < monthValues.length; i++) {
        if (monthValues[i] < 1 || monthValues[i] > 12) {
          console.log("ERROR: Month number out of range = ", monthValues[i]);
          continue;
        }
        if (i > 0) {
          if (i + 1 === monthValues.length) {
            months += " & ";
          } else {
            months += ", ";
          }
        }
        months += MONTH_NAMES[monthValues[i] - 1];
      }
      this.monthOfYearText = months;
    }
    return haveMonths;
  }

  doMinuteAndHour(): boolean {
    // This method finds the time of day for date oriented schedules.
    if (
      this.minuteField?.fieldType === FieldType.SINGLE_VALUE &&
      this.hourField?.fieldType === FieldType.SINGLE_VALUE
    ) {
      // At one specific time of day, every day or other day constraints.
      this.hourAndMinuteText = this.formatHourAndMinute(
        this.hourField.singleValue,
        this.minuteField.singleValue
      );
      return true;
    }
    return false;
  }

  formatHourAndMinute(hour: number, minute: number): string {
    // Assume hour in range 0-23
    // Coerce hour to integer
    hour = parseInt("" + hour);
    if (hour < 0 || hour > 23) {
      return "Bad Time Value";
    }
    let period: string = "AM";
    if (hour >= 12) {
      period = "PM";
    }
    if (hour === 0) {
      hour = 12;
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    const minuteText = pad(minute, 2);
    const hourText = "" + hour;

    return `${hourText}:${minuteText} ${period}`;
  }
} // end class CronFields

const pad = (num: number, size: number): string => {
  var s = "000000000" + num;
  return s.substr(s.length - size);
};

export class Field {
  singleValue: number = 0;
  discreteValues: number[] = [];
  rangeMin: number = 0;
  rangeMax: number = 0;
  repeatInterval: number = 0;
  fieldType: FieldType = FieldType.UNKNOWN;

  // constructor
  constructor(field: string) {
    if (checkDefault(field)) {
      this.fieldType = FieldType.DEFAULT;
    } else if (checkSingleValue(field)) {
      this.fieldType = FieldType.SINGLE_VALUE;
      this.singleValue = parseInt(field);
    } else if (checkDiscreteValues(field)) {
      this.fieldType = FieldType.DISCRETE_VALUES;
      const numbers: number[] = convertDiscreteValues(field);
      this.discreteValues = numbers;
    } else if (checkInterval(field)) {
      this.fieldType = FieldType.INTERVAL;
      const slashPosition = field.indexOf("/");
      this.repeatInterval = parseInt(field.substring(slashPosition + 1));
    } else if (checkRange(field)) {
      this.fieldType = FieldType.RANGE;
      const dashPosition = field.indexOf("-");
      const minString = field.substring(0, dashPosition);
      const maxString = field.substring(dashPosition + 1);
      this.rangeMin = parseInt(minString);
      this.rangeMax = parseInt(maxString);
    } else if (checkRangeAndInterval(field)) {
      this.fieldType = FieldType.RANGE_AND_INTERVAL;
      const dashPosition = field.indexOf("-");
      const slashPosition = field.indexOf("/");
      const minString = field.substring(0, dashPosition);
      const maxString = field.substring(dashPosition + 1, slashPosition);
      this.rangeMin = parseInt(minString);
      this.rangeMax = parseInt(maxString);
      this.repeatInterval = parseInt(field.substring(slashPosition + 1));
    } else {
      this.fieldType = FieldType.INVALID;
    }
  }
}

export const convertDiscreteValues = (field: string): number[] => {
  let numbers: number[] = [];
  const tokens: string[] = field.split(",");
  // console.log("convertDiscreteValues: tokens = "+tokens);
  let token: string;
  let jj: number;
  for(jj = 0; jj < tokens.length; jj++) {
    token = tokens[jj];
    // console.log("next token = "+token);
    if(checkRange(token)) {
      // console.log("range token = "+token);
      const dashPosition = token.indexOf("-");
      const minString = token.substring(0, dashPosition);
      const maxString = token.substring(dashPosition + 1);
      const min = parseInt(minString);
      const max = parseInt(maxString);
      // console.log("min = " + min + " max = "+ max);
      if(max <= 12 && max > min) {
        let i: number;
        for (i = min; i <= max; i++) {
          numbers.push(i);
        }
      }
    } else {
      // console.log("single token = "+token);
      numbers.push(parseInt(token));
    }
  }
  // console.log("returning numbers = "+numbers);
  return numbers;
}

export const checkDefault = (field: string): boolean => {
  return field === "*";
};

export const checkSingleValue = (field: string): boolean => {
  return !!field.match(/^[0-9]+$/);
};

export const checkDiscreteValues = (field: string): boolean => {
  if(checkRange(field)) return false;
  // return !!field.match(/^[0-9]+(,[0-9]+)*$/);
  return !!field.match(/^[0-9]+(-[0-9]+)?(,[0-9]+(-[0-9]+)?)*$/);
};

export const checkInterval = (field: string): boolean => {
  return !!field.match(/^\*\/[0-9]+$/);
};

export const checkRange = (field: string): boolean => {
  return !!field.match(/^[0-9]+-[0-9]+$/);
};

export const checkRangeAndInterval = (field: string): boolean => {
  return !!field.match(/^[0-9]+-[0-9]+\/[0-9]+$/);
};
