/* eslint-disable */
import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, notification } from "antd";
import React from "react";
import { API } from "../../../services/LookerAPI/LookerAPI";
import "./FavoritesItemOptionsMenu.scss"

interface IFavoritesItemOptionsMenu{
    favoriteId: string;
    reloadFavorites: () => void;
}

const FavoritesItemOptionsMenu: React.FC<IFavoritesItemOptionsMenu> = ({favoriteId, reloadFavorites}) => {

    const stopPropagation = (e) =>{
        e.stopPropagation();
    }

    const openNotification = (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description,
        placement: "topRight",
        duration: 3
      });
    };

    const deleteFavorite = async (favoriteId: string) => {
        const setResult = (result: any) => {
          openNotification('Success', 'Successfully removed from favorites', 'success');
          reloadFavorites();
        }
        const setError = (error: Error) => {
          openNotification('Error', 'An unexpected error occurred removing from favorites', 'error');
        }
        API.deleteFavorite(favoriteId, setResult, setError);
    }
    
    const itemMoreOptions = (favoriteId: string) => (
        <Menu className="favorites-content-options-menu">
          {
            <Menu.Item 
              onClick={(event) =>{
                    deleteFavorite(favoriteId);
                }
              }>
              Remove from Favorites...
            </Menu.Item>
          }
        </Menu>
    );

  return (
    <div onClick={stopPropagation} style={{ display:"inline"}}>
        <Dropdown 
          overlay={itemMoreOptions(favoriteId)} 
          trigger={['click']}
        >
        <Button 
            shape="circle" 
            size="large"
            type="text" 
            icon={<MoreOutlined style={{ fontSize: "30px", fontWeight: "bolder"}}  />} />          
        </Dropdown>
    </div>
  );
};
 
export default FavoritesItemOptionsMenu;
  