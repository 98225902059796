import { Reducer } from 'redux'

import { ISettingsState, SettingsActions, SettingsActionsTypes } from '../actions/SettingsActionsTypes';

const initialSettingsState: ISettingsState = {
    lookerContentInfo: null,
    printDesignerData: null,
    standardReports: null,
    standardReportsMap: null,
    standardReportsError: null,   
    customerSettings: null,
    customerSettingsError: null,
    selectedExploreTab: "EnterpriseRx",
};

export const settingsReducer: Reducer<
    ISettingsState,
    SettingsActions
> = (state = initialSettingsState, action): ISettingsState => {
    switch (action.type) {
        case SettingsActionsTypes.SET_LOOKER_CONTENT_INFO: {
            return {
                ...state,
                lookerContentInfo: {
                    type: action.contentType,
                    lookerId: action.lookerId,
                    embedUrl: action.embedUrl
                }
            }
        }
        case SettingsActionsTypes.SET_PRINT_DESIGNER_DATA: {
            return {
                ...state,
                printDesignerData: action.printDesignerData
            }
        }
        case SettingsActionsTypes.GET_STANDARD_REPORTS: {
            let errMessage = (state.standardReportsError != null && action.standardReportsError != null && state.standardReportsError.trim()===action.standardReportsError.trim()) ? 
            (state.standardReportsError + ' ') : action.standardReportsError;
            return {
                ...state,
                standardReports: action.standardReports,
                standardReportsMap: action.standardReportsMap,
                standardReportsError: errMessage
            }
        }
        case SettingsActionsTypes.GET_CUSTOMER_SETTINGS: {
            let settingsErrMessage = (state.customerSettingsError != null && action.customerSettingsError != null && state.customerSettingsError.trim()===action.customerSettingsError.trim()) ? 
            (state.customerSettingsError + ' ') : action.customerSettingsError;
            return {
                ...state,
                customerSettings: action.customerSettings,
                customerSettingsError: settingsErrMessage
            }
        }
        case SettingsActionsTypes.SET_LAST_EXPLORE_TAB: {
            return {
                ...state,
                selectedExploreTab: action.selectedExploreTab
            }
        }
        default: {
            return state;
        }
    }
};