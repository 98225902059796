import { Reducer } from 'redux'
import { IMessageState, MessageActions, MessageActionsTypes } from '../actions/MessageActionsTypes';

const initialSettingsState: IMessageState = {
    messages: [],
    looksForLargeLookMessage: []
};

export const messageReducer: Reducer<
    IMessageState,
    MessageActions
> = (state = initialSettingsState, action): IMessageState => {
    switch (action.type) {
        case MessageActionsTypes.DISPLAY_MESSAGE: {
            if(action.message == null || action.message.disabled) {
                return state;
            }
            return {
                ...state,
                messages: [...state.messages, action.message]
            }
        }
        case MessageActionsTypes.LARGE_LOOK_MESSAGE_FOR_LOOK: {
            if(state.looksForLargeLookMessage.includes(action.lookId) || action.message.disabled){
                return state;
            }
            return {
                ...state,
                looksForLargeLookMessage: [...state.looksForLargeLookMessage, action.lookId],
                messages: [...state.messages, action.message]
            }
        }
        case MessageActionsTypes.CLEAR_FIRST_MESSAGE: {
            return {
                ...state,
                messages: state.messages.filter((value,index) => index > 0)
            }
        }
        default: {
            return state;
        }
    }
};