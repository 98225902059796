import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { getCurrentEmbedUserId, getGroupUsernames, getRootFolderIds } from "../../services/FolderManagement/FolderManagement";
import { IFolderViewTypes, IEmbedUsername, IDisplayFolderItem, IAncestorInfo } from "../../services/FolderManagement/FolderModels";
import { FoldersActions, FoldersActionTypes, IFoldersState, IGetGroupMemberNamesAction, ISetEmbedUserAction, ISetFoldersAction, ISetFolderViewAction, ISetFolderHistoryViewAction, ISetFolderCurrentItemAction, ISetMoveStartFolderAction } from "./FoldersTypes";



const getRootFolderInfo = (realm: string, ignoreError: boolean): Promise<ISetFoldersAction> => {
  let rootFoldersError: string = null;

  return getRootFolderIds(realm).then(
    (rootFolders) => {
      const action: FoldersActions = {
        type: FoldersActionTypes.SET_FOLDERS,
        rootFolders: rootFolders,
        errorMessage: rootFoldersError
      };
      return action;
    }, () => {
      if(!ignoreError){
        rootFoldersError = "There was an error loading root folder information.";
      }
      // ensure to return a value in this case too
      // you might want to create an error action instead
      const action: FoldersActions = {
        type: FoldersActionTypes.SET_FOLDERS,
        rootFolders: null,
        errorMessage: rootFoldersError
      };
      return action;
    });
};

const getUser = async (): Promise<ISetEmbedUserAction> => {
  let userId: string = null;
  const setResult = (id: string) => {
    userId = id;
  }
  await getCurrentEmbedUserId(setResult, () => {});
  const action: FoldersActions =
  {
    type: FoldersActionTypes.SET_EMBED_USER,
    userId: userId,
  };
  return action;
}

const getGroupMemberNamesInfo = async (): Promise<IGetGroupMemberNamesAction> => {
  let groupMemberNames: IEmbedUsername[] = null;
  let groupMemberNameMap: Map<string, IEmbedUsername> = new Map<string, IEmbedUsername>();
  const setGroupUserames = (usernames: IEmbedUsername[]) => {
    groupMemberNames = usernames;
    groupMemberNames.forEach(username => {
      groupMemberNameMap.set(username.id, username);
    });
  }
  await getGroupUsernames(setGroupUserames, () => {});
  const action: FoldersActions =
  {
    type: FoldersActionTypes.GET_GROUP_MEMBER_NAMES,
    groupMemberNameMap: groupMemberNameMap,
  };
  return action;
}


export const getRootFolders: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IFoldersState,
  null,
  ISetFoldersAction
>> = (realm: string, ignoreError: boolean = false) => {
  return async (dispatch: Dispatch) => {
    const action = await getRootFolderInfo(realm, ignoreError);
    return dispatch(action);
  };
};

export const getEmbedUser: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IFoldersState,
  null,
  ISetEmbedUserAction
>> = () => {
  return async (dispatch: Dispatch) => {
    const action = await getUser();
    return dispatch(action);
  };
};

export const setFolderView: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IFoldersState,
  null,
  ISetFolderViewAction
>> = (view: IFolderViewTypes) => {
  return async (dispatch: Dispatch) => {
    const action: FoldersActions = {
      type: FoldersActionTypes.SET_FOLDERS_VIEW,
      viewType: view
    }
    return dispatch(action);
  };
};

export const setFolderHistoryView: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IFoldersState,
  null,
  ISetFolderHistoryViewAction
>> = (view: IFolderViewTypes) => {
  return async (dispatch: Dispatch) => {
    const action: FoldersActions = {
      type: FoldersActionTypes.SET_FOLDERS_HISTORY_VIEW,
      historyViewType: view
    }
    return dispatch(action);
  };
};

export const setCurrentItemAndAncestors: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IFoldersState,
  null,
  ISetFolderCurrentItemAction
>> = (item: IDisplayFolderItem, ancestors: IAncestorInfo[]) => {
  return async (dispatch: Dispatch) => {
    const action: FoldersActions = {
      type: FoldersActionTypes.SET_CURRENT_ITEM,
      item: item,
      ancestors: ancestors,
    }
    return dispatch(action);
  };
};

export const getGroupMemberNames: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IFoldersState,
  null,
  IGetGroupMemberNamesAction
>> = () => {
  return async (dispatch: Dispatch) => {
    const action = await getGroupMemberNamesInfo();
    return dispatch(action);
  };
};

export const setMoveStartFolder: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IFoldersState,
  null,
  ISetMoveStartFolderAction
>> = (item: IDisplayFolderItem) => {
  return async (dispatch: Dispatch) => {
    const action: FoldersActions = {
      type: FoldersActionTypes.SET_MOVE_START_FOLDER,
      item: item
    }
    return dispatch(action);
  };
};