import { TeamOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAlertInfo, LookerContentType } from "../../../AppModels";
import {
  IGroupInfo,
  IUserInfo,
} from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import { API } from "../../../services/LookerAPI/LookerAPI";
import {
  getCustomGroups,
  getLicensedUsers,
} from "../../../store/actions/EditGroupsActions";
import { ILookerContentInfo } from "../../../store/actions/SettingsActionsTypes";
import { IApplicationState } from "../../../store/Store";
import "./SetGroupHomePage.scss";

const { Option } = Select;
const { Text, Paragraph } = Typography;

interface ISetGroupHomePageProps {
  openNotification: (
    message: string,
    description: string,
    type: string
  ) => void;
  lookerContentInfo: ILookerContentInfo;
  realm: string;
  resetMode: boolean;
  exit: () => void;
}

const SetGroupHomePage: React.FC<ISetGroupHomePageProps> = ({
  openNotification,
  lookerContentInfo,
  realm,
  resetMode,
  exit,
}) => {

  const customGroups: IGroupInfo[] = useSelector<IApplicationState>(
    (state) => state.editGroups.customGroupDetails
  ) as IGroupInfo[];
  const licensedUsers: IUserInfo[] = useSelector<IApplicationState>(
    (state) => state.editGroups.licensedUsers
  ) as IUserInfo[];
  const [alertInfo, setAlertInfo] = React.useState<IAlertInfo>({
    visible: false,
    type: "warning",
    message: "",
    description: "",
  });

  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [groupOptions, setGroupOptions] = React.useState<React.ReactNode[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>("");

  const dispatch = useDispatch();

  useEffect(() => {

    const getContentTitle = (type: LookerContentType) => {
      const showResult = (result: any) => {
        setTitle(result.title);
      }
  
      const showError = (error: Error) => {
        console.log(error);
        setTitle(lookerContentInfo.type);
      }
  
      switch (lookerContentInfo.type) {
         case LookerContentType.LOOK: {
          API.getLookTitle(lookerContentInfo.lookerId, showResult, showError); 
          
          break;
         }
         case LookerContentType.DASHBOARD: {
           API.getDashboardTitle(lookerContentInfo.lookerId, showResult, showError);
           
          break;
         }
         case LookerContentType.EXPLORE: {
          let exploreUrl: string = lookerContentInfo.embedUrl;
           let modelName: string = exploreUrl.substring(exploreUrl.indexOf("explore/")+ "explore/".length, exploreUrl.lastIndexOf("/"));
           let exploreName: string = exploreUrl.substring(exploreUrl.lastIndexOf("/")+1, exploreUrl.length);
           API.getExploreTitle(modelName, exploreName, showResult, showError);
           break;
         }
         default: {
           console.log(lookerContentInfo.type)
         }
      }
    }

    if (lookerContentInfo) {
      getContentTitle(lookerContentInfo.type as LookerContentType)
    }
  }, [lookerContentInfo])

  useEffect(() => {
    if (lookerContentInfo) {
      dispatch(getCustomGroups());
      dispatch(getLicensedUsers());
    }
  }, [lookerContentInfo, dispatch]);

  const setGroupHomePage = async (group) => {
    const showResult = (result: any) => {
      setAlertInfo({
        visible: true,
        type: "success",
        message: `Home Page was successfully ${resetMode ? "reset" : "updated"} for group "${group}"`,
        description: "",
      });
    };

    const showError = (error: Error) => {
      console.log(error);
      setAlertInfo({
        visible: true,
        type: "error",
        message: `There was a problem ${resetMode ? "resetting" : "updating"} the Home Page for group "${group}"`,
        description: "",
      });
    };

    setLoading(true);
    await API.setGroupHomePage(
      resetMode ? "" : lookerContentInfo.type,
      resetMode ? "" : lookerContentInfo.lookerId,
      resetMode ? "" : lookerContentInfo.embedUrl,
      realm + "@" + group,
      showResult,
      showError
    );
    setLoading(false);
    setSelectedGroup(null);
  };

  const handleGroupSelectChange = (option) => {
    setSelectedGroup(option);
  };

  const closeGroupHomePageModal = () => {
    exit();
  };

  const onAlertClose = () => {
    setAlertInfo({
      visible: false,
      type: "success",
      message: "",
      description: "",
    });
  };

  useEffect(() => {
    if (licensedUsers && customGroups) {
      let groups = [];

      // object to store every group along with user counts. initialized with ExploreUsers and ViewUsers
      let memberCounts = {
        ViewUsers: licensedUsers.filter(
          (user) => user.holdingLicense && user.group === "ViewUsers"
        ).length,
        ExploreUsers: licensedUsers.filter(
          (user) => user.holdingLicense && user.group === "ExploreUsers"
        ).length,
        AdminUsers: licensedUsers.filter(
          (user) => user.holdingLicense && user.group === "AdminUsers"
        ).length,
      };

      // adds each custom group along with user count to memberCounts object
      for (let group of customGroups) {
        memberCounts[group.groupName] = group.totalUniqueUsers;
      }

      // adds candidate groups to the dropdown if they aren't already in the currentPermissions
      for (let group in memberCounts) {
        groups.push(
          <Option value={group} key={group}>
            <Row gutter={[15, 0]}>
              <Col span={2}>
                <TeamOutlined />
              </Col>
              <Col span={14}>
                <Paragraph
                  ellipsis={{ tooltip: true }}
                  style={{ width: "100%" }}
                >
                  {group}
                </Paragraph>
              </Col>
              <Col span={6}>
                <Text>
                  {memberCounts[group]}{" "}
                  {memberCounts[group] === 1 ? "user" : "users"}
                </Text>
              </Col>
            </Row>
          </Option>
        );
      }
      setGroupOptions(groups);
    }
  }, [customGroups, licensedUsers]);

  return (
    <>
      <Modal
        title={resetMode ? "Reset Group Home Page" : `Set ${lookerContentInfo.type} "${title}" as a Group Home Page`}
        visible={true}
        onOk={setGroupHomePage}
        okText={"Yes"}
        onCancel={closeGroupHomePageModal}
        width={550}
        footer={
          <Button type="primary" onClick={exit}>
            Close
          </Button>
        }
      >
        <Text className="group-homepage-modal-header">
          Select a group to {resetMode ? "reset" : "set"} the Home Page
        </Text>
        <Divider style={{ marginBottom: 0, marginTop: 10 }} />
        {alertInfo.visible && (
          <Alert
            type={alertInfo.type}
            message={alertInfo.message}
            description={alertInfo.description}
            closable
            onClose={onAlertClose}
          />
        )}
        <Row style={{ width: "100%", margin: 0 }} gutter={24}>
            <Col span={16}>
                <div className="grp-homepage-centered">
                <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Select group..."
                    optionLabelProp="label"
                    onChange={handleGroupSelectChange}
                    value={selectedGroup}
                    loading={!customGroups}
                    disabled={!customGroups || loading}
                >
                    {groupOptions}
                </Select>
                </div>
            </Col>
            <Col span={8}>
                <div className="grp-homepage-centered" style={{ float: "right" }}>
                <Button
                    type="primary"
                    loading={loading}
                    disabled={!(selectedGroup)}
                    onClick={() => setGroupHomePage(selectedGroup)}
                >
                    {resetMode ? "Reset" : "Set"} Home Page
                </Button>
                </div>
            </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SetGroupHomePage;
