import { Alert, Button, Col, Layout, Popconfirm, Row, Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IAlertInfo } from '../../AppModels';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import { API } from '../../services/LookerAPI/LookerAPI';
import { getAlerts, getAlertsAdmin } from '../../services/Schedules/Alerts';
import { ILookerAlertInfo } from '../../services/Schedules/AlertsModels';
import './AlertsPage.scss';
import DeleteAlertsItem from './DeleteAlertsItem/DeleteAlertsItem';

interface IProps {
    admin?: boolean;
    username?: string;
}

const AlertsPage: React.FC<IProps> = (props: IProps) => {

    const MAX_RECIPIENTS_DISPLAY = 3;
    const ITEMS_PER_PAGE = 20;
    
    const [alert, setAlert] = React.useState<IAlertInfo>({type: 'success', message: '', description: '', visible: false}); 
    const [alerts, setAlerts] = React.useState<ILookerAlertInfo[]>([]);
    const [alertToDelete, setAlertToDelete] = React.useState<ILookerAlertInfo>(null);
    const [alertsLoading, setAlertsLoading] = React.useState<boolean>(true);

    const history = useHistory();

    
    const clearAlert = () =>{
        setAlert(null);
    }

    const deleteAlert = (alert: ILookerAlertInfo) => {
        setAlertToDelete(alert);
    }

    const cancelDelete = () => {
        setAlertToDelete(null)
    }

    const refreshAlerts = () => {

        const setResult = (result: ILookerAlertInfo[]) => {
            setAlerts(result);
            setAlertsLoading(false);
        }
    
        const setError = (error: Error) => {
            setAlert({
                type: 'error',
                message: 'There was an unexpected error retrieving Alerts.',
                visible: true
            });
            setAlertsLoading(false);
        }

        setAlertsLoading(true);
        if (props.admin) getAlertsAdmin(props.username, setResult, setError);
        else getAlerts(setResult, setError);
    }

    React.useEffect(() =>{
        if(alert!=null){
          document.getElementById('app-main-content-pane').scrollTop = 0;
        }
    }, [alert]);

    React.useEffect(() => {
        refreshAlerts()
        document.title = 'Alerts';
         // eslint-disable-next-line
    }, [])

    return (
        <Layout>
            <div className="alerts-area">
            { !alertsLoading ?
            <Layout.Content className={!props.admin ? 'alerts-layout' : ''}>
                { !props.admin &&
                    <>
                        <br />
                        <Row>
                            <Col span={24}>
                                <h3>Alerts</h3>
                            </Col>
                        </Row>    
                        <br />
                    </>
                }
                { alert.visible &&
                    <Alert 
                    style={{ marginBottom: "24px" }}
                    type={alert.type}
                    message={alert.message}  
                    description={alert.description}
                    closable 
                    afterClose={clearAlert} 
                    />
                }
                <Table
                    dataSource={alerts}
                    rowKey={(alert) => alert.alert_id}
                    pagination={{
                        size:"small",
                        defaultPageSize: ITEMS_PER_PAGE,
                        hideOnSinglePage: true,
                        position: ["bottomRight"],
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} alerts`
                    }}
                >
                    <Column
                        title="ID"
                        key="alert_id"
                        dataIndex="alert_id"
                        align='center'
                        width="5%"
                        sorter={(a: ILookerAlertInfo, b: ILookerAlertInfo) => a.alert_id.localeCompare(b.alert_id)}
                    />
                    <Column
                        title="Name"
                        key="name"
                        width="30%"
                        sorter={(a: ILookerAlertInfo, b: ILookerAlertInfo) => a.name.localeCompare(b.name)}
                        render={(alert: ILookerAlertInfo) => {
                            if (alert.dashboard_element_id && !props.admin) {
                                return <Button style={{paddingLeft: 0}} type='link' onClick={() => {

                                    const navigateToContent = (result: any) => {
                                        history.push("/dashboards/" + result['dashboard_id']);
                                    }

                                    const setError = (error: Error) => {
                                        setAlert({
                                            type: 'error',
                                            message: 'There was an unexpected error retrieving the dashboard.',
                                            visible: true
                                        });
                                        setAlertsLoading(false);
                                    }

                                    setAlertsLoading(true);
                                    API.getDashboardElement(alert.dashboard_element_id, "id,dashboard_id", navigateToContent, setError);
                                }}><u>{alert.name}</u></Button>
                            } else {
                                return alert.name;
                            }
                        }}
                    />
                    <Column
                        title="Frequency"
                        key="crontab_description"
                        dataIndex="crontab_description"
                        width="15%"
                    />
                    <Column
                        title="Recipients"
                        key="recipients"
                        width="25%"
                        render={(alert: ILookerAlertInfo) => {
                            let recipients = '', i;
                            for (i = 0; i < alert.destinations.length; i++) {
                                if (alert.destinations[i].destination_type === 'EMAIL') {
                                    if (i === MAX_RECIPIENTS_DISPLAY) {
                                        recipients += '& ' + (alert.destinations.length - MAX_RECIPIENTS_DISPLAY) + ' more';
                                        break;
                                    }
                                    recipients += alert.destinations[i].email_address + ', ';
                                }
                            }
                            if (alert.destinations.length < MAX_RECIPIENTS_DISPLAY && recipients.length > 0) recipients = recipients.substring(0, recipients.length-2);
                            return <span>{recipients}</span>
                        }}
                    />
                    <Column
                        title={() => <Tooltip placement='top' title='Condition met on Last Run'>Condition Met</Tooltip>}
                        key="condition_met"
                        dataIndex='condition_met'
                        align='center'
                        width="10%"
                    />
                    <Column
                        title="Status"
                        key="status"
                        align='center'
                        width="10%"
                        render={(alert: ILookerAlertInfo) => {
                            return (
                                <Tooltip placement='top' title={alert.status_reason}>
                                    {alert.status}
                                </Tooltip>
                            )
                        }}
                    />
                    <Column
                        title="Delete"
                        key="delete"
                        align='center'
                        width="5%"
                        render={(alert: ILookerAlertInfo) => {
                            if (props.admin) {
                                return (
                                    <Popconfirm
                                        placement="top"
                                        title="Are you sure you want to delete this alert?"
                                        onConfirm={() => {
                                            const deleteComplete = () => {     
                                                refreshAlerts();
                                                setAlert({
                                                    type: "success",
                                                    message:  'Alert successfully deleted.',
                                                    visible: true
                                                });
                                            }
                                            const deleteError = () => {        
                                                setAlert({
                                                    type: "error",
                                                    message: 'An unexpected error occured attempting to delete alert.',
                                                    visible: true
                                                });
                                            }
                                            API.deleteAlertAdmin(alert.alert_id, props.username, deleteComplete, deleteError);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                        icon={null}
                                        style={{ width: '20px' }}
                                        overlayClassName='remove-btn-container'>
                                            <Button type="primary" danger>Delete</Button>
                                    </Popconfirm>
                                )
                            } else {
                                return <Button type="primary" danger onClick={() => deleteAlert(alert)}>Delete</Button>
                            }
                        }}
                    />
                </Table>
                { alertToDelete &&
                    <DeleteAlertsItem 
                    alert={alertToDelete}
                    showAlert={setAlert}
                    triggerAlertsRefresh={refreshAlerts}
                    exit={cancelDelete}
                    />
                }
            </Layout.Content>
            :
            <Layout.Content className='alerts-layout-loading'>
                <CustomSpinner />
            </Layout.Content>
            }
            </div>
        </Layout>
    )
}

export default AlertsPage;