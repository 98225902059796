import { Button, Modal } from "antd";
import React from "react";
import { ensureScheme } from "../../../services/CustomUtils";
import { useDispatch } from "react-redux";
import { setNotificationStatus } from "../../../store/actions/NotificationActions";
import { INotification } from "../../../services/NotificationsManagement/NotificationsModels";

interface ILinkConfirmModalProps {
    link: string;
    notification: INotification;
    exit: () => void;
    getIsReadStatus: (notification: INotification) => boolean;
}

const LinkConfirmModal: React.FC<ILinkConfirmModalProps> = (props: ILinkConfirmModalProps) => {

    const dispatch = useDispatch();

    const closeModal = () =>{
        props.exit();
    }

  return (
    <>
    <Modal
        title="Leaving EnterpriseRx Data Insights"
        open={true}
        onOk={closeModal}
        okText={'Proceed'}
        onCancel={closeModal}
        width={650}
        footer={[
            <Button key="cancel" onClick={(e) => closeModal()}>
              Cancel
            </Button>,
            <Button
            type="primary" // Makes it look like a regular link
            href={ensureScheme(props.link)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              // Mark the notification as read when the link is clicked
              if (!props.getIsReadStatus(props.notification)) {
                dispatch(
                  setNotificationStatus(
                    props.notification.notificationId,
                    true
                  )
                );
              }
              closeModal()
            }}
            >
              Continue
            </Button>,
          ]}
    >      
    <>
        <h4>WARNING: You are leaving EnterpriseRx Data Insights</h4>
        <br />
        <>
        <div className="modal-user-delete-warning">
            This action will open a new tab and navigate to the following link: {props.link}
        </div>
        </>
    </>
    </Modal>
    </>
  );
};
 
export default LinkConfirmModal;
  