import { Alert, Col, Divider, Layout, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { IAlertInfo } from "../../AppModels";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { recentlyRunReports } from "../../services/FolderManagement/FolderManagement";
import { IContentDisplayTypes, IDashboardInfo, IDisplayFolderItem, IDisplayFolderItemType, ILookInfo } from "../../services/FolderManagement/FolderModels";
import { IApplicationState } from "../../store/Store";
import ReportsContentContainer from "../ReportsFolder/ReportsContentContainer/ReportsContentContainer";

interface IProps {
}

const ReportHistory: React.FC<IProps> = () => {
  const [dashboards, setDashboards] = React.useState<IDashboardInfo[]>(null);
  const [looks, setLooks] = React.useState<ILookInfo[]>(null);
  const [alert, setAlert] = React.useState<IAlertInfo>(null); 
  const history = useHistory();

  const setError = (error: Error) => {
    setAlert({
      type: "error",
      message: "There was an error loading Recently Viewed Reports. Please try again later."
    })
    //Recently Reviewed Reports currently uses a System Activity view that only allows one connection at at time, so intermittent errors are subject to occurring 
  }

  React.useEffect(() => {
    recentlyRunReports(setDashboards, setLooks, setError);
    document.title = 'Recently Viewed Reports';
  }, []);

  const goToItem = (item: IDisplayFolderItem)=>{
    if(item.type === IDisplayFolderItemType.LOOK){
      history.push('/looks/' + item.id);
    } else if(item.type === IDisplayFolderItemType.DASHBOARD){
      history.push('/dashboards/' + item.id);
    }
  };

  const clearAlert = () =>{
    setAlert(null);
  }

  return (
    <>
      <Layout>
        <div className="folder-content-area">
        {
          (dashboards != null || looks != null || alert != null) ?
          <Layout.Content 
            style = {{
              padding: "8px 64px 4px 64px"
            }}
          > 
            <br />
            <Row>
              <Col span={24}>
                <h3>Recently Viewed Reports</h3>
              </Col>
            </Row>    
            <Divider style={{ margin: "0px 0 36px" }} />
            { alert &&
              <Alert 
                style={{ marginBottom: "24px"  }}
                type={alert.type}
                message={alert.message}  
                description={alert.description}
                closable 
                afterClose={clearAlert} 
              />
            }
            <Row>
              { ((dashboards && dashboards.length > 0)) &&
                  <Col span={14}>
                    <h3>Dashboards</h3>
                  </Col>
              } 
              {(dashboards && dashboards.length > 0) &&
                (
                  <ReportsContentContainer 
                    type={IDisplayFolderItemType.DASHBOARD}
                    data={dashboards}
                    display={IContentDisplayTypes.HISTORY}
                    rootFolders={null}
                    folder={null}
                    setAction={null}
                    goToItem={goToItem}
                  />
                )
              }
            </Row>
            <Row>
              { ((looks && looks.length > 0)) &&
                  <Col span={14}>
                    <h3>Looks</h3>
                  </Col>
              } 
              {(looks && looks.length > 0) &&
                (
                  <ReportsContentContainer 
                    type={IDisplayFolderItemType.LOOK}
                    data={looks}
                    display={IContentDisplayTypes.HISTORY}
                    rootFolders={null}
                    folder={null}
                    setAction={null}
                    goToItem={goToItem}
                  />
                )
              }
            </Row>
          </Layout.Content>
          : 
          <Layout.Content
            style={{ height: "85hv" , width: "100%"}}
          >
            <CustomSpinner />
          </Layout.Content>
        }
      </div>
    </Layout>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportHistory);
