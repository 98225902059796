import { Modal } from "antd";
import React from "react";
import { IAlertInfo } from "../../AppModels";
import { API } from "../../services/LookerAPI/LookerAPI";
import { IScheduleInfo } from "../../services/Schedules/ScheduleModels";

interface IDeleteFolderItemContainerProps {
    schedule: IScheduleInfo;
    triggerFolderRefresh: () => void;
    showAlert: (alertInfo: IAlertInfo) => void;
    exit: () => void;
}

const DeleteScheduleItem: React.FC<IDeleteFolderItemContainerProps> = (props: IDeleteFolderItemContainerProps) => {

    const closeDeleteModal = () => {
        props.exit();
    }
        
    const deleteComplete = () => {     
        props.triggerFolderRefresh();   
        props.showAlert({
            type: "success",
            message:  'Schedule successfully deleted.',
        });
    }

    const deleteError = () => {        
      props.showAlert({
          type: "error",
          message: 'An unexpected error occured attempting to delete schedule.',
      });
    }

    const deleteScheduleItem = () => {
        API.deleteScheduledPlan(props.schedule.id, deleteComplete, deleteError);
        props.exit();
    }


  return (
    <>
    <Modal
        title={'Delete "' + props.schedule.name + '"?'}
        visible={true}
        onOk={deleteScheduleItem}
        okText={'Delete Schedule'}
        onCancel={closeDeleteModal}
        width={650}
    >      
    <>
        <h4>Are you sure you want to delete the schedule "{props.schedule.name}"?</h4>
        <br />
        This <b>CANNOT</b> be undone.
    </>
    </Modal>
    </>
  );
};
 
export default DeleteScheduleItem;
  