import { useOktaAuth } from '@okta/okta-react';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import * as React from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import { postOktaLogin } from '../../../store/actions/UserAuthActions';
import { IFederatedPromptCredentials, IFederatedPromptState } from "../../../store/actions/UserAuthTypes";

interface IProps {
  federatedPrompt: IFederatedPromptState;
}

const FederatedLinkageModal: React.FC<IProps> = ({ federatedPrompt }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm<IFederatedPromptCredentials>();
  const [existingUser, setExistingUser] = React.useState<string>(federatedPrompt.existingCustomer ? "yes" : "no");
  const { authState } = useOktaAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  
  const handleExistingUserChange = (value: string) => {
    setExistingUser(value);
  };

  const processForm = (values: IFederatedPromptCredentials) =>{
    setLoading(true);
    dispatch(postOktaLogin(authState, values));
  }

  React.useEffect(()=>{
    setLoading(false);
  },[federatedPrompt]);


  return (
    <>
      <Modal
        title={'New Federated Login'}
        visible={true}
        width={500}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={()=>{history.push("/"); window.location.reload();}}
        maskClosable={false}
      >     
        <Row>
          <div style={{textAlign: "center", marginBottom: "20px", marginTop: "10px", color: "black", fontWeight: "bold"}}>
          <div>*NEW FEDERATED LOGIN DETECTED*</div>
            { federatedPrompt.existingCustomer &&
              <>
                <div>
                  If you are an existing Data Insights User, you must complete this step accurately to maintain access to your existing reports and schedules.
                </div>
              </>
            }
            { federatedPrompt.erxOpenAm &&
              <>
                <div>
                  {federatedPrompt.existingCustomer ? 'Additionally, ' : '' } Records show this realm utilizes Open AM for EnterpriseRx Login. 
                  Please provide your Open AM Credentials so the appropriate scope of control is linked to this account. 
                </div>
              </>
            }
          </div>
        </Row>
        <Row>
          <Col span={3}></Col>
          <Col span={18}>
          <Form
              form={form}
              initialValues={{
                  existingUser: federatedPrompt.existingCustomer ? "yes" : "no",
                  username: null,
                  password: null,
                  realm: federatedPrompt.realm
                }
              }
              layout="vertical"
              name="federated_linkage"
              className="login-form"
              requiredMark={false}
              onFinish={processForm}
          >
            <Form.Item
              name="existingUser"
              label="Are you an existing Data Insights User?"
              className="login-label"
              hidden={!federatedPrompt.existingCustomer}
            >
              <Select 
                onChange={handleExistingUserChange}
              >
                <Select.Option value="yes">Yes</Select.Option>
                <Select.Option value="no">No</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="username"
              label={"Open AM" + (federatedPrompt.existingCustomer ? '/Former' : '') + " User Name"}
              className="login-label"
              rules={[{ required: existingUser === "yes" || federatedPrompt.erxOpenAm, message: 'Please enter your Open AM Username.' }]}
              hidden={!(existingUser === "yes" || federatedPrompt.erxOpenAm)}
              
            >
              <Input className="login-input" />
            </Form.Item>
            <Form.Item
              name="password"
              label={"Open AM" + (federatedPrompt.existingCustomer ? '/Former' : '') + " Password"}
              className="login-label"
              rules={[{ required: existingUser === "yes" || federatedPrompt.erxOpenAm, message: 'Please enter your Open AM Password.' }]}
              hidden={!(existingUser === "yes" || federatedPrompt.erxOpenAm)}
            >
              <Input
                className="login-input" 
                type="password"
              />
            </Form.Item>
            <Form.Item
              name="realm"
              label="Realm"
              className="login-label"
            >
              <Input
                  className="login-input" 
                  disabled
              />
            </Form.Item>
            { federatedPrompt.errorMessage &&
              <div className="alert alert-danger">
                <div role="alert">{federatedPrompt.errorMessage}</div>
              </div>
            }

            <Form.Item style={{ marginBottom: "0px"}}>
              <Button 
                  type="primary" 
                  htmlType="submit" 
                  className="login-form-button login-button-wide" 
                  loading={loading}
                >
                Complete New Login Configuration
              </Button>
            </Form.Item>
          </Form>
          </Col>
          <Col span={3}></Col>
        </Row>
      </Modal>
    </>
  );
};

export default FederatedLinkageModal;
