import { Button, Modal, Table, Typography, Divider, Alert, Tooltip } from "antd";
import React from "react";
import Column from "antd/lib/table/Column";
import {
  ILastRunStatus,
  IScheduleInfo,
} from "../../../services/Schedules/ScheduleModels";
import "./LastRunStatus.scss";
import { getScheduleLastRunStatus } from "../../../services/Schedules/Schedules";
import { IAlertInfo } from "../../../AppModels";
import { RedoOutlined } from "@ant-design/icons";

interface IProps {
  clearModal(): void;
  scheduleInfo: IScheduleInfo;
}

const { Text  } = Typography;

const LastRunStatus: React.FC<IProps> = ({ scheduleInfo, clearModal }) => {
  const [lastRuns, setLastRuns] = React.useState<ILastRunStatus[]>(null);
  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const [alert, setAlert] = React.useState<IAlertInfo>(null); 

  const refreshLastRuns = () => {
    setLastRuns(null);
    if(scheduleInfo && scheduleInfo.id){
      const setResult = (result: any) => {
        setLastRuns(result);
        setRefreshing(false);
      }
      const setError = ()=> {
        setAlert({
          type: "error",
          message: "An unexpected error occurred loading Last Run Status Information."
        })
        setLastRuns([]);
        setRefreshing(false);
      }
      setRefreshing(true);
      getScheduleLastRunStatus(scheduleInfo.id, setResult, setError);
    }  
  }

  React.useEffect(() => {
    if(scheduleInfo && scheduleInfo.id){
      const setResult = (result: any) => {
        setLastRuns(result);
      }
      const setError = ()=> {
        setAlert({
          type: "error",
          message: "An unexpected error occurred loading Last Run Status Information."
        })
        setLastRuns([]);
      }
      getScheduleLastRunStatus(scheduleInfo.id, setResult, setError);
    }    
  }, [scheduleInfo]);

  return (
    <Modal
      title={`Last Run Details`}
      visible={true}
      onCancel={() => {
          clearModal();
        }}
      width={750}
      footer={
        <Button type="primary" onClick={() => {
            clearModal();
            }}>
          OK
        </Button>
      }
    >
      <Text className="last-run-modal-header">
        Details for up to the last twenty runs { scheduleInfo ? (' of "' + scheduleInfo.name + '"') : ''}
      </Text>
      <Tooltip title='Refresh'>
        <Button style={{float: 'right'}} shape='circle' type='primary' disabled={refreshing} icon={<RedoOutlined spin={refreshing}/>} onClick={refreshLastRuns}/>
      </Tooltip>
      <Divider />
      { alert &&
          <Alert 
            style={{ marginBottom: "24px"  }}
            type={alert.type}
            message={alert.message}  
            description={alert.description}
            closable 
            afterClose={()=>{setAlert(null)}} 
          />
        }
      <Table dataSource={lastRuns}
                      loading={lastRuns == null}
                      rowKey={(record) => record.last_run_at}
                      pagination={{
                        size:"small",
                        defaultPageSize: 5,
                        hideOnSinglePage: true,
                        position: ["bottomRight"],
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} runs`
                      }}>
        <Column
          title="Status"
          key="schedule_status"
          dataIndex="schedule_status"
          width="5%"
          render={(value, record) => {
            let jsonRecord = record['schedule_status_detail'] && record['schedule_status_detail'].includes(`"status": `) ? JSON.parse(record['schedule_status_detail']) : null;
            if (jsonRecord && jsonRecord['status']) {
              return jsonRecord['status'];
            } else {
              return value ? value.charAt(0).toUpperCase() + value.slice(1) : '';
            }
          }}
        />
        <Column
          title="Details"
          key="schedule_status_detail"
          dataIndex="schedule_status_detail"
          width="75%"
          render={(value, record) => {
            let jsonRecord = record['schedule_status_detail'] && record['schedule_status_detail'].includes(`"status": `) ? JSON.parse(record['schedule_status_detail']) : null;
            if (record['schedule_status'] && record['schedule_status'].toLowerCase() === 'in_progress') {
              return "In Progress.";
            }
            if (jsonRecord && jsonRecord['status']) {
              return `runId: ${jsonRecord['runId']} locationUrl: ${jsonRecord['locationUrl']}`;
            } else {
              if (record['schedule_status'] && record['schedule_status'].toLowerCase() === 'success') {
                return "Completed Successfully.";
              }
              else return value ? value : "";
            }
            
          }}
        />
        <Column
          title="Last Ran"
          key="last_run_at"
          dataIndex="last_run_at"
          width="20%"
          render={(value) => {
            var date = new Date(value + " UTC");
            return date.toLocaleString()
          }}
        />
      </Table>
    </Modal>
  );
};

export default LastRunStatus;
