import { Button } from "antd";
import React from "react";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";

interface IProps {
    userInfo: IUserInfo;
    setDeleteUser: (deleteUser: IUserInfo) => void;
}

const DeleteUser: React.FC<IProps> = (props: IProps) => {

    return (
        <>
            <Button 
                type="primary" 
                onClick={()=> { props.setDeleteUser(props.userInfo);}}
                danger
            >
                Delete
            </Button>
            
        </>
    );
};

export default DeleteUser;