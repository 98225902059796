import { Alert, Layout, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import IFrame from "../../components/IFrame/IFrame";
import { API } from "../../services/LookerAPI/LookerAPI";
import { IApplicationState } from "../../store/Store";
import "./Looker.scss";
import { IDisplayFolderItem } from "../../services/FolderManagement/FolderModels";
import { useWindowDimensions } from "../../services/CustomHooks";


interface IProps {
  embeddedPath: string;
  cssClassOverride?: string;
  windowTitle?: string;
  refreshCount?: number;
  contentChangeFunction?: (content: IDisplayFolderItem) => void;
}

const Looker: React.FC<IProps> = ({
  embeddedPath,
  cssClassOverride,
  windowTitle,
  refreshCount,
  contentChangeFunction
}) => {

  const [embeddedUrl, setEmbeddedUrl] = React.useState<string>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>(null);

  const {height, width} = useWindowDimensions(); //can be used to get page width and height

  React.useEffect(()=>{
    if(windowTitle != null){
      document.title = windowTitle;
      console.log(embeddedPath);
      console.log(embeddedUrl);
    }
  }, [windowTitle, embeddedPath, embeddedUrl])
  
  React.useEffect(() => {
    setEmbeddedUrl(null);
    const setResult = (result: any) =>{
      setEmbeddedUrl(result.url);
    }
    const setError = (error: Error) =>{
      setErrorMessage("An error occurred retrieving the URL for the requested content from Looker.");
    }
    API.getSsoEmbeddedUrl(embeddedPath,setResult,setError);
  }, [embeddedPath, refreshCount]);
  
  //style={{ height: heightOverride == null ? "90vh" : (heightOverride + "vh") }}>
  return (
    <div className={cssClassOverride == null ? "full-height-minus-header" : cssClassOverride}>
      <Row
        style={{
          background: "white",
          height: width < 767 && height < width ? '200%' : '100%',
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        {embeddedUrl ? (
          <IFrame embeddedUrl={embeddedUrl} contentChangeFunction={contentChangeFunction} />
        ) : errorMessage ? (
          <Layout.Content style = {{ padding: "32px 64px"  }} > 
            <Alert 
                style={{ marginBottom: "24px"  }}
                type="error"
                message={errorMessage} 
              />
          </Layout.Content>
        ) : (
          <CustomSpinner />
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {

  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Looker);
