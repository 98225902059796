import React, { useEffect } from "react";
import { Alert, Button, Space, Table } from "antd";
import { ColumnsType } from 'antd/es/table'
import { IDocument } from '../../../services/DocumentationManagement/DocumentationModels'
import { IAlertInfo } from "../../../AppModels";

interface IProps {
    documents: IDocument[];
    setLoading(isLoading: boolean): void;
    setAlertInfo(alertInfo: IAlertInfo);
    loading: boolean;
}

const MiscDocumentationView: React.FC<IProps> = (props: (IProps & IAlertInfo)) => {

    const [documents, setDocuments] = React.useState([]);

    const onAlertClose = () => {
        props.setAlertInfo({
            visible: false,
            type: 'warning',
            message: '',
            description: ''
        })
    }

    useEffect(() => {
        const data = [];
        let key = 0;
        for (let i of props.documents) {
            if (i.category === 'Miscellaneous') {
                data.push({
                    key: key,
                    title: String(i.title).replaceAll('.', '/'),
                    updated: i.updated,
                    pdfUrl: i.pdfUrl,
                });
                key++;
            }
        }
        setDocuments(data)
    }, [props.documents])

    const columns: ColumnsType<IDocument> = [
        { 
            title: 'Title', 
            dataIndex: 'title', 
            key: 'title', 
            width: '90%',
        },
        {
            title: 'Updated',
            dataIndex: 'updated',
            key: 'updated',
            align: 'center',
            width: '5%'
        },
        {
            title: 'Action',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
            width: '5%',
            render: (item, record, index) => (
            <Space size="middle">
                <Button className='view-document-btn' onClick={() => window.open(record.pdfUrl, "_blank")}>View</Button>
            </Space>
            ),
        },
    ];

    return (
        <>
            {
            props.visible &&
            <Alert
                style={{ marginBottom: "24px"  }}
                type={props.type}
                message={props.message}  
                description={props.description}
                closable 
                onClose={onAlertClose}/>
            }
            <Table<IDocument>
                rowKey='name'
                columns={columns}
                dataSource={documents}
                loading={props.loading}
                pagination={false}
            />
        </>
    );
};

export default MiscDocumentationView;