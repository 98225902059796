import { IRootFolderIds, IFolderViewTypes, IEmbedUsername, IDisplayFolderItem, IAncestorInfo } from "../../services/FolderManagement/FolderModels";

export enum FoldersActionTypes {
  SET_FOLDERS = "FOLDERS_ACTION/SET",
  SET_EMBED_USER = "FOLDERS_ACTION/SET_USER",
  SET_FOLDERS_VIEW = "FOLDERS_ACTION/SET_VIEW",
  SET_FOLDERS_HISTORY_VIEW = "FOLDERS_ACTION/SET_HISTORY_VIEW",
  GET_GROUP_MEMBER_NAMES = "FOLDERS_ACTION/GET_GROUP_MEMBER_NAMES",
  SET_CURRENT_ITEM = "FOLDERS_ACTION/SET_CURRENT_ITEM",
  SET_MOVE_START_FOLDER = "FOLDER_ACTIONS/SET_MOVE_START_FOLDER"
}

export type FoldersActions =
  | ISetFoldersAction
  | ISetEmbedUserAction
  | ISetFolderViewAction
  | ISetFolderHistoryViewAction
  | IGetGroupMemberNamesAction
  | ISetFolderCurrentItemAction
  | ISetMoveStartFolderAction;

export interface ISetFoldersAction {
  type: FoldersActionTypes.SET_FOLDERS;
  rootFolders: IRootFolderIds;
  errorMessage: string;
}

export interface ISetEmbedUserAction {
  type: FoldersActionTypes.SET_EMBED_USER;
  userId: string;
}

export interface ISetFolderViewAction {
  type: FoldersActionTypes.SET_FOLDERS_VIEW;
  viewType: IFolderViewTypes;
}

export interface ISetFolderHistoryViewAction {
  type: FoldersActionTypes.SET_FOLDERS_HISTORY_VIEW;
  historyViewType: IFolderViewTypes;
}

export interface IGetGroupMemberNamesAction {
  type: FoldersActionTypes.GET_GROUP_MEMBER_NAMES;
  groupMemberNameMap: Map<string,IEmbedUsername>;
}

export interface ISetFolderCurrentItemAction {
  type: FoldersActionTypes.SET_CURRENT_ITEM;
  item: IDisplayFolderItem;
  ancestors: IAncestorInfo[];
}

export interface ISetMoveStartFolderAction {
  type: FoldersActionTypes.SET_MOVE_START_FOLDER,
  item: IDisplayFolderItem;
}

export interface IFoldersState {
  readonly rootFolders: IRootFolderIds;
  readonly rootFoldersError: string;
  readonly userId: string;
  readonly viewType: IFolderViewTypes;
  readonly historyViewType: IFolderViewTypes;
  readonly groupMemberNameMap: Map<string,IEmbedUsername>;
  readonly currentItem: IDisplayFolderItem;
  readonly currentAncestors: IAncestorInfo[];
  readonly moveStartFolder: IDisplayFolderItem;
}
