import { Alert, Col, Layout, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { IAlertInfo } from "../../AppModels";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { getFavorites } from "../../services/FolderManagement/FolderManagement";
import { IContentDisplayTypes, IDashboardInfo, IDisplayFolderItem, IDisplayFolderItemType, ILookInfo } from "../../services/FolderManagement/FolderModels";
import ReportsContentContainer from "../ReportsFolder/ReportsContentContainer/ReportsContentContainer";

interface IProps {
}

const Favorites: React.FC<IProps> = () => {
  const [dashboards, setDashboards] = React.useState<IDashboardInfo[]>(null);
  const [looks, setLooks] = React.useState<ILookInfo[]>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const [alertInfo, setAlertInfo] = React.useState<IAlertInfo>({visible: false, message: '', type: 'error', description: ''})

const history = useHistory();

const reloadFavorites = async () => {
    const setResult = (data: any) => {
        setDashboards(data['dashboards']);
        setLooks(data['looks']);
        setLoading(false)
    }
    const setError = (error: Error) => {
        setAlertInfo({
            visible: true,
            message: 'An unexpected error occurred loading favorites',
            type: 'error',
            description: ''
        })
        setLoading(false)
    }
    setLoading(true);
    await getFavorites(setResult, setError);
}

React.useEffect(() => {
    const setResult = (data: any) => {
        setDashboards(data['dashboards']);
        setLooks(data['looks']);
        setLoading(false)
    }
    const setError = (error: Error) => {
        setAlertInfo({
            visible: true,
            message: 'An unexpected error occurred loading favorites',
            type: 'error',
            description: ''
        })
        setLoading(false)
    }
    
    if (!looks || !dashboards) {
        setLoading(true);
        document.title = 'Favorites';
        getFavorites(setResult, setError);
    }
}, [dashboards, looks])

  const goToItem = (item: IDisplayFolderItem)=>{
    
      if(item.type === IDisplayFolderItemType.LOOK){
        history.push('/looks/' + item.id);
      } else if(item.type === IDisplayFolderItemType.DASHBOARD){
        history.push('/dashboards/' + item.id);
      }
  };

  const clearAlert = () =>{
    setAlertInfo({visible: false, type: 'error', message: '', description: ''});
  }

  return (
    <>
      <Layout>
        <div className="folder-content-area">
        {
          (!loading) ?
          <Layout.Content 
            style = {{
              padding: "8px 64px 4px 64px"
            }}
          > 
            <br />
            <Row>
              <Col span={24}>
                <h3>Favorites</h3>
                {(dashboards && dashboards.length === 0) && (looks && looks.length === 0) ? "You currently have no favorites.": null}
              </Col>
            </Row>    
            <br />
            { alertInfo.visible &&
              <Alert 
                style={{ marginBottom: "24px"  }}
                type={alertInfo.type}
                message={alertInfo.message}  
                description={alertInfo.description}
                closable 
                afterClose={clearAlert} 
              />
            }
            <Row>
            { ((dashboards && dashboards.length > 0)) &&
                <Col span={14}>
                  <h3>Dashboards</h3>
                </Col>
            } 
            {(dashboards && dashboards.length > 0) &&
              (
                <ReportsContentContainer 
                  type={IDisplayFolderItemType.DASHBOARD}
                  data={dashboards}
                  display={IContentDisplayTypes.FAVORITES}
                  rootFolders={null}
                  folder={null}
                  setAction={null}
                  goToItem={goToItem}
                  reloadFavorites={reloadFavorites}
                />
              )
            }
            </Row>
            <Row>
              { ((looks && looks.length > 0)) &&
                  <Col span={14}>
                    <h3>Looks</h3>
                  </Col>
              } 
              {(looks && looks.length > 0) &&
                (
                  <ReportsContentContainer 
                    type={IDisplayFolderItemType.LOOK}
                    data={looks}
                    display={IContentDisplayTypes.FAVORITES}
                    rootFolders={null}
                    folder={null}
                    setAction={null}
                    goToItem={goToItem}
                    reloadFavorites={reloadFavorites}
                  />
                )
              }
            </Row>
          </Layout.Content>
          : 
          <Layout.Content
            style={{ height: "85hv" , width: "100%"}}
          >
            <CustomSpinner />
          </Layout.Content>
        }
      </div>
    </Layout>
    </>
  );
};

export default Favorites;