import { API } from "../LookerAPI/LookerAPI";
import { CronFields } from "./CronFields";
import { ILastRunStatus, IRunOnceScheduleInfo } from "./ScheduleModels";

export const getScheduledPlans = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const setResultDescribeCron = (result: any) => {
    const plans = result as object[];
    const plansUpdated = plans.map((x) => {
        const crontab = x["crontab"];
        const cronFields = new CronFields(crontab);
        const crontabDescription = cronFields.getFullText();
      return { ...x, crontab_description: crontabDescription };
    });
    setResult(plansUpdated);
  };
  API.getScheduledPlans(setResultDescribeCron, setError);
};

export const getScheduledPlansAdmin = async (
  username: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const setResultDescribeCron = (result: any) => {
    const plans = result as object[];
    const plansUpdated = plans.map((x) => {
        const crontab = x["crontab"];
        const cronFields = new CronFields(crontab);
        const crontabDescription = cronFields.getFullText();
      return { ...x, crontab_description: crontabDescription };
    });
    setResult(plansUpdated);
  };
  API.getScheduledPlansAdmin(username, setResultDescribeCron, setError);
};

export const getScheduleLastRunStatus = async (
  scheduledPlanId: string,
  setResult: (result: ILastRunStatus[]) => void,
  setError: (error: Error) => void
) => {
  const formatResult = (result: object[]) => {
    let formattedResult = result.map((value) => {
      return {
        schedule_plan_id: value['scheduled_plan.id'],
        user_name: value['user.name'],
        user_id: value['user.id'],
        schedule_status_detail: value['scheduled_job.status_detail'],
        schedule_status: value['scheduled_job.status'],
        last_run_at: value['scheduled_job.finalized_time']
      }
    })
    formattedResult = formattedResult.filter(element => element.last_run_at != null);
    setResult(formattedResult);
  }
  API.getScheduleLastRunStatus(scheduledPlanId, formatResult, setError);
}

export const getBulkScheduleLastRunStatus = async(
  scheduledPlanIds: string[],
  setResultMap: (result: Map<string, string>) => void,
  setError: (error: Error) => void
) => {
  const setResult = (result: object[]) =>{
    let resultMap = new Map<string, string>();
    result.forEach(schedule => {
      resultMap.set(String(schedule['scheduled_plan.id']), schedule['scheduled_job.status']);
    });
    setResultMap(resultMap);
  }
  API.getBulkScheduleLastRunStatus(scheduledPlanIds, setResult, setError);
}

export const getRunOnceScheduledPlans = async (
  setResult: (result: IRunOnceScheduleInfo[]) => void,
  setError: (error: Error) => void
) => {
  const parseResults = (result: object[]) => {
    const parsedResult = result.map((value) => {
      return {
        id: value['scheduled_plan.id'],
        name: value['scheduled_plan.name'],
        dashboard_id: value['scheduled_plan.dashboard_id'],
        look_id: value['scheduled_plan.look_id'],
        addresses: value['scheduled_plan.destination_addresses'],
        schedule_status_detail: value['scheduled_job.status_detail'],
        schedule_status: value['scheduled_job.status'],
        schedule_destination_type: value['scheduled_plan_destination.type'],
        schedule_destination_parameters: value['scheduled_plan_destination.parameters'],
        submitted_time: value['scheduled_plan.created_time']
      }
    })
    setResult(parsedResult);
  }
  API.getRunOnceScheduledPlans(parseResults, setError);
}
