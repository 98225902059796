import { AppstoreOutlined, CaretDownOutlined, DownOutlined, UnorderedListOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Col, Pagination, Select, Tooltip } from "antd";
import React from "react";
import { connect } from "react-redux";
import { IEditFolderPermissions } from "../../../services/AdminManagement/FolderPermissionsManagement/FolderPermissionsModels";
import { IContentDisplayTypes, IDashboardInfo, IDisplayFolderItem, IDisplayFolderItemType, IEmbedUsername, IFolderInfo, IFolderViewTypes, ILookInfo, IRootFolderIds, ReportsAction } from "../../../services/FolderManagement/FolderModels";
import { setFolderHistoryView, setFolderView } from "../../../store/actions/FolderActions";
import { IApplicationState } from "../../../store/Store";
import ReportsCardContainer from "../ReportsCardContainer/ReportsCardContainer";
import ReportsListContainer from "../ReportsListContainer/ReportsListContainer";

interface IProps {
    type: IDisplayFolderItemType;
    data: IDashboardInfo[] | ILookInfo[];
    rootFolders: IRootFolderIds;
    folder: IFolderInfo;
    viewType: IFolderViewTypes;
    historyViewType: IFolderViewTypes;
    display: IContentDisplayTypes;
    groupMemberNameMap: Map<string, IEmbedUsername>;
    editFolderPermissions?: IEditFolderPermissions;
    
    setFolderView: (view: IFolderViewTypes) => void;
    setFolderHistoryView: (view: IFolderViewTypes) => void;
    setAction: (action: ReportsAction) => void;
    goToItem: (item: IDisplayFolderItem) => void;
    reloadFavorites?: () => void;
}

enum SortTypes {
    NAME = "NAME",
    CREATED = "CREATED",
    UPDATED = "UPDATED",
    LAST_VIEWED = "LAST_VIEWED",
    LOCATION = "LOCATION",
}

const ReportsContentContainer: React.FC<IProps> = ({type, data, rootFolders, folder, viewType, historyViewType, 
    display, groupMemberNameMap, setFolderView, setFolderHistoryView, setAction, goToItem, reloadFavorites, editFolderPermissions}) => {

    const itemsPerPage = 20;
    const [firstDisplayIndex, setFirstDisplayIndex] = React.useState<number>(0);
    const [lastDisplayIndex, setLastDisplayIndex] = React.useState<number>(itemsPerPage);
    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [sortType, setSortType] = React.useState<SortTypes>(display === IContentDisplayTypes.HISTORY ? SortTypes.LAST_VIEWED : SortTypes.NAME);
    const [reverseSort, setReverseSort] = React.useState<boolean>(display === IContentDisplayTypes.HISTORY ? true : false);
    const [sortedData, setSortedData] = React.useState<IDashboardInfo[] | ILookInfo[]>([]);
    const [localViewType, setLocalViewType] = React.useState<IFolderViewTypes>(
        (display === IContentDisplayTypes.HISTORY || display === IContentDisplayTypes.SEARCH) ? historyViewType : viewType);

    const changePage = (page: number, pageSize: number) => {
        setFirstDisplayIndex((page-1) * pageSize);
        setLastDisplayIndex(page * pageSize);
        setPageNumber(page);
    }

    const reverseOrder = () => {
        setReverseSort(!reverseSort);
    }

    const switchView = (view: IFolderViewTypes) => {
        if(display === IContentDisplayTypes.HISTORY || display === IContentDisplayTypes.SEARCH){
            setFolderHistoryView(view);
        } else{
            setFolderView(view);
        }
    }

    const changeSortType = (newSortType : SortTypes) =>{
        setSortType(newSortType);
        setReverseSort(newSortType === SortTypes.LAST_VIEWED ? true : false);
    }

    const sortOptions = () => {
        let options = [<Select.Option className="report-sort-selector-item" key={SortTypes.NAME} value={SortTypes.NAME}>Sort By Name</Select.Option>]; 
        if(display === IContentDisplayTypes.HISTORY) {
            options.push(<Select.Option className="report-sort-selector-item" key={SortTypes.LAST_VIEWED} value={SortTypes.LAST_VIEWED}>Sort By Last Viewed Date</Select.Option>);
        } else if(display === IContentDisplayTypes.SEARCH) {
            options.push(<Select.Option className="report-sort-selector-item" key={SortTypes.LOCATION} value={SortTypes.LOCATION}>Sort By Location</Select.Option>);
        } else {
            if(type === IDisplayFolderItemType.LOOK){
                options.push(<Select.Option className="report-sort-selector-item" key={SortTypes.UPDATED} value={SortTypes.UPDATED}>Sort By Updated Date</Select.Option>)
            } else{
                options.push(<Select.Option className="report-sort-selector-item" key={SortTypes.CREATED} value={SortTypes.CREATED}>Sort By Created Date</Select.Option>)
            }
        }
        return options;
    }

    React.useEffect(()=>{
        if(display === IContentDisplayTypes.HISTORY || display === IContentDisplayTypes.SEARCH){
            setLocalViewType(historyViewType);
        } else{
            setLocalViewType(viewType);
        }
    },[display, viewType, historyViewType]);

    React.useEffect(()=>{
        //For now - history page only allows sort by recently viewed, everything else sort by name
        //This will get fleshed out in next sprint
        let dataArray = data.slice(); //copy, since sort reverses array in place
        if (sortType === SortTypes.LAST_VIEWED){
            if(!reverseSort){
                setSortedData(dataArray.sort((a,b)=> {
                    return a.last_viewed.localeCompare(b.last_viewed);
                }));
            } else {
                setSortedData(dataArray.sort((a,b)=> {
                    return b.last_viewed.localeCompare(a.last_viewed);
                }));
            }
        } else if (sortType === SortTypes.NAME) { 
            if(!reverseSort){
                setSortedData(dataArray.sort((a,b)=> {
                    return a.title.localeCompare(b.title);
                }));
            } else {
                setSortedData(dataArray.sort((a,b)=> {
                    return b.title.localeCompare(a.title);
                }));
            }
        } else if (sortType === SortTypes.CREATED){
            if(!reverseSort){
                setSortedData(dataArray.sort((a,b)=> {
                    return a.created_at.localeCompare(b.created_at);
                }));
            } else {
                setSortedData(dataArray.sort((a,b)=> {
                    return b.created_at.localeCompare(a.created_at);
                }));
            }
        } else if (sortType === SortTypes.UPDATED){
            if(!reverseSort){
                setSortedData(dataArray.sort((a,b)=> {
                    return a.updated_at.localeCompare(b.updated_at);
                }));
            } else {
                setSortedData(dataArray.sort((a,b)=> {
                    return b.updated_at.localeCompare(a.updated_at);
                }));
            }
        } else if (sortType === SortTypes.LOCATION) { 
            if(!reverseSort){
                setSortedData(dataArray.sort((a,b)=> {
                    return a.folder_name.localeCompare(b.folder_name);
                }));
            } else {
                setSortedData(dataArray.sort((a,b)=> {
                    return b.folder_name.localeCompare(a.folder_name);
                }));
            }
        }
    },[sortType, reverseSort, data, display]); 

  return (
    <>
        <Col span={10} style={{ textAlign:"right" }}>
            { display !== IContentDisplayTypes.FAVORITES ? 
            <Select
                className="report-sort-selector"
                value={sortType}
                onChange={changeSortType}
                showArrow={true}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: "none"}}/>}
            >
                { 
                    sortOptions()
                }
            </Select>
                : null}
            <Tooltip title="Sort Order">
                <Button
                    type="link" 
                    onClick={reverseOrder}
                    icon={ reverseSort ? 
                        <DownOutlined /> :
                        <UpOutlined />
                        }
                />
            </Tooltip>
            <Tooltip 
                title={localViewType === IFolderViewTypes.GRID ? 
                        'Show items in List' : 'Show items in Grid'}>
                <Button 
                    type="link"
                    onClick={() =>
                        switchView(localViewType === IFolderViewTypes.GRID ? 
                            IFolderViewTypes.LIST : IFolderViewTypes.GRID)}
                    icon={ localViewType === IFolderViewTypes.GRID  ? 
                        <UnorderedListOutlined /> :
                        <AppstoreOutlined /> 
                    }
                />
            </Tooltip>
        </Col>   
        <Col span={24}>        
            <br />
            {
                localViewType === IFolderViewTypes.GRID ? 
                <ReportsCardContainer 
                    type={type}
                    data={sortedData.slice(firstDisplayIndex, Math.min(lastDisplayIndex,sortedData.length))}
                    display={display}
                    rootFolders={rootFolders}
                    folder={folder}
                    setAction={setAction}
                    goToItem={goToItem}
                    groupMemberNameMap={groupMemberNameMap}
                    editFolderPermissions={editFolderPermissions}
                    reloadFavorites={reloadFavorites}
                />
                :
                <ReportsListContainer 
                    type={type}
                    data={sortedData.slice(firstDisplayIndex, Math.min(lastDisplayIndex,sortedData.length))}
                    display={display}
                    rootFolders={rootFolders}
                    folder={folder}
                    setAction={setAction}
                    goToItem={goToItem}
                    groupMemberNameMap={groupMemberNameMap}
                    editFolderPermissions={editFolderPermissions}
                    reloadFavorites={reloadFavorites}
                />
            }
            <Pagination
                className="report-pagination"
                size="small"
                current={pageNumber}
                style={{ textAlign: "right", paddingBottom: "32px"}}
                total={sortedData.length}
                showTotal={(total, range) => `${firstDisplayIndex+1}-${Math.min(lastDisplayIndex,sortedData.length)} 
                    of ${total} ${type.toLowerCase() + 's'}`}
                defaultPageSize={itemsPerPage}
                onChange={changePage}
                showSizeChanger={false}
                hideOnSinglePage
            />
            <br />
            <br />
        </Col>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
    return {
        viewType: state.folders.viewType,
        historyViewType: state.folders.historyViewType,
        groupMemberNameMap: state.folders.groupMemberNameMap,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        setFolderView: (viewType: IFolderViewTypes) => dispatch(setFolderView(viewType)),
        setFolderHistoryView: (viewType: IFolderViewTypes) => dispatch(setFolderHistoryView(viewType)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContentContainer);
