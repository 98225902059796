import { API } from "../../services/LookerAPI/LookerAPI";
import { IDataRefreshedDateTime } from "../DataMetricsManagement/DataMetricsModels";
import {
  IETLRunStatus,
  INotification,
  IRealmUser,
} from "./NotificationsModels";

export const sendNotifications = async (
  notifications: INotification[]
): Promise<INotification> => {
  return new Promise((resolve, reject) => {
    const setResult = (result: INotification) => {
      resolve(result);
    };

    const setError = (error: Error) => {
      reject(error);
    };

    API.sendNotifications(notifications, setResult, setError);
  });
};

export const editNotification = async (
  notification: INotification
): Promise<INotification> => {
  return new Promise((resolve, reject) => {
    const setResult = (result: INotification) => {
      resolve(result);
    };

    const setError = (error: Error) => {
      reject(error);
    };

    API.editNotification(notification, setResult, setError);
  });
};

export const getAllRealms = async (): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const setResult = (result: string[]) => {
      resolve(result);
    };

    const setError = (error: Error) => {
      reject(error);
    };

    API.getRealms(setResult, setError);
  });
};

export const getUsersInRealms = async (
  realms: string[]
): Promise<IRealmUser[]> => {
  return new Promise((resolve, reject) => {
    const setResult = (result: IRealmUser[]) => {
      resolve(result);
    };

    const setError = (error: Error) => {
      reject(error);
    };

    API.getUsersByRealms(realms, setResult, setError);
  });
};

export const getAllNotifications = async (): Promise<INotification[]> => {
  return new Promise((resolve, reject) => {
    const setResult = (result: INotification[]) => {
      resolve(result);
    };

    const setError = (error: Error) => {
      reject(error);
    };

    API.getAllNotifications(setResult, setError);
  });
};

export const getLastDataRefreshDateTime = async (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const setResult = (result: IDataRefreshedDateTime) => {
      const date = new Date(result.changesReadDateTime + "Z");
      const formattedDate = date.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
      resolve(formattedDate);
    };

    const setError = (error: Error) => {
      console.error(error);
      reject("Failed to retrieve...");
    };

    API.getDataLastRefreshDateTime(setResult, setError);
  });
};

export const getLastEtlCompletedDateTime = async (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const setResult = (result: IETLRunStatus) => {
      const date = new Date(result.runEndTs + "Z");
      const formattedDate = date.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
      resolve(formattedDate);
    };

    const setError = (error: Error) => {
      console.error(error);
      reject("Failed to retrieve...");
    };

    API.getLastEtlCompleted(setResult, setError);
  });
};