import { Reducer } from "redux";

import { filterChoices } from "../../containers/LookerFilterDemo/LookerFilters";

import {
  ILookerFilterState,
  LookerFilterActions,
  LookerFilterActionTypes,
} from "../actions/LookerFilterTypes";

const initialLookerFilterState: ILookerFilterState = {
  filterType: filterChoices,
  lookerContentType: null,
  facilityFilterPresent: false,
  facilityFilterList: null,
  corporateHierarchy: null,
  corporateHierarchyError: null,
};

export const lookerFilterReducer: Reducer<
  ILookerFilterState,
  LookerFilterActionTypes
> = (state = initialLookerFilterState, action): ILookerFilterState => {
  // console.log("lookerFilterReducer");
  // console.log("action = ", action);
  // console.log("action.type = " + action.type);
  switch (action.type) {
    case LookerFilterActions.SET_FILTER: {
      return {
        ...state,
        filterType: action.filter,
      };
    }
    case LookerFilterActions.SET_LOOKER_CONTENT_INFO: {
      return {
        ...state,
        lookerContentType: action.lookerContentType,
        facilityFilterPresent: action.facilityFilterPresent
      }
    }
    case LookerFilterActions.SET_FACILITY_FILTER_LIST: {
      return {
        ...state, 
        facilityFilterList: action.facilityFilterList
      }
    }
    case LookerFilterActions.GET_CORPORATE_HIERARCHY: {
      return {
        ...state,
        corporateHierarchy: action.corporateHierarchy,
        corporateHierarchyError: action.corporateHierarchyError
      }
    }
    default: {
      return state;
    }
  }
};
