import { Modal } from "antd";
import React from "react";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import "./DeleteUserConfirm.scss";
import { IAlertInfo } from "../../../AppModels";
import { API } from "../../../services/LookerAPI/LookerAPI";

interface IDeleteUserContainerProps {
    deleteUser: IUserInfo;
    refreshData: () => void;
    setAlertInfo: (alertInfo: IAlertInfo) => any;
    setLoading(isLoading: boolean): void;
    exit: () => void;
}

const DeleteUserConfirm: React.FC<IDeleteUserContainerProps> = (props: IDeleteUserContainerProps) => {

    const closeDeleteModal = () =>{
        props.exit();
    }

    const deleteUser = () => {
        props.setLoading(true);
        API.deleteUser(props.deleteUser.username, deleteComplete, deleteError);
        props.exit();
      }
    
    const deleteComplete = () => {    
        props.setAlertInfo({
            visible: true,
            type: "success",
            message:  "Successfully deleted user '" + props.deleteUser.displayName + "'.",
        });    
        //refreshData will set loading true again, and back to false when complete
        props.refreshData();
    }

    const deleteError = () => {   
      props.setLoading(false);     
      props.setAlertInfo({
          visible: true,
          type: "error",
          message: "There was an unexpected error attempting to delete user '" + props.deleteUser.displayName + "'.",
      });
  }

  return (
    <>
    <Modal
        title={'Delete ' + props.deleteUser.displayName + '?'}
        visible={true}
        onOk={deleteUser}
        okText={'Delete User'}
        onCancel={closeDeleteModal}
        width={650}
    >      
    <>
        <h4>Are you sure you want to delete the user "{props.deleteUser.displayName}"?</h4>
        <br />
            <>
            <div className="modal-user-delete-warning">
                This action will:
                <ul>
                    <li>Delete this user from the application</li>
                    <li>Delete any content saved in this user's 'My Reports'</li> 
                    <li>Delete any schedules belonging to this user</li>
                    <li>End this user's membership in any groups</li>
                </ul>
                {
                    props.deleteUser.holdingLicense &&
                    <>
                        This will remove the {props.deleteUser.license} License from this user and allow it to be reassigned.
                    </>
                }
            </div>
            <br />
            </>
        This <b>CANNOT</b> be undone.
    </>
    </Modal>
    </>
  );
};
 
export default DeleteUserConfirm;
  