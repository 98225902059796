import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { getCustomGroupDetails, getTotalUsers } from '../../services/AdminManagement/EditGroupsManagement/EditGroupsManagement'
import { 
    IGroupInfo,
    IUserInfo
 } from '../../services/AdminManagement/EditGroupsManagement/EditGroupsModels'
import { EditGroupsActions, EditGroupsActionTypes, IEditGroupsState, IGetCustomGroupDetailsAction, IGetLicensedUsersAction } from './EditGroupsTypes'

const getGroups = async (): Promise<IGetCustomGroupDetailsAction> => {
    let groupDetails: IGroupInfo[] = null;
    const setResult = (groups: IGroupInfo[]) => {

        
        for (let group of groups){
            let uniqueUsernames: string[] = [];
            for (let user of group.users){
                if(user.holdingLicense){
                    if(!uniqueUsernames.includes(user.username)) {
                        uniqueUsernames.push(user.username);
                    }
                }
            }

            for (let includedGroup of group.includedGroups) {
                for (let user of includedGroup.users) {
                    if(user.holdingLicense){
                        if(!uniqueUsernames.includes(user.username)) {
                            uniqueUsernames.push(user.username);
                        }
                    }
                }
            }
            group.totalUniqueUsers = uniqueUsernames.length;
        }
        groupDetails = groups;
    }
    await getCustomGroupDetails(setResult, () => {});
    const action: EditGroupsActions =
    {
        type: EditGroupsActionTypes.GET_CUSTOM_GROUP_DETAILS,
        customGroupDetails: groupDetails,
    };
    return action;
};

const getUsers = async (): Promise<IGetLicensedUsersAction> => {
    let licensedUsers: IUserInfo[] = null;
    const setResult = (users: IUserInfo[]) => {
        licensedUsers = users;
    }
    await getTotalUsers(setResult, () => {});
    const action: EditGroupsActions =
    {
        type: EditGroupsActionTypes.GET_LICENSED_USERS,
        licensedUsers: licensedUsers,
    };
    return action;
};

export const getCustomGroups: ActionCreator<ThunkAction<
    Promise<AnyAction>,
    IEditGroupsState,
    null,
    IGetCustomGroupDetailsAction
>> = () => {
    return async (dispatch: Dispatch) => {
        const action = await getGroups();
        return dispatch(action);
    };
};

export const getLicensedUsers: ActionCreator<ThunkAction<
    Promise<AnyAction>,
    IEditGroupsState,
    null,
    IGetLicensedUsersAction
>> = () => {
    return async (dispatch: Dispatch) => {
        const action = await getUsers();
        return dispatch(action);
    };
};
