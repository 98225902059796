import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import thunk from "redux-thunk";

import { IUserAuthState, UserAuthActionTypes } from "./actions/UserAuthTypes";
import { userAuthReducer } from "./reducers/UserAuthReducer";
import { ILookerFilterState } from "./actions/LookerFilterTypes";
import { lookerFilterReducer } from "./reducers/LookerFilterReducer";
import { foldersReducer } from "./reducers/FoldersReducer";
import { IFoldersState } from "./actions/FoldersTypes";
import { editGroupsReducer } from './reducers/EditGroupsReducer';
import { IEditGroupsState } from './actions/EditGroupsTypes'
import { editFolderPermissionsReducer } from './reducers/EditFolderPermissionsReducer';
import { IEditFolderPermissionsState } from './actions/EditFolderPermissionsTypes';
import { settingsReducer } from './reducers/SettingsReducer';
import { ISettingsState } from "./actions/SettingsActionsTypes";
import { messageReducer } from "./reducers/MessageReducer";
import { IMessageState } from "./actions/MessageActionsTypes";
import { notificationsReducer } from "./reducers/NotificationsReducer";
import { INotificationsState } from "./actions/NotificationActionTypes";

export interface IApplicationState {
  userAuth: IUserAuthState;
  filter: ILookerFilterState;
  folders: IFoldersState;
  editGroups: IEditGroupsState;
  editFolderPermissions: IEditFolderPermissionsState;
  settings: ISettingsState;
  messages: IMessageState;
  notifications: INotificationsState;
}

const appReducer = combineReducers<IApplicationState>({
  userAuth: userAuthReducer,
  filter: lookerFilterReducer,
  folders: foldersReducer,
  editGroups: editGroupsReducer,
  editFolderPermissions: editFolderPermissionsReducer,
  settings: settingsReducer,
  messages: messageReducer,
  notifications: notificationsReducer
});

const rootReducer = (state: IApplicationState, action) => {
  if (action.type === UserAuthActionTypes.LOGOUT || action.type === UserAuthActionTypes.PROPAGATE_LOGOUT) {
    //https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
    console.log('Logout detected in root reducer, resetting state');
    state = undefined;
  }

  return appReducer(state, action)
}

export const store = createStore(rootReducer, applyMiddleware(thunk));

export default function configureStore(): Store<IApplicationState> {
  return store;
}
