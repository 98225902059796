import { Col, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { IAlertInfo } from "../../AppModels";
import { getLicensedUsersInfo } from "../../services/AdminManagement/EditUsersManagement/EditUsersManagement";
import { ILicenseInfo } from "../../services/AdminManagement/EditUsersManagement/EditUsersModels";
import "./EditUsers.scss";
import LicenseInformationModal from "./LicenseInformationModal/LicenseInformationModal";
import UsersView from "./UsersView/UsersView";

interface IProps {}

const EditUsers: React.FC<IProps> = (props: IProps) => {

  const [loading, setLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({visible: false, type:'warning', message:'', description: ''});

  const [userLicenseInfo, setUserLicenseInfo] = useState<ILicenseInfo>();
  const [licenseModalVisible, setLicenseModalVisible] = useState<boolean>(false);

  const showLicenseModal = () =>{
    setLicenseModalVisible(true);
  }

  const hideLicenseModal = () =>{
    setLicenseModalVisible(false);
  }

  const handleGetLicensedUsersInfo = async () => {
    const setResult = (result: ILicenseInfo) => {
      setUserLicenseInfo(result)
    }

    const setError = (error: Error) => {
      setAlertInfo({
        visible: true,
        type: 'error',
        message: 'There was an error retrieving the list of users!',
        description: '',
      })
      console.log(error);
    }

    setLoading(true);
    await getLicensedUsersInfo(setResult, setError);
    setLoading(false);
  }

  useEffect(() => {
    if (!userLicenseInfo) {
      document.title = "Admin - Users";
      handleGetLicensedUsersInfo();
    }
  }, [userLicenseInfo]);

  return (
    <>
    <Layout>
      <div className="users-content-area">
        <Layout.Content>
          <br />
          <Row style={{height:35.5}}>
            <Col span={24}>
              <h3 style={{float:'left', margin:0}}>Users</h3>
              <div style={{float:'right', margin:0}}>
                { userLicenseInfo && userLicenseInfo.contractInfo && 
                  <>
                  <h5 style={{paddingTop: "8px"}}>
                    <u 
                      className="clickable"
                      onClick={showLicenseModal}>
                        Licenses Available
                    </u>
                    : {userLicenseInfo.contractInfo.exploreLicenseCount - userLicenseInfo.contractInfo.exploreLicensesInUse} Explore,&nbsp;
                    {userLicenseInfo.contractInfo.viewLicenseCount - userLicenseInfo.contractInfo.viewLicensesInUse} View
                  </h5>
                  </>
                }
              </div>
            </Col>
          </Row>
          <br />
          <div className='flex-container' style={{overflow: 'auto'}}>
            <UsersView  
                licenseInfo={userLicenseInfo} 
                setLoading={setLoading} 
                loading={loading} 
                refreshData={handleGetLicensedUsersInfo}
                setAlertInfo={setAlertInfo} 
                {...alertInfo} />
          </div>
        </Layout.Content>
      </div>
      {
        licenseModalVisible &&
        <LicenseInformationModal 
          exit={hideLicenseModal}
          contractInfo={userLicenseInfo.contractInfo}
        />
      }
    </Layout>
    </>
  );
};

export default EditUsers;