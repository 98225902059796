import React from "react";

interface IProps {
}

const CustomSpinner: React.FC<IProps> = () => {
  return (
    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  );
};

export default CustomSpinner;
