import { Button, Modal } from "antd";
import React, { useEffect } from "react";
import { LookerContentType } from "../../../AppModels";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import { API } from "../../../services/LookerAPI/LookerAPI";
import { ILookerContentInfo } from "../../../store/actions/SettingsActionsTypes";

interface ISetUserHomePageProps {
    openNotification: (message: string, description: string, type: string) => void;
    lookerContentInfo: ILookerContentInfo;
    realm: string,
    resetMode: boolean,
    exit: () => void;
}

const SetRealmHomePage: React.FC<ISetUserHomePageProps> = ({openNotification, lookerContentInfo, realm, resetMode, exit}) => {

  const [title, setTitle] = React.useState<string>("");

  const setRealmHomePage = () => {
      const showResult = (result: any) => {
        openNotification("Success!", "The Global Home Page was successfully " + (resetMode ? "reset." : "updated."), "success");
      }
  
      const showError = (error: Error) => {
        console.log(error);
        openNotification("Error!", "There was a problem " + (resetMode ? "resetting" : "updating") + " the Global Home Page.", "error");
      }
  
      API.setRealmHomePage(resetMode ? "" : lookerContentInfo.type, resetMode ? "" : lookerContentInfo.lookerId, resetMode ? "" : lookerContentInfo.embedUrl, realm, showResult, showError);
      exit();
    }

  const closeRealmHomePageModal = () =>{
      exit();
  }

  useEffect(() => {

    const getContentTitle = (type: LookerContentType) => {
      const showResult = (result: any) => {
        setTitle(result.title);
      }
  
      const showError = (error: Error) => {
        console.log(error);
        setTitle(lookerContentInfo.type);
      }
  
      switch (lookerContentInfo.type) {
         case LookerContentType.LOOK: {
          API.getLookTitle(lookerContentInfo.lookerId, showResult, showError); 
          
          break;
         }
         case LookerContentType.DASHBOARD: {
           API.getDashboardTitle(lookerContentInfo.lookerId, showResult, showError);
           
          break;
         }
         case LookerContentType.EXPLORE: {
          let exploreUrl: string = lookerContentInfo.embedUrl;
           let modelName: string = exploreUrl.substring(exploreUrl.indexOf("explore/")+ "explore/".length, exploreUrl.lastIndexOf("/"));
           let exploreName: string = exploreUrl.substring(exploreUrl.lastIndexOf("/")+1, exploreUrl.length);
           API.getExploreTitle(modelName, exploreName, showResult, showError);
           break;
         }
         default: {
           console.log(lookerContentInfo.type)
         }
      }
    }

    if (lookerContentInfo) {
      getContentTitle(lookerContentInfo.type as LookerContentType)
    }
  }, [lookerContentInfo])

  return (
    <>
    <Modal
        title={title ? resetMode ? "Reset Global Home Page" : `Set Global Home Page to "${title}"` : null}
        visible={true}
        onOk={setRealmHomePage}
        okText={'Yes'}
        onCancel={closeRealmHomePageModal}
        width={650}
        footer={title ? [
          <Button id="rlm-hm-cncl-btn" type='default' onClick={closeRealmHomePageModal}>Cancel</Button>,
          <Button id="rlm-hm-yes-btn" type='primary' onClick={setRealmHomePage}>Yes</Button>
        ] : null}
    >     
    {title ?
    <>
        <h4>Are you sure you want {resetMode ? "to reset the" : ` the "${title}" ${lookerContentInfo.type} to be the`} Global Home Page?</h4>
    </>
    : 
    <div style={{height: 150}}>
      <CustomSpinner/>
    </div>
    }
    </Modal>
    </>
  );
};
 
export default SetRealmHomePage;