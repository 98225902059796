import { LookerContentType, IPrintDesignerDataSource, IStandardReportsGroupItem, IStandardReportsDashboardItem, ICustomerSettings } from "../../AppModels"

export interface ILookerContentInfo {
    readonly type: LookerContentType,
    readonly lookerId: string,
    readonly embedUrl: string,
}

export interface ISettingsState {
    readonly lookerContentInfo: ILookerContentInfo;
    readonly printDesignerData: IPrintDesignerDataSource;
    readonly standardReports: IStandardReportsGroupItem[];
    readonly standardReportsMap: Map<string, IStandardReportsDashboardItem>;
    readonly standardReportsError: string;
    readonly customerSettings: ICustomerSettings;
    readonly customerSettingsError: string;
    readonly selectedExploreTab: string;
}

export enum SettingsActionsTypes {
    SET_LOOKER_CONTENT_INFO = "SETTINGS/LOOKER_CONTENT_INFO",
    SET_PRINT_DESIGNER_DATA = "SETTINGS/SET_PRINT_DESIGNER_DATA",
    GET_STANDARD_REPORTS = "SETTINGS/GET_STANDARD_REPORTS",
    GET_CUSTOMER_SETTINGS = "SETTINGS/GET_CUSTOMER_SETTINGS",
    SET_LAST_EXPLORE_TAB = "SETTINGS/SET_LAST_EXPLORE_TAB",
}

export type SettingsActions =
    | ISetLookerContentInfo
    | ISetPrintDesignerDataAction
    | IGetStandardReportsAction
    | IGetCustomerSettingsAction
    | ISetLastExploreTabAction;

export interface ISetLookerContentInfo {
    type: SettingsActionsTypes.SET_LOOKER_CONTENT_INFO;
    contentType: LookerContentType;
    lookerId: string;
    embedUrl: string;
}

export interface ISetPrintDesignerDataAction {
    type: SettingsActionsTypes.SET_PRINT_DESIGNER_DATA;
    printDesignerData: IPrintDesignerDataSource;
}

export interface IGetStandardReportsAction {
    type: SettingsActionsTypes.GET_STANDARD_REPORTS;
    standardReports: IStandardReportsGroupItem[];
    standardReportsMap: Map<string, IStandardReportsDashboardItem>;
    standardReportsError: string;
}

export interface IGetCustomerSettingsAction {
    type: SettingsActionsTypes.GET_CUSTOMER_SETTINGS;
    customerSettings: ICustomerSettings;
    customerSettingsError: string;
}

export interface ISetLastExploreTabAction {
    type: SettingsActionsTypes.SET_LAST_EXPLORE_TAB;
    selectedExploreTab: string;
}