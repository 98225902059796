import { Alert, Form, Input, Modal } from "antd";
import React from "react";
import { addDashboard, addFolder } from "../../services/FolderManagement/FolderManagement";
import { IDashboardInfo, IDisplayFolderItem, IDisplayFolderItemType, IFolderInfo, INewSource, ReportsAction } from "../../services/FolderManagement/FolderModels";
import { IAlertInfo } from "../../AppModels";

interface INewFolderItemContainerProps {
  newSource: INewSource;
  setAction: (action: ReportsAction) => void;
  goToItem: (item: IDisplayFolderItem) => void;
  showAlert: (alert: IAlertInfo) => void;
}

const NewFolderItemContainer: React.FC<INewFolderItemContainerProps> = (props: INewFolderItemContainerProps) => {
    const [errorMessage, setErrorMessage] = React.useState<string>(null);

    const [dashboardForm] = Form.useForm();
    const [folderForm] = Form.useForm();

    const processFolderAdd = (newFolder: IFolderInfo) => {
      props.goToItem({
        id: newFolder.id,
        name: newFolder.name,
        type: IDisplayFolderItemType.FOLDER
      });
    }

    const processFolderFailure= (error: Error) => {
      props.showAlert({
        type: "error",
        message: "An unexpected error ocurred attempting to create new folder."
      });
    }

    const processDashboardAdd = (newDashboard: IDashboardInfo) => {
      props.goToItem({
        id: newDashboard.id,
        name: newDashboard.title,
        type: IDisplayFolderItemType.DASHBOARD
      });
    }
    
    const processDashboardFailure= (error: Error) => {
      props.showAlert({
        type: "error",
        message: "An unexpected error ocurred attempting to create new dashboard."
      });
    }

    const addNewFolder = (name: string) => {
        name = name == null ? '' : name.trim();
        if(name.length === 0){
          setErrorMessage('Folder Name cannot be blank.');
          return;
        }
        if(name.toUpperCase().indexOf("@") !== -1){
          setErrorMessage('Folder Name cannot contain @.');
          return;
        }
        if(name.toUpperCase().indexOf("TEAMSHARED") !== -1){
          setErrorMessage('Folder Name cannot contain "TeamShared".');
          return;
        }
        if(props.newSource.folderInfo.children.some(element => element.name.toUpperCase() === name.toUpperCase())){
         setErrorMessage('A folder named "' + name + '" already exists in this folder.');
         return; 
        }

        addFolder(props.newSource.folderInfo.id, name,  processFolderAdd, processFolderFailure);
        setErrorMessage(null);
        props.setAction(null);
    }

    const addNewDashboard = (name: string) => {
        name = name == null ? '' : name.trim();
        if(name.length === 0){
          setErrorMessage('Dashboard Name cannot be blank.');
          return;
        }
        if(props.newSource.folderInfo.dashboards.some(element => element.title.toUpperCase() === name.toUpperCase())){
         setErrorMessage('A dashboard named "' + name + '" already exists in this folder.');
         return; 
        }

        addDashboard(props.newSource.folderInfo.id, name, processDashboardAdd, processDashboardFailure);
        setErrorMessage(null);
        props.setAction(null);
    }

    const closeDashboardModal = () => {
      props.setAction(null);
    };

    const closeFolderModal = () => {
      props.setAction(null);
    };

  return (
    <>
    {(()=> {
      switch(props.newSource.type){
      case IDisplayFolderItemType.DASHBOARD:
          return <>
            <Modal
              title="Create Dashboard"
              visible={true}
              onOk={() => {
                dashboardForm.validateFields()
                  .then(values => {
                    dashboardForm.resetFields();
                    addNewDashboard(values.dashboard_name);
                  })
              }}
              okText="Create Dashboard"
              onCancel={closeDashboardModal}
              width={650}
            >
              <Form
                form={dashboardForm}
                name="new_dashboard_form"
              >
                <Form.Item name="dashboard_name" label="Name">
                  <Input type="textarea" />
                </Form.Item>
              </Form>
              
              <p>This new dashboard will be created in the "{props.newSource.folderInfo.name}" folder.</p>
              { errorMessage && 
                <Alert type="error" message={errorMessage} />
              }
            </Modal>
          </>;
      case IDisplayFolderItemType.FOLDER:
          return  <>
          <Modal
            title="Create Folder"
            visible={true}
            onOk={() => {
              folderForm.validateFields()
                .then(values => {
                  folderForm.resetFields();
                  addNewFolder(values.folder_name);
                })
            }}
            okText="Create Folder"
            onCancel={closeFolderModal}
            width={650}
          >
            <Form
              form={folderForm}
              name="new_folder_form"
            >
              <Form.Item name="folder_name" label="Name">
                <Input type="textarea" />
              </Form.Item>
            </Form>
            
            <p>This new folder will be created in the "{props.newSource.folderInfo.name}" folder.</p>
            { errorMessage && 
              <Alert type="error" message={errorMessage} />
            }
          </Modal>
        </>;
      default:
          return <>         
              </>;
    }
    })()}
    </>
  );
};
 
export default NewFolderItemContainer;
  
