import { Button, Col, Layout, Row } from 'antd';
import * as React from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { OKTA_RESTORE_URI, WEBAPP_VERSION } from '../../Constants';
import { lookupUser, postLogin } from "../../store/actions/UserAuthActions";
import { IFederatedPromptState, IUserLoginCredentials } from "../../store/actions/UserAuthTypes";
import { IApplicationState } from "../../store/Store";
import "./Login.scss";
import LoginForm from "./LoginForm/LoginForm";
import LoginHelp from "./LoginHelp/LoginHelp";
import FederatedLoginButton from './FederatedLoginButton/FederatedLoginButton';
import { useOktaAuth } from '@okta/okta-react';
import FederatedLinkageModal from './FederatedLinkageModal/FederatedLinkageModal';

const { Header, Content, Footer } = Layout;

interface IProps {
  postLogin: typeof postLogin;
  lookupUser: typeof lookupUser;
  errorMessage: string;
  checkForUserComplete: boolean;
  oktaReturn: boolean; //return from okta authentication attempt - try to complete app login
  logoutOkta?: boolean; //flag to trigger logout from okta
  setOktaSignInAttributes: (regularLogin: boolean, supportRealm: string, selectedUser: string) => void;
}

const Login: React.FC<IProps> = ({ postLogin, lookupUser, errorMessage, checkForUserComplete, oktaReturn, logoutOkta = false, setOktaSignInAttributes}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [help, setHelp] = React.useState<boolean>(false);
  const [supportLogin, setSupportLogin] = React.useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const { oktaAuth } = useOktaAuth();
  const logoutOktaFromState: boolean = useSelector<IApplicationState>(state => state.userAuth.oktaLogout) as boolean;
  const federatedPrompt: IFederatedPromptState = useSelector<IApplicationState>(state => state.userAuth.federatedPrompt) as IFederatedPromptState;
  const [openAm, setOpenAm] = React.useState<boolean>(false);
  const [submittedRealm, setSubmittedRealm] = React.useState<string>(null);
  
  const submitForm = (values : IUserLoginCredentials, force?: boolean) => {
    setLoading(true);
    postLogin({
      username: values.username,
      password: values.password,
      realm: values.realm,
      support: location.pathname === "/internal" || location.pathname === ("/internal" + OKTA_RESTORE_URI),
      selectedUser: values.selectedUser ? values.selectedUser : null,
      scopeOfControlOnly: (values.scopeOfControlOnly && values.selectedUser) ? values.scopeOfControlOnly : false, 
      forceFlag: force ? true : false,
    });
  };

  const submitFormWithForce = (values : IUserLoginCredentials) => {
    submitForm(values, true);
  };

  useEffect(() => {
    document.title = 'EnterpriseRx Data Insights';
  }, []);

  useEffect(() => {
    if(oktaReturn || logoutOkta || logoutOktaFromState){
      //nothing here
    } else if(!checkForUserComplete){
      lookupUser();
    } else {
      //If user not logged in, reset route to main dashboard
      if(location.pathname !== "/internal" && location.pathname !== ("/internal" + OKTA_RESTORE_URI) && location.pathname !== "/") {
        history.push("/");
      }  
    }
  }, [checkForUserComplete, lookupUser, history, location, oktaReturn, logoutOkta, logoutOktaFromState]);

  useEffect(()=>{
    setLoading(false);
  },[errorMessage]);

  useEffect(() => {
    setSupportLogin(location.pathname === "/internal" || location.pathname === ("/internal" + OKTA_RESTORE_URI));
  },[location.pathname]);

  useEffect(()=>{
    if(logoutOkta || logoutOktaFromState){
      oktaAuth.signOut()
        .then(response => {console.log('response', response)})
        .catch(error => {
            console.log("Error invoking okta signout function: ", error)
            window.location.href = window.location.origin;
          })
    }
  }, [logoutOkta, oktaAuth, logoutOktaFromState])

  const changeHelpFlag= () => {
    setHelp(!help);
  }

  return (
    <>
     <Layout className="login-blue-background" style={{ minHeight: "100vh" }}>
      <Header
        className="login-header">
        <img className="logo" src="/McKesson_Logo_White.png" alt="McKesson Logo"/>  
      </Header>
      { ((checkForUserComplete || oktaReturn) && !logoutOkta && !logoutOktaFromState) ? 
          <Content>
          <Row justify="space-around" align="middle" style={{ height:'75vh', overflowY:"auto"}}>    
            <div className="login-white-background"
              style={{ height: ((500 + (supportLogin ? 100 : 50) + (errorMessage ? 80 : 0)) + "px")}}
            >
              <Col> 
                <h2>EnterpriseRx Data Insights&trade;</h2> <br />
                <Row>
                  {
                    help ? <LoginHelp />
                    :  <LoginForm
                          setOktaSignInAttributes={setOktaSignInAttributes}
                          submitForm={submitForm}
                          submitFormWithForce={submitFormWithForce}
                          loading={loading}
                          errorMessage={errorMessage}
                          supportLogin={supportLogin}
                          oktaReturn={oktaReturn}
                          openAm={openAm}
                          setOpenAm={setOpenAm}
                          submittedRealm={submittedRealm}
                          setSubmittedRealm={setSubmittedRealm}
                        />
                  }
                </Row>

                  {
                    !help && !supportLogin &&
                    <FederatedLoginButton
                      oktaReturn={oktaReturn}
                      disabled={loading}
                      setOktaSignInAttributes={setOktaSignInAttributes}
                    />
                  }
                  
                  <Button 
                    type="link" 
                    onClick={() => window.open("https://www.mckesson.com/saas/enterprise-rx/", "_blank")}
                    className="login-help-link"
                  > 
                    <u>
                      Privacy Notice
                    </u>
                  </Button>

                  {
                    (openAm && !supportLogin) ?
                    <Button 
                    type="link" 
                    onClick={changeHelpFlag}
                    className="login-help-link"
                  > 
                    <u>
                    { 
                      help 
                      ? 'Return to Login Form'
                      : 'Forgot Username or Password?'
                    }
                    </u>
                  </Button>
                    :
                    <></>
                  }

              </Col>
            </div>
          </Row>
          </Content>
          :
          <Content>
            <Row style={{ height:'75vh'}}>    
              <CustomSpinner />
            </Row>
          </Content>

      }
    <Footer
      className="login-footer"
      style={ { textAlign: 'right'} }>
       EnterpriseRx Data Insights&trade; {WEBAPP_VERSION}
    </Footer>
    </Layout>
    {
      federatedPrompt && 
      <FederatedLinkageModal
        federatedPrompt={federatedPrompt}
      />
    }
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    errorMessage: state.userAuth.errorMessage,
    checkForUserComplete: state.userAuth.checkForUserComplete,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    postLogin: (credentials: IUserLoginCredentials) =>
      dispatch(postLogin(credentials)),
    lookupUser: () =>
      dispatch(lookupUser()),  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
