import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPrintDesignerDataSource, LookerContentType } from "../../AppModels";
import { LOCAL_STORAGE_LAST_ACTIVITY, MODELS_WITH_FACILITY_SELECTION } from "../../Constants";
import { IDisplayFolderItem, IDisplayFolderItemType } from "../../services/FolderManagement/FolderModels";
import { lookupLookAndSetLookerContentInfo, setFacilityFilterList, setLookerContentInfo } from "../../store/actions/LookerFilterActions";
import { setLookerPageInfo, setPrintDesignerData } from "../../store/actions/SettingsActions";
import { IApplicationState } from "../../store/Store";
import dompurify from 'dompurify';

interface IProps {
  embeddedUrl: string;
  contentChangeFunction?: (content: IDisplayFolderItem) => void;
}

const IFrame: React.FC<IProps> = ({embeddedUrl, contentChangeFunction}) => {

  const dispatch = useDispatch();

  const facilityFilterList: string = useSelector<IApplicationState>(state => state.filter.facilityFilterList) as string;
  const lookerContentType: LookerContentType = useSelector<IApplicationState>(state => state.filter.lookerContentType) as LookerContentType;

  //Listen for events from iframe to check for type of content 
  // By LookML configuration, all looks and explores will have facility filter
  // For Dashboards, explicitly check for facilty filter
  const eventListener = useCallback((event) => {
    let lookerUrl : string;
    if(window.location.href.indexOf("dev-mps-looker") !== -1 ||
        window.location.href.indexOf("dev-reporting") !== -1 ||
        window.location.href.indexOf("localhost") !== -1 ){
          lookerUrl = "https://looker-dev-reporting.rxservices.mckesson.com";
    } else if(window.location.href.indexOf("uat-mps-looker") !== -1 ||
        window.location.href.indexOf("uat-reporting") !== -1 ){
      lookerUrl = "https://looker.uat-reporting.rxservices.mckesson.com";
    } else if(window.location.href.indexOf("staging-mps-looker") !== -1 ||
        window.location.href.indexOf("staging-reporting") !== -1 ){
      lookerUrl = "https://looker-staging-reporting.rxservices.mckesson.com";
    } else {
      lookerUrl = "https://looker.reporting.rxservices.mckesson.com";
    }
    let element = document.getElementById("looker");
    let iFrame = element as HTMLIFrameElement;
    if (event.source === iFrame.contentWindow) {
      if (event.origin === lookerUrl) {
        let VERBOSE = false; 
        if (VERBOSE) {
          console.log(event.data);
        }
        let data = JSON.parse(event.data);
        if(data){
          if(data.type === "dashboard:download" || data.type === "dashboard:tile:download"){
            //dispatch(displayMessage(MESSAGE_DOWNLOAD_WARNING)); //No longer needed
          }
          else if (data.type === "dashboard:loaded") {
            let filters = data.dashboard.dashboard_filters;
            console.log('dashboard:filters:changed event, filters', filters);
            dispatch(setLookerContentInfo(LookerContentType.DASHBOARD, filters && filters["Facility ID"] !== undefined && filters["Facility ID"] !== null));
            if(contentChangeFunction){
              contentChangeFunction({
                type: IDisplayFolderItemType.DASHBOARD,
                id: data.dashboard.id,
                name: null
              });
            }
            /*let printDesignerData : IPrintDesignerData = {
              type: LookerContentType.DASHBOARD,
              id: data.dashboard.id,
              filters: data.dashboard.dashboard_filters,
              time: Date.now()
            };
            dispatch(setPrintDesignerData(printDesignerData));*/
            dispatch(setLookerPageInfo(IDisplayFolderItemType.DASHBOARD, data.dashboard.id, "/dashboards/" + data.dashboard.id));
            dispatch(setPrintDesignerData(null));
            localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
          }
          else if (data.type === "look:ready") {
            console.log('look:ready event');
            let url : string = data.look.url;
            const idFromUrl = url.substring(url.indexOf("looks/") + 6, url.indexOf("?"));
            if(contentChangeFunction){
              contentChangeFunction({
                type: IDisplayFolderItemType.LOOK,
                id: idFromUrl,
                name: null
              });
            }
            dispatch(lookupLookAndSetLookerContentInfo(idFromUrl));
            dispatch(setLookerPageInfo(IDisplayFolderItemType.LOOK, idFromUrl, "/looks/" + idFromUrl));
            localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
          }
          /*else if (data.type === "look:save:complete") {
            console.log('look:save:complete event');
            let url : string = data.look.url;
            dispatch(checkSaveLookUrlAndDisplayMessage(url));
          }*/
          else if (data.type === "explore:ready") {
            console.log('explore:ready event');
            let url: string = data.explore.url;
            url = url.substring(url.indexOf("/explore/"), url.indexOf("?"));
            let model = url.substring(9, url.indexOf("/", 9));
            dispatch(setLookerContentInfo(LookerContentType.EXPLORE, MODELS_WITH_FACILITY_SELECTION.includes(model)));
            dispatch(setLookerPageInfo(LookerContentType.EXPLORE, "", url));
            localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
          }
          else if (data.type === "look:state:changed"){ 
            localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
            let printDesignerData : IPrintDesignerDataSource;
            let url : string = data.look.url;
            printDesignerData = {
              type: LookerContentType.LOOK,
              id: url.substring(url.indexOf("looks/") + 6, url.indexOf("?")),
              time: Date.now()
            };

            if(url.indexOf("qid=") !== -1){
              let qid = url.substr(url.indexOf("qid=") + 4);
              if(qid.indexOf("&") !== -1){
                qid = qid.substring(0, qid.indexOf("&"));
              }
              printDesignerData.qid = qid;
            }
            dispatch(setPrintDesignerData(printDesignerData));
          }
          else if (data.type === "explore:state:changed"){
            localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
            /*let qid : string = data.explore.url;
            qid = qid.substr(qid.indexOf("qid=") + 4);
            if(qid.indexOf("&") !== -1){
              qid = qid.substring(0, qid.indexOf("&"));
            }
            let printDesignerData : IPrintDesignerData = {
              type: LookerContentType.EXPLORE,
              qid: qid,
              time: Date.now()
            };
            dispatch(setPrintDesignerData(printDesignerData));*/
            dispatch(setPrintDesignerData(null));
          }
          else if (data.type === "dashboard:filters:changed"){
            localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
            /*let printDesignerData : IPrintDesignerData = {
              type: LookerContentType.DASHBOARD,
              id: data.dashboard.id,
              filters: data.dashboard.dashboard_filters,
              time: Date.now()
            };
            dispatch(setPrintDesignerData(printDesignerData));*/
            dispatch(setPrintDesignerData(null));
          }
        }
      } else {
        //console.log("IGNORING: event: ", event);
      }
    }
  }, [dispatch, contentChangeFunction]);

  React.useEffect(()=>{
    if(facilityFilterList!=null){
      let lookerUrl : string;
      if(window.location.href.indexOf("dev-mps-looker") !== -1 ||
          window.location.href.indexOf("dev-reporting") !== -1 ||
          window.location.href.indexOf("localhost") !== -1 ){
        lookerUrl = "https://looker-dev-reporting.rxservices.mckesson.com";
      } else if(window.location.href.indexOf("uat-mps-looker") !== -1 ||
          window.location.href.indexOf("uat-reporting") !== -1 ){
        lookerUrl = "https://looker.uat-reporting.rxservices.mckesson.com";
      } else if(window.location.href.indexOf("staging-mps-looker") !== -1 ||
          window.location.href.indexOf("staging-reporting") !== -1 ){
        lookerUrl = "https://looker-staging-reporting.rxservices.mckesson.com";
      } else {
        lookerUrl = "https://looker.reporting.rxservices.mckesson.com";
      }
      let filter_request;
      let run_request;
      switch(lookerContentType) {
        case LookerContentType.DASHBOARD:
          run_request = JSON.stringify(
            {
              type: "dashboard:run"
            }
          );
          filter_request = JSON.stringify(
            {
              type: "dashboard:filters:update",
              filters: {
                "Facility ID": facilityFilterList
              }
            }
          );
          break;
        case LookerContentType.LOOK:
          run_request = JSON.stringify(
            {
              type: "look:run"
            }
          );
          filter_request = JSON.stringify(
            {
              type: "look:filters:update",
              filters: {
                "scope_of_control.fd_facility_id": facilityFilterList
              }
            }
          );
          break;
        case LookerContentType.EXPLORE:
          run_request = JSON.stringify(
            {
              type: "explore:run"
            }
          );
          filter_request = JSON.stringify(
            {
              type: "explore:filters:update",
              filters: {
                "scope_of_control.fd_facility_id": facilityFilterList
              }
            }
          );
          break;
        default:
          console.log('TYPE ERROR, type = ', lookerContentType);
      }
      let my_iframe = document.getElementById("looker") as HTMLIFrameElement;
      console.log('applying filter_request', filter_request);
      console.log('applying run_request', run_request);
      my_iframe.contentWindow.postMessage(filter_request, lookerUrl);
      my_iframe.contentWindow.postMessage(run_request, lookerUrl);
      dispatch(setFacilityFilterList(null));
    }
  }, [facilityFilterList, lookerContentType, dispatch])
   
  useEffect(() => {
    console.log('adding event listener');
    window.addEventListener("message", eventListener);
  }, [eventListener]);

  //On Event Listener Change, Remove previous listener
  useEffect( () => () => {
    if(eventListener!=null){
      console.log('removing event listener');
      window.removeEventListener("message", eventListener);
    }
  }, [eventListener, dispatch] );

   //On Dismount
   useEffect( () => () => {
    dispatch(setLookerContentInfo(null, false));
    dispatch(setPrintDesignerData(null));
  }, [dispatch] );

  return (
    <>
    <iframe
      id="looker"
      src={dompurify.sanitize(embeddedUrl)}
      height="100%"
      width="100%"
      seamless={true}
      title="Looker Embedded IFrame"
    ></iframe>
    </>
  );
};

export default IFrame;
