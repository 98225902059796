import { Button, Modal } from "antd";
import React, { useEffect } from "react";
import { LookerContentType } from "../../../AppModels";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import { API } from "../../../services/LookerAPI/LookerAPI";
import { ILookerContentInfo } from "../../../store/actions/SettingsActionsTypes";

interface ISetUserHomePageProps {
    openNotification: (message: string, description: string, type: string) => void;
    lookerContentInfo: ILookerContentInfo;
    realm: string,
    username: string,
    resetMode: boolean,
    exit: () => void;
}

const SetUserHomePage: React.FC<ISetUserHomePageProps> = ({openNotification, lookerContentInfo, realm, username, resetMode, exit}) => {

  const [title, setTitle] = React.useState<string>("");

    const setUserHomePage = () => {
        const showResult = (result: any) => {
          openNotification("Success!", "Your Home Page was successfully " + (resetMode ? "reset." : "updated."), "success");
        }
    
        const showError = (error: Error) => {
          console.log(error);
          openNotification("Error!", "There was a problem " + (resetMode ? "resetting" : "updating") + " your Home Page.", "error");
        }
    
        API.setUserHomePage(resetMode? "" : lookerContentInfo.type, resetMode? "" : lookerContentInfo.lookerId, resetMode? "" : lookerContentInfo.embedUrl, showResult, showError);
        exit();
    }

    const closeUserHomePageModal = () =>{
        exit();
    }

    useEffect(() => {

      const getContentTitle = (type: LookerContentType) => {
        const showResult = (result: any) => {
          setTitle(result.title);
        }
    
        const showError = (error: Error) => {
          console.log(error);
          setTitle(lookerContentInfo.type);
        }
    
        switch (lookerContentInfo.type) {
           case LookerContentType.LOOK: {
            API.getLookTitle(lookerContentInfo.lookerId, showResult, showError); 
            
            break;
           }
           case LookerContentType.DASHBOARD: {
             API.getDashboardTitle(lookerContentInfo.lookerId, showResult, showError);
             
            break;
           }
           case LookerContentType.EXPLORE: {
            let exploreUrl: string = lookerContentInfo.embedUrl;
             let modelName: string = exploreUrl.substring(exploreUrl.indexOf("explore/")+ "explore/".length, exploreUrl.lastIndexOf("/"));
             let exploreName: string = exploreUrl.substring(exploreUrl.lastIndexOf("/")+1, exploreUrl.length);
             API.getExploreTitle(modelName, exploreName, showResult, showError);
             break;
           }
           default: {
             console.log(lookerContentInfo.type)
           }
        }
      }
  
      if (lookerContentInfo) {
        getContentTitle(lookerContentInfo.type as LookerContentType)
      }
    }, [lookerContentInfo])

  return (
    <>
    <Modal
        title={title ? resetMode ? "Reset Home Page" : `Set Home Page to "${title}"` : null}
        visible={true}
        onOk={setUserHomePage}
        okText={'Yes'}
        onCancel={closeUserHomePageModal}
        width={650}
        footer={title ? [
          <Button id="usr-hm-cncl-btn" type='default' onClick={closeUserHomePageModal}>Cancel</Button>,
          <Button id="usr-hm-yes-btn" type='primary' onClick={setUserHomePage}>Yes</Button>
        ] : null}
    >      
    {title ?
    <>
        <h4>Are you sure you want {resetMode ? "to reset your" : ` the "${title}" ${lookerContentInfo.type} to be your`} Home Page?</h4>
    </>
    : 
    <div style={{height: 150}}>
      <CustomSpinner/>
    </div>
    }
    </Modal>
    </>
  );
};
 
export default SetUserHomePage;
  