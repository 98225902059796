import { CheckOutlined } from "@ant-design/icons";
import React from "react";
import { IAlertInfo } from "../../../AppModels";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";

interface IProps {
    userInfo: IUserInfo,
}

const LicenseAssignment: React.FC<IProps> = (props: (IProps & IAlertInfo)) => {

    return (
        <>
            { props.userInfo.holdingLicense &&
                <>
                <CheckOutlined style={{ fontSize: "16px"}}/>
                </>
            }
        </>
    );
};

export default LicenseAssignment;