import { INotification, IReadLogEntry } from "../../services/NotificationsManagement/NotificationsModels";

export interface INotificationsState {
    readonly notifications: INotification[];
    readonly error: INotificationActionError | null;
}

export enum NotificationActionTypes {
    GET_ALL_NOTIFICATIONS = "NOTIFICATIONS/GET_ALL_NOTIFICATIONS",
    SET_NOTIFICATION_STATUS = "NOTIFICATIONS/SET_NOTIFICATION_STATUS",
    SET_NOTIFICATION_STATUS_BULK = "NOTIFICATIONS/SET_NOTIFICATION_STATUS_BULK",
    CLEAR_ERROR = "NOTIFICATIONS/CLEAR_ERROR"
}

export type NotificationActions =
    | IGetAllNotifications
    | ISetNotificationStatus
    | ISetNotificationStatusBulk
    | IClearNotificationError;

export interface IGetAllNotifications {
    type: NotificationActionTypes.GET_ALL_NOTIFICATIONS;
    notifications: INotification[];
    error: INotificationActionError;
}

export interface ISetNotificationStatus {
    type: NotificationActionTypes.SET_NOTIFICATION_STATUS;
    readEntry: IReadLogEntry;
    error: INotificationActionError;
}

export interface ISetNotificationStatusBulk {
    type: NotificationActionTypes.SET_NOTIFICATION_STATUS_BULK;
    readEntries: IReadLogEntry[];
    error: INotificationActionError;
}

export interface IClearNotificationError {
    type: NotificationActionTypes.CLEAR_ERROR;
}

export interface INotificationActionError {
    type: NotificationActionTypes;
    error: Error
}