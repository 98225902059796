export interface IDrugListItem {
    key?: number;
    code: string;
    description: string;
    valid?: boolean;
}

export interface IDrugListItemResult {
    code: string;
    description: string;
    valid: boolean; // True if this list item matched one or more known drugs, otherwise false.
}

export interface ICustomDrugList {
    drug_list_name: string;
    drug_list_description: string;
    list_type: LIST_TYPE; // GPI or NDC
    items: IDrugListItem[];
}

export interface IDrugListSaveResult {
    drug_list_name: string;
    drug_list_description: string;
    list_type: LIST_TYPE; // GPI or NDC
    all_valid: boolean; // True only if every list item matches some known drug(s).
    items: IDrugListItemResult[];
}

export interface  IDrugListHeader {
    drug_list_name: string;
    drug_list_description: string;
    list_type: LIST_TYPE; // GPI or NDC
    status: string; // "UPDATE PENDING" or "CURRENT"
    last_modified: string; // YYYY-MM-DD HH:MM
}

export interface IAllLists {
    drug_lists: IDrugListHeader[];
    custom_drug_lists_enabled: boolean;
    max_drug_lists: number;
}

export enum LIST_TYPE {
    NDC="NDC",
    GPI="GPI",
    GCN="GCN"
}

export enum VALIDATION_METHOD {
    HTTP='http',
    FORM='form'
}

export enum STANDARD_DRUG_CLASSES {
    CHOLESTEROL="CHOLESTEROL",
    DIABETES="DIABETES",
    HYPERTENSION="HYPERTENSION",
    SUPD="SUPD",
    HRM="HRM",
    COPD="COPD"
}

// Operations:
//
// 1. getAllLists
// Args: ClientRealm: string
// Returns: Json String in IAllLists format
//
// 2. getListDetails
// Args: ClientRealm: string
//       drug_list_name: string
// Returns: Json String of ICustomDrugList
//
// 3. verifyAndSaveList
// Args: ClientRealm: string
//       drugList: Json String in ICustomDrugList format
// Returns: saveResult: Json String in IDrugListSaveResult format
//
// 4. deleteList
// Args: ClientRealm: string
//       drug_list_name: string
// Returns: status: string "OK" or error message