import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { Store } from "redux";
import configureStore from "./store/Store";
import type {} from 'redux-thunk/extend-redux';

import { IApplicationState } from "./store/Store";
import { BrowserRouter } from "react-router-dom";

interface IProps {
  store: Store<IApplicationState>;
}
const Root: React.FC<IProps> = (props) => {
  return (
    <Provider store={props.store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

export const store: Store<IApplicationState> = configureStore();
ReactDOM.render(
  <Root store={store} />,
  document.getElementById("root") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
