import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch } from 'react-redux'
import { IGroupInfo } from "../../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import { getCustomGroups } from '../../../../store/actions/EditGroupsActions'
import { deleteGroup } from '../../../../services/AdminManagement/EditGroupsManagement/EditGroupsManagement'
import { IAlertInfo } from "../../../../AppModels";

interface IProps {
    text: object,
    record: IGroupInfo,
    index: number,
    setLoading(isLoading: boolean): void,
    setAlertInfo(alertInfo: IAlertInfo): void
}

const DeleteGroupButton: React.FC<IProps> = (props: IProps) => {

    const dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleModal = () => {
        if(isModalVisible) {
            setIsModalVisible(false);
        }
        else {
            setIsModalVisible(true);
        }
    }

    const handleOnClick = async () => {
        toggleModal();
        props.setLoading(true);

        const setResults = (results: any) => {
            console.log("Results for deleteGroup: ", results);
            props.setAlertInfo({
                visible: true,
                type: 'success',
                message: `Group ${props.record.groupName} was successfully deleted!`,
                description: ''
            })
        }

        const setError = (error: Error) => {
            console.log("deleteGroup Error: ", error);
            props.setAlertInfo({
                visible: true,
                type: 'error',
                message: `Failed to delete group ${props.record.groupName}!`,
                description: ''
            })
        }

        await deleteGroup(props.record.groupName, setResults, setError)
        await dispatch(getCustomGroups());
        props.setLoading(false);
    }

    return (
        <>
            <Modal
                title={'Delete "' + props.record.groupName + '"?'}
                visible={isModalVisible}
                onOk={handleOnClick}
                okText={'Delete Group'}
                onCancel={toggleModal}
                width={650}
            >      
            <>
                <h4>Are you sure you want to delete the group "{props.record.groupName}"?</h4>
                <br />
                This <b>CANNOT</b> be undone.
            </>
            </Modal>
            <Button 
                type="primary"
                className="group-btn-delete" 
                onClick={toggleModal}
                danger
            >
                Delete
            </Button>
        </>
    );
};

export default DeleteGroupButton;