import { API } from "../LookerAPI/LookerAPI";
import { CronFields } from "./CronFields";
import { ILookerAlertInfo } from "./AlertsModels";

const parseScheduleResponse = (result: any) => {
  const resultObject = result as object[];
  const alertsWithConditionMet: Set<string> = new Set(resultObject["alertsWithConditionMet"].map((alertObject) => alertObject["id"]));
  const alertsWithConditionNotMet: Set<string> = new Set(resultObject["alertsWithConditionNotMet"].map((alertObject) => alertObject["id"]));
  const alertsParsed: ILookerAlertInfo[] = result["alerts"].map(x =>{
    const id = x["id"];
    const crontab = x["cron"];
    const cronFields = new CronFields(crontab);
    const crontabDescription = cronFields.getFullText();
    let conditionMet = null;
    if(alertsWithConditionMet.has(id)){
      conditionMet = "Yes";
    } else if(alertsWithConditionNotMet.has(id)){
      conditionMet="No";
    }
    let name = "";
    if(x["custom_title"]){
      name = x["custom_title"];
    } else {
      const comparison_type: string = x["comparison_type"];
      name = x["field"]["title"] + 
        (comparison_type.indexOf("BY") === -1 ? " is " : " " ) + 
        comparison_type.toLowerCase().replace(/_/g, " ") + 
        " " + x["threshold"];
    }
    return {
      alert_id: id,
      name: name,
      crontab: crontab,
      crontab_description: crontabDescription,
      dashboard_element_id: x["is_disabled"] ? null : x["dashboard_element_id"],
      condition_met: conditionMet,
      status: x["is_disabled"] ?  "Disabled" : "Active",
      status_reason: x["disabled_reason"],
      destinations: x["destinations"]
    };
  });
  return alertsParsed;
};

export const getAlerts = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const processResult = (result:any) => {
    setResult(parseScheduleResponse(result));
  }
  API.getAlerts(processResult, setError);
};

export const getAlertsAdmin = async (
  username: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const processResult = (result:any) => {
    setResult(parseScheduleResponse(result));
  }
  API.getAlertsAdmin(username, processResult, setError);
};