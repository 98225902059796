import { DownOutlined, FolderOutlined, UpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Button, Card, Col, Pagination, Row, Tooltip, Select } from "antd";
import React from "react";
import { IChildInfo, IDisplayFolderItem, IDisplayFolderItemType } from "../../../../services/FolderManagement/FolderModels";
import "../ReportsCardContainer.scss";

interface IProps {
    type: IDisplayFolderItemType;
    data: IChildInfo[];
    goToItem: (item: IDisplayFolderItem) => void;
    resetContent: () => void;
}

enum SortTypes {
    NAME = "NAME",
    CREATED = "CREATED"
}

const ReportsFolderContainer: React.FC<IProps> = ( props: IProps ) => {

    const itemsPerPage = 20;
    const responsiveColumnSpans = { xs: 24, sm: 12, md: 12, lg: 8, xl: 6 };
    const [firstDisplayIndex, setFirstDisplayIndex] = React.useState<number>(0);
    const [lastDisplayIndex, setLastDisplayIndex] = React.useState<number>(itemsPerPage);
    const [sortType, setSortType] = React.useState<SortTypes>(SortTypes.NAME);
    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [reverseSort, setReverseSort] = React.useState<boolean>(false);
    const [sortedData, setSortedData] = React.useState<IChildInfo[]>([]);

    const changePage = (page: number, pageSize: number) => {
        setFirstDisplayIndex((page-1) * pageSize);
        setLastDisplayIndex(page * pageSize);
        setPageNumber(page);
    }

    const reverseOrder = () => {
        setReverseSort(!reverseSort);
    }

    const changeSortType = (newSortType) =>{
        setSortType(newSortType);
        setReverseSort(false);
    }

    React.useEffect(()=>{
        let dataArray = props.data.slice(); //copy, since sort reverses array in place
        if (sortType === SortTypes.NAME) { 
            if(!reverseSort){
                setSortedData(dataArray.sort((a,b)=> {
                    return a.name.localeCompare(b.name);
                }));
            } else {
                setSortedData(dataArray.sort((a,b)=> {
                    return b.name.localeCompare(a.name);
                }));
            }
        } else if (sortType === SortTypes.CREATED){
            if(!reverseSort){
                setSortedData(dataArray.sort((a,b)=> {
                    return a.created_at.localeCompare(b.created_at);
                }));
            } else {
                setSortedData(dataArray.sort((a,b)=> {
                    return b.created_at.localeCompare(a.created_at);
                }));
            }
        }
    },[sortType, reverseSort, props.data]);

  return (
    <>
        <Col span={10} style={{ textAlign:"right" }}>
            <Select
                value={sortType}
                onChange={changeSortType}
                showArrow={true}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: "none"}}/>}
                className="report-sort-selector"
            >
                <Select.Option className="report-sort-selector-item" key={SortTypes.NAME} value={SortTypes.NAME}>Sort By Name</Select.Option>
                <Select.Option className="report-sort-selector-item" key={SortTypes.CREATED} value={SortTypes.CREATED}>Sort By Created Date</Select.Option>
            </Select>
            <Tooltip title="Sort Order">
                <Button
                    type="link" 
                    onClick={reverseOrder}
                    icon={ reverseSort ? 
                        <DownOutlined /> :
                        <UpOutlined />}
                />
            </Tooltip>
        </Col> 
        <Col span={24}>        
            <br />
            <div className="site-card-wrapper folder-content-cards">
            <Row gutter={[32,32]}>
                {(sortedData.slice(firstDisplayIndex, Math.min(lastDisplayIndex,sortedData.length))
                    .map((child, index) =>
                (<Col key={'colf' + index} {...responsiveColumnSpans} >
                    <Card
                    hoverable
                    onClick={() => {

                        props.goToItem({
                            type: IDisplayFolderItemType.FOLDER,
                            name: child.name,
                            id: child.id
                        })
                    
                    }}>
                    <Card.Meta title={child.name} avatar={<FolderOutlined />}/>
                    </Card>
                </Col>)))}
            </Row>
            </div>
            <Pagination
                className="report-pagination"
                size="small"
                current={pageNumber}
                style={{ textAlign: "right"}}
                total={sortedData.length}
                showTotal={(total, range) => `${firstDisplayIndex+1}-${Math.min(lastDisplayIndex,sortedData.length)} 
                    of ${total} ${props.type.toLowerCase() + 's'}`}
                defaultPageSize={itemsPerPage}
                onChange={changePage}
                showSizeChanger={false}
                hideOnSinglePage
            />
            <br />
            <br />
        </Col> 
    </>
  );
};

export default ReportsFolderContainer;
