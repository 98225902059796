// Importing necessary modules and components from the libraries
import React, { useMemo } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";

// Importing the IDocumentationLink interface from DocumentationModels
import { IDocumentationLink } from '../../../services/DocumentationManagement/DocumentationModels';

// Importing the IApplicationState interface from Store
import { IApplicationState } from "../../../store/Store";

// Defining an empty props interface
interface IProps {}

// Creating a functional component LinksDocumentationView with no properties
const LinksDocumentationView: React.FC<IProps> = () => {
    // Using useSelector hook to select 'models' property from userAuth state
    const accessGrants = useSelector<IApplicationState, string[]>(state => state.userAuth.models);

    // Creating a memoized data source for our Table component. 
    // useMemo is used here to optimize performance by avoiding expensive computations on every render.
    // In this case, the computation of the dataSource (which includes filtering operation) will only be re-run if 'accessGrants' changes.
    // This is specified by passing 'accessGrants' as a dependency in the second argument array of useMemo.
    const dataSource: IDocumentationLink[] = useMemo(() => ([
        {
            title: 'http://learning.mckesson.com',
            summary: 'McKesson Training Portal',
            required_models: ['star-schema', 'apsmain', 'cps-phase1']
        },
    ]).filter(link => link.required_models.some(model => accessGrants.includes(model))), [accessGrants]);


    // Defining the columns for our Table component.
    const columns = useMemo(() => ([
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
            render: text => <a href={text} target='_blank' rel='noopener noreferrer'>{text}</a>,
        },
        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
            width: '50%',
        },
    ]), []);

    // Rendering the Table component with our defined data source and columns.
    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
        />
    );
};

export default LinksDocumentationView;
