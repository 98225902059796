import { Modal } from "antd";
import React from "react";
import { IAlertInfo } from "../../../AppModels";
import { ILicenseChangeAction } from "../../../services/AdminManagement/EditUsersManagement/EditUsersModels";
import { API } from "../../../services/LookerAPI/LookerAPI";
import "./ChangeLicenseModal.scss";

interface IChangeLicenseModalProps {
    licenseChange: ILicenseChangeAction;
    refreshData: () => void;
    setAlertInfo: (alertInfo: IAlertInfo) => any;
    setLoading(isLoading: boolean): void;
    exit: () => void;
}

const ChangeLicenseModal: React.FC<IChangeLicenseModalProps> = ({ licenseChange, refreshData, 
    setAlertInfo, setLoading, exit }) => {

    const closeDeleteModal = () =>{
        exit();
    }

    const changeLicense = () => {
        setLoading(true);
        API.setUserHoldsLicense(licenseChange.user.username, licenseChange.newLicenseStatus, changeComplete, changeError);
        exit();
      }
    
    const changeComplete = () => {    
        setAlertInfo({
            visible: true,
            type: "success",
            message:  licenseChange.newLicenseStatus ?
                "Successfully assigned " + licenseChange.user.license + " License to user '" + licenseChange.user.displayName + "'." :
                "Successfully removed " + licenseChange.user.license + " License from user '" + licenseChange.user.displayName + "'."
        });    
        //refreshData will set loading true again, and back to false when complete
        refreshData();
    }

    const changeError = () => {   
      setLoading(false);     
      setAlertInfo({
          visible: true,
          type: "error",
          message: licenseChange.newLicenseStatus ?
          "There was an unexpected error attempting to assign license to user '" + licenseChange.user.displayName + "'." :
          "There was an unexpected error attempting to remove license from user '" + licenseChange.user.displayName + "'." 
      });
  }

  return (
    <>
    {   
        licenseChange.newLicenseStatus ? 
        <Modal
            title={'Assign ' + licenseChange.user.license + ' License to ' + licenseChange.user.displayName + '?'}
            visible={true}
            onOk={changeLicense}
            okText={'Assign License'}
            onCancel={closeDeleteModal}
            width={650}
        >      
        <>
            <h4>Are you sure you want to assign one {licenseChange.user.license} License to user "{licenseChange.user.displayName}"?</h4>
        </>
        </Modal>
        :
        <Modal
            title={'Remove ' + licenseChange.user.license + ' License from ' + licenseChange.user.displayName + '?'}
            visible={true}
            onOk={changeLicense}
            okText={'Remove License'}
            onCancel={closeDeleteModal}
            width={650}
        >      
        <>
            <h4>Are you sure you want to remove the {licenseChange.user.license} License from user "{licenseChange.user.displayName}"?</h4>
            <br />
                <div className="modal-user-license-warning">
                    This will immediately stop any schedules created by this user. 
                    <br />
                    Unlicensed users and their content are periodically deleted from this application.
                </div>

        </>
        </Modal>
    }
    </>
  );
};
 
export default ChangeLicenseModal;
  