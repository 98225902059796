import { BarChartOutlined, ControlOutlined, HomeOutlined, LeftOutlined, PlusCircleOutlined, RightOutlined, ScheduleOutlined, SolutionOutlined, TeamOutlined, UserOutlined, BellOutlined } from "@ant-design/icons";
import { Layout, Menu, Tooltip, Image } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { ICustomerSettings, IStandardReportsGroupItem } from "../../AppModels";
import { isAuthorized, isRealmAuthorized } from "../../services/RoleManagement/RoleManagement";
import { Permission, Role } from "../../services/RoleManagement/RoleModels";
import { getCustomerSettings, getStandardReports } from "../../store/actions/SettingsActions";
import { cpsPremiumStatus } from "../../store/actions/UserAuthActions";
import { IApplicationState } from "../../store/Store";
import "./SideBar.scss";
import StandardReportsMenu from "./StandardReports/StandardReportsMenu";

const { Sider } = Layout;

interface ISidebarProps {
  roleMapping: Map<Role, boolean>;
  realm: string;
  refreshFunction: () => void;
}

function Sidebar(props: ISidebarProps) {
  let location = useLocation();
  const dispatch = useDispatch();
  const standardReports: IStandardReportsGroupItem[] = useSelector<IApplicationState>(state => state.settings.standardReports) as IStandardReportsGroupItem[];
  const customerSettings: ICustomerSettings = useSelector<IApplicationState>(state => state.settings.customerSettings) as ICustomerSettings;
  //const {width} = useWindowDimensions();

  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [urlKey, setUrlKey] = React.useState<string>("");

  const dataMarts: string[] = useSelector<IApplicationState>(state => state.userAuth.models) as string[];

  React.useEffect(() => {
    dispatch(cpsPremiumStatus())
  }, [dispatch])

  useEffect(()=>{
    if(standardReports==null){
      dispatch(getStandardReports());
    }
  }, [standardReports, dispatch])

  useEffect(()=>{
    if(customerSettings==null){
      dispatch(getCustomerSettings());
    } 
  }, [customerSettings, dispatch])

  useEffect(() => {
    setUrlKey(location.pathname);
  }, [location]);

  function changeCollapse() {
    setCollapsed(!collapsed);
    console.log(dataMarts)
  }

  const showMoveTest: boolean = false;

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      width="220px"
      className="custom-sidebar-border"
      style={{
        overflow: "auto",
        height: "100vh",
      }}
      trigger={
        <div className="custom-sidebar-border" onClick={changeCollapse}>
          {collapsed ? (
            <Tooltip title="&nbsp;&nbsp;Maximize Menu" placement="right">
              <RightOutlined className="sidebar-icon" />
            </Tooltip>
          ) : (
            <div className="custom-sidebar-trigger-expanded">
              <LeftOutlined className="sidebar-icon" />
              <span>Minimize Menu</span>
            </div>
          )}
        </div>
      }
    >
      {collapsed ? (
        <div style={{width: '100%', textAlign: 'center'}}>
         <Image preview={false} rootClassName="monogram" src="/McKesson_Monogram.png" alt="McKesson Logo"/>
        </div>
      ) : (
        <Image preview={false} rootClassName="logo" src="/McKesson_Logo.png" alt="McKesson Logo" />
      )}
      <div className="custom-sidebar-menu">
        <Menu selectedKeys={[urlKey]} mode="inline" onClick={props.refreshFunction}>
          <Menu.Item key="/home" icon={<HomeOutlined className="sidebar-icon" />}>
            <Link to="/home">
              <span>Home</span>
            </Link>
          </Menu.Item>
          <StandardReportsMenu
            collapsed={collapsed}
            roleMapping={props.roleMapping}
            dataMarts={dataMarts}
          />
          <SubMenu
            className="custom-sidebar-submenu"
            key="myReportsSubMenu"
            title={
              <span>
                <UserOutlined className="sidebar-icon" />
                <span>My Reports</span>
              </span>
            }
          >
            <Menu.Item key="/myReports" className="custom-sidebar-submenu-item" disabled={!isAuthorized(props.roleMapping,Permission.VIEW_MY_REPORTS_TAB)}>
              <Link to="/myReports">
                <span>My Reports</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/favorites" className="custom-sidebar-submenu-item">
              <Link to="/favorites">
                <span>Favorites</span>
              </Link>
            </Menu.Item>
            {
              //Commenting favorites for now since there is no targeted completion date for implementing
              /*<Menu.Item key="/favorites" className="custom-sidebar-submenu-item">
              <Link to="/favorites">
                <span>Favorites</span>
              </Link>
            </Menu.Item>*/
            }
            <Menu.Item
              key="/reportHistory"
              className="custom-sidebar-submenu-item"
            >
              <Link to="/reportHistory">
                <span>Recently Viewed Reports</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/sharedReports" icon={<TeamOutlined className="sidebar-icon" />}>
              <Link to="/sharedReports">
                <span>Shared Reports</span>
              </Link>
          </Menu.Item>
          <Menu.Item key="/explore" disabled={!isAuthorized(props.roleMapping,Permission.VIEW_EXPLORE_TAB)} icon={<BarChartOutlined className="sidebar-icon" />}>
            <Link to="/explore">
              <span>Explore</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/schedules" icon={<ScheduleOutlined className="sidebar-icon" />}>
            <Link to="/schedules">
              <span>Schedules</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/alerts" disabled={!isAuthorized(props.roleMapping,Permission.VIEW_ALERTS_TAB)} icon={<BellOutlined className="sidebar-icon" />}>
            <Link to="/alerts">
              <span>Alerts</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/documentation" icon={<SolutionOutlined className="sidebar-icon" />}>
            <Link to="/documentation">
              <span>Documentation</span>
            </Link>
          </Menu.Item>
          { isAuthorized(props.roleMapping, Permission.VIEW_ADMIN_TAB) && (
            <SubMenu
              className="custom-sidebar-submenu"
              key="adminSubMenu"
              title={
                <span>
                  <ControlOutlined className="sidebar-icon" />
                  <span>Admin</span>
                </span>
              }
            >
              <Menu.Item key="/admin/groups" className="custom-sidebar-submenu-item">
                <Link to="/admin/groups">
                  <span>Groups</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/users" className="custom-sidebar-submenu-item">
                <Link to="/admin/users">
                  <span>Users</span>
                </Link>
              </Menu.Item>
              { isAuthorized(props.roleMapping, Permission.VIEW_NOTIFICATION_MANAGEMENT) && 
                isRealmAuthorized(props.realm, Permission.VIEW_NOTIFICATION_MANAGEMENT) && (
                <Menu.Item key="/admin/notificationManagement" className="custom-sidebar-submenu-item">
                  <Link to="/admin/notificationManagement">
                      <span>Notification Management</span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}
          {showMoveTest && (
            <Menu.Item key="/moveTests">
              <Link to="/moveTests">
                <PlusCircleOutlined className="sidebar-icon" />
                <span>Move Tests</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="empty">
            {/* Empty because trigger hides last menu item */}
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    roleMapping: state.userAuth.roleMapping,
    realm: state.userAuth.realm,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
