import { Reducer } from "redux";

import {
  IUserAuthState,
  UserAuthActions,
  UserAuthActionTypes,
} from "../actions/UserAuthTypes";
import { LOCAL_STORAGE_USER, LOCAL_STORAGE_FACILITY_SELECTIONS, LOCAL_STORAGE_LAST_ACTIVITY, LOCAL_STORAGE_OKTA_REGULAR_FLAG, LOCAL_STORAGE_OKTA_SUPPORT_REALM, LOCAL_STORAGE_OKTA_SELECTED_USER } from "../../Constants";
import { LocalStorageUserInfo } from "../../AppModels";

const initialUserAuthState: IUserAuthState = {
  credentials: { username: "", password: "", realm: "", support: false },
  isLoggedIn: false,
  checkForUserComplete: false,
  errorMessage: null,
  authorizedAt: null, // Track time of receiving embeddedUrl, in case we need to renew.
  roleMapping: null,
  models: null,
  username: null,
  realm: null,
  isSupport: false,
  expiration: null,
  sudoUser: null,
  scopeOfControlUser: null,
  printDesignerRole: null,
  oktaLogin: false,
  federatedLogin: false,
  oktaLogout: false,
  federatedPrompt: null,
  cpsPremiumEnabled: false,
  loginId: null,
};

export const userAuthReducer: Reducer<IUserAuthState, UserAuthActions> = (
  state = initialUserAuthState,
  action
): IUserAuthState => {
  // console.log("userAuthReducer");
  // console.log("action = ", action);
  // console.log("action.type = " + action.type);
  switch (action.type) {
    case UserAuthActionTypes.LOGIN: {
      let errMessage = (state.errorMessage && action.errorMessage && state.errorMessage.trim()===action.errorMessage.trim()) ? 
        (state.errorMessage + ' ') : action.errorMessage;
      return {
        ...state,
        credentials: action.credentials,
        errorMessage: errMessage,
        isLoggedIn: action.isLoggedIn,
        roleMapping: action.roleMapping,
        models: action.models,
        username: action.username,
        realm: action.realm,
        isSupport: action.isSupport,
        expiration: action.expiration,
        sudoUser: action.sudoUser,
        scopeOfControlUser: action.scopeOfControlUser,
        printDesignerRole: action.printDesignerRole,
        oktaLogin: action.oktaLogin,
        federatedLogin: action.federatedLogin,
        loginId: action.loginId,
      };
    }
    case UserAuthActionTypes.OKTA_LOGIN: {
      let errMessage = (state.errorMessage && action.errorMessage && state.errorMessage.trim()===action.errorMessage.trim()) ? 
        (state.errorMessage + ' ') : action.errorMessage;
      return {
        ...state,
        errorMessage: errMessage,
        isLoggedIn: action.isLoggedIn,
        roleMapping: action.roleMapping,
        models: action.models,
        username: action.username,
        realm: action.realm,
        isSupport: action.isSupport,
        expiration: action.expiration,
        sudoUser: action.sudoUser,
        scopeOfControlUser: action.scopeOfControlUser,
        printDesignerRole: action.printDesignerRole,
        oktaLogin: action.oktaLogin,
        federatedLogin: action.federatedLogin,
        federatedPrompt: action.federatedPrompt,
        loginId: action.loginId,
      };
    }
    case UserAuthActionTypes.LOOKUP: {
      let errMessage = (state.errorMessage && action.errorMessage && state.errorMessage.trim()===action.errorMessage.trim()) ? 
        (state.errorMessage + ' ') : action.errorMessage;
      return {
        ...state,
        errorMessage: errMessage,
        isLoggedIn: action.isLoggedIn,
        roleMapping: action.roleMapping,
        models: action.models,
        username: action.username,
        realm: action.realm,
        isSupport: action.isSupport,
        checkForUserComplete: true,
        expiration: action.expiration,
        sudoUser: action.sudoUser,
        scopeOfControlUser: action.scopeOfControlUser,
        printDesignerRole: action.printDesignerRole,
        oktaLogin: action.oktaLogin,
        federatedLogin: action.federatedLogin,
        loginId: action.loginId,
      };
    }
    case UserAuthActionTypes.PROPAGATE_LOGIN: {
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
        errorMessage: action.errorMessage,
        roleMapping:  action.roleMapping,
        models: action.models,
        username: action.username,
        realm: action.realm,
        isSupport: action.isSupport,
        expiration: action.expiration,
        sudoUser: action.sudoUser,
        scopeOfControlUser: action.scopeOfControlUser,
        printDesignerRole: action.printDesignerRole,
        oktaLogin: action.oktaLogin,
        federatedLogin: action.federatedLogin,
        loginId: action.loginId,
      }
    }
    case UserAuthActionTypes.LOGOUT: {
      const userInfo : LocalStorageUserInfo = {
        loggedIn: false,
        username: null,
        realm: null,
        roles: null,
        models: null,
        isSupport: false,
        expiration: null,
        updated: new Date(),
        sudoUser: null,
        scopeOfControlUser: null,
        printDesignerRole: null,
        oktaLogin: false,
        federatedLogin: false,
        loginId: null
      }
      localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(userInfo));
      localStorage.removeItem(LOCAL_STORAGE_FACILITY_SELECTIONS);
      localStorage.removeItem(LOCAL_STORAGE_LAST_ACTIVITY);
      localStorage.removeItem(LOCAL_STORAGE_OKTA_REGULAR_FLAG);
      localStorage.removeItem(LOCAL_STORAGE_OKTA_SUPPORT_REALM);
      localStorage.removeItem(LOCAL_STORAGE_OKTA_SELECTED_USER);
      return {
        ...state,
        isLoggedIn: false,
        credentials: { username: "", password: "", realm: "", support: false },
        username:  null,
        realm: null,
        isSupport: false,
        checkForUserComplete: true,
        errorMessage: null,
        roleMapping: null,
        models: null,
        sudoUser: null,
        scopeOfControlUser: null,
        printDesignerRole: null,
        oktaLogin: false,
        federatedLogin: false,
        oktaLogout: action.oktaLogout,
        loginId: null,
      };
    }
    case UserAuthActionTypes.PROPAGATE_LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        credentials: { username: "", password: "", realm: "", support: false },
        username:  null,
        realm: null,
        isSupport: false,
        checkForUserComplete: true,
        errorMessage: null,
        roleMapping: null,
        models: null,
        sudoUser: null,
        scopeOfControlUser: null,
        printDesignerRole: null,
        oktaLogin: false,
        federatedLogin: false,
        loginId: null,
      };
    }
    case UserAuthActionTypes.CPS_PREMIUM: {
      return {
        ...state,
        cpsPremiumEnabled: action.cpsPremiumEnabled
      }
    }
    case UserAuthActionTypes.SET_ERROR_FROM_OKTA: {
      return {
        ...state,
        errorMessage: action.errorMessage
      }
    }
    default: {
      return state;
    }
  }
};
