import React from 'react';
import { Modal, Button } from 'antd';
import { INotification } from '../../../services/NotificationsManagement/NotificationsModels';

// Interface defining the props for this component
interface IProps {
  title?: string;                 // Optional Modal title
  content?: React.ReactNode;      // Optional Modal content
  visible: boolean;               // Visibility state of the modal
  onClose: () => void;            // Function to close the modal
  notification: INotification;    // Notification details to display
}

// React functional component for displaying notification details
const NotificationDetails: React.FC<IProps> = ({
  title = "Notification Details",
  content,
  visible,
  onClose,
  notification
}) => {
  
  return (
    <Modal
      width={650}
      title={notification.title || title}  // The title of the modal is either the notification's title or a default value
      open={visible}                       // Controls whether the modal is open or not
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>
      ]}
      onCancel={onClose}
      zIndex={1050}
    >
      { //content || notification.modalInfo || notification.summary}  {/* The content of the modal is determined by multiple properties from the notification object */}
        notification.modalInfo &&
        notification.modalInfo.split('\n').map((modalLine, index) => 
          {
            return <span key={'line' + index}>{modalLine}<br /><br /></span>
          }
        )
      }
    </Modal>
  );
}

export default NotificationDetails;
