import React from "react";
import { connect } from "react-redux";
import { IReportGroupType, IRootFolderIds, IDisplayFolderItem, IAncestorInfo, IEmbedUsername } from "../../services/FolderManagement/FolderModels";
import { getRootFolders, setCurrentItemAndAncestors, getGroupMemberNames } from "../../store/actions/FolderActions";
import { IApplicationState } from "../../store/Store";
import ReportsContainer from "../ReportsContainer/ReportsContainer";
import { Layout, Alert } from "antd";

interface IProps {
  rootFolders: IRootFolderIds;
  rootFoldersError: string;
  group: IReportGroupType;
  realm: string;
  getRootFolders: typeof getRootFolders;
  currentItem: IDisplayFolderItem;
  currentAncestors: IAncestorInfo[];
  setCurrentItemAndAncestors: typeof setCurrentItemAndAncestors;
  groupMemberNameMap: Map<string, IEmbedUsername>;
  getGroupMemberNames: () => void;
}

const GroupedReports: React.FC<IProps> = ({ rootFolders, rootFoldersError, group, getRootFolders, currentItem, currentAncestors, setCurrentItemAndAncestors, realm, groupMemberNameMap, getGroupMemberNames }) => {

  const [startFolderId, setStartFolderId] = React.useState(null as string);
  const [item, setItem] = React.useState<IDisplayFolderItem>(null);
  const [ancestors, setAncestors] = React.useState<IAncestorInfo[]>(null);
  const [rootFolderId, setRootFolderId] = React.useState(null as string);
  const [rootFolderName, setRootFolderName] = React.useState(null as string);

  React.useEffect(()=>{
    if(currentItem!=null && currentAncestors!=null){
      setItem(currentItem);
      setAncestors(currentAncestors);
      setCurrentItemAndAncestors(null, null);
    } 
  }, [currentItem, currentAncestors, setCurrentItemAndAncestors]);

  React.useEffect(() => {
    if (rootFolders == null || rootFolders.personalRootId == null) {
      getRootFolders(realm);
    }
    if (rootFolders) {
      setStartFolderId((group === IReportGroupType.TEAM) ?
        rootFolders.teamSharedRootId : rootFolders.personalRootId);
      if(group === IReportGroupType.TEAM && rootFolders.teamRootId){
        setRootFolderId(rootFolders.teamRootId);
        setRootFolderName("Root Folder");
      }
    }
    if (group) {
      document.title = group;
    }
  }, [getRootFolders, rootFolders, group, realm]);

  React.useEffect(() =>{
    if(groupMemberNameMap == null){
      getGroupMemberNames();
    }
  }, [groupMemberNameMap, getGroupMemberNames])

  return (
    <React.Fragment>
      <div>
        {
          startFolderId 
          ?
          <ReportsContainer
            rootFolderId={rootFolderId}
            rootFolderName={rootFolderName}
            startFolderId={startFolderId}
            startFolderName={group}
            startingItem={item}
            startingBreadcrumbs={ancestors}
          />
          :
          (
            rootFoldersError &&
              <Layout.Content 
              style = {{
                padding: "32px 64px"
              }}
              > 
                <Alert 
                  style={{ marginBottom: "24px"  }}
                  type="error"
                  message={rootFoldersError}  
                />
              </Layout.Content>
          )
        }
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    rootFolders: state.folders.rootFolders,
    rootFoldersError: state.folders.rootFoldersError,
    currentItem: state.folders.currentItem,
    currentAncestors: state.folders.currentAncestors,
    realm: state.userAuth.realm,
    groupMemberNameMap: state.folders.groupMemberNameMap,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getRootFolders: (realm: string) =>
      dispatch(getRootFolders(realm)),
    setCurrentItemAndAncestors: (item: IDisplayFolderItem, ancestors: IAncestorInfo[]) =>
      dispatch(setCurrentItemAndAncestors(item, ancestors)),
    getGroupMemberNames: () => dispatch(getGroupMemberNames())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupedReports);
