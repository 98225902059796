import { Reducer } from 'redux'
import { INotificationsState, NotificationActionTypes, NotificationActions } from '../actions/NotificationActionTypes';

const initialSettingsState: INotificationsState = {
    notifications: [],
    error: null
};

export const notificationsReducer: Reducer<
    INotificationsState,
    NotificationActions
> = (state = initialSettingsState, action): INotificationsState => {
    switch (action.type) {
        case NotificationActionTypes.GET_ALL_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.notifications,
                error: action.error
            }
        }
        case NotificationActionTypes.SET_NOTIFICATION_STATUS: {

            return {
                ...state,
                notifications: state.notifications.map(notification => {
                    if (action.readEntry && notification.notificationId === action.readEntry.notificationId) {
                        const existingEntryIndex = notification.readHistory.findIndex(
                            e => e.notificationId === action.readEntry.notificationId && e.realmUsername === action.readEntry.realmUsername
                        );
            
                        if (existingEntryIndex > -1) {
                            // Update the existing entry
                            notification.readHistory[existingEntryIndex] = action.readEntry;
                        } else {
                            // Add a new entry
                            notification.readHistory.push(action.readEntry);
                        }
                    }
                    return notification;
                }),
                error: action.error
            }
        }
        case NotificationActionTypes.SET_NOTIFICATION_STATUS_BULK: {
            return {
                ...state,
                notifications: state.notifications.map(notification => {
                    // Checking if there's a matching read entry for the current notification
                    const matchingReadEntry = action.readEntries.find(entry => entry.notificationId === notification.notificationId);
                    
                    if (matchingReadEntry) {
                        const existingEntryIndex = notification.readHistory.findIndex(
                            e => e.notificationId === matchingReadEntry.notificationId && e.realmUsername === matchingReadEntry.realmUsername
                        );
        
                        if (existingEntryIndex > -1) {
                            // Update the existing entry
                            notification.readHistory[existingEntryIndex] = matchingReadEntry;
                        } else {
                            // Add a new entry
                            notification.readHistory.push(matchingReadEntry);
                        }
                    }
                    return notification;
                }),
                error: action.error
            }
        }
        
        case NotificationActionTypes.CLEAR_ERROR: {
            return {
                ...state,
                error: null
            }
        }
        default: {
            return state;
        }
    }
};