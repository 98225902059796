import { Modal } from "antd";
import React from "react";
import { IAlertInfo } from "../../AppModels";
import { IDeleteSource, IDisplayFolderItemType } from "../../services/FolderManagement/FolderModels";
import { API } from "../../services/LookerAPI/LookerAPI";

interface IDeleteFolderItemContainerProps {
    deleteSource: IDeleteSource;
    deleteFolderNavigation: () => void;
    triggerFolderRefresh: () => void;
    showAlert: (alertInfo: IAlertInfo) => void;
    exit: () => void;
}

const DeleteFolderItemContainer: React.FC<IDeleteFolderItemContainerProps> = (props: IDeleteFolderItemContainerProps) => {

    const closeDeleteModal = () =>{
        props.exit();
    }

    const deleteItem = () => {
        if(props.deleteSource.type === IDisplayFolderItemType.DASHBOARD){
          API.deleteDashboard(props.deleteSource.id, deleteComplete, deleteError);
        } else if (props.deleteSource.type === IDisplayFolderItemType.LOOK){
          API.deleteLook(props.deleteSource.id, deleteComplete, deleteError);
        } else if (props.deleteSource.type === IDisplayFolderItemType.FOLDER){
          API.deleteFolder(props.deleteSource.id, deleteComplete, deleteError);
        }
        props.exit();
      }
    
    const deleteComplete = () => {        
        if(props.deleteSource.type === IDisplayFolderItemType.FOLDER){
            props.deleteFolderNavigation();
        } else {
            props.triggerFolderRefresh();
        }
        props.showAlert({
            type: "success",
            message:  props.deleteSource.type + ' successfully deleted.',
        }) 
    }

    const deleteError = () => {        
      props.showAlert({
          type: "error",
          message: 'An unexpected error occured attempting to delete ' + props.deleteSource.type + '.',
      })
  }

  return (
    <>
    <Modal
        title={'Delete "' + props.deleteSource.name + '"?'}
        visible={true}
        onOk={deleteItem}
        okText={'Delete ' + props.deleteSource.type}
        onCancel={closeDeleteModal}
        width={650}
    >      
    <>
        <h4>Are you sure you want to delete the {props.deleteSource.type.toLocaleLowerCase()} "{props.deleteSource.name}"?</h4>
        <br />
            { props.deleteSource.type === IDisplayFolderItemType.FOLDER 
                ?
            <>
            <div className="modal-folder-delete-warning">
                This will delete all contents of this folder, including <b>{props.deleteSource.folderInfo.looks.length} look{props.deleteSource.folderInfo.looks.length === 1 ? '' : 's'} </b> 
                and <b>{props.deleteSource.folderInfo.dashboards.length} dashboard{props.deleteSource.folderInfo.dashboards.length === 1 ? '' : 's'}</b>.
                <br />
                This will also delete <b>{props.deleteSource.folderInfo.children.length} folder{props.deleteSource.folderInfo.children.length === 1 ? '' : 's'}</b> and <b>ALL</b> contents of those folders.
                <br/>
                This will also reset any home pages that were set using the contents of this folder.
            </div>
            <br />
            </>
            :
            <>
            <div className="modal-folder-delete-warning">
                This will reset any home pages that were set using this {[props.deleteSource.type]}.
            </div>
            <br />
            </>
            }
        This <b>CANNOT</b> be undone.
    </>
    </Modal>
    </>
  );
};
 
export default DeleteFolderItemContainer;
  