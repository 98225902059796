import React from "react";
import { connect } from "react-redux";
import { IAlertInfo } from "../../AppModels";
import { IDisplayFolderItem, ReportActionTypes, ReportsAction } from "../../services/FolderManagement/FolderModels";
import { IApplicationState } from "../../store/Store";
import DeleteFolderItemContainer from "../DeleteFolderItem/DeleteFolderItemContainer";
import MoveToFolderContainer from "../MoveToFolder/MoveToFolderContainer";
import NewFolderItemContainer from "../NewFolderItem/NewFolderItemContainer";
import RenameFolderItemContainer from "../RenameFolderItem/RenameFolderItemContainer";


interface IProps {
    action: ReportsAction;
    setAction: (action: ReportsAction) => void;
    showAlert: (alert: IAlertInfo) => void;
    goToItem: (item: IDisplayFolderItem) => void;
    goBackOneBreadcrumb: () => void;
    triggerFolderRefresh: () => void;
    updateFolderNameInBreadcrumb: (name: string) => void;
}

const ReportsActionContainer: React.FC<IProps> = ({ action, setAction, showAlert, 
    goToItem, goBackOneBreadcrumb, triggerFolderRefresh, updateFolderNameInBreadcrumb}) => {

  const exit = () => {
    setAction(null);
  }

  return (
    <>
      {(()=> {
        switch(action.type){
        case ReportActionTypes.MOVE:{
            return <MoveToFolderContainer
                    moveFrom={action.moveSource}
                    abortMove={exit}
                    showAlert={showAlert}
                    moveFolderNavigation={goBackOneBreadcrumb}
                    triggerFolderRefresh={triggerFolderRefresh}
                    copy={false}
                    />;
        }
        case ReportActionTypes.COPY:
          return <MoveToFolderContainer
                  moveFrom={action.copySource}
                  abortMove={exit}
                  showAlert={showAlert}
                  moveFolderNavigation={goBackOneBreadcrumb}
                  triggerFolderRefresh={triggerFolderRefresh}
                  copy={true}
                  />;
        case ReportActionTypes.NEW:
            return <NewFolderItemContainer
                        newSource={action.newSource}
                        setAction={setAction}
                        goToItem={goToItem}
                        showAlert={showAlert}
                    />;
        case ReportActionTypes.RENAME:
          return <RenameFolderItemContainer
                      renameSource={action.renameSource}
                      setAction={setAction}
                      goToItem={goToItem}
                      updateFolderNameInBreadcrumb={updateFolderNameInBreadcrumb}
                      triggerFolderRefresh={triggerFolderRefresh}
                      showAlert={showAlert}
                  />;            
        case ReportActionTypes.DELETE:
            return <DeleteFolderItemContainer
                        deleteSource={action.deleteSource}
                        deleteFolderNavigation={goBackOneBreadcrumb}
                        triggerFolderRefresh={triggerFolderRefresh}
                        showAlert={showAlert}
                        exit={exit}
                    />
        default:            
            return <>         
                </>;
        }
        })()}
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    rootFolders: state.folders.rootFolders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsActionContainer);
