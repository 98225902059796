import { API } from "../LookerAPI/LookerAPI";
import { IAllLists, ICustomDrugList, IDrugListSaveResult } from "./CustomAdherenceDrugListModels";

export const getAllApsDrugLists = async (
    setResult: (drug_list: IAllLists) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: IAllLists) => {
        setResult(result);
    };
    await API.getAllApsCustomDrugLists(getResult, setError);
};

export const getApsDrugListDetails = async (
    listName: string,
    setResult: (drug_list: ICustomDrugList) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: ICustomDrugList) => {
        setResult(result);
    };
    await API.getApsCustomDrugListDetails(listName, getResult, setError);
};

export const deleteApsDrugList = async (
    listName: string,
    setResult: (status: string) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: string) => {
        setResult(result);
    };
    await API.deleteApsCustomDrugList(listName, getResult, setError);
};

export const saveAndVerifyDrugList = async (
    drugList: ICustomDrugList,
    setResult: (status: IDrugListSaveResult) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: IDrugListSaveResult) => {
        setResult(result);
    };
    await API.saveAndVerifyApsCustomDrugList(drugList, getResult, setError);
};