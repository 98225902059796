import { CalendarOutlined, InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { Badge, Breadcrumb, Col, Layout, Row, Tooltip } from "antd";
import React, { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAlertInfo, ICustomerSettings } from "../../AppModels";
import { getFolderAncestors } from "../../services/FolderManagement/FolderManagement";
import { IAncestorInfo, IDisplayFolderItem, IDisplayFolderItemType, IReportGroupType, IRootFolderIds, ReportsAction } from "../../services/FolderManagement/FolderModels";
import { API } from "../../services/LookerAPI/LookerAPI";
import { getCustomerSettings } from "../../store/actions/SettingsActions";
import { IApplicationState } from "../../store/Store";
import Looker from "../Looker/Looker";
import ReportsActionContainer from "../ReportsActionContainer.tsx/ReportsActionContainer";
import ReportsFolder from "../ReportsFolder/ReportsFolder";
import "./ReportsContainer.scss";

interface IProps {
  rootFolderId: string;
  rootFolderName: string;
  startFolderId: string;
  startFolderName: string;
  startingItem: IDisplayFolderItem;
  startingBreadcrumbs: IAncestorInfo[];
}

interface BreadcrumbInfo {
  position: number;
  item: IDisplayFolderItem;
  disabled?: boolean;
}

const ReportsContainer: React.FC<IProps> = (props: IProps) => {
  const [displayedItem, setDisplayedItem] =
    React.useState<IDisplayFolderItem>(null);
  const [currentItem, setCurrentItem] =
    React.useState<IDisplayFolderItem>(null);
  const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbInfo[]>([]);
  const [alert, setAlert] = React.useState<IAlertInfo>(null);
  const [action, setAction] = React.useState<ReportsAction>(null);
  const [folderRefreshCount, setFolderRefreshCount] = React.useState(0);
  const [hideBadge, setHideBadge] = React.useState<boolean>(true);
  const [rtErrorMessage, setRtErrorMessage] = React.useState<ReactNode>(null);

  const rootFolders: IRootFolderIds = useSelector<IApplicationState>(
    (state) => state.folders.rootFolders
  ) as IRootFolderIds;
  const customerSettings: ICustomerSettings = useSelector<IApplicationState>(
    (state) => state.settings.customerSettings
  ) as ICustomerSettings;
  const customerSettingsError: string = useSelector<IApplicationState>(state => state.settings.customerSettingsError) as string;

  const dispatch = useDispatch();

  React.useEffect(() => {
    setDisplayedItem(currentItem);
  }, [currentItem]);

  const updateBreadcrumbs = React.useCallback(
    (
      rootItem: IDisplayFolderItem,
      parentItem: IDisplayFolderItem,
      newItem: IDisplayFolderItem,
      newAncestors: IAncestorInfo[]
    ) => {
      let _breadcrumbs: BreadcrumbInfo[];
      if (rootItem && parentItem) {
        _breadcrumbs = [
          { position: 0, item: rootItem },
          { position: 1, item: parentItem },
        ];
      } else if (rootItem) {
        _breadcrumbs = [{ position: 0, item: rootItem }];
      } else {
        _breadcrumbs = [{ position: 0, item: parentItem }];
      }
      newAncestors.forEach((ancestor) => {
        _breadcrumbs.push({
          position: _breadcrumbs.length,
          item: {
            type: IDisplayFolderItemType.FOLDER,
            id: ancestor.id,
            name: ancestor.name,
          },
        });
      });
      _breadcrumbs.push({
        position: _breadcrumbs.length,
        item: newItem,
      });
      setBreadcrumbs(_breadcrumbs);
    },
    []
  );

  const changeDisplayedItem = React.useCallback(
    
    (newlyDisplayedItem: IDisplayFolderItem) => {
      

      if (
        newlyDisplayedItem != null &&
        displayedItem != null &&
        (newlyDisplayedItem.type !== displayedItem.type ||
          newlyDisplayedItem.id.toString() !== displayedItem.id.toString())
      ) {
        const getAncestorsForFolder = (data: any) => {
          if (data && data.folder && data.folder.id && data.folder.name) {
            const processAncestors = (result: any) => {
              let ancestors: IAncestorInfo[] = result;
              ancestors.push({
                id: data.folder.id,
                name:
                  data.folder.name.indexOf("@TeamShared") !== -1
                    ? "Shared Reports"
                    : data.folder.name,
                parent_id: null,
              });
              let item: IDisplayFolderItem = {
                type: newlyDisplayedItem.type,
                id: data.id,
                name: data.title,
              };

              for (let i = 0; i < ancestors.length; i++) {
                let ancestor = ancestors[i];
                if (
                  rootFolders &&
                  rootFolders.teamRootId &&
                  ancestor.id === rootFolders.teamRootId
                ) {
                  const rootFolder = {
                    name: "Root Folder",
                    type: IDisplayFolderItemType.FOLDER,
                    id: rootFolders.teamRootId,
                  };
                  updateBreadcrumbs(
                    rootFolder,
                    null,
                    item,
                    ancestors.slice(i + 1)
                  );
                  setDisplayedItem(newlyDisplayedItem);
                  return;
                } else if (
                  rootFolders &&
                  rootFolders.personalRootId &&
                  ancestor.id === rootFolders.personalRootId
                ) {
                  const startFolder = {
                    name: IReportGroupType.MY,
                    type: IDisplayFolderItemType.FOLDER,
                    id: rootFolders.personalRootId,
                  };
                  updateBreadcrumbs(
                    null,
                    startFolder,
                    item,
                    ancestors.slice(i + 1)
                  );
                  setDisplayedItem(newlyDisplayedItem);
                  return;
                }
              }
            };
            getFolderAncestors(data.folder.id, processAncestors, () => {});
          }
        };
        if (newlyDisplayedItem.type === IDisplayFolderItemType.LOOK) {
          API.getLook(
            newlyDisplayedItem.id,
            null,
            getAncestorsForFolder,
            () => {}
          );
        } else if (
          newlyDisplayedItem.type === IDisplayFolderItemType.DASHBOARD
        ) {
          API.getDashboard(
            newlyDisplayedItem.id,
            null,
            getAncestorsForFolder,
            () => {}
          );
        }
      }
    },
    [displayedItem, rootFolders, updateBreadcrumbs]
  );

  React.useEffect(() => {
    setHideBadge(true)

    const setDashboardResult = (result: any) => {
      if (result["dashboard_elements"]) {
        result["dashboard_elements"].forEach((value) => {
          if (value["query"] && value["title"]) {
            if (value["query"]["model"] === "star-schema") {
              setHideBadge(false)
            } 
          }
        });
      }
    };

    const setLookResult = (result: any) => {
      if (result && result["query"] && result["title"]) {
        if (result["query"]["model"] === "star-schema") {
          setHideBadge(false)
        } 
      }
    };

    if (currentItem && currentItem.type === IDisplayFolderItemType.DASHBOARD) {
      
      API.getDashboard(
        currentItem.id,
        "dashboard_elements(query(view, model), title)",
        setDashboardResult,
        () => null
      );
    } else if (
      currentItem &&
      currentItem.type === IDisplayFolderItemType.LOOK
    ) {
      API.getLook(
        currentItem.id,
        "title,query(view, model)",
        setLookResult,
        () => null
      );
    } 

  }, [currentItem, customerSettings]);

  React.useEffect(()=>{
    if(customerSettings==null && customerSettingsError!=null){
      setRtErrorMessage(
        <div 
        onClick={() => {
          dispatch(getCustomerSettings());
          setRtErrorMessage(null); 
        }} 
        className="clickable">
        There was an error checking Real-Time Configuration. <u>Click to Retry <ReloadOutlined /></u>
      </div>
      )
    } else if(customerSettings!=null){
      setRtErrorMessage(null);
    }
  },[customerSettings, customerSettingsError, dispatch])

  React.useEffect(() => {
    let rootItem: IDisplayFolderItem = null;
    if (props.rootFolderId != null) {
      rootItem = {
        name: props.rootFolderName,
        type: IDisplayFolderItemType.FOLDER,
        id: props.rootFolderId,
      };
    }
    const parentItem: IDisplayFolderItem = {
      name: props.startFolderName,
      type: IDisplayFolderItemType.FOLDER,
      id: props.startFolderId,
    };
    if (props.startingItem && props.startingBreadcrumbs) {
      //If there are starting breadcrumbs, we assume they include all but the top level we will be displaying
      updateBreadcrumbs(
        rootItem ? rootItem : parentItem,
        null,
        props.startingItem,
        props.startingBreadcrumbs
      );
      setCurrentItem(props.startingItem);
    } else {
      setCurrentItem(parentItem);
      if (rootItem) {
        setBreadcrumbs([
          { position: 0, item: rootItem },
          { position: 1, item: parentItem },
        ]);
      } else {
        setBreadcrumbs([{ position: 0, item: parentItem }]);
      }
    }
  }, [
    props.startingItem,
    props.startingBreadcrumbs,
    props.startFolderId,
    props.startFolderName,
    props.rootFolderId,
    props.rootFolderName,
    updateBreadcrumbs,
  ]);

  React.useEffect(() => {
    document.getElementById("app-main-content-pane").scrollTop = 0;
  }, [alert]);

  const triggerFolderRefresh = () => {
    setFolderRefreshCount(folderRefreshCount + 1);
  };

  const goToItem = (item: IDisplayFolderItem) => {
    setBreadcrumbs([
      ...breadcrumbs,
      {
        position: breadcrumbs.length,
        item: item,
      },
    ]);
    clearAlert();
    setCurrentItem(item);
  };

  const goToBreadcrumb = (breadcrumbInfo: BreadcrumbInfo) => {
    const index = breadcrumbInfo.position;
    if (
      index === breadcrumbs.length - 1 &&
      currentItem.type === breadcrumbInfo.item.type &&
      currentItem.id === breadcrumbInfo.item.id
    ) {
      triggerFolderRefresh();
      return;
    }
    if (index < breadcrumbs.length - 1) {
      setBreadcrumbs(
        breadcrumbs.filter(function (breadcrumb) {
          return breadcrumb.position <= index;
        })
      );
    }
    setCurrentItem(breadcrumbInfo.item);
  };

  const goBackOneBreadcrumb = () => {
    if (breadcrumbs.length > 1) {
      goToBreadcrumb(breadcrumbs[breadcrumbs.length - 2]);
    }
  };

  const updateFolderNameInBreadcrumb = (newName: string) => {
    let _breadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);
    let lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
    _breadcrumbs.push({
      ...lastBreadcrumb,
      item: {
        ...lastBreadcrumb.item,
        name: newName,
      },
    });
    setBreadcrumbs(_breadcrumbs);
  };

  const clearAlert = () => {
    setAlert(null);
  };

  return (
    <>
      <Layout>
        <div className="folder-content-area">
          <Layout.Content>
            <div className="breadcrumb-wrapper">
              <Row>
                <Col span={21}>
                  <Breadcrumb
                    style={{ padding: "0px 8px", paddingRight: "100px" }}
                    separator=">"
                  >
                    {breadcrumbs.map((breadcrumbInfo) => {
                      return (
                        <Breadcrumb.Item
                          key={
                            breadcrumbInfo.item.type + breadcrumbInfo.item.id
                          }
                          href={null}
                          onClick={
                            breadcrumbInfo.disabled
                              ? (e) => {
                                  e.preventDefault();
                                }
                              : (e) => {
                                  e.preventDefault();
                                  clearAlert();
                                  goToBreadcrumb(breadcrumbInfo);
                                }
                          }
                        >
                          <span
                            className={
                              breadcrumbInfo.disabled
                                ? "breadcrumb-disabled"
                                : ""
                            }
                          >
                            <h4>{breadcrumbInfo.item.name}</h4>
                          </span>
                        </Breadcrumb.Item>
                      );
                    })}
                  </Breadcrumb>
                </Col>
                <Col span={3}>
                  {currentItem && (currentItem.type === IDisplayFolderItemType.DASHBOARD || currentItem.type === IDisplayFolderItemType.LOOK) && !hideBadge && (
                      <Badge.Ribbon
                        style={{ right: "0px", paddingRight: "20px" }}
                        color="#005A8C"
                        text={
                          <span style={{ fontSize: "16px", fontWeight: 500 }}>
                            &nbsp;
                            <CalendarOutlined/> DAILY
                          </span>
                        }
                      ></Badge.Ribbon>
                    )}
                    {
                      rtErrorMessage && currentItem && (currentItem.type === IDisplayFolderItemType.DASHBOARD || currentItem.type === IDisplayFolderItemType.LOOK) &&
                      <div style={{width: "100%", textAlign: "right"}}>
                        <Tooltip
                        title={rtErrorMessage}
                      >
                        <InfoCircleOutlined style = {{ paddingRight: "20px", paddingTop: "8px", fontSize: "20px"}}/>
                      </Tooltip>
                      </div>
                    }
                </Col>
              </Row>
            </div>
            {action && (
              <ReportsActionContainer
                action={action}
                setAction={setAction}
                showAlert={setAlert}
                goToItem={goToItem}
                goBackOneBreadcrumb={goBackOneBreadcrumb}
                triggerFolderRefresh={triggerFolderRefresh}
                updateFolderNameInBreadcrumb={updateFolderNameInBreadcrumb}
              />
            )}
            {currentItem &&
              (() => {
                switch (currentItem.type) {
                  case IDisplayFolderItemType.DASHBOARD: {
                    return (
                      <>
                        <Looker
                          contentChangeFunction={changeDisplayedItem}
                          embeddedPath={"/dashboards/" + currentItem.id}
                          cssClassOverride="full-height-minus-header-and-breadcrumbs"
                          refreshCount={folderRefreshCount}
                        />
                      </>
                    );
                  }
                  case IDisplayFolderItemType.LOOK: {
                    return (
                      <Looker
                        contentChangeFunction={changeDisplayedItem}
                        embeddedPath={"/looks/" + currentItem.id}
                        cssClassOverride="full-height-minus-header-and-breadcrumbs"
                        refreshCount={folderRefreshCount}
                      />
                    );
                  }
                  default:
                    return (
                      <>
                        <ReportsFolder
                          goToItem={goToItem}
                          item={currentItem}
                          setAction={setAction}
                          folderRefreshCount={folderRefreshCount}
                          alert={alert}
                          clearAlert={clearAlert}
                          showAlert={setAlert}
                        />
                      </>
                    );
                }
              })()}
          </Layout.Content>
        </div>
      </Layout>
    </>
  );
};

export default ReportsContainer;
