import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { IDisplayFolderItemType, IFolderInfo, INewSource, ReportActionTypes, ReportsAction } from "../../../services/FolderManagement/FolderModels";

interface IReportsFolderNewButtonProps {
  setAction: (action: ReportsAction) => void;
  folderInfo: IFolderInfo;
  showNewFolderButton: boolean;
  showNewDashboardButton: boolean;
}

const ReportsFolderNewButton: React.FC<IReportsFolderNewButtonProps> = (props: IReportsFolderNewButtonProps) => {

    const startNewFolder = () =>{
      const source: INewSource = {
        type: IDisplayFolderItemType.FOLDER,
        folderInfo: props.folderInfo
      };
      props.setAction({
        type: ReportActionTypes.NEW,
        newSource: source
      });
    }

    const startNewDashboard = () =>{
      const source: INewSource = {
        type: IDisplayFolderItemType.DASHBOARD,
        folderInfo: props.folderInfo
      };
      props.setAction({
        type: ReportActionTypes.NEW,
        newSource: source
      });
    }

    const newMenu = (
        <Menu>
          { props.showNewFolderButton &&
            <Menu.Item id='reports-new-folder'
                onClick={startNewFolder}
            >New Folder</Menu.Item>
          }
          { props.showNewDashboardButton &&
            <Menu.Item
                id='reports-new-dashboard'
                onClick={startNewDashboard}
            >New Dashboard</Menu.Item>
          }
        </Menu>
    );

  return (
    <>
      { (props.showNewDashboardButton || props.showNewFolderButton) &&
        <Dropdown overlay={newMenu} trigger={['click']}>
            <Button id='reports-new-btn'>
            New <DownOutlined />
            </Button>
        </Dropdown>
      }
    </>
  );
};
 
export default ReportsFolderNewButton;
  
