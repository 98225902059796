import { Alert, Form, Input, Modal } from "antd";
import React from "react";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { getFolderChildren, renameFolder } from "../../services/FolderManagement/FolderManagement";
import { IChildInfo, IDisplayFolderItem, IDisplayFolderItemType, IRenameSource, ReportsAction } from "../../services/FolderManagement/FolderModels";
import { IAlertInfo } from "../../AppModels";

interface INewFolderItemContainerProps {
  renameSource: IRenameSource;
  setAction: (action: ReportsAction) => void;
  goToItem: (item: IDisplayFolderItem) => void;
  updateFolderNameInBreadcrumb: (name: string) => void;
  triggerFolderRefresh: () => void;
  showAlert: (alert: IAlertInfo) => void;
}

const RenameFolderItemContainer: React.FC<INewFolderItemContainerProps> = (props: INewFolderItemContainerProps) => {
    const [errorMessage, setErrorMessage] = React.useState<string>(null);
    const [siblingFolders, setSiblingFolders] = React.useState<IChildInfo[]>(null);
    const [folderForm] = Form.useForm();

    const errorRetrievingSiblings = (error: Error) =>{
      setSiblingFolders([]);
    }

    React.useEffect(()=>{
      getFolderChildren(props.renameSource.folderInfo.parent_id, setSiblingFolders, errorRetrievingSiblings);
    },[props.renameSource.folderInfo]);

    const renameError = (error: Error) => {
      props.showAlert({
        type: "error",
        message: "An unexpected error ocurred attempting to rename folder."
      });
    }

    const processRenameFolder = (name: string) => {
        name = name == null ? '' : name.trim();
        if(name.length === 0){
          setErrorMessage('Folder Name cannot be blank.');
          return;
        }
        if(name.toUpperCase().indexOf("TEAMSHARED") !== -1){
          setErrorMessage('Folder Name cannot contain "TeamShared".');
          return;
        }

        if(siblingFolders.some(element => element.name.toUpperCase() === name.toUpperCase())
          && name.toUpperCase() !== props.renameSource.folderInfo.name.toUpperCase() ){
         setErrorMessage('A folder named "' + name + '" already exists in parent folder.');
         return; 
        }
        const renameComplete = (result: any) => {
          props.updateFolderNameInBreadcrumb(name);
          props.triggerFolderRefresh();
        }

        renameFolder(props.renameSource.folderInfo.id, name, renameComplete, renameError);
        setErrorMessage(null);
        props.setAction(null);
    }

    const closeFolderModal = () => {
      props.setAction(null);
    };

  return (
    <>
    {(()=> {
      switch(props.renameSource.type){
      case IDisplayFolderItemType.FOLDER:
          return  <>
          <Modal
            title={'Rename Folder "' + props.renameSource.folderInfo.name + '"'}
            visible={true}
            onOk={() => {
              folderForm.validateFields()
                .then(values => {
                  folderForm.resetFields();
                  processRenameFolder(values.folder_name);
                })
            }}
            okText="Rename Folder"
            onCancel={closeFolderModal}
            width={650}
          >
           { 
             siblingFolders ?
             <>
                <Form
                  form={folderForm}
                  name="rename_folder_form"
                >
                  <Form.Item name="folder_name" label="Name" initialValue={props.renameSource.folderInfo.name}>
                    <Input type="textarea" />
                  </Form.Item>
                </Form>
                
                { errorMessage && 
                  <Alert type="error" message={errorMessage} />
                }
              </>
              :
              <CustomSpinner />
            }
          </Modal>
        </>;
      default:
          return <>    
                  {/* only permitted for folder at this time. */}     
              </>;
    }
    })()}
    </>
  );
};
 
export default RenameFolderItemContainer;
  
