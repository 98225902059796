import { useOktaAuth } from '@okta/okta-react';
import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOCAL_STORAGE_OKTA_REGULAR_FLAG } from "../../../Constants";
import { postOktaLogin } from "../../../store/actions/UserAuthActions";
import { IApplicationState } from "../../../store/Store";

interface IProps {
    oktaReturn: boolean;
    disabled: boolean;
    setOktaSignInAttributes: (regularLogin: boolean, supportRealm: string, selectedUser: string)  => void;
  }

const FederatedLoginButton: React.FC<IProps> = ({ oktaReturn, disabled, setOktaSignInAttributes }) => {
    
    const dispatch = useDispatch();
    const errorMessage: string = useSelector<IApplicationState>(state => state.userAuth.errorMessage) as string;
    const [federatedReturn, setFederatedReturn] = React.useState<boolean>(false);

    const { authState } = useOktaAuth();
    const oktaLogin = () => {
        setOktaSignInAttributes(false, null, null);
    }

    React.useEffect(()=>{
        if(oktaReturn && authState !=null && authState.isAuthenticated){
            dispatch(postOktaLogin(authState));
        }
    }, [oktaReturn, authState, dispatch]);

    React.useEffect(() => {
        const regularFlag = localStorage.getItem(LOCAL_STORAGE_OKTA_REGULAR_FLAG);
        if(regularFlag == null){
          setFederatedReturn(true);
        }
    },[oktaReturn])
    
    return (
    <>
        <div style={{textAlign: "center", marginBottom: "10px", marginTop: "10px"}}>
            <div style={{ color: "black", marginBottom: "10px"}}>------- OR -------</div>
            <Button 
                type="primary" 
                onClick={oktaLogin}
                disabled={disabled || (oktaReturn && !federatedReturn && authState !=null && authState.isAuthenticated && !errorMessage)}
                loading={oktaReturn && federatedReturn && authState !=null && authState.isAuthenticated && !errorMessage}
            > 
                Federated Login
            </Button>
        </div>

    </>
    );
}

export default FederatedLoginButton;