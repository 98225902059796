import {
  Button,
  Col,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Form,
  FormItemProps,
  Table,
  ConfigProvider,
  Empty,
  Upload,
  AlertProps,
  Alert,
  Tooltip,
  Space,
  List,
} from "antd";
import Column from "antd/lib/table/Column";
import React, { useEffect } from "react";
import {
  ICustomDrugList,
  IDrugListHeader,
  IDrugListItem,
  IDrugListSaveResult,
  LIST_TYPE,
  STANDARD_DRUG_CLASSES,
} from "../../../services/CustomAdherenceDrugListManagement/CustomAdherenceDrugListModels";
import Papa from "papaparse";
import "./ListDetailsModal.scss";
import { usePrev } from "../../../services/CustomHooks";

// hides async validator warnings in console
import Schema from "async-validator";
Schema.warning = function () {};

interface IListDetailsModalProps {
  listDetails: ICustomDrugList;
  isEditing: boolean;
  isCreating: boolean;
  showModal: boolean;
  loading: boolean;
  allLists: IDrugListHeader[];
  setLoading: (loading: boolean) => void;
  closeModal: (showModal: boolean) => void;
  saveList: (drugList: ICustomDrugList) => Promise<IDrugListSaveResult>;
}

enum FORM_FIELD_NAMES {
  NAME = "customDrugListName",
  TYPE = "customDrugListType",
  LIST_DESCRIPTION = "customDrugListDescription",
  LIST_VALUES = "customDrugListValues",
}

const { TextArea } = Input;
const { Option } = Select;

const ListDetailsModal: React.FC<IListDetailsModalProps> = (
  props: IListDetailsModalProps
) => {

  const [componentMounted, setComponentMounted] = React.useState<boolean>(true);

  // stores the list name
  const [listName, setListName] = React.useState<string>("");

  // stores currently selected list type
  const [listType, setListType] = React.useState<LIST_TYPE>(LIST_TYPE.NDC);
  // stores the previous state value for listType
  const prevListType = usePrev(listType);

  // stores the list description
  const [listDescription, setListDescription] = React.useState<string>("");

  // stores the data source aka list codes and descriptions
  const [dataSource, setDataSource] = React.useState<IDrugListItem[]>([]);
  // stores the previous state value for dataSource
  const prevDataSource = usePrev(dataSource);
  // stores the response when saving or creating a list to check for invalid codes in the response
  const [resData, setResData] = React.useState<IDrugListItem[]>([])

  // stores a boolean to see if adding a new row was triggered
  const [newRowAdded, setNewRowAdded] = React.useState<boolean>(false);
  // stores a boolean to see if the form was recently submitted
  const [recentlySubmitted, setRecentlySubmitted] = React.useState<boolean>(false);

  // stores the selected row keys in the table of codes
  const [selectedRowKeys, setSelectedRowKeysState] = React.useState<
    React.Key[]
  >([]);
  // stores all row keys in the table of codes
  const [rowKeys, setRowKeys] = React.useState<number[]>([]);

  // form reference for controlling input validation 
  const [form] = Form.useForm();

  // stores csv import errors if any
  const [csvErrors, setCsvErrors] = React.useState<{hide: Boolean, errors: []}>({hide: true, errors: []})

  // stores alert info for the table validation
  const [tableAlert, setTableAlert] = React.useState<AlertProps & {visible: boolean}>({
    visible: false,
    message: "",
    type: "success",
    description: ""
  })

  const formLabelProps: FormItemProps<any> = {
    colon: false,
    labelAlign: "left",
    labelCol: { span: 5, style: { paddingLeft: 16 } },
  };

  const onFinish = async (values: any): Promise<void> => {
    var drugList: ICustomDrugList = {
      drug_list_name: values["customDrugListName"].toUpperCase(),
      drug_list_description: values["customDrugListDescription"],
      list_type: values["customDrugListType"],
      items: dataSource.map((value) => {
        return {
          code: value.code,
          description: value.description,
        };
      }),
    };

    props.saveList(drugList).then((res) => {
      setRecentlySubmitted(false);
      if (!res.all_valid) {
        const data: IDrugListItem[] = dataSource.map((value) => {

          for (let item of res.items) {

            if (
              value.code === item.code &&
              value.description === item.description
            ) {
              return {
                ...value,
                valid: item.valid,
              };
            }
          }
          return null
        });
        setResData(data);
        props.setLoading(false);
      }
    });

    Promise.resolve();
  };

  const validateListName = async (value: string): Promise<void> => {
    const regex = {
      startWithLetter: /^([A-Za-z][A-Za-z0-9\W\w]{0,19})?$/,
      onlyLettersNumbersUnderscores: /^.{0,1}\w*$/,
    };
    if (value in STANDARD_DRUG_CLASSES) 
      return Promise.reject("List name cannot match one of the six standard drug classes!")
    else if (!regex.startWithLetter.test(value))
      return Promise.reject("List name must start with a letter!");
    else if (!regex.onlyLettersNumbersUnderscores.test(value))
      return Promise.reject(
        "List name can only contain letters, numbers, and underscores!"
      );
    else if (
      props.allLists.filter(
        (list) => list.drug_list_name === value.toUpperCase()
      ).length > 0 &&
      props.isCreating
    )
      return Promise.reject("A List with that name already exists!");
    else return Promise.resolve();
  };

  const handleTypeChange = (value: LIST_TYPE): void => {
    setResData([])
    setListType(value);
  };

  const validateDrugCode = async (
    list: IDrugListItem,
    type: LIST_TYPE
  ): Promise<string> => {
    const regex = {
      NDC: /^(\d{9}$|^\d{11})?$/,
      GPI: /^((?=\d{4,14}$)(?=^(\d{2})+$)\d+)?$/,
      // GCN: TODO
    };

    const promise = new Promise<string>((resolve, reject) => {

      if (list.code.length === 0) reject();

      if (resData.filter((value) => value.code === list.code && value.description === list.description && !value.valid).length > 0) {
        reject(`This is not a valid ${listType} code!`);
      }

      switch (type) {
        case LIST_TYPE.NDC: {
          if (!regex.NDC.test(list.code)) {
            reject("A valid NDC must be 9 or 11 digits!");
          }
          break;
        }
        case LIST_TYPE.GPI: {
          if (!regex.GPI.test(list.code)) {
            reject("GPI length must be even between 4 and 14 digits!");
          }
          break;
        }
      }

      resolve("Success!");
    });
    return promise;
  };

  const handleCodeChange = async (
    value: string,
    record: IDrugListItem
  ): Promise<IDrugListItem[]> => {
    let promise = new Promise<IDrugListItem[]>((resolve, reject) => {
      validateDrugCode(record, listType).then(
        (res) => {
          let newData = dataSource.map((elem) => {
            if (elem.key === record.key) {
              return {
                ...elem,
                code: value,
                valid: true,
              };
            }
            return elem;
          });
          resolve(newData);
        },
        (res) => {
          let newData = dataSource.map((elem) => {
            if (elem.key === record.key) {
              return {
                ...elem,
                code: value,
                valid: false,
              };
            }
            return elem;
          });
          resolve(newData);
        }
      );
    });
    return promise;
  };

  const handleCodeDescriptionChange = async (
    value: string,
    record: IDrugListItem
  ) => {
    let newData = dataSource.map((elem) => {
      if (elem.key === record.key) {
        return {
          ...elem,
          description: value,
        };
      }
      return elem;
    });
    setDataSource(newData);
  };

  const findMissingIndex = (arr: number[]) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== i) {
        return i;
      }
    }
    return arr.length;
  };

  const addNewRow = (code: string, description: string) => {
    setNewRowAdded(true);
    let index = findMissingIndex(rowKeys);

    setRowKeys((data) => {
      const newData = [...data, index];
      newData.sort((a, b) => a - b);
      return newData;
    });

    let newData = {
      key: index,
      code: code,
      description: description,
    };

    setDataSource([newData, ...dataSource]);
  };

  const addNewRows = async (rows: { code: string; description: string }[]) => {
    let currKeys: number[] = [...rowKeys];
    let currData: IDrugListItem[] = [...dataSource];

    rows.forEach((row, idx) => {
      if (row.code) {
        let index = findMissingIndex(currKeys);

        currKeys.push(index);
        currKeys.sort((a, b) => a - b);
  
        currData.push({
          key: index,
          code: row.code,
          description: row.description ? row.description : "",
        });
      }
    });

    setRowKeys(currKeys);
    setDataSource(currData);
    Promise.resolve();
  };

  const removeRow = (index: React.Key[]): void => {
    setDataSource((data) => {
      let newData = [...data];
      newData = newData.filter((value) => !index.includes(value.key));
      newData.sort((a, b) => a.key - b.key);

      index.forEach((value) => {
        if (
          form &&
          (form.getFieldValue(`drugCode-${value}`) !== undefined ||
            form.getFieldValue(`drugDescription-${value}`) !== undefined)
        ) {
          form.setFieldValue(`drugCode-${value}`, undefined);
          form.setFieldValue(`drugDescription-${value}`, undefined);
        }
      });

      return newData;
    });

    setRowKeys((data): number[] => {
      let newData = [...data];
      newData = newData.filter((value) => !index.includes(value));
      newData.sort((a, b) => a - b);
      return newData;
    });

    setSelectedRowKeysState([]);
  };

  const handleImport = async (file: File) => {
    try {

      Papa.parse(file, {
        preview: 1,
        step: function(row) {
          var headers: string[] = row.data.map((header) => header.trim())
          const hasHeader: boolean = headers.filter((value) => value.includes('code')).length > 0 && headers.filter((value) => value.includes('description')).length > 0
          Papa.parse(file, {
            header: hasHeader,
            skipEmptyLines: true,
            trim: true,
            transformHeader: (header) => {
              return header.trim().replace(/(^")|("$)/g, () => '')
            },
            transform: (value) => {
              return value.trim().replace(/(^")|("$)/g, () => '')
            },
            complete: async (results) => {
              if (results && results['data']) {
                // trims white space off of values
                let finalResults = results['data'].map((value) => {
                  let trimmedObj = {
                    code: "",
                    description: ""
                  }
    
                  if ('code' in value && 'description' in value) {
                    trimmedObj['code'] = value['code'].trim()
                    trimmedObj['description'] = value['description'].trim()
                    return trimmedObj;
                  } else {
                    return value
                  }
                });
    
                // handles if the csv does not have a header
                if (!hasHeader) {
    
                  // if there is no header that means there are no code/description keys so they need to be created
                  finalResults = results['data'].map((values) => {
                    let newObj = {
                      code: "",
                      description: ""
                    }
    
                    values.forEach((value, index) => {
                      if (index === 0) {
                        newObj['code'] = value.trim();
                      }
                      if (index === 1) {
                        newObj['description'] = value.trim();
                      }
                    })
    
                    return newObj;
                  })
                }
    
                if (results['errors'].length > 0) {
                  setCsvErrors(prevState => ({...prevState, errors: results['errors']}))
                }
    
                await addNewRows(finalResults);
              }
            }
          });
        }
      })
  

  
      return false;
    } catch (error) {
      console.error('Failed to read file:', error);
      setTableAlert({
        visible: true,
        message: `There was an error parsing values from CSV.`,
        description: "",
        type: 'error',
      })
      return false;
    }
  };

  useEffect(() => {
    if (csvErrors['errors'].length > 0) {
      setTableAlert({
        visible: true,
        message: `There were some CSV format warnings detected during import.`,
        description: !csvErrors['hide'] && <List size="small">{csvErrors['errors'].map((value, index) => <List.Item key={index}>{`Row ${value['row']} - ${value['type']} - ${value['message']}`}</List.Item>)}</List>,
        type: 'warning',
        action: <Button size="small" type="link" onClick={() => setCsvErrors(prevState => ({...prevState, hide: prevState['hide'] ? false : true}))}>Details</Button>,
        onClose: () => {setCsvErrors({hide: false, errors: []})},
        className: 'csv-alert'
      })
    }
  }, [csvErrors])

  const validateDataSource = async (
    type?: LIST_TYPE
  ): Promise<IDrugListItem[]> => {
    let promise = new Promise<IDrugListItem[]>((resolve, reject) => {
      let newData = [...dataSource];
      newData.forEach((value, index) => {
        validateDrugCode(value, type).then(
          (msg) => {
            newData[index] = {
              key: value.key,
              code: value.code,
              description: value.description,
              valid: true,
            };
          },
          (msg) => {
            newData[index] = {
              key: value.key,
              code: value.code,
              description: value.description,
              valid: false,
            };
          }
        );
      });
      resolve(newData);
    });
    return promise;
  };

  useEffect(() => {
    if (props.isEditing) {
      if (
        props.listDetails.items.filter((item) =>
          dataSource.every(
            (dataItem) =>
              item.code !== dataItem.code &&
              item.description !== dataItem.description &&
              item.key !== dataItem.key
          )
        ).length > 0
      ) {
        addNewRows(props.listDetails.items);
      }
      form.setFieldValue(
        FORM_FIELD_NAMES.NAME,
        props.listDetails.drug_list_name
      );
      form.setFieldValue(
        FORM_FIELD_NAMES.LIST_DESCRIPTION,
        props.listDetails.drug_list_description
      );
      form.setFieldValue(FORM_FIELD_NAMES.TYPE, props.listDetails.list_type);

      setListName(form.getFieldValue(FORM_FIELD_NAMES.NAME));
      setListDescription(form.getFieldValue(FORM_FIELD_NAMES.LIST_DESCRIPTION));
      setListType(form.getFieldValue(FORM_FIELD_NAMES.TYPE));
    }
  // eslint-disable-next-line
  }, [props.listDetails]);

  useEffect(() => {
    if (
      JSON.stringify(prevDataSource) !== JSON.stringify(dataSource) &&
      dataSource.length > 0 &&
      !newRowAdded &&
      !componentMounted
    ) {
      validateDataSource(listType).then((list) => {
        setDataSource(list);
        setTimeout(() => {
            form.validateFields()
        }, 100)
      });
    } else if (newRowAdded) setNewRowAdded(false)
  // eslint-disable-next-line
  }, [dataSource, newRowAdded, recentlySubmitted]);

  useEffect(() => {
    if (resData.length > 0) {

        const numCodes = resData.filter(val => !val.valid).length;

        validateDataSource(listType).then(() => {
            form.validateFields();
        })

        setTableAlert({
            visible: true,
            message: `Please correct the ${numCodes} invalid ${numCodes > 1 ? `${listType}s` : listType} before saving.`,
            description: '',
            type: 'error'
        })
    }
  // eslint-disable-next-line
  }, [resData])

  useEffect(() => {
    validateDataSource(listType).then(list => {
        setDataSource(list)
        if (prevListType !== listType && !componentMounted) {
            setTimeout(() => {
              form.validateFields();
            }, 100);
        } 
    });
  // eslint-disable-next-line
  }, [listType]);

  useEffect(() => {
    setDataSource([]);
    setComponentMounted(false)
  }, []);

  return (
    <Modal
      open={true}
      width={825}
      title={
        props.isEditing
          ? `Editing - "${props.listDetails.drug_list_name}"`
          : "Create New List"
      }
      onCancel={(e) => {
        props.closeModal(false);
      }}
      footer={
        <Space>
          <Button
            key="1"
            type="default"
            onClick={() => {
              props.closeModal(false);
            }}
          >
            Close
          </Button>
          <Tooltip placement="top" title={dataSource.length === 0 ? "List cannot be empty!" : ""}>
            <Button
              key="2"
              type="primary"
              loading={props.loading && dataSource.length > 0}
              disabled={props.loading || dataSource.length === 0}
              onClick={() => {
                props.setLoading(true);
                setTimeout(() => {
                  form
                    .validateFields()
                    .then((values) => {
                      onFinish(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed: ", info);
                      setTableAlert({
                        visible: true,
                        message: `Invalid input detected. Please review and correct!`,
                        description: '',
                        type: 'error'
                    })
                      props.setLoading(false);
                    });
                }, 500);
              }}
            >
              Save
            </Button>
          </Tooltip>
        </Space>
      }
    >
      <Layout>
        <Layout.Content>
          <Form
            form={form}
            requiredMark={true}
            layout="vertical"
            scrollToFirstError
            onSubmitCapture={(event) => {
              event.preventDefault();
            }}
            onFinishFailed={(errorInfo) => {
              console.log(errorInfo);
            }}
          >
            <>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name={FORM_FIELD_NAMES.NAME}
                    label="Name"
                    labelAlign="left"
                    initialValue={listName}
                    rules={[
                      {
                        required: true,
                        message: "A name for the list is required!",
                      },
                      {
                        validator(_, value: string) {
                          return validateListName(value);
                        },
                      },
                    ]}
                    {...formLabelProps}
                  >
                    <Input
                      maxLength={20}
                      disabled={props.isEditing || props.loading}
                      onBlur={() => form.validateFields()}
                      showCount
                    />
                  </Form.Item>
                </Col>
                <Col offset={2} span={10}>
                  <Form.Item
                    name={FORM_FIELD_NAMES.TYPE}
                    label="List Type"
                    initialValue={listType}
                    rules={[
                      {
                        required: true,
                        message: "A type for the list is required!",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 120 }}
                      onChange={handleTypeChange}
                      loading={props.loading}
                      disabled={props.loading}
                    >
                      <Option value="NDC">NDC</Option>
                      <Option value="GPI">GPI</Option>
                      {/* <Option value="GCN" disabled>GCN</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="List Description"
                    name={FORM_FIELD_NAMES.LIST_DESCRIPTION}
                    initialValue={listDescription}
                  >
                    <TextArea
                      maxLength={300}
                      showCount
                      disabled={props.loading}
                    />
                  </Form.Item>
                </Col>
              </Row>
              { tableAlert.visible && 
              <Row style={{ marginBottom: 8, marginTop: 5 }}>
                <Col span={24}>
                  <div className='drug-list-table-alert-wrapper'>
                    <Alert {...tableAlert} onClose={() => setTableAlert(prev => {return{...prev, visible: false}})} closable/> 
                  </div>
                </Col>
              </Row>
              }
              <Row>
                <Col span={24}>
                  <ConfigProvider
                    renderEmpty={() => (
                      <Empty
                        style={{
                          height: "280px",
                          paddingTop: "10%",
                        }}
                        description={`No ${listType} Codes`}
                      />
                    )}
                  >
                    <Table
                      className="tbl-list-details"
                      rowClassName="tbl-row-list-details"
                      rowKey="key"
                      dataSource={[...dataSource]}
                      size="small"
                      loading={props.loading}
                      pagination={{
                        pageSizeOptions: ["10", "20", "50"],
                        onChange: () => {
                          setTimeout(() => {
                            form.validateFields();
                          }, 100);
                        },
                        showTotal: (total, range) => {
                          return (
                            <span style={{ left: 0, position: "absolute" }}>
                              {range[0]}-{range[1]} of {dataSource.length} items{" "}
                              {selectedRowKeys.length > 0
                                ? `- ${selectedRowKeys.length} selected`
                                : ""}
                            </span>
                          );
                        },
                      }}
                      scroll={{
                        y: 200,
                      }}
                      rowSelection={{
                        type: "checkbox",
                        onChange(selectedKeys, selectedRows, info) {
                          setSelectedRowKeysState(selectedKeys);
                        },
                        selectedRowKeys: selectedRowKeys,
                      }}
                    >
                      <Column
                        title={`${listType} Code`}
                        dataIndex="code"
                        key="code"
                        width={"50%"}
                        filters={[
                          {
                            text: "Invalid",
                            value: false,
                          },
                        ]}
                        onFilter={(value: boolean, record: IDrugListItem) =>
                          record.valid === value || resData.filter((val) => record.code === val.code && record.description === val.description && val.valid === value).length > 0
                        }
                        render={(text, record: IDrugListItem, index) => {
                          let maxLength = 0;
                          switch (form.getFieldValue(FORM_FIELD_NAMES.TYPE)) {
                            case LIST_TYPE.NDC: {
                              maxLength = 11;
                              break;
                            }
                            case LIST_TYPE.GPI: {
                              maxLength = 14;
                              break;
                            }
                            // TODO:
                            // case LIST_TYPE.GCN: {

                            //     break;
                            // }
                          }

                          return (
                            <Form.Item
                              name={`drugCode-${record.key}`}
                              initialValue={text}
                              rules={[
                                {
                                  required: true,
                                  message: `A valid ${listType} code is required!`,
                                },
                                {
                                  validator(_, value: string) {
                                    return validateDrugCode(record, listType);
                                  },
                                },
                              ]}
                              validateTrigger="onBlur"
                            >
                              <TextArea
                                className="tbl-input-list-details"
                                maxLength={maxLength}
                                //onChange={(e) => handleCodeChange(e.target.value, record)}
                                showCount
                                autoSize
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault();
                                  }
                                }}
                                onBlur={(e) =>
                                  handleCodeChange(e.target.value, record).then(list => setDataSource(list))
                                }
                                onFocus={(e) => {
                                      form.setFields([
                                        {
                                          name: `drugCode-${record.key}`,
                                          errors: [],
                                        },
                                      ])
                                }}
                              />
                            </Form.Item>
                          );
                        }}
                      />
                      <Column
                        title="Description"
                        dataIndex="description"
                        key="description"
                        width={"50%"}
                        render={(text, record: IDrugListItem, index) => {
                          return (
                            <Form.Item
                              name={`drugDescription-${record.key}`}
                              initialValue={text}
                            >
                              <TextArea
                                className="tbl-input-list-details"
                                maxLength={100}
                                showCount
                                autoSize
                                onBlur={(e) =>
                                  handleCodeDescriptionChange(
                                    e.target.value,
                                    record
                                  )
                                }
                              />
                            </Form.Item>
                          );
                        }}
                      />
                    </Table>
                  </ConfigProvider>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Button
                    className="btn-list-details-remove"
                    disabled={selectedRowKeys.length === 0 || props.loading}
                    onClick={() => {
                      removeRow(selectedRowKeys);
                    }}
                  >
                    Remove
                  </Button>
                  <Upload
                    className="btn-list-details-import"
                    accept=".csv"
                    showUploadList={false}
                    beforeUpload={handleImport}
                    multiple={false}
                  >
                    <Button disabled={props.loading}>Import</Button>
                  </Upload>
                  <Button
                    className="btn-list-details-add"
                    disabled={props.loading}
                    onClick={() => addNewRow("", "")}
                  >
                    Add Row
                  </Button>
                </Col>
              </Row>
            </>
          </Form>
        </Layout.Content>
      </Layout>
    </Modal>
  );
};

export default ListDetailsModal;
