import { useOktaAuth } from '@okta/okta-react';
import { Button, Col, Form, Input, Modal } from 'antd';
import * as React from "react";
import { LOCAL_STORAGE_OKTA_REGULAR_FLAG, LOCAL_STORAGE_OKTA_SELECTED_USER, LOCAL_STORAGE_OKTA_SUPPORT_REALM } from "../../../Constants";
import { API } from '../../../services/LookerAPI/LookerAPI';
import { IRealmLoginConfiguration, IUserLoginCredentials } from "../../../store/actions/UserAuthTypes";

interface IProps {
  submitForm: (values : IUserLoginCredentials) => void,
  submitFormWithForce: (values : IUserLoginCredentials) => void,
  loading: boolean,
  errorMessage: string,
  supportLogin: boolean,
  oktaReturn: boolean,
  setOktaSignInAttributes: (regularLogin: boolean, supportRealm: string, selectedUser: string)  => void
  openAm: boolean,
  setOpenAm: (openAm: boolean) => void,
  submittedRealm: string,
  setSubmittedRealm: (submittedRealm: string) => void
}

const LoginForm: React.FC<IProps> = ({ submitForm, submitFormWithForce, loading, errorMessage, supportLogin, oktaReturn, setOktaSignInAttributes, 
  openAm, setOpenAm, submittedRealm, setSubmittedRealm}) => {

  const [form] = Form.useForm<IUserLoginCredentials>();
  const [realmForm] = Form.useForm<IUserLoginCredentials>();
  const [checkRealm, setCheckRealm] = React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<string>(null);
  const [realmErrorMessage, setRealmErrorMessage] = React.useState<string>(null);
  const { authState } = useOktaAuth();
  const [regularOktaReturn, setRegularOktaReturn] = React.useState<boolean>(false);

  React.useEffect(()=>{
    if(errorMessage && errorMessage.trim() === 'Warning: New Realm'){
      setCheckRealm(true);
    }
  }, [errorMessage]);

  const processForm = (values: IUserLoginCredentials) =>{
    values = {...values,
              realm: submittedRealm, 
              selectedUser: selectedUser };
    if(checkRealm){
      submitFormWithForce(values);
      setCheckRealm(false);
    } else{
      submitForm(values);
    }
  }

  const processRealm = (values: IUserLoginCredentials) =>{
    const setResult = (result: IRealmLoginConfiguration) => {
      if(result.success){
        if(result.found && result.okta && (!supportLogin || !result.openamSupportOverride)){
          setOktaSignInAttributes(true, supportLogin ? values.realm : null, values.selectedUser);
        } else { 
          setSubmittedRealm(values.realm);
          setSelectedUser(values.selectedUser);
          setOpenAm(true);
          localStorage.removeItem(LOCAL_STORAGE_OKTA_REGULAR_FLAG);
          localStorage.removeItem(LOCAL_STORAGE_OKTA_SUPPORT_REALM);
          localStorage.removeItem(LOCAL_STORAGE_OKTA_SELECTED_USER);
        }
      } else {
        setRealmErrorMessage("An error occurred checking realm configuration.");
      }
    }
    const setError = () => {
      setRealmErrorMessage("An unexpected error occurred.");
    }
    API.getLoginConfigurationForRealm(values.realm, setResult, setError);
  }

  React.useEffect(() => {
    const regularFlag = localStorage.getItem(LOCAL_STORAGE_OKTA_REGULAR_FLAG);
    if(regularFlag != null){
      setRegularOktaReturn(true);
    }
  },[oktaReturn])

  return (
    <>
      <Col span={3}></Col>
      <Col span={18}>
      <Form
        form={realmForm}
        layout="vertical"
        name="realm_check"
        className="login-form"
        requiredMark={false}
        onFinish={processRealm}
      >
        <Form.Item
          name="realm"
          label="Realm"
          className="login-label login-form-item"
          hidden={openAm}
          rules={[{ required: true, message: 'Please enter your realm.' }]}
        >
          <Input
              className="login-input" 
          />
        </Form.Item>
        {
          openAm &&
          <Form.Item
              label="Realm"
              className="login-label login-form-item"
            >
            <Input 
              disabled
              value={submittedRealm}
            />
          </Form.Item>
        }
        { supportLogin &&
          <>
            <Form.Item
              name="selectedUser"
              label="Selected Username (Optional)"
              className="login-label"
              style={{marginBottom: "0px"}}

            >
              <Input
                  disabled={openAm}
                  className="login-input" 
              />
            </Form.Item>
          </>
        }
        <div style={{textAlign: "center", marginBottom: "10px", marginTop: "10px"}}>
          <Form.Item style={ supportLogin ? {} : { marginBottom: "0px"}}>
          { !openAm &&
              <Button 
                type="primary" 
                htmlType="submit" 
                className="login-form-button" 
                disabled={loading || (oktaReturn && authState !=null && authState.isAuthenticated && !errorMessage) || openAm}
              > 
                Next
              </Button>
            }
          
          { openAm &&
            <Button
                onClick={() => {
                  setSubmittedRealm(null);
                  setSelectedUser(null);
                  setOpenAm(false);
                  realmForm.resetFields();
                }}
              >
              Clear
            </Button>
          }
          </Form.Item>
        </div>
      </Form>  

      <Form
        form={form}
        layout="vertical"
        name="normal_login"
        className="login-form"
        requiredMark={false}
        onFinish={processForm}
      >
        { !openAm &&
          <div style={{ height:  "140px"}}>
          </div>
        }

        <Form.Item
          hidden={!openAm}
          name="username"
          label="Username"
          className="login-label login-form-item"
          rules={[{ required: true, message: 'Please enter your Username.' }]}
        >
          <Input className="login-input" />
        </Form.Item>
        <Form.Item
          hidden={!openAm}
          name="password"
          label="Password"
          className="login-label"
          rules={[{ required: true, message: 'Please enter your Password.' }]}
        >
          <Input
            className="login-input" 
            type="password"
          />
        </Form.Item>
        { realmErrorMessage &&
          <div className="alert alert-danger login-alert">
            <div role="alert">{realmErrorMessage}</div>
          </div>
        }

        { errorMessage &&
          <div className="alert alert-danger login-alert">
            <div role="alert">{errorMessage}</div>
          </div>
        }

        <Form.Item style={ supportLogin ? {} : { marginBottom: "0px"}}>
          <Button 
              hidden={!openAm && !(oktaReturn && regularOktaReturn && authState !=null && authState.isAuthenticated && !errorMessage)}
              type="primary" 
              htmlType="submit" 
              className="login-form-button login-button-wide" 
              loading={loading || (oktaReturn && regularOktaReturn && authState !=null && authState.isAuthenticated && !errorMessage)}
            >
            Sign in
          </Button>
        </Form.Item>
      </Form>
      </Col>
      <Col span={3}></Col>

      {
        checkRealm &&
        <Modal
          title={'Confirm Login Realm "' + submittedRealm + '"?'}
          visible={true}
          onOk={() => form.submit()}
          okText={'Proceed with Realm "' + submittedRealm + '"'}
          onCancel={() => setCheckRealm(false)}
          width={500}
        >      
        <>
            <h4>Please review entered realm: "{submittedRealm}"</h4>
            <br />
            <div className="modal-new-realm-warning">
            <b>No users have logged in with this realm before. </b>
            <br />
            <b>Proceeding will initiate setup for this realm.</b>
            </div>
        </>
        </Modal>
      }
    </>
  );
};

export default LoginForm;