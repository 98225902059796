import { ClusterOutlined } from "@ant-design/icons";
import { Button, Input, Layout, Modal, Table, Tooltip, Alert } from "antd";
import React from "react";
import { connect } from "react-redux";
import { LookerContentType } from "../../AppModels";
import { ICorporateHierarchyNode } from "../../services/GroupManagement/GroupModels";
import { getCorporateHierarchy, setFacilityFilterList, setLookerContentInfo } from "../../store/actions/LookerFilterActions";
import { IApplicationState } from "../../store/Store";
import "./FacilitySelector.scss";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { LOCAL_STORAGE_FACILITY_SELECTIONS, LOCAL_STORAGE_LAST_ACTIVITY } from "../../Constants";

interface IFacilitySelectorProps {
  facilityFilterPresent: boolean;
  isSupport: boolean;
  setFacilityFilterList: typeof setFacilityFilterList;
  corporateHierarchy: ICorporateHierarchyNode[];
  corporateHierarchyError: string;
  getCorporateHierarchy: typeof getCorporateHierarchy;
}

const FacilitySelector: React.FC<IFacilitySelectorProps> = ({facilityFilterPresent, setFacilityFilterList,
    corporateHierarchy, corporateHierarchyError, getCorporateHierarchy, isSupport}) => {
  
  const [showFacilityModal, setShowFacilityModal] = React.useState<boolean>(false);
  const [checkedKeys, setCheckedKeys] = React.useState<string[]>([]);
  const [filterText, setFilterText] = React.useState<string>('');
  const [keysCount, setKeysCount] = React.useState<number>(0);

  React.useEffect(()=>{
    if(showFacilityModal){
      const checkedKeysInStorage =  JSON.parse(localStorage.getItem(LOCAL_STORAGE_FACILITY_SELECTIONS));
      localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
      if(checkedKeysInStorage != null){
        setCheckedKeys(checkedKeysInStorage);
      }
    }
  },[showFacilityModal]);

  React.useEffect(()=>{
    setKeysCount(checkedKeys.filter(key => (key.charAt(0) === 'F')).length);
  },[checkedKeys]);

  React.useEffect(()=>{
    if(corporateHierarchy == null &&
      (showFacilityModal === true || !isSupport)){
      getCorporateHierarchy(!showFacilityModal);
      //If user has not launched modal yet, ignore error, which would be expected
      //  if this is the first time user is logging into system and the Looker User was not created via SSO yet
      //For support users - do not make call until modal is launched
      //  Support user realm is modified when first Looker dashboard loads
      //  Calling before dashboard load can result in data for wrong client
    }
  }, [corporateHierarchy, getCorporateHierarchy, showFacilityModal, isSupport]);  

  const updateFilterText = (inputText: string) => {
    setFilterText(inputText.toUpperCase());
  }

  const closeFacilityModal = () => {
    setCheckedKeys([]);
    setShowFacilityModal(false);
    setFilterText('');
  }

  const openFacilityModal = () => {
    setShowFacilityModal(true);
  }

  const onSelectAll = (selected, selectedRows, changeRows) => {
    onCustomSelectAll(selected);
  };

  const onCustomSelectAll = (selected: boolean) => {
    if(selected){
      let _keys : string[] = [];
      if(corporateHierarchy!=null){
        corporateHierarchy.forEach(node => { collectKeys(node, _keys)});
      }
      setCheckedKeys(_keys);
    } else {
      setCheckedKeys([]);
    }
  };

  
  const onSelectRow = (record, selected, selectedRows, nativeEvent) => {
    const impactedKeys: string[] = [];
    collectKeys(record, impactedKeys);
    if(selected){
      setCheckedKeys(Array.from( new Set<string>([...checkedKeys, ...impactedKeys])))
    } else {
      const toRemove = new Set(impactedKeys);
      setCheckedKeys(checkedKeys.filter(key => !toRemove.has(key)));
    }
  };

  const collectKeys = (node: ICorporateHierarchyNode, keyList: string[])=>{
    keyList.push(node.key);
    if(node.children !=null){
      node.children.forEach(child =>{
        collectKeys(child,keyList);
      });
    }
    return;
  }

  const updateFacilityFilterList = () => {
    let newFacilityString = "";
    checkedKeys.forEach(key => {
      if(key.charAt(0) === 'F'){
        const facilityIdStartIndex = 
          key.indexOf(';', key.indexOf(';') + 1) + 1;
        if(facilityIdStartIndex < key.length){
          newFacilityString = newFacilityString + key.substring(facilityIdStartIndex) + ',';
        }
      }
    });
    if(newFacilityString.length > 0){
      newFacilityString = newFacilityString.substring(0,newFacilityString.length-1);
    }
    console.log('newFacilityString = ', newFacilityString);
    localStorage.setItem(LOCAL_STORAGE_FACILITY_SELECTIONS, JSON.stringify(checkedKeys));
    setFacilityFilterList(newFacilityString);
    closeFacilityModal();
  }

  const containsFilterValue = (filterValue: string, record: ICorporateHierarchyNode) => {
    if(record.name.indexOf(filterValue)!== -1){
      return true;
    }
    if(record.children == null){
      return false;
    }
    return record.children.some(child => containsFilterValue(filterValue, child));
  }

  const filterRecord = (filterValue: string, record: ICorporateHierarchyNode) =>{
    return containsFilterValue(filterValue, record);
  }

  return (
      <>
      <Tooltip placement="bottomRight" title={facilityFilterPresent ? "Select Facility Groupings" : "Select Facility Groupings - Disabled"}>
        <Button 
          className="facility-selector-button"
          type="primary" 
          shape="circle" 
          disabled={!facilityFilterPresent} 
          icon={<ClusterOutlined style={{ fontSize: "24px",  verticalAlign: "middle"}} />} 
          onClick={openFacilityModal}/>
      </Tooltip>
      { showFacilityModal && 
        <Modal
          className="facility-modal"
          title="Select Facility Groupings"
          visible={true}
          onCancel={closeFacilityModal}
          width={650}
          footer={
            <>
            <div style={{float: "left"}}>
              <Button type="primary" onClick={()=> onCustomSelectAll(true)}>Select All</Button>       
              <Button onClick={()=> onCustomSelectAll(false)}>Deselect All</Button>
            </div>
            <div>
              <Button onClick={closeFacilityModal}>
                Cancel
              </Button>
              <Button type="primary" 
                onClick={() => {
                  updateFacilityFilterList();
                }}
              >
                Apply Facility Selections ({keysCount})
              </Button>
            </div>
          </>
          }
        >
        <Layout.Content
          style={{
            overflowY: "auto",
            height: "60vh"
          }}
        >
          { corporateHierarchy == null && corporateHierarchyError == null && 
              <CustomSpinner />
          }
          { corporateHierarchy == null && corporateHierarchyError != null &&
              <Alert 
                type="error"
                message={corporateHierarchyError}
              />              
          }
          { corporateHierarchy!= null &&
              <div className="facility-selection-modal">
                <Table
                  dataSource={corporateHierarchy}
                  pagination={false}
                  rowSelection={{ 
                    selectedRowKeys: checkedKeys, 
                    preserveSelectedRowKeys: true, 
                    onSelect: onSelectRow, 
                    onSelectAll: onSelectAll,
                  }}
                >
                  <Table.Column
                    title={
                      <>
                        <div style={{display: "inline-block"}}>Name</div>
                        &ensp;&ensp;
                        <Input.Search
                          style={{width: "250px"}}
                          placeholder="Filter By Name..."
                          onSearch={updateFilterText}
                          enterButton
                          className="facility-selector-search"
                        />
                      </>
                    }
                    key="name"
                    filteredValue={filterText && filterText.trim().length > 0 ? [filterText] : []}
                    onFilter={filterRecord}
                    render={(element) => 
                      <>
                      { element.nodeType === 'I' ?
                        (<div><b>{element.name}</b></div>) :
                        (<div>{element.name}</div>)
                      }
                    </>
                    }
                  /> 
                  <Table.Column
                    title="Facility ID"
                    key="facilityId"
                    dataIndex="facilityId"
                  /> 
                </Table>
              </div>
          }
        </Layout.Content>
        </Modal>
      }
      </>
  );
}


const mapStateToProps = (state: IApplicationState) => {
  return {
    facilityFilterPresent: state.filter.facilityFilterPresent,
    corporateHierarchy: state.filter.corporateHierarchy,
    corporateHierarchyError: state.filter.corporateHierarchyError,
    isSupport: state.userAuth.isSupport,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLookerContentInfo: (lookerContentType: LookerContentType, facilityFilterPresent: boolean) =>
      dispatch(setLookerContentInfo(lookerContentType, facilityFilterPresent)),
    setFacilityFilterList: (facilityFilterList: string) =>
      dispatch(setFacilityFilterList(facilityFilterList)),
    getCorporateHierarchy: (ignoreError: boolean) => 
      dispatch(getCorporateHierarchy(ignoreError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilitySelector);
