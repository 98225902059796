import { IStandardReportsGroupItem, IStandardReportsDashboardItem } from "../../AppModels";
import { API } from "../LookerAPI/LookerAPI";



  export const getAndParseStandardReports = async (
    setStandardReports: (data: IStandardReportsGroupItem[]) => void,
    setReportMap: (map:  Map<string, IStandardReportsDashboardItem>) => void,
    setError: (error: Error) => void
  ) => {
    const setResult = (data: IStandardReportsGroupItem[]) => {
        data.sort((a: IStandardReportsGroupItem, b: IStandardReportsGroupItem) => {
            if(a.pinnedToTop && b.pinnedToTop){
                return a.name.localeCompare(b.name);
            } else if(a.pinnedToTop){
                return -1;
            } else if(b.pinnedToTop){
                return 1;
            } else {
                return a.name.localeCompare(b.name);                
            }
        });
        let reportMap = new Map<string, IStandardReportsDashboardItem>();
        const allSpaces = new RegExp(' ', 'g');
        data.forEach((group)=>{
            if(group.dashboards) {
                group.dashboards.forEach((dashboard) =>{
                    dashboard.dashboardId = group.name.replace(allSpaces, '') + "/" + dashboard.name.replace(allSpaces, '');
                    reportMap.set(dashboard.dashboardId, dashboard);
                });
            }
            if(group.menus) {
                group.menus.forEach((menu) =>{
                    if(menu.dashboards){
                        menu.dashboards.forEach((dashboard)=>{
                            dashboard.dashboardId = group.name.replace(allSpaces, '') + "/" + menu.name.replace(allSpaces, '') + "/" + dashboard.name.replace(allSpaces, '');
                            reportMap.set(dashboard.dashboardId, dashboard);
                        });
                    }
                    if(menu.subMenus){
                        menu.subMenus.forEach((subMenu) =>{
                            if(subMenu.dashboards){
                                subMenu.dashboards.forEach((dashboard)=>{
                                    dashboard.dashboardId = group.name.replace(allSpaces, '') + "/" + menu.name.replace(allSpaces, '') + "/" + subMenu.name.replace(allSpaces, '') + "/" + dashboard.name.replace(allSpaces, '');
                                    reportMap.set(dashboard.dashboardId, dashboard);
                                });
                            }
                        });
                    }
                });
            }
        });
        setStandardReports(data);
        setReportMap(reportMap);
    } ;
    await API.getStandardReports(setResult, setError);
  };