import { API } from "../../LookerAPI/LookerAPI";
import {
    IUserInfo,
    IGroupInfo
} from "./EditGroupsModels";

export const getCustomGroupDetails = async (
    setResult: (groups: IGroupInfo[]) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: any) => {
        const groups: IGroupInfo[] = result as IGroupInfo[];
        setResult(groups);
    };
    await API.getCustomGroupDetails(getResult, setError);
};

export const createGroup = async (
    groupname: string,
    groupCreated: (newGroupInfo: IGroupInfo) => void,
    setError: (error: Error) => void
) => {
    const setResult = (result: any) => {
        console.log("createGroup returns: ", result);
        let newGroupInfo: IGroupInfo = {
            groupName: groupname,
            includedGroups: [],
            users: [],
            totalUniqueUsers: 0,
        }
        groupCreated(newGroupInfo);
    };
    await API.createGroup(groupname, setResult, setError);
};

export const addGroupToGroup = async (
    groupname: string,
    addGroup: string,
    setResult: (result: any) => void,
    setError: (error: Error) => void
) => {
    await API.addGroupToGroup(groupname, addGroup, setResult, setError);
};

export const addUserToGroup = async (
    groupname: string,
    addUser: string,
    setResult: (result: any) => void,
    setError: (error: Error) => void
) => {
    await API.addUserToGroup(groupname, addUser, setResult, setError);
};

export const removeGroupFromGroup = async (
    groupname: string,
    removeGroup: string,
    setResult: (result: any) => void,
    setError: (error: Error) => void
) => {
    await API.removeGroupFromGroup(groupname, removeGroup, setResult, setError);
};

export const removeUserFromGroup = async (
    groupname: string,
    removeUser: string,
    setResult: (result: any) => void,
    setError: (error: Error) => void
) => {
    await API.removeUserFromGroup(groupname, removeUser, setResult, setError);
};

export const deleteGroup = async (
    groupname: string,
    setResult: (result: any) => void,
    setError: (error: Error) => void
) => {
    await API.deleteGroup(groupname, setResult, setError);
};

export const getTotalUsers = async (
    setResult: (groups: IUserInfo[]) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: any) => {
        const users: IUserInfo[] = result as IUserInfo[];
        setResult(users);
    };
    await API.getLicensedUsers(getResult, setError);
};