export interface IFolderInfo {
  id: string;
  name: string;
  parent_id: string;
  dashboards?: IDashboardInfo[];
  looks?: ILookInfo[];
  children?: IChildInfo[];
  userCanEditContent?: boolean;
  userCanDeleteFolder?: boolean;
}

export interface IDashboardInfo {
  id: string;
  title: string;
  embed_url?: string;
  image_embed_url?: string;
  view_count?: number;
  favorite_count?: number;
  last_viewed?: string;
  user_id?: string;
  created_at?: string;
  updated_at?: string;
  folder_id?: string; //in Search Results, folder_id=0 is used to mark/identify Standard Reports
  folder_name?: string;
  favorite_id?: string;
}

export interface ILookInfo {
  id: string;
  title: string;
  embed_url?: string;
  image_embed_url?: string;
  view_count?: number;
  favorite_count?: number;
  last_viewed?: string;
  user_id?: string;
  created_at?: string;
  updated_at?: string;
  folder_id?: string;
  folder_name?: string;
  favorite_id?: string;
}

export interface IChildInfo {
  id: string;
  name: string;
  created_at?: string;
}

export interface IAncestorInfo {
  id: string;
  name: string;
  parent_id: string;
}

export interface IRootFolderIds {
  embedUsersRootId: string;
  personalRootId: string;
  teamSharedRootId: string;
  teamRootId: string;
  sharedRootId: string;
}

export interface IDisplayFolderItem {
  type: IDisplayFolderItemType,
  id: string,
  name: string,
  parent_id?: string,
  parent_name?: string,
  favorite_id?: string
}

export enum IDisplayFolderItemType {
  LOOK = 'Look',
  DASHBOARD = 'Dashboard',
  FOLDER = "Folder",
}

export interface IFolderTree {
  id: string,
  name: string,
  children: IFolderTree[],
}

export interface INode {
  id: string,
  name: string,
  childIds: string[],
}

export interface IMoveSource {
  startFolderId: string;
  startFolderName: string;
  moveFromFolderId: string;
  moveType: IDisplayFolderItemType;
  moveItemId: string;
  moveItemName: string;
}

export interface INewSource {
  type: IDisplayFolderItemType;
  folderInfo: IFolderInfo;
}

export interface IRenameSource {
  type: IDisplayFolderItemType;
  folderInfo: IFolderInfo;
}

export interface IDeleteSource {
  type: IDisplayFolderItemType;
  id: string;
  name: string;
  folderInfo?: IFolderInfo;
}

export enum IReportGroupType {
  TEAM = "Shared Reports",
  MY = "My Reports",
}

export enum ReportActionTypes {
  MOVE = "MOVE",
  NEW = "NEW",
  DELETE = "DELETE",
  COPY = "COPY",
  RENAME = "RENAME",
}

export enum IFolderViewTypes {
  GRID = "Grid",
  LIST = "List"
}

export enum IContentDisplayTypes {
  HISTORY = "Recently Viewed",
  FAVORITES = "Favorites",  
  GROUP = "Grouped Reports",
  SEARCH = "Search Results",
}

export interface IFolderItemMoveAction {
  type: ReportActionTypes.MOVE;
  moveSource: IMoveSource;
}

export interface IFolderItemCopyAction {
  type: ReportActionTypes.COPY;
  copySource: IMoveSource;
}

export interface IFolderItemNewAction {
  type: ReportActionTypes.NEW;
  newSource: INewSource;
}

export interface IFolderItemRenameAction {
  type: ReportActionTypes.RENAME;
  renameSource: IRenameSource;
}

export interface IFolderItemDeleteAction {
  type: ReportActionTypes.DELETE;
  deleteSource: IDeleteSource;
}

export type ReportsAction =
  IFolderItemMoveAction |
  IFolderItemRenameAction |
  IFolderItemCopyAction |
  IFolderItemNewAction |
  IFolderItemDeleteAction;

export interface IEmbedUsername {
  id: string,
  first_name: string,
  last_name: string,
  display_name: string,
}
