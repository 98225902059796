import React from 'react'
import { Layout, Table  } from 'antd'
import { INotification } from '../../../services/NotificationsManagement/NotificationsModels';
import { ColumnType, TableProps } from 'antd/lib/table';
import { IAlertInfo } from '../../../AppModels';
import "./NotificationsTable.scss";

interface IProps extends TableProps<INotification>  {
  refreshNotifications(): void;
  setAlertInfo: React.Dispatch<React.SetStateAction<IAlertInfo>>;
  columns: ColumnType<INotification>[];
}

const NotificationsTable: React.FC<IProps> = ({setAlertInfo, refreshNotifications, ...tableProps}) => {

  function getTextWidth(text: string, font: string, fontSize: number, fontWeight: string): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      throw new Error("Failed to retrieve 2D context from canvas.");
    }

    context.font = `${fontWeight} ${fontSize}px ${font}`;
    const metrics = context.measureText(text);
    return metrics.width;
  }
  

  const titleWidths: { [key: string]: { title: string, width: number } } = tableProps.columns.reduce((acc, column) => {
    if (typeof column.title === 'string') {
      acc[column.title] = {
        title: column.title,
        width: getTextWidth(column.title, "Roboto", 14, "500") + 40,
      };
    }
    return acc;
  }, {} as { [key: string]: { title: string, width: number } });
  
  return (
    <Layout>
      <Layout.Content>
        <Table
        className="notification-management-table"
        {...tableProps}
        columns={tableProps.columns.map(column => {
          return {
            ...column,
            onCell: () => ({
              style: { minWidth: `${titleWidths[column.title as string].width}px` },
            }),
          }
        })}/>
      </Layout.Content>
    </Layout>
  )
}

export default NotificationsTable