import { Col, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IAlertInfo } from "../../AppModels";
import { IGroupInfo } from '../../services/AdminManagement/EditGroupsManagement/EditGroupsModels';
import { getCustomGroups } from '../../store/actions/EditGroupsActions';
import { IApplicationState } from '../../store/Store';
import AddGroupButton from './AddGroupButton/AddGroupButton';
import "./EditGroups.scss";
import GroupsView from './GroupsView/GroupsView';

interface IProps {}

const EditGroups: React.FC<IProps> = (props: IProps) => {

  const customGroups: IGroupInfo[] = useSelector<IApplicationState>(state => state.editGroups.customGroupDetails) as IGroupInfo[];
  const [loading, setLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({visible: false, type:'warning', message:'', description: ''});

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Admin - Groups";

    const getGroups = async () => {
      setLoading(true);
      await dispatch(getCustomGroups());
      setLoading(false);
    };
    getGroups()
  }, [dispatch]);

  return (
    <>
    <Layout>
      <div className="groups-content-area">
        <Layout.Content>
          <br />
          <Row style={{height:35.5}}>
            <Col span={12}>
              <h3 style={{float:'left', margin:0}}>Groups</h3>
            </Col>
            <Col span={12}>
              <div className="edit-groups-header">
                <AddGroupButton groups={customGroups} setLoading={setLoading} loading={loading} setAlertInfo={setAlertInfo}/>
              </div>
            </Col>
          </Row>
          <br />
              <GroupsView groups={customGroups} setLoading={setLoading} loading={loading} setAlertInfo={setAlertInfo} {...alertInfo}/>
        </Layout.Content>
      </div>
    </Layout>
    </>
  );
};

export default EditGroups;