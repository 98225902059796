export interface IDataInsightsMessage {
    heading: string;
    title: string;
    messages: string[];
    severity: "info" | "warning" | "error";
    disabled?: boolean;
}

export const MESSAGE_DOWNLOAD_WARNING: IDataInsightsMessage = {
    heading: "Download Warning",
    title: "Download attempts can fail as a result of network timeouts.",
    messages: [
        "Consider using the Schedule functionality to perform a one-time or recurring send of this report instead."
    ],
    severity: "info",
    disabled: true
}

/*export const MESSAGE_LARGE_LOOK_SAVE_WARNING: IDataInsightsMessage = {
    heading: ">50 Fields Warning",
    title: "Reports with greater than 50 fields are subject to performance issues.",
    messages: [
        "Viewing looks with greater than 50 fields can cause the browser screen to freeze or become unresponsive.", 
        "Consider reducing the number of fields or use a data feed instead."
    ],
    severity: "warning"
}*/

export const MESSAGE_LARGE_LOOK_LOAD_WARNING: IDataInsightsMessage = {
    heading: ">50 Fields Warning",
    title: "Reports with greater than 50 fields are subject to performance issues.",
    messages: [
        "Viewing looks with greater than 50 fields can cause the browser screen to freeze or become unresponsive.",
        "Attempting to download looks with greater than 50 fields often fails.",
        "Consider using the Send or Schedule functionality to schedule a delivery of this report instead."
    ],
    severity: "warning",
    disabled: false
}
