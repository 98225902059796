import { AutoComplete, Input } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import '../ExploreTiles.scss';

interface IProps {
    setSearchText: Dispatch<SetStateAction<string>>;
    value: string;
}

const ExploreTile: React.FC<IProps> = ({setSearchText, value}) => {
    

    const handleTileSearch = (value) => {
        value ? setSearchText(value) : setSearchText('');

    }

    return (
        <div style={{float: 'left', marginBottom: '15px', marginTop:'10px'}}>
            <AutoComplete
                style={{width: '300px', float: 'left'}}
                onChange={handleTileSearch}
                onClear={() => setSearchText("")}
                value={value}
            >
                <Input.Search size="middle" placeholder={'Search for an Explore'} allowClear/>
            </AutoComplete>  
        </div>
    );
};

export default ExploreTile;