import React, { useEffect } from "react";
import { Alert, Button, Space, Table } from "antd";
import { ColumnsType } from 'antd/es/table'
import { IDocument, IDocumentationCategory } from '../../../services/DocumentationManagement/DocumentationModels'
import { IAlertInfo } from "../../../AppModels";

interface IProps {
    documents: IDocument[];
    setLoading(isLoading: boolean): void;
    setAlertInfo(alertInfo: IAlertInfo);
    loading: boolean;
}

const DocumentationView: React.FC<IProps> = (props: (IProps & IAlertInfo)) => {

    const [categories, setCategories] = React.useState([]);

    const onAlertClose = () => {
        props.setAlertInfo({
            visible: false,
            type: 'warning',
            message: '',
            description: ''
        })
    }

    useEffect(() => {
        let catBuilder: IDocumentationCategory[] = [];
        for (let document of props.documents) {
            if (document.category !== 'Miscellaneous') {
                if (catBuilder.filter(cat => cat.name === document.category).length === 0) {
                    catBuilder.push({name: document.category, documents: props.documents.filter(doc => doc.category === document.category)});
                }   
            }
        }
        setCategories(catBuilder);
    }, [props.documents])
    
    const expandedRowRender = (item) => {

        const nestedColumns: ColumnsType<IDocument> = [
            { 
                title: 'Title', 
                dataIndex: 'title', 
                key: 'title', 
                width: '90%',
            },
            {
                title: 'Updated',
                dataIndex: 'updated',
                key: 'updated',
                align: 'center',
                width: '5%'
            },
            {
                title: 'Action',
                dataIndex: 'title',
                key: 'title',
                align: 'center',
                width: '5%',
                render: (item, record, index) => (
                <Space size="middle">
                    <Button className='view-document-btn' onClick={() => window.open(record.pdfUrl, "_blank")}>View</Button>
                </Space>
                ),
            },
        ];

        
        const data = [];
        let key = 0;
        for (let i of item.documents) {
            data.push({
                key: key,
                title: String(i.title).replaceAll('.', '/'),
                updated: i.updated,
                pdfUrl: i.pdfUrl,
            });
            key++;
        }

    return <Table id='nested-table' columns={nestedColumns} dataSource={data} pagination={false} />;
    };

    const columns: ColumnsType<IDocumentationCategory> = [
        {
            title: 'Category', 
            dataIndex: 'name', 
            key:'name',
        },
    ]

    return (
        <>
            {
            props.visible &&
            <Alert
                style={{ marginBottom: "24px"  }}
                type={props.type}
                message={props.message}  
                description={props.description}
                closable 
                onClose={onAlertClose}/>
            }
            <Table<IDocumentationCategory>
                rowKey='name'
                columns={columns}
                dataSource={categories}
                expandable={{ expandedRowRender }}
                loading={props.loading}
                pagination={false}
            />
        </>
    );
};

export default DocumentationView;