import { Reducer } from "redux";

import {
  IFoldersState,
  FoldersActions,
  FoldersActionTypes,
} from "../actions/FoldersTypes";
import { IDisplayFolderItemType, IFolderViewTypes } from "../../services/FolderManagement/FolderModels";

const initialFoldersState: IFoldersState = {
  rootFolders: null,
  rootFoldersError: null,
  userId: null,
  viewType: IFolderViewTypes.GRID,
  historyViewType: IFolderViewTypes.LIST,
  groupMemberNameMap: null,
  currentItem: null,
  currentAncestors: null,
  moveStartFolder: {
    id: null,
    name: null,
    type: IDisplayFolderItemType.FOLDER
  },
};

export const foldersReducer: Reducer<
  IFoldersState,
  FoldersActions
> = (state = initialFoldersState, action): IFoldersState => {
  // console.log("FoldersReducer"); 
  // console.log("action = ", action);
  // console.log("action.type = " + action.type);
  switch (action.type) {
    case FoldersActionTypes.SET_FOLDERS: {
      return {
        ...state,
        rootFolders: action.rootFolders,
        rootFoldersError: action.errorMessage
      };
    }
    case FoldersActionTypes.SET_EMBED_USER: {
      return {
        ...state,
        userId: action.userId,
      };
    }
    case FoldersActionTypes.SET_FOLDERS_VIEW:
      return {
        ...state,
        viewType: action.viewType
      }
    case FoldersActionTypes.SET_FOLDERS_HISTORY_VIEW:
      return {
        ...state,
        historyViewType: action.historyViewType
      }
    case FoldersActionTypes.GET_GROUP_MEMBER_NAMES:
      return {
        ...state,
        groupMemberNameMap: action.groupMemberNameMap
      }
    case FoldersActionTypes.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item,
        currentAncestors: action.ancestors,
      }
    case FoldersActionTypes.SET_MOVE_START_FOLDER:
      return {
        ...state,
        moveStartFolder: action.item
      }
    default: {
      return state;
    }
  }
};
