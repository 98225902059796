import { ReloadOutlined } from "@ant-design/icons";
import { Alert, Layout } from "antd";
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ICustomerSettings, IStandardReportsDashboardItem } from "../../AppModels";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { getCustomerSettings, getStandardReports } from "../../store/actions/SettingsActions";
import { IApplicationState } from "../../store/Store";
import Looker from "../Looker/Looker";

interface MatchParams {
  id: string;
}

interface IDashboardProps extends RouteComponentProps<MatchParams> {}

function Reports(props: IDashboardProps) {
  const [dashboard, setDashboard] = React.useState<IStandardReportsDashboardItem>(null);
  const [path, setPath] = React.useState<string>(null);
  const [errorMessage, setErrorMessage] = React.useState<ReactNode>(null);

  const dispatch = useDispatch();
  const customerSettings: ICustomerSettings = useSelector<IApplicationState>(state => state.settings.customerSettings) as ICustomerSettings;
  const customerSettingsError: string = useSelector<IApplicationState>(state => state.settings.customerSettingsError) as string;
  const standardReportsMap: Map<string, IStandardReportsDashboardItem> = useSelector<IApplicationState>(state => state.settings.standardReportsMap) as Map<string, IStandardReportsDashboardItem>;
  const standardReportsError: string = useSelector<IApplicationState>(state => state.settings.standardReportsError) as string;

  useEffect(() => {
    if(customerSettings !=null && standardReportsMap != null && props.match.params.id != null){
      if(standardReportsMap.has(props.match.params.id)){
        setErrorMessage(null);
        setDashboard(standardReportsMap.get(props.match.params.id));
      } else {
        setErrorMessage("The report URL does not exist or you do not have access to it.");
      }
    } else if((standardReportsMap == null && standardReportsError !=null) || (customerSettings == null && customerSettingsError != null)){
      setErrorMessage(
        <div 
          onClick={() => {
            if(standardReportsError){
             dispatch(getStandardReports()); 
            }
            if(customerSettingsError){
              dispatch(getCustomerSettings());
            } 
            setErrorMessage(null); 
          }} 
          className="clickable">
          There was an error loading Standard Reports. <u>Click to Retry <ReloadOutlined /></u>
        </div>
      );
    }
  }, [standardReportsMap, props.match.params.id, standardReportsError, dispatch, customerSettings, customerSettingsError]);

  useEffect(() => {
    console.log(dashboard);
    if (dashboard && customerSettings) {
      document.title = dashboard.name;
      const realTimeAllowed = customerSettings.realTimeAll || dashboard.explores==null || 
        (customerSettings.realTimeExplores && dashboard.explores.some(explore => customerSettings.realTimeExplores.has(explore)));
      if(realTimeAllowed && dashboard.realtimeEmbeddedPath){
        setPath(dashboard.realtimeEmbeddedPath);
      } else if (dashboard.embeddedPath){
        setPath(dashboard.embeddedPath)
      } else {
        setErrorMessage("There may be a configuration error for the requested dashboard, or you may not have access to it.");
      }
    }
  }, [dashboard, customerSettings]);

  return (
    <>
    { path ? 
      (
        <Looker embeddedPath={path} />
      ) :
      (         
          <Layout.Content 
          style = {{
            padding: "32px 64px"
          }}
          > 
            { errorMessage!=null ?
              <Alert 
                style={{ marginBottom: "24px"  }}
                type="error"
                message={errorMessage}  
              /> :
              <CustomSpinner />
            }
          </Layout.Content>
      )
    }
    </>
  );
}

export default Reports;
