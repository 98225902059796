import { API } from "../../LookerAPI/LookerAPI";
import {
    IFolderPermissions,
    IEditFolderPermissions
} from "./FolderPermissionsModels";

export const getFolderPermissionStatus = async (
    folderId: string,
    setResult: (permissions: IFolderPermissions) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: any) => {
        const permissions: IFolderPermissions = result as IFolderPermissions;
        setResult(permissions);
    };
    await API.getFolderPermissionStatus(folderId, getResult, setError);
};

export const setFolderPermission = async (
    folderId: string,
    groupname: string,
    access: string,
    setResult: (result: any) => void,
    setError: (error: Error) => void
) => {
    await API.setFolderPermission(folderId, groupname, access, setResult, setError);
}

export const getFolderEditPermissions = async (
    folderId: string,
    parentId: string,
    setResult: (result: IEditFolderPermissions) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: any) => {
        const editPermissions: IEditFolderPermissions = result as IEditFolderPermissions;
        setResult(editPermissions);
    }
    await API.getFolderEditPermissions(folderId, parentId, getResult, setError);
}
