import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { Button, Statistic, Tooltip } from "antd";
import { IScheduleInfo } from "../../../../services/Schedules/ScheduleModels";
import {
  GLOBAL_SCHEDULE_RUN_COUNTDOWN,
  SINGLE_SCHEDULE_RUN_COUNTDOWN,
} from "../../../../Constants";
import { LoadingOutlined } from "@ant-design/icons";

const { Countdown } = Statistic;

interface SendNowButtonProps {
  schedule: IScheduleInfo;
  schedules: IScheduleInfo[];
  sendNow: (schedule: IScheduleInfo) => Promise<IScheduleInfo>;
  sending: boolean;
  globalCountdown: boolean;
  setGlobalCountdown: (state: boolean) => void;
  countdowns: Map<string, number>;
  addCountdown: (id: string, cooldownEndTime: number) => void;
  removeCountdown: (id: string) => void;
}

const SendNowButton: React.FC<SendNowButtonProps> = ({
  schedule,
  sendNow,
  sending,
  globalCountdown,
  setGlobalCountdown,
  countdowns,
  addCountdown,
  removeCountdown,
}) => {
  const isDisabled: boolean =
    sending || globalCountdown || countdowns.has(schedule.id);

  const handleClick = useCallback(() => {
    sendNow(schedule).then((sentSchedule) => {
      addCountdown(sentSchedule.id, SINGLE_SCHEDULE_RUN_COUNTDOWN);
    });
    setGlobalCountdown(true);
  }, [sendNow, schedule, addCountdown, setGlobalCountdown]);

  const countdown = countdowns.has(schedule.id)
    ? countdowns.get(schedule.id)
    : Date.now() + GLOBAL_SCHEDULE_RUN_COUNTDOWN;

  useEffect(() => {
    if (countdowns.has(schedule.id) && countdown < Date.now()) {
      removeCountdown(schedule.id);
    }
  }, [countdowns, removeCountdown, countdown, schedule.id]);

  const tooltipTitle = "Please wait to send again.";

  const buttonText = useMemo(() => {
    const handleCountdownFinish = () => {
      if (countdowns.has(schedule.id)) removeCountdown(schedule.id);
      else setGlobalCountdown(false);
    };

    let text: ReactElement = <span>Send Now</span>;

    if (!sending && isDisabled) {
      text = (
        <Countdown
          valueStyle={{ fontSize: "16px" }}
          value={countdown}
          format="m:ss"
          onFinish={handleCountdownFinish}
        />
      );
    } else if (sending && isDisabled) {
      text = (
        <div className="sending-container">
          <LoadingOutlined />
          <span>Sending...</span>
        </div>
      );
    }

    return <div className="send-now-button-content">{text}</div>;
  }, [
    sending,
    isDisabled,
    countdowns,
    schedule.id,
    removeCountdown,
    setGlobalCountdown,
    countdown,
  ]);

  return (
    <Tooltip title={isDisabled ? tooltipTitle : ""}>
      <Button
        className="send-now-button"
        onClick={() => handleClick()}
        disabled={isDisabled}
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
};

export default SendNowButton;
