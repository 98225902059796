import { Button, Col, Modal, Row, Statistic } from "antd";
import React from "react";
import { IContractInfo } from "../../../services/AdminManagement/EditUsersManagement/EditUsersModels";

interface ILicenseInformationModalProps {
    exit: () => void;
    contractInfo: IContractInfo;
}

const LicenseInformationModal: React.FC<ILicenseInformationModalProps> = ({ exit, contractInfo }) => {

    const closeModal = () =>{
        exit();
    }

  return (
    <>
    <Modal
        title={`Contracted Licenses`}
        visible={true}
        onCancel={closeModal}
        width={600}
        footer={
            <Button type='primary' onClick={closeModal}>
            Close
            </Button>
        }
    >      
    <h3>Explore Licenses</h3>
    <Row gutter={16}>
        <Col span={8}>
            <Statistic title="Licenses Contracted" value={contractInfo.exploreLicenseCount}/>
        </Col>
        <Col span={8}>
            <Statistic title="Licenses In Use" value={contractInfo.exploreLicensesInUse} />
        </Col>
        <Col span={8}>
            <Statistic title="Licenses Available" value={contractInfo.exploreLicenseCount - contractInfo.exploreLicensesInUse} />
        </Col>
    </Row>    
    <br />
    <br />
    <h3>View Licenses</h3>
    <Row gutter={16}>
        <Col span={8}>
            <Statistic title="Licenses Contracted" value={contractInfo.viewLicenseCount} />
        </Col>
        <Col span={8}>
            <Statistic title="Licenses In Use" value={contractInfo.viewLicensesInUse} />
        </Col>
        <Col span={8}>
            <Statistic title="Licenses Available" value={contractInfo.viewLicenseCount - contractInfo.viewLicensesInUse} />
        </Col>
    </Row>
    </Modal>
    </>
  );
};
 
export default LicenseInformationModal;