import React, { useEffect, useState } from "react";
import { Modal, Button, Col, Row, Select, Typography, Table, Popconfirm, Alert } from "antd";
import { ColumnsType } from 'antd/es/table'
import {
    UserOutlined,
    TeamOutlined
} from "@ant-design/icons";
import { IApplicationState } from '../../../../store/Store'
import {
    IGroupInfo,
    IUserInfo
} from "../../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import { getCustomGroups, getLicensedUsers } from "../../../../store/actions/EditGroupsActions"
import { useDispatch, useSelector } from "react-redux";
import {
    addGroupToGroup,
    addUserToGroup,
    removeGroupFromGroup,
    removeUserFromGroup
} from '../../../../services/AdminManagement/EditGroupsManagement/EditGroupsManagement'
import { IAlertInfo } from "../../../../AppModels";

interface IProps {
    text: object,
    record: IGroupInfo,
    index: number,
}

interface IMemberInfo {
    key: React.Key[],
    type: string,
    name: string,
    displayName: string,
    details: string,
}

const { Text, Paragraph } = Typography;
const { Option } = Select;

const EditGroupButton: React.FC<IProps> = (props: IProps) => {

    const dispatch = useDispatch();

    const licensedUsers: IUserInfo[] = useSelector<IApplicationState>(state => state.editGroups.licensedUsers) as IUserInfo[];
    const customGroups: IGroupInfo[] = useSelector<IApplicationState>(state => state.editGroups.customGroupDetails) as IGroupInfo[];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addDisabled, setAddDisabled] = useState(true);
    const [removeDisabled, setRemoveDisabled] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<IMemberInfo[]>([]);
    const [record, setRecord] = useState<IGroupInfo>();
    const [isLoading, setIsLoading] = useState(false);
    const [members, setMembers] = useState<IMemberInfo[]>([]);
    const [memberOptions, setMemberOptions] = useState([]);
    const [alertInfo, setAlertInfo] = useState<IAlertInfo>({visible: false, type:'warning', message:'', description: ''});
    const itemsPerPage = 6;

    useEffect(() => {
        if (selectedOptions.length > 0) {
            setAddDisabled(false);
        } else {
            setAddDisabled(true);
        }
    }, [selectedOptions])

    useEffect(() => {
        if (selectedRows.length > 0) {
            setRemoveDisabled(false);
        } else {
            setRemoveDisabled(true);
        }
    }, [selectedRows])

    const showModal = () => {
        setIsModalVisible(true);
    }

    const handleClose = async () => {
        setIsModalVisible(false);
    }

    const handleOnClick = async () => {
        setRecord(props.record);
        dispatch(getLicensedUsers());
        showModal();
    }

    const handleAddButton = async () => {
        setIsLoading(true);

        let options = [];
        let successes = {users: 0, groups: 0};
        let failures = {users: 0, groups: 0};
        for (let option of selectedOptions) {

            options.push(option);
            for (let user of licensedUsers) {
                if (user.username === option) {
                    if (await handleAddUserToGroup(option, record.groupName)) {
                        successes.users++;
                    } else {
                        failures.users++;
                    }
                }
            }

            for (let group of customGroups) {
                if (group.groupName === option) {
                    if (await handleAddGroupToGroup(option, record.groupName)) {
                        successes.groups++;
                    } else {
                        failures.groups++;
                    }
                }
            }
        }
        setSelectedOptions([])
        setIsLoading(false);

        if (!failures.groups && !failures.users) {

            let alertMessage: string = '';
            if(successes.users === 0) {
                alertMessage = `Successfully added ${successes.groups} ${successes.groups > 1 ? 'groups' : 'group'}!`
            } else if (successes.groups === 0) {
                alertMessage = `Successfully added ${successes.users} ${successes.users > 1 ? 'users' : 'user'}!`
            } else {
                alertMessage = `Successfully added ${successes.groups} ${successes.groups > 1 ? 'groups' : 'group'} and ${successes.users} ${successes.users > 1 ? 'users' : 'user'}!`
            }

            setAlertInfo({
                visible: true,
                type: 'success',
                message: alertMessage,
                description: ''
            })
        } else if (!failures.groups && failures.users) {
            setAlertInfo({
                visible: true,
                type: 'error',
                message: `Failed to add ${failures.users} ${failures.users > 1 ? 'users' : 'user'}!`,
                description: ''
            })
        } else if (failures.groups && !failures.users) {
            setAlertInfo({
                visible: true,
                type: 'error',
                message: `Failed to add ${failures.groups} ${failures.groups > 1 ? 'groups' : 'group'}!`,
                description: ''
            })
        } else {
            setAlertInfo({
                visible: true,
                type: 'error',
                message: `Failed to add ${failures.groups} ${failures.groups > 1 ? 'groups' : 'group'} and ${failures.users} ${failures.users > 1 ? 'users' : 'user'}!`,
                description: ''
            })
        }
    }

    const handleRemoveButton = async () => {
        setIsLoading(true);

        let successes = {users: 0, groups: 0};
        let failures = {users: 0, groups: 0};
        for (let option of selectedRows) {
            for (let user of licensedUsers) {
                if (user.username === option.name) {
                    if (await handleRemoveUserFromGroup(option.name, record.groupName)){
                        successes.users++;
                    } else {
                        failures.users++;
                    }
                }
            }

            for (let group of customGroups) {
                if (group.groupName === option.name) {
                    if (await handleRemoveGroupFromGroup(option.name, record.groupName)) {
                        successes.groups++;
                    } else {
                        failures.groups++;
                    }
                }
            }
        }
        setSelectedRows([]);
        setSelectedRowKeys([]);
        setIsLoading(false);

        if (!failures.groups && !failures.users) {

            let alertMessage: string = '';
            if(successes.users === 0) {
                alertMessage = `Successfully removed ${successes.groups} ${successes.groups > 1 ? 'groups' : 'group'}!`
            } else if (successes.groups === 0) {
                alertMessage = `Successfully removed ${successes.users} ${successes.users > 1 ? 'users' : 'user'}!`
            } else {
                alertMessage = `Successfully removed ${successes.groups} ${successes.groups > 1 ? 'groups' : 'group'} and ${successes.users} ${successes.users > 1 ? 'users' : 'user'}!`
            }

            setAlertInfo({
                visible: true,
                type: 'success',
                message: alertMessage,
                description: ''
            })
        } else if (!failures.groups && failures.users && failures.groups !== successes.groups) {
            setAlertInfo({
                visible: true,
                type: 'error',
                message: `Failed to remove ${failures.users} ${failures.users > 1 ? 'users' : 'user'}!`,
                description: ''
            })
        } else if (failures.groups && !failures.users && failures.users !== successes.users) {
            setAlertInfo({
                visible: true,
                type: 'error',
                message: `Failed to remove ${failures.groups} ${failures.groups > 1 ? 'groups' : 'group'}!`,
                description: ''
            })
        } else {
            setAlertInfo({
                visible: true,
                type: 'error',
                message: `Failed to remove ${failures.groups} ${failures.groups > 1 ? 'groups' : 'group'} and ${failures.users} ${failures.users > 1 ? 'users' : 'user'}!`,
                description: ''
            })
        }
    }

    const handleSelectChange = (options: []) => {
        setSelectedOptions(options);
    }

    const handleAddUserToGroup = async (user, group) => {

        let success = false;

        const setResult = (results: any) => {
            console.log('Results for addUserToGroup: ', results);
            setAlertInfo({
                visible: true,
                type: 'success', 
                message: `User ${user} was successfully added to ${group}!`,
                description: ''
            })
            success = true;
        }

        const setError = (error: Error) => {
            console.log('addUserToGroupError: ', error);
            setAlertInfo({
                visible: true,
                type: 'error', 
                message: `Failed to add user ${user} to ${group}!`,
                description: ''
            })
            success = false;
        }

        await addUserToGroup(group, user, setResult, setError);
        await dispatch(getCustomGroups());
        return success;
    }

    const handleAddGroupToGroup = async (newGroup, group) => {
        
        let success = false;

        const setResult = (results: any) => {
            console.log('Results for addGroupToGroup: ', results);
            setAlertInfo({
                visible: true,
                type: 'success', 
                message: `Group ${newGroup} was successfully added to ${group}!`,
                description: ''
            })
            success = true;
        }

        const setError = (error: Error) => {
            console.log('addgroupToGroupError: ', error);
            setAlertInfo({
                visible: true,
                type: 'error', 
                message: `Failed to add group ${newGroup} to ${group}!`,
                description: ''
            })
            success = false;
        }

        await addGroupToGroup(group, newGroup, setResult, setError);
        await dispatch(getCustomGroups());
        return success;
    }

    const handleRemoveUserFromGroup = async (removeUser, group) => {

        let success = false;

        const setResult = (results: any) => {
            console.log('Results for removeUserFromGroup: ', results);
            setAlertInfo({
                visible: true,
                type: 'success', 
                message: `User ${removeUser} was successfully removed from ${group}!`,
                description: ''
            })
            success = true;
        }

        const setError = (error: Error) => {
            console.log('removeUserFromGroupError: ', error);
            setAlertInfo({
                visible: true,
                type: 'success', 
                message: `Failed to remove user ${removeUser} from ${group}!`,
                description: ''
            })
            success = false;
        }

        await removeUserFromGroup(group, removeUser, setResult, setError);
        await dispatch(getCustomGroups());
        return success;
    }

    const handleRemoveGroupFromGroup = async (removeGroup, group) => {

        let success = false;

        const setResult = (results: any) => {
            console.log('Results for removeGroupFromGroup: ', results);
            setAlertInfo({
                visible: true,
                type: 'success', 
                message: `Group ${removeGroup} was successfully removed from ${group}!`,
                description: ''
            })
            success = true;
        }

        const setError = (error: Error) => {
            console.log('removeGroupFromGroupError: ', error);
            setAlertInfo({
                visible: true,
                type: 'error', 
                message: `Failed to remove group ${removeGroup} from ${group}!`,
                description: ''
            })
            success = false;
        }

        await removeGroupFromGroup(group, removeGroup, setResult, setError);
        await dispatch(getCustomGroups());
        return success;
    }

    const onAlertClose = () => {
        setAlertInfo({
            visible: false,
            type: 'warning',
            message: '',
            description: ''
        })
    }

    useEffect(() => {

        if (record) {
            let data = [];
            let children = [];
            let isPartOfGroup: boolean = false;
            for (let customGroup of customGroups) {
                for (let includedGroup of customGroup.includedGroups) {
                    if (includedGroup.groupName === record.groupName) {

                        isPartOfGroup = true;
                        break;
                    }
                }
                if (isPartOfGroup) break;
            }

            let count: number = 0;
            for (let customGroup of customGroups) {
                for (let group of record.includedGroups) {
                    if (group.groupName === customGroup.groupName) {
                        let userCount = group.users.filter(user => user.holdingLicense).length;
                        data.push({
                            key: count,
                            type: 'group',
                            name: group.groupName,
                            displayName: group.groupName,
                            details: `${userCount} member${userCount === 1 ? '' : 's'}`
                        });
                        count++;
                    }
                }

                let groupToWithhold: string;
                for (let group of record.includedGroups) {
                    if (group.groupName === customGroup.groupName) {
                        groupToWithhold = group.groupName;
                        break;
                    }
                }

                let length = customGroup.users.filter(user => user.holdingLicense).length;;
                if (customGroup.groupName !== record.groupName &&
                    customGroup.groupName !== groupToWithhold &&
                    customGroup.includedGroups.length === 0 &&
                    !isPartOfGroup) {
                    children.push(
                        <Option
                            value={customGroup.groupName}
                            key={customGroup.groupName}
                            label={customGroup.groupName}
                        >
                            <Row gutter={15}>
                                <Col span={2}>
                                    <TeamOutlined />
                                </Col>
                                <Col span={14}>
                                    <Paragraph ellipsis={{tooltip: true}} style={{ width: '100%' }}>
                                        {customGroup.groupName}
                                    </Paragraph>
                                </Col>
                                <Col span={6}>
                                    <Text>
                                        {length > 1 || length === 0 ? length + " members" : length + " member"}
                                    </Text>
                                </Col>
                            </Row>
                        </Option>
                    );
                }
            }

            for (let licensedUser of licensedUsers) {

                for (let user of record.users) {
                    if (licensedUser.username === user.username) {
                        data.push({
                            key: count,
                            type: 'user',
                            name: licensedUser.username,
                            displayName: licensedUser.displayName,
                            details: licensedUser.license + " User" || '',
                        });
                        count++;
                    }
                }

                let userToWithhold: string;
                for (let user of record.users) {
                    if (user.username === licensedUser.username) {
                        userToWithhold = user.username;
                        break;
                    }
                }

                if (licensedUser.holdingLicense && licensedUser.group !== "AdminUsers") {
                    if (licensedUser.username !== userToWithhold) {
                        children.push(
                            <Option
                                value={licensedUser.username}
                                key={licensedUser.username}
                            >
                                <Row gutter={15}>
                                    <Col span={2}>
                                        <UserOutlined />
                                    </Col>
                                    <Col span={14}>
                                        <Paragraph ellipsis={{tooltip: true}} style={{ width: '100%' }}>
                                            {licensedUser.displayName}
                                        </Paragraph>
                                    </Col>
                                    <Col span={6}>
                                        <Text>
                                            {licensedUser.license + " User" || ''}
                                        </Text>
                                    </Col>
                                </Row>
                            </Option>
                        );
                    }
                }
            }
            setMemberOptions(children);
            setMembers(data);
        }

    }, [record, customGroups, licensedUsers]);

    useEffect(() => {
        setRecord(props.record);
    }, [props.record])


    // Creates the columns for the member view when adding groups/users to groups
    const columns: ColumnsType<IMemberInfo> = [
        {
            title: 'Details',
            dataIndex: 'details',
            width: '40%',
        },
        {
            title: 'Name',
            dataIndex: 'displayName',
            sortDirections: ['descend', 'ascend'],
            width: '50%',
            key: 'displayName',
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: '',
            dataIndex: 'type',
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'ascend',
            width: '5%',
            render: (text, record, index) =>
                <div>
                    {record.type === 'user' ? <UserOutlined /> : <TeamOutlined />}
                </div>,
            sorter: (a, b) => a.type.localeCompare(b.type),
        }
    ]

    const rowSelection = {
        selectedRowKeys,
        columnTitle: 'Remove',
        onChange: (selectedRowKeys: React.Key[], selectedRows: IMemberInfo[]) => {
            setSelectedRows(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    return (
        <>
            <Button
                className="group-btn-edit"
                onClick={handleOnClick}
            >
                Edit
            </Button>
            <Modal
                title={`Edit Group - ${record ? record.groupName : null}`}
                visible={isModalVisible}
                onCancel={handleClose}
                width={'550px'}
                destroyOnClose
                afterClose={() => {
                    setSelectedRows([]);
                    setSelectedOptions([]);
                }}
                footer={
                    <Button type='primary' onClick={handleClose}>
                        Close
                    </Button>
                }
            >
                <Row>
                    <Col span={6}>
                        <Text className='edit-group-form-item'>Add Members</Text>
                    </Col>
                    <Col span={13}>
                        <Select
                            mode='multiple'
                            allowClear
                            style={{ width: '100%' }}
                            placeholder='Add group or user...'
                            onChange={handleSelectChange}
                            optionLabelProp="label"
                            value={selectedOptions}
                        >
                            {memberOptions}
                        </Select >
                    </Col>
                    <Col span={5}>
                        <Button className='edit-group-modal-btn-add'
                            disabled={addDisabled}
                            onClick={handleAddButton}
                        >
                            Add
                    </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className='edit-group-members-view-container'>
                            {
                                alertInfo.visible &&
                                <Alert
                                    style={{ marginBottom: "24px"  }}
                                    type={alertInfo.type}
                                    message={alertInfo.message}  
                                    description={alertInfo.description}
                                    closable 
                                    onClose={onAlertClose}
                                    />
                            }
                            <Table<IMemberInfo>
                                className='edit-group-members-view'
                                columns={columns}
                                dataSource={members}
                                rowKey='name'
                                loading={isLoading}
                                pagination={{
                                    size: "small",
                                    defaultPageSize: itemsPerPage,
                                    hideOnSinglePage: true,
                                    position: ["bottomRight"],
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} members`
                                }}
                                rowSelection={rowSelection}

                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={4} offset={20}>
                        <Popconfirm
                            placement="top"
                            title="Are you sure you want to remove these?"
                            onConfirm={handleRemoveButton}
                            okText="Yes"
                            cancelText="No"
                            icon={null}
                            style={{ width: '20px' }}
                            overlayClassName='remove-btn-container'>
                            <div>
                                <Button
                                    className='edit-group-modal-btn-remove'
                                    disabled={removeDisabled}
                                    block
                                >
                                    Remove
                            </Button>
                            </div>
                        </Popconfirm>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default EditGroupButton;