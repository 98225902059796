import { AlertProps } from "antd/lib/alert";

export interface IAlertInfo {
  visible?: boolean,
  type?: AlertProps["type"],
  message?: AlertProps["message"],
  description?: AlertProps["description"]
}

export enum LookerContentType {
  LOOK = "Look",
  DASHBOARD = "Dashboard",
  EXPLORE = "Explore"
}

export interface LocalStorageUserInfo {
  loggedIn: boolean;
  username: string;
  realm: string;
  isSupport: boolean;
  roles: string[];
  models: string[];
  expiration: number;
  updated: Date;
  sudoUser: string;
  scopeOfControlUser: string;
  printDesignerRole: string;
  oktaLogin: boolean;
  federatedLogin: boolean;
  loginId: string;
}

export interface IPrintDesignerDataSource {
  type: LookerContentType,
  id?: string,
  qid?: string,
  filters?: any,
  time?: number
}

export interface IStandardReportsDashboardItem {
  name: string;
  embeddedPath?: string;
  realtimeEmbeddedPath?: string;
  explores?: string[];
  dashboardId?: string;
}

export interface IStandardReportsSubMenuItem {
  name: string;
  dashboards?: IStandardReportsDashboardItem[];
}

export interface IStandardReportsMenuItem {
  name: string;
  subMenus?: IStandardReportsSubMenuItem[];
  dashboards?: IStandardReportsDashboardItem[];
}

export interface IStandardReportsGroupItem {
  name: string;
  dashboards?: IStandardReportsDashboardItem[];
  menus?: IStandardReportsMenuItem[];
  pinnedToTop?: boolean;
}

export interface ICustomerSettings{
  realTimeAll: boolean;
  realTimeExplores: Set<string>;
}