import { API, ILookerApiBody } from "../LookerAPI/LookerAPI";
import {
  IFolderInfo,
  IDashboardInfo,
  ILookInfo,
  IChildInfo,
  IRootFolderIds,
  IFolderTree,
  INode,
  IEmbedUsername,
  IAncestorInfo,
} from "./FolderModels";

export const EMBED_USER_ROOT_FOLDER_NAME = "Embed Users";
export const TEAM_SHARED_ROOT_FOLDER_SUFFIX = "TeamShared";

export const getRootFolderIds = async (
  realm: string
) => {
  return new Promise<IRootFolderIds>((resolve, reject) => {
    const teamSharedName = realm + "@" + TEAM_SHARED_ROOT_FOLDER_SUFFIX;

    let getRootFolders = new Promise((resolve, reject) => {

      let ids = {
        embedUsersRootId: "",
        sharedRootId: ""
      }

      API.searchFolders({
        fields: "is_embed_users_root,is_shared_root,id",
        name: EMBED_USER_ROOT_FOLDER_NAME,
        is_shared_root: true,
        filter_or: true
      }, 
      async (rootFolders: any[]) => {

        rootFolders.forEach((x) => {
          if (x['is_embed_users_root'])
            ids['embedUsersRootId'] = x['id']
          if (x['is_shared_root'])
            ids['sharedRootId'] = x["id"];
        })

        resolve(ids)

      }, () => reject())
    })

    let getPersonalFolder = new Promise((resolve, reject) => {

      let ids = {
        personalRootId: ""
      }

      getCurrentEmbedUserId(
        async (embed_user_id: string) => {
        API.searchFolders({
          fields: "is_personal,id,parent_id",
          creator_id: embed_user_id
        }, 
        async (personalFolders: any[]) => {
          personalFolders.forEach((x) => {
            if (x["is_personal"])
              ids['personalRootId'] = x["id"];
          })

          resolve(ids)

        }, () => reject())
      }, () => reject())
    })

    let getTeamFolders = new Promise((resolve, reject) => {

      let ids = {
        teamSharedRootId: "",
        teamRootId: ""
      }

      API.searchFolders({
        fields: "id,parent_id,name",
        name: teamSharedName
      },
      (sharedFolders: any[]) => {
        sharedFolders.forEach((x) => {
          if (x["name"] === teamSharedName) {
            ids['teamSharedRootId'] = x["id"];
            ids['teamRootId'] = x["parent_id"];
          }
        })

        resolve(ids)

      }, () => reject())
    })

    Promise.all([getRootFolders, getPersonalFolder, getTeamFolders]).then(
      (values) => {
        resolve(Object.assign({}, ...values) as IRootFolderIds)
    }, () => reject())
  })
}

export const getPersonalRootFolder = async (
  setFolderInfo: (folderInfo: IFolderInfo) => void,
  setError: (error: Error) => void
) => {
  let folderDataEmbedRoot: object = null;
  let folderDataEmbedUserPersonal: object = null;
  const setFolderArray1 = (folderData: any) => {
    const folderArray = folderData as any[];
    if (folderArray.length === 1) {
      folderDataEmbedRoot = folderArray[0];
    }
  };
  const setFolderArray2 = (folderData: any) => {
    const folderArray = folderData as any[];
    if (folderArray.length === 1) {
      folderDataEmbedUserPersonal = folderArray[0];
    }
  };
  await API.searchFoldersByName(
    EMBED_USER_ROOT_FOLDER_NAME,
    setFolderArray1,
    () => {}
  );

  if (folderDataEmbedRoot) {
    await API.getFolderChildren(
      folderDataEmbedRoot["id"],
      "id,name,created_at,parent_id",
      setFolderArray2,
      () => {}
    );
  } else {
    console.log("ERROR: ****** folderDataEmbedRoot NOT FOUND!");
    setError({
      name: "No Embed Root Found",
      message: "There was an error obtaining the embed root folder."
    });
    return;
  }

  if (folderDataEmbedUserPersonal) {
    await getFolderDetails(
      folderDataEmbedUserPersonal,
      setFolderInfo,
      setError
    );
  } else {
    console.log("ERROR: ****** folderDataEmbedUserPersonal NOT FOUND!");
    setError({
      name: "No Personal Root Found",
      message: "There was an error obtaining the personalroot folder."
    });
    return;
  }
};

export const getTeamSharedRootFolder = async (
  realm: string,
  setFolderInfo: (folderInfo: IFolderInfo) => void,
  setError: (error: Error) => void
) => {
  let folderData: object = null;
  const teamSharedName = realm + "@" + TEAM_SHARED_ROOT_FOLDER_SUFFIX;
  const setFolderArray1 = (folderData: any) => {
    const folderArray = folderData as any[];
    if (folderArray.length === 1) {
      folderData = folderArray[0];
    }
  };
  await API.searchFoldersByName(
    teamSharedName,
    setFolderArray1,
    () => {}
  );

  if (folderData) {
    await getFolderDetails(folderData, setFolderInfo, setError);
  } else {
    console.log("getTeamSharedRootFolder: NOT FOUND");
    setError({
      name: "No Team Shared Root Found",
      message: "There was an error obtaining the Team Shared root folder."
    });
  }
};

export const getFolderById = async (
  folderId: string,
  fields: string,
  setFolderInfo: (folderInfo: IFolderInfo) => void,
  setError: (error: Error) => void
) => {
  if (!folderId) {
    console.log(
      "FolderManagement.getFolderById called with folderId = ",
      folderId
    );
    throw new Error("getFolderById called with folderId is null.");
  }
  let folderData: object = null;
  const setFolderData = (folderData1: any) => {
    folderData = folderData1;
  };
  await API.getFolder(folderId, fields, setFolderData, () => {});
  if(folderData){
    await getFolderDetails(folderData, setFolderInfo, setError);
  } else {
    setError({
      name: "Error retrieving folder",
      message: "Error retrieving folder data"
    });
  }
};

const getFolderDetails = async (
  folderData: any,
  setFolderInfo: (folderInfo: IFolderInfo) => void,
  setError: (error: Error) => void
) => {
  const serverHost = process.env.REACT_APP_DATA_INSIGHTS_API_BASE_URL;
  const imageUrlPrefix = serverHost + "/looker/content/image?imagePath=";
  let folderInfo: IFolderInfo = null;
  const folderId: string = folderData["id"];


  let dashboardData: object[] = [];
  let lookData: object[] = [];
  let childrenData: object[] = [];
  let dashboardError : Error = null, lookError : Error = null, folderError: Error = null;

  const setDashboardArray = (folderArray: object[]) => {
    dashboardData = folderArray;
  };
  const setDashboardError = (error: Error) => {
    dashboardError = error;
  };
  const setLookArray = (folderArray: object[]) => {
    lookData = folderArray;
  };
  const setLookError = (error: Error) => {
    lookError = error;
  };
  const setFolderArray = (folderArray: object[]) => {
    childrenData = folderArray;
  };
  const setFolderError = (error: Error) => {
    folderError = error;
  };

  await Promise.all([
    API.getFolderDashboards(folderId, "id,title,embed_url,view_count,favorite_count,user_id,created_at,updated_at,image_embed_url", setDashboardArray, setDashboardError),
    API.getFolderLooks(folderId, "id,title,embed_url,view_count,favorite_count,user_id,created_at,updated_at,image_embed_url", setLookArray, setLookError),
    API.getFolderChildren(folderId, "id,name,created_at,parent_id", setFolderArray, setFolderError),
  ]);

  let folderName = folderData["name"];
  let isRootFolder = false;
  if(folderName.indexOf('@RootFolder') !== -1){
    folderName = 'Root Folder';
    isRootFolder = true;
  } else if(folderName.indexOf('@TeamShared') !== -1){
    folderName = 'Shared Reports';
  } 

  let dashboards: IDashboardInfo[] = [];
  let looks: ILookInfo[] = [];
  let children: IChildInfo[] = [];

  dashboardData.forEach((x: object) => {
    let imagePath = null;
    if (x["image_embed_url"]) {
      imagePath = imageUrlPrefix + x["image_embed_url"];
    }
    const dashboard: IDashboardInfo = {
      id: x["id"],
      title: x["title"],
      embed_url: x["embed_url"],
      image_embed_url: imagePath,
      view_count: x["view_count"],
      favorite_count: x["favorite_count"],
      user_id: x["user_id"],
      created_at: x["created_at"],
    };
    dashboards.push(dashboard);
  });

  lookData.forEach((x: object) => {
    let imagePath = null;
    if (x["image_embed_url"]) {
      imagePath = imageUrlPrefix + x["image_embed_url"];
    }
    const look: ILookInfo = {
      id: x["id"],
      title: x["title"],
      embed_url: x["embed_url"],
      image_embed_url: imagePath,
      view_count: x["view_count"],
      favorite_count: x["favorite_count"],
      user_id: x["user_id"],
      updated_at: x["updated_at"],
    };
    looks.push(look);
  });

  childrenData.forEach((x: object) => {
    const child: IChildInfo = {
      id: x["id"],
      name: (isRootFolder && x["name"].indexOf('@TeamShared') !== -1) ? 'Shared Reports' : x["name"],
      created_at: x["created_at"],
    };
    children.push(child);
  });

  folderInfo = {
    id: folderData["id"],
    name: folderName,
    looks: looks,
    parent_id: folderData["parent_id"],
    dashboards: dashboards,
    children: children,
  };
  console.log("in getFolderDetails folderInfo = ",folderInfo); ///!!! DEBUG CODE -- DELETE ME

  if(folderError && lookError && dashboardError){
    //All failed, set error and do not populate folder
    setError({
      name: "API Errors",
      message: "Loading all folder content failed."
    });    
  } else if(folderError == null && lookError == null && dashboardError == null){
    //All succeeeded
    setFolderInfo(folderInfo);
  } else {
    //Mix of failure and success, set folder info and also warning
    setFolderInfo(folderInfo);
    setError({
      name: "WARN",
      message: "There were errors loading the following type of content: " + 
        (folderError ? 'subfolders; ' : '') + 
        (lookError ? 'looks; ' : '') + 
        (dashboardError ? 'dashboards; ' : '') 
    });    
  }


};

export const getFolderChildren = async (
  folderId: string,
  setFolderChildren: (children: IChildInfo[]) => void,
  setError: (error: Error) => void
) => {
  if (!folderId) {
    console.log(
      "FolderManagement.getFolderChildren called with folderId = ",
      folderId
    );
    throw new Error("getFolderChildren called with getFolderChildren is null.");
  }
  await API.getFolderChildren(folderId, "id,name,created_at,parent_id", setFolderChildren, setError);
};


export const addFolder = async (
  parentId: string,
  name: string,
  reportCompletion: (newFolderInfo: IFolderInfo) => void,
  setError: (error: Error) => void
) => {
  const body: ILookerApiBody = {
    name: name,
    parent_id: parentId,
  };
  console.log("addFolder: body = ", body);
  const setResult = (result: any) => {
    console.log("addFolder returns: ", result);
    let newFolderInfo: IFolderInfo = {
      id: result.id,
      name: result.name,
      parent_id: parentId,
      dashboards: [],
      looks: [],
      children: [],
    };
    reportCompletion(newFolderInfo);
  };
  await API.createFolder(body, setResult, setError);
};

export const addDashboard = async (
  parentId: string,
  name: string,
  reportCompletion: (newDashboardInfo: IDashboardInfo) => void,
  setError: (error: Error) => void
) => {
  const setResult = (result: any) => {
    console.log("addDashboard returns: ", result);
    let newDashboardInfo: IDashboardInfo = {
      id: result.id,
      title: result.title,
      embed_url: null,
      image_embed_url: null,
      view_count: result.view_count,
      favorite_count: result.favorite_count,
    };
    reportCompletion(newDashboardInfo);
  };
  await API.createDashboard(name, parentId, setResult, setError);
};

export const getCurrentEmbedUserId = async (
  setResult: (id: string) => void,
  setError: (error: Error) => void
) => {
  const queryParams: object = {
    fields: "id",
  };
  const getResult = (result: any) => {
    const id: string = result["id"] as string;
    setResult(id);
  };
  await API.getEmbedUser(queryParams, getResult, setError);
};

export const getFolderTree = async (
  rootId: string,
  setResult: (tree: IFolderTree) => void,
  setError: (error: Error) => void
) => {
  const idToNodeMap: object = {};
  let rootNode: INode = null;
  let folderTree: IFolderTree = null;

  // Recursive function to convert tree of INode into tree of IFolderTree.
  const traverseTree = (node: INode): IFolderTree => {
    const tree: IFolderTree = {
      id: node.id,
      name: node.name,
      children: [],
    };
    node.childIds.forEach((id) => {
      const childNode: INode = idToNodeMap[id];
      const childTree = traverseTree(childNode);
      tree.children.push(childTree);
    });
    return tree;
  };

  const setFolderData1 = (folders: any[]) => {
    // First Pass create an INode for each known folder.
    folders.forEach((x) => {
      const node: INode = {
        id: x.id,
        name: x.name,
        childIds: [],
      };
      idToNodeMap[x.id] = node;
      if (x.id === rootId) {
        // Remember which folder INode matches the root folder Id
        rootNode = node;
      }
    });
    // Second Pass, link each INode to child folder Ids
    folders.forEach((x) => {
      // If the parent_id is found in the map, mark current folder
      // as a child of the parent
      if (x.parent_id && idToNodeMap[x.parent_id]) {
        idToNodeMap[x.parent_id].childIds.push(x.id);
      }
    });

    // Start a recursive traversal of the tree from the root INode
    folderTree = traverseTree(rootNode);
    setResult(folderTree);
  };
  const params: object = {
    fields: "id,parent_id,name",
  };
  await API.getAllFolders(params, setFolderData1, setError);
};

const folderIdCheck = (
  fromFolderId: string,
  toFolderId: string,
  reportCompletion: (result: any) => void,
  setError: (error: Error) => void
): boolean => {
  if (!fromFolderId || !toFolderId) {
    setError({
      name: "Validation Failed",
      message: "From Folder Id and To Folder Id cannot be null"
    });
    return false;
  }
  if (fromFolderId === toFolderId) {
    setError({
      name: "Validation Failed",
      message: "From Folder Id and To Folder Id cannot be equal"
    });
    return false;
  }
  return true;
};

const folderIdCheckCopy = (
  fromFolderId: string,
  toFolderId: string,
  reportCompletion: (result: any) => void,
  setError: (error: Error) => void
): boolean => {
  if (!fromFolderId || !toFolderId) {
    setError({
      name: "Validation Failed",
      message: "From Folder Id and To Folder Id cannot be null"
    });
    return false;
  }
  return true;
};

export const moveFolder = async (
  moveItemId: string,
  fromFolderId: string,
  toFolderId: string,
  reportCompletion: (result: any) => void,
  setError: (error: Error) => void
) => {
  if (!folderIdCheck(fromFolderId, toFolderId, reportCompletion, setError)) {
    return;
  }
  if (!folderIdCheck(moveItemId, toFolderId, reportCompletion, setError)) {
    return;
  }
  const queryParams: object = { from: fromFolderId, to: toFolderId };
  // Actual move starts HERE!!!!!!!!!!!!!!
  API.moveFolder(moveItemId, queryParams, reportCompletion, setError);
};

export const renameFolder = async (
  folderId: string,
  newName: string,
  reportCompletion: (result: any) => void,
  setError: (error: Error) => void
) => {
  API.renameFolder(folderId, newName, reportCompletion, setError);
};

export const moveLook = async (
  moveItemId: string,
  fromFolderId: string,
  toFolderId: string,
  reportCompletion: (result: any) => void,
  setError: (error: Error) => void
) => {
  if (!folderIdCheck(fromFolderId, toFolderId, reportCompletion, setError)) {
    return;
  }
  const lookId = moveItemId;
  // Actual move starts HERE!!!!!!!!!!!!!!
  API.moveLook(lookId, toFolderId, reportCompletion, setError);
};

export const copyLook = async (
  moveItemId: string,
  fromFolderId: string,
  toFolderId: string,
  reportCompletion: (result: any) => void,
  setError: (error: Error) => void
) => {
  if (!folderIdCheckCopy(fromFolderId, toFolderId, reportCompletion, setError)) {
    return;
  }
  // Actual move starts HERE!!!!!!!!!!!!!!
  API.copyLook(moveItemId, toFolderId, reportCompletion, setError);
};

export const moveDashboard = async (
  moveItemId: string,
  fromFolderId: string,
  toFolderId: string,
  reportCompletion: (result: any) => void,
  setError: (error: Error) => void
) => {
  if (!folderIdCheck(fromFolderId, toFolderId, reportCompletion, setError)) {
    return;
  }
  // Actual move starts HERE!!!!!!!!!!!!!!
  API.moveDashboard(moveItemId, toFolderId, reportCompletion, setError);
};

export const copyDashboard = async (
  moveItemId: string,
  fromFolderId: string,
  toFolderId: string,
  reportCompletion: (result: any) => void,
  setError: (error: Error) => void
) => {
  if (!folderIdCheckCopy(fromFolderId, toFolderId, reportCompletion, setError)) {
    return;
  }
  // Actual move starts HERE!!!!!!!!!!!!!!
  API.copyDashboard(moveItemId, toFolderId, reportCompletion, setError);
};

export const recentlyRunReports = async (
  setDashboards: (dashboards: IDashboardInfo[]) => void,
  setLooks: (looks: ILookInfo[]) => void,
  setError: (error: Error) => void
) => {
  let userId: string;
  const setUser = (user_id: string) => {
    userId = user_id;
  };
  await getCurrentEmbedUserId(setUser, ()=>{});
  if(!userId){
    setError({
      name: "Error obtaining user id",
      message: "Error obtaining user id"
    });
    return;
  }
  console.log(
    "FolderManagement.searchContentViews retrieved user_id = ",
    userId
  );
  const filterViews = (result: object[]) => {
    if (result) {
      let views = result.filter((x) => x["look.id"] || x["dashboard.id"]);
      let take = views.length;
      let dashboardData: IDashboardInfo[] = [];
      let lookData: ILookInfo[] = [];
      // Return a maximum of 10 views
      if (take > 10) {
        take = 10;
      }
      views = views.slice(0, take);

      console.log("recentlyRunReports count = " + result.length);
      console.log("filtered views length = ", views.length);
      views.forEach((x) => {
        if (x["dashboard.id"] != null) {
          dashboardData.push({
            id: x["dashboard.id"],
            title: x["dashboard.title"],
            last_viewed: x["history.most_recent_query_date"],
          });
        } else if (x["look.id"] != null) {
          lookData.push({
            id: x["look.id"],
            title: x["look.title"],
            last_viewed: x["history.most_recent_query_date"],
          });
        }
      });
      setDashboards(dashboardData);
      setLooks(lookData);
    }
  };
  API.recentlyRunReports(userId, filterViews, setError);
};

export const getGroupUsernames = async (
  setResult: (result: object) => void,
  setError: (error: Error) => void
) => {
  const getResult = (result: any) => {
    const userNameArray: IEmbedUsername[] = result as IEmbedUsername[];
    setResult(userNameArray);
  };
  await API.getGroupUsernames(getResult, setError);
};

export const deleteFolderByName = async (
  name: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let id: string = null;
  const setFolderArray1 = (result: any[]) => {
    if (result.length === 1) {
      id = result[0]["id"];
    }
    console.log("deleteFolderByName found id = ", id);
  };
  await API.searchFoldersByName(name, setFolderArray1, ()=>{});
  if(id ==null){
    setError({
      name: "Error finding folder by name",
      message: "Error finding folder by name"
    });
    return;
  }
  API.deleteFolder(id, setResult, setError);
};

export const deleteDashboardByName = async (
  name: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let id: string = null;
  const setFolderArray1 = (result: any[]) => {
    if (result.length === 1) {
      id = result[0]["id"];
    }
    console.log("deleteDashboardByName found id = ", id);
  };
  await API.searchDashboardsByName(name, setFolderArray1, ()=>{});
  if(id ==null){
    setError({
      name: "Error finding dashboard by name",
      message: "Error finding dashboard by name"
    });
    return;
  }
  API.deleteDashboard(id, setResult, setError);
};
// Search for Looks and Dashboards by partial string matching title.
export const search = async (
  title: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const setSearchResults = (result : any) => {
    let looks: ILookInfo[] = [];
    let dashboards: IDashboardInfo[] = [];
    if(result.looks){
      result.looks.forEach(look =>{
        looks.push({
          id: look["id"],
          title: look["title"],
          folder_id: look["folder_id"],
          folder_name: (look["folder_name"] && look["folder_name"].indexOf('@TeamShared') !== -1) ? 
            'Shared Reports' : look["folder_name"]
        });
      });
    }
    if(result.dashboards){
      result.dashboards.forEach(dashboard =>{
        dashboards.push({
          id: dashboard["id"],
          title: dashboard["title"],
          folder_id: dashboard["folder_id"],
          folder_name: (dashboard["folder_name"] && dashboard["folder_name"].indexOf('@TeamShared') !== -1) ? 
            'Shared Reports' : dashboard["folder_name"]
        });
      });
    }
    setResult({
      dashboards: dashboards,
      looks: looks
    });
  }
  API.search(title, setSearchResults, setError);
};
// Get Favorites
export const getFavorites = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const setFavoriteResults = (resultArray : any) => {
    let looks: ILookInfo[] = [];
    let dashboards: IDashboardInfo[] = [];
    resultArray.forEach(favorite => {
      if(favorite.dashboard){
        dashboards.push({
          id: favorite["dashboard"]["id"],
          title: favorite["dashboard"]["title"],
          folder_id: favorite["dashboard"]["folder"]["id"],
          folder_name: (favorite["dashboard"]["folder"]["name"] && favorite["dashboard"]["folder"]["name"].indexOf('@TeamShared') !== -1) ?
            'Shared Reports' : favorite["dashboard"]["folder"]["name"],
          favorite_id: favorite["id"]
        });
      } else if(favorite.look){
        looks.push({
          id: favorite["look"]["id"],
          title: favorite["look"]["title"],
          favorite_id: favorite["id"]
        });
      }
    });
    setResult({
      dashboards: dashboards,
      looks: looks
    });
  }
  API.getFavorites(setFavoriteResults, setError);
};

export const getFolderAncestors = async (
  folderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const setAncestors = (ancestorData : any) => {
    let ancestors: IAncestorInfo[] = [];
    ancestorData.forEach((x: object) => {
      const ancestor: IAncestorInfo = {
        id: x["id"],
        name: (x["name"].indexOf('@TeamShared') !== -1) ? 'Shared Reports' : x["name"],
        parent_id: x["parent_id"]
      };
      ancestors.push(ancestor);
    });
    setResult(ancestors);
  }
  API.getFolderAncestors(folderId, setAncestors, setError);
};
