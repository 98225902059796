import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { IDemoFilter } from "../../containers/LookerFilterDemo/LookerFilters";

import { LookerContentType } from "../../AppModels";
import { MODELS_WITH_FACILITY_SELECTION } from "../../Constants";
import { MESSAGE_LARGE_LOOK_LOAD_WARNING } from "../../containers/Messages/MessageList";
import { getCorporateHierarchyForUser } from "../../services/GroupManagement/GroupManagement";
import { ICorporateHierarchyNode } from "../../services/GroupManagement/GroupModels";
import { API } from "../../services/LookerAPI/LookerAPI";
import {
  IGetCorporateHierarchyAction, ILookerFilterState, ISetFacilityFilterListAction, ISetFilterAction, ISetLookerContentInfoAction, LookerFilterActions, LookerFilterActionTypes
} from "./LookerFilterTypes";
import { displayLargeLookMessageForLook } from "./MessageActions";

const processLookAndSetLookerContentInfo = async(
  lookId: string
): Promise<ISetLookerContentInfoAction>=>{
  let facilityFilterPresent = false;
  let lookGreaterThan50Fields = false;

  const setResult = (result:any) =>{
    facilityFilterPresent = result && result.model && result.model.id && MODELS_WITH_FACILITY_SELECTION.includes(result.model.id);
    if(result && result.query && result.query.fields && result.query.fields.length > 50){
      lookGreaterThan50Fields = true;
    }
  }
  const setError = (error:any) => {
    //Do nothing, but facility filter selector will be disabled
  }

  await API.getLook(lookId, "id,query(fields),model", setResult, setError);

  return {
    type: LookerFilterActions.SET_LOOKER_CONTENT_INFO,
    lookerContentType: LookerContentType.LOOK,
    facilityFilterPresent: facilityFilterPresent,
    lookGreaterThan50Fields: lookGreaterThan50Fields
  };
}

export const setFilter: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  ILookerFilterState,
  null,
  ISetFilterAction
>> = (filter: IDemoFilter) => {
  return async (dispatch: Dispatch) => {
    const action = {
      type: LookerFilterActions.SET_FILTER,
      filter: filter,
    };
    return dispatch(action);
  };
};

export const setLookerContentInfo: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  ILookerFilterState,
  null,
  ISetLookerContentInfoAction
>> = (lookerContentType: LookerContentType, facilityFilterPresent: boolean) => {
  return async (dispatch: Dispatch) => {
    const action = {
      type: LookerFilterActions.SET_LOOKER_CONTENT_INFO,
      lookerContentType: lookerContentType,
      facilityFilterPresent: facilityFilterPresent,
    };
    return dispatch(action);
  };
};

export const lookupLookAndSetLookerContentInfo: ActionCreator<ThunkAction<
    void,
    ILookerFilterState,
    null,
    ISetLookerContentInfoAction
  >> = (lookId: string) => {
    return async (dispatch: Dispatch) => {
      const action = await processLookAndSetLookerContentInfo(lookId);
      dispatch(action);
      if(action.lookGreaterThan50Fields){
        dispatch(displayLargeLookMessageForLook(
          MESSAGE_LARGE_LOOK_LOAD_WARNING,
          lookId
        )); 
      }
    };
};

export const setFacilityFilterList: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  ILookerFilterState,
  null,
  ISetFacilityFilterListAction
>> = (facilityFilterList: string) => {
  return async (dispatch: Dispatch) => {
    const action = {
      type: LookerFilterActions.SET_FACILITY_FILTER_LIST,
      facilityFilterList: facilityFilterList,
    };
    return dispatch(action);
  };
};

export const getCorporateHierarchy: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  ILookerFilterState,
  null,
  IGetCorporateHierarchyAction
>> = (ignoreError?: boolean) => {
  return async (dispatch: Dispatch) => {
    const action = await getCorporateHierarchyInfo(ignoreError);
    return dispatch(action);
  };
};

const getCorporateHierarchyInfo = async (ignoreError: boolean): Promise<IGetCorporateHierarchyAction> => {
  let corporateHierarchy: ICorporateHierarchyNode[] = null;
  let corporateHierarchyError: string = null;
  const setResult = (hierarchyNodes: ICorporateHierarchyNode[]) => {
    corporateHierarchy = hierarchyNodes;
  }
  const setError = (error: Error) => {
    if(ignoreError){
    } else {
      corporateHierarchyError = error.message;
    }
  }  
  await getCorporateHierarchyForUser(setResult, setError);
  const action : LookerFilterActionTypes =
  {
    type: LookerFilterActions.GET_CORPORATE_HIERARCHY,
    corporateHierarchy: corporateHierarchy,
    corporateHierarchyError: corporateHierarchyError
  };
  return action;
}