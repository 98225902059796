import { 
    IGroupInfo,
    IUserInfo
 } from "../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";

export enum EditGroupsActionTypes {
    GET_CUSTOM_GROUP_DETAILS = "EDIT_GROUPS_ACTIONS/GET_CUSTOM_GROUP_DETAILS",
    GET_LICENSED_USERS = "EDIT_GROUPS_ACTIONS/GET_LICENSED_USERS",
}

export type EditGroupsActions =
    | IGetCustomGroupDetailsAction
    | IGetLicensedUsersAction;

export interface IGetCustomGroupDetailsAction {
    type: EditGroupsActionTypes.GET_CUSTOM_GROUP_DETAILS;
    customGroupDetails: IGroupInfo[];
}

export interface IGetLicensedUsersAction {
    type: EditGroupsActionTypes.GET_LICENSED_USERS;
    licensedUsers: IUserInfo[];
}

export interface IEditGroupsState {
    readonly customGroupDetails: IGroupInfo[],
    readonly licensedUsers: IUserInfo[],
}