import { Alert, Breadcrumb, Modal, Dropdown, Menu, Button } from "antd";
import React from "react";
import { IAlertInfo } from "../../AppModels";
import { getFolderById, moveDashboard, moveFolder, moveLook, copyDashboard, copyLook } from "../../services/FolderManagement/FolderManagement";
import { IDisplayFolderItem, IDisplayFolderItemType, IFolderInfo, IMoveSource, IRootFolderIds } from "../../services/FolderManagement/FolderModels";
import MoveToFolder from "./MoveToFolder";
import "./MoveToFolderContainer.scss";
import { IApiError } from "../../services/LookerAPI/LookerAPI";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../store/Store";


interface IProps {
  moveFrom: IMoveSource,
  copy: boolean,
  abortMove: () => void,
  showAlert: (alert: IAlertInfo) => void,
  moveFolderNavigation: () => void;
  triggerFolderRefresh: () => void;
}

interface BreadcrumbInfo {
  position: number;
  item: IDisplayFolderItem;
}

//This Component handles both Move and Copy actions; the component property 'copy' is used to denote the copy case
//Both behave similarly, they just use different methods, as one will leave the original in its from location
//Copy currently not available for folders
const MoveToFolderContainer: React.FC<IProps> = ({ moveFrom, abortMove, copy, showAlert, moveFolderNavigation, triggerFolderRefresh }: IProps) => {

  const [firstItem, setFirstItem] = React.useState<IDisplayFolderItem>({
    name: moveFrom.startFolderName,
    type: IDisplayFolderItemType.FOLDER,
    id: moveFrom.startFolderId
  });
  const [currentItem, setCurrentItem] = React.useState<IDisplayFolderItem>(firstItem);
  const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbInfo[]>([{ position: 0, item: firstItem }]);
  const [folder, setFolder] = React.useState<IFolderInfo>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>(null);

  const rootFolders: IRootFolderIds = useSelector<IApplicationState>(state => state.folders.rootFolders) as IRootFolderIds;

  React.useEffect(() => {
    setBreadcrumbs([{position: 0, item: firstItem}]);
    setCurrentItem(firstItem);
  }, [firstItem])

  React.useEffect(() => {
    setErrorMessage(null);
    const setFolderLoadError = (error: Error) =>{
      if(error.name !== 'WARN'){
        setFolder({
          id: currentItem.id,
          name: currentItem.name,
          parent_id: null,
          dashboards: [],
          looks: [],
          children: []
        });
      }
      setErrorMessage('There was an error loading the contents of this folder.');
    }
    getFolderById(currentItem.id, "id,name,parent_id", setFolder, setFolderLoadError);
  }, [currentItem]);

  const goToItem = (item: IDisplayFolderItem) => {
    setBreadcrumbs([
      ...breadcrumbs,
      {
        position: breadcrumbs.length,
        item: item
      }
    ]);
    setCurrentItem(item);
  }
  const goToBreadcrumb = (breadcrumbInfo: BreadcrumbInfo) => {
    const index = breadcrumbInfo.position;
    if (index < breadcrumbs.length - 1) {
      setBreadcrumbs(breadcrumbs.filter(function (breadcrumb) {
        return breadcrumb.position <= index;
      }));
    }
    setCurrentItem(breadcrumbInfo.item);
  }

  const cancel = () => {
    abortMove();
  }

  const moveComplete = (result: any) => {
    console.log("moveComplete: result = ", result);
    showAlert({
      type: 'success',
      message: moveFrom.moveType + ' successfully ' + (copy ? 'copied.' : 'moved.')
    });
    if(moveFrom.moveType === IDisplayFolderItemType.FOLDER){
      moveFolderNavigation();
    } else if ((!copy || moveFrom.moveFromFolderId === currentItem.id) && triggerFolderRefresh){
      triggerFolderRefresh();
    }
  }

  const moveFailed = (error: IApiError) => {
    console.log('moveFailed: error = ', error);
    if (error.status === 403) {
      showAlert({
        type: 'error',
        message: "You do not have the necessary permissions to modify " + folder.name
      });
    } else {
      showAlert({
        type: 'error',
        message: "An unexected error occurred attempting to " + (copy ? 'copy ' : 'move ') + moveFrom.moveType
      });
    }
  }

  const moveHere = () => {
    const msg = `Attempting to ${copy ? 'copy' : 'move'} ${moveFrom.moveType} with id: ${moveFrom.moveItemId} 
    and name: ${moveFrom.moveItemName} 
    fromFolderId: ${moveFrom.moveFromFolderId} toFolderId: ${currentItem.id}`;
    console.log(msg);

    if(!copy && moveFrom.moveFromFolderId === currentItem.id){
      setErrorMessage('"' + moveFrom.moveItemName + '" is already in this folder.');
      return;
    }

    switch (moveFrom.moveType) {
      case IDisplayFolderItemType.DASHBOARD:
        if(!copy && folder.dashboards.some(element => element.title.toUpperCase() === moveFrom.moveItemName.toUpperCase())){
          //API handles renaming in case of copy
          setErrorMessage('A dashboard named "' + moveFrom.moveItemName + '" already exists in this folder.');
          return; 
        }
        if(copy){
          //INSERT COPY DASHBOARD METHOD HERE 
          copyDashboard(moveFrom.moveItemId, moveFrom.moveFromFolderId, currentItem.id, moveComplete, moveFailed);
        } else {
          moveDashboard(moveFrom.moveItemId, moveFrom.moveFromFolderId, currentItem.id, moveComplete, moveFailed);
        }
        break;
      case IDisplayFolderItemType.LOOK:
        if(!copy && folder.looks.some(element => element.title.toUpperCase() === moveFrom.moveItemName.toUpperCase())){
          //API handles renaming in case of copy
          setErrorMessage('A look named "' + moveFrom.moveItemName + '" already exists in this folder.');
          return; 
        }
        if(copy) {
          //INSERT COPY LOOK METHOD HERE 
          copyLook(moveFrom.moveItemId, moveFrom.moveFromFolderId, currentItem.id, moveComplete, moveFailed);
        } else {
          moveLook(moveFrom.moveItemId, moveFrom.moveFromFolderId, currentItem.id, moveComplete, moveFailed);
        }
        break;
      case IDisplayFolderItemType.FOLDER:
        if(folder.children.some(element => element.name.toUpperCase() === moveFrom.moveItemName.toUpperCase())){
          setErrorMessage('A folder named "' + moveFrom.moveItemName + '" already exists in this folder.');
          return; 
        }
        if(copy){
          console.log('ERROR CONDITION - COPY OF FOLDERS NOT CURRENTLY ALLOWED');
        } else {
          moveFolder(moveFrom.moveItemId, moveFrom.moveFromFolderId, currentItem.id, moveComplete, moveFailed);
        }
        break;
    }
    abortMove();
  }

  const roots = (
    <Menu>
      <Menu.Item onClick={(() => setFirstItem({
        id: rootFolders.teamRootId,
        name: "Shared Root Folder",
        type: IDisplayFolderItemType.FOLDER
      }))}>
        Shared Root Folder
      </Menu.Item >
      <Menu.Item onClick={(() => setFirstItem({
        id: rootFolders.personalRootId,
        name: "My Reports",
        type: IDisplayFolderItemType.FOLDER
      }))}>
        My Reports
      </Menu.Item>
    </Menu>
  )

  return (
    <>
        <Modal
          title={((copy ? 'Copy "' : 'Move "') + moveFrom.moveItemName + '"')}
          visible={true}
          style={{ top: 20, bottom: 20}}
          onOk={moveHere}
          okText={((copy ? 'Copy ' : 'Move ') + moveFrom.moveType)}
          okButtonProps={{ disabled: ((currentItem.id ===  moveFrom.startFolderId && moveFrom.startFolderName === 'Shared Root Folder') || (!folder) || currentItem.id !== folder.id) }}
          onCancel={cancel}
          width={750}
        >
          <div className="modal-with-list-and-navigation">
            <h4 style={{display:"inline", color: "black"}}>{(copy ? 'Copy' : 'Move') + ' to Folder:'}</h4>
            <Breadcrumb
              style={{ padding: "0px 8px" , display: "inline" }}
              separator=">" >
              {breadcrumbs.map(breadcrumbInfo => {
                return <Breadcrumb.Item
                  key={breadcrumbInfo.item.type + breadcrumbInfo.item.id}
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    if (breadcrumbInfo.position !== 0) {
                      goToBreadcrumb(breadcrumbInfo);
                    }
                  }
                  }
                >
                  {
                    breadcrumbInfo.position === 0 ? 
                      <Dropdown overlay={roots} placement='bottomLeft'>
                        <Button onClick={() => breadcrumbInfo.position === 0 ? goToBreadcrumb(breadcrumbInfo) : null}>
                          <h4>
                            {breadcrumbInfo.item.name}
                          </h4>
                        </Button>
                      </Dropdown> 
                    : 
                      <h4>{breadcrumbInfo.item.name}</h4>
                  }
                </Breadcrumb.Item>
              })
              }
            </Breadcrumb>
            <br></br>
            <MoveToFolder
              goToItem={goToItem}
              item={currentItem}
              folder={folder}
              moveFolderId={moveFrom.moveType === IDisplayFolderItemType.FOLDER ? moveFrom.moveItemId : null}
            />
            { errorMessage && 
              <Alert type="error" message={errorMessage} />
            }
          </div>
      </Modal>
    </>
  );
};

export default MoveToFolderContainer;
