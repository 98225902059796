import { FolderOutlined, LayoutOutlined, LineChartOutlined } from "@ant-design/icons";
import { Divider, Input, Layout, List } from "antd";
import React, { useEffect } from "react";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { IDisplayFolderItem, IDisplayFolderItemType, IFolderInfo } from "../../services/FolderManagement/FolderModels";

interface IProps {
  folder: IFolderInfo;
  item: IDisplayFolderItem;
  goToItem: (item: IDisplayFolderItem) => void;
  moveFolderId?: string 
}
//moveFolderId - If the item being moved is a folder, do not let user try to move item to that folder or its children
// do this by disabling moveFolderId from being selected in folder list

const MoveToFolder: React.FC<IProps> = ({ item, goToItem, folder, moveFolderId }) => {

  const [filteredFolderInfo, setFilteredFolderInfo] = React.useState<IFolderInfo>(null);
  const [filterText, setFilterText] = React.useState<string>('');

  useEffect(() =>{
    setFilteredFolderInfo(folder);
  }, [folder]);

  useEffect(() =>{
    setFilterText('');
  }, [item]);

  const updateFilterText = (event) => {
    setFilterText(event.target.value);
  }

  const filterFolder = (filterText: string) =>{
    if(filterText.trim().length === 0){
      setFilteredFolderInfo(folder);
    }
    else{
      setFilteredFolderInfo({
        id: folder.id,
        name: folder.name,
        parent_id: folder.parent_id,
        dashboards: folder.dashboards.filter(dashboard => 
          dashboard.title.toUpperCase().indexOf(filterText.toUpperCase()) !== -1),
        looks: folder.looks.filter(look => 
          look.title.toUpperCase().indexOf(filterText.toUpperCase()) !== -1),
        children: folder.children.filter(child => 
          child.name.toUpperCase().indexOf(filterText.toUpperCase()) !== -1)
      });
   }
  }
  
  return (
    <>
      <Divider style={{ margin: "12px 0"}} />
      <Input.Search
        value={filterText}
        className="modal-list-filter"
        placeholder="Filter By Title..."
        onChange={updateFilterText} 
        onSearch={filterFolder}
        />
      <Layout.Content
        style={{
          overflowY: "auto",
          height: "50vh"
        }}
      >
  
      { (filteredFolderInfo) && item.id === filteredFolderInfo.id ?
        (
          <>
            <List 
            className="modal-list"
            itemLayout="horizontal"
            size="small"
            >
            {(filteredFolderInfo.children.length > 0) &&
            (
              <>
              {(filteredFolderInfo.children.map((child, index) =>
                (
                  moveFolderId && moveFolderId.toString() === child.id.toString() ?
                  <List.Item
                    key={'lif' + index}
                    className="modal-list-item-disabled"
                    >
                    <List.Item.Meta 
                      avatar={<FolderOutlined />}
                      title={child.name}
                    />
                  </List.Item>
                  :
                  <List.Item
                    key={'lif' + index}
                    className="modal-list-item-clickable"
                    onClick={() => goToItem({
                      type: IDisplayFolderItemType.FOLDER,
                      name: child.name,
                      id: child.id
                    })}>
                    <List.Item.Meta 
                      avatar={<FolderOutlined />}
                      title={child.name}
                    />
                  </List.Item>
                )
              ))}
              </>
            )
          }
          {(filteredFolderInfo.dashboards.length > 0) &&
            (
              <>
              {(filteredFolderInfo.dashboards.map((dashboard, index) =>
                (
                  <List.Item
                    key={'lif' + index}
                    className="modal-list-item-disabled"
                    >
                    <List.Item.Meta 
                      avatar={<LayoutOutlined />}
                      title={dashboard.title}
                    />
                  </List.Item>
                )
              ))}
              </>
            )
          }
          {(filteredFolderInfo.looks.length > 0) &&
            (
              <>
              {(filteredFolderInfo.looks.map((look, index) =>
                (
                  <List.Item
                    key={'lif' + index}
                    className="modal-list-item-disabled">
                    <List.Item.Meta 
                      avatar={<LineChartOutlined />}
                      title={look.title}
                    />
                  </List.Item>
                )
              ))}
              </>
            )
          }
          </List>
          </>
        )
        :
        (
          <CustomSpinner />
        )
      }
      </Layout.Content>
    </>
  );
};

export default MoveToFolder;
