import { Modal } from "antd";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { IDLE_LOGOUT_TIME, IDLE_WARNING_TIME, LOCAL_STORAGE_LAST_ACTIVITY } from "../../Constants";
import { logout } from "../../store/actions/UserAuthActions";

interface IProps {
}

const IdleTimeout: React.FC<IProps> = () => {
  const [timeoutModal, setTimeoutModal] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const eventListener = useCallback(()=>{
    if(document.getElementById("idleTimeoutWarning")){
    } else{
      localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
    }
  }, []);

  React.useEffect(() => {
    console.log('adding mouse listeners');
    window.addEventListener("click", eventListener);
    window.addEventListener("mouseover", eventListener);
  }, [eventListener]);

  //On Dismount
  React.useEffect( () => () => {
    if(eventListener!=null){
      console.log('removing mouse listeners');
      window.removeEventListener("click", eventListener);
      window.removeEventListener("mouseover", eventListener);
    }
  }, [eventListener]);

  React.useEffect(
    ()=>{
      let idleInterval = window.setInterval(function(){
        const lastActivity = localStorage.getItem(LOCAL_STORAGE_LAST_ACTIVITY);
        if(lastActivity !== null ){         
            const difference = Date.now() - Number(lastActivity);
            if(difference > IDLE_LOGOUT_TIME){
                dispatch(logout());
            } else if(difference > IDLE_WARNING_TIME){
                setTimeoutModal(true);
            } else {
                setTimeoutModal(false);
            }
        } 
      },1000);  

      return () =>{
        clearInterval(idleInterval);
      }
    },[dispatch]);

  const handleStayLoggedIn = () => {
    localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
    setTimeoutModal(false);
  }

  return (
    <>
    { timeoutModal &&
        <Modal
          title="Your session is about to expire!"
          visible={true}
          closable={false}
          maskClosable={false}
          onOk={handleStayLoggedIn}
          okText={'Stay Logged In'}
          onCancel={() => dispatch(logout())}
          cancelText={'Logout'}
          width={500}
        >      
            <h4 id="idleTimeoutWarning">Your session is about to expire due to inactivity. Do you want to stay logged in?</h4>
        </Modal>
      }
   </>
  );
}

export default IdleTimeout;
