import * as React from "react";

interface IProps {

}

const LoginHelp: React.FC<IProps> = () => {

  return (
    <>
    <div className="login-help">
      <p>
        Please use your EnterpriseRx username and password to log into EnterpriseRx Data Insights&trade;.
      </p>
      <p>
        To reset your password <br />
        Erx &gt; Tools &gt; My Info &gt; Change Password &gt; Open AM (opens) for password reset.
      </p>
    </div>
    </>
  );
};

export default LoginHelp;
