import { LoadingOutlined } from "@ant-design/icons";
import { Security } from '@okta/okta-react';
import { Layout, Spin } from "antd";
import React from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { LocalStorageUserInfo } from "./AppModels";
import { LOCAL_STORAGE_OKTA_REGULAR_FLAG, LOCAL_STORAGE_OKTA_SELECTED_USER, LOCAL_STORAGE_OKTA_SUPPORT_REALM, LOCAL_STORAGE_USER, OKTA_CALLBACK_PATH, OKTA_LOGOUT_URI, OKTA_RESTORE_URI, REFRESH_NOTIFICATIONS } from "./Constants";
import AlertsPage from "./containers/AlertsPage/AlertsPage";
import CustomDrugList from "./containers/CustomAdherenceDrugList/CustomDrugList";
import Dashboard from "./containers/Dashboard/Dashboard";
import Documentation from "./containers/Documentation/Documentation";
import EditGroups from "./containers/EditGroups/EditGroups";
import EditUsers from "./containers/EditUsers/EditUsers";
import Explore from "./containers/Explore/Explore";
import Favorites from "./containers/Favorites/Favorites";
import GroupedReports from "./containers/GroupedReports/GroupedReports";
import Header from "./containers/Header/Header";
import HomePage from "./containers/HomePage/HomePage";
import IdleTimeout from "./containers/IdleTimeout/IdleTimeout";
import InvalidRoute from "./containers/InvalidRoute/InvalidRoute";
import Login from "./containers/Login/Login";
import Look from "./containers/Look/Look";
import MainDashboard from "./containers/MainDashboard/MainDashboard";
import Messages from "./containers/Messages/Messages";
//import MoveTests from "./containers/MoveTests/MoveTests";
import { OktaAuth } from "@okta/okta-auth-js";
import CustomLoginCallback from "./containers/Login/CustomLoginCallback/CustomLoginCallback";
import NotificationManagement from "./containers/NotificationManagementPage/NotificationManagement";
import ReportHistory from "./containers/ReportHistory/ReportHistory";
import Reports from "./containers/Reports/Reports";
import Schedules from "./containers/Schedules/Schedules";
import SearchResults from "./containers/SearchResults/SearchResults";
import Sidebar from "./containers/SideBar/SideBar";
import FederatedOktaAuth from "./services/Auth/FederatedOktaAuth";
import GeneralOktaAuth from "./services/Auth/GeneralOktaAuth";
import { IReportGroupType } from "./services/FolderManagement/FolderModels";
import { hasDataMart, isAuthorized, isRealmAuthorized } from "./services/RoleManagement/RoleManagement";
import { Permission, Role } from "./services/RoleManagement/RoleModels";
import { propagateLogin, propagateLogout } from "./store/actions/UserAuthActions";
import { DATA_MART } from "./store/actions/UserAuthTypes";
import { IApplicationState } from "./store/Store";

const { Content } = Layout;

interface IApplicationProps {
  isLoggedIn: boolean;
  roleMapping: Map<Role, boolean>;
  realm: string,
  propagateLogout: typeof propagateLogout;
  propagateLogin: typeof propagateLogin;
}

const App: React.FC<IApplicationProps> = ({isLoggedIn, roleMapping, realm, propagateLogout, propagateLogin}) => {
  const mainDashboardPath = "/dashboards/PAClMmDC2wPNW3AJ5xlg36";
  const [searchText, setSearchText] = React.useState<string>('');
  const [pageLoad, setPageLoad] = React.useState<number>(0);
  const [refreshNotificationsCount, setRefreshNotificationsCount] = React.useState<number>(0);
  const [customOktaAuthWithClient, setCustomOktaAuthWithClient] = React.useState<OktaAuth>(
    localStorage.getItem(LOCAL_STORAGE_OKTA_REGULAR_FLAG) ? 
    new OktaAuth(GeneralOktaAuth)
    :
    new OktaAuth(FederatedOktaAuth)
    );


  const dataMarts: DATA_MART[] = useSelector<IApplicationState>(state => state.userAuth.models) as DATA_MART[];
  const cpsPremiumEnabled: boolean = useSelector<IApplicationState>(state => state.userAuth.cpsPremiumEnabled) as boolean;

  const triggerRefresh = () => {
    setPageLoad(pageLoad + 1);
  };

  React.useEffect(() => {
    // sets the LoadingOutlined icon as the global antd loading indicator
    Spin.setDefaultIndicator(<LoadingOutlined style={{color: 'gray'}}/>);
  });

  React.useEffect(()=>{
    window.addEventListener('storage', function(event){
      if (event.key === LOCAL_STORAGE_USER) { 
        const userInfo : LocalStorageUserInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
        if(userInfo.loggedIn){
          //propagateLogin(userInfo);
        } else {
          propagateLogout();
        }
      } else if(event.key === REFRESH_NOTIFICATIONS){
        setRefreshNotificationsCount(refreshNotificationsCount => refreshNotificationsCount + 1);
      } else if(event.key === LOCAL_STORAGE_OKTA_REGULAR_FLAG){
        const regularFlag = localStorage.getItem(LOCAL_STORAGE_OKTA_REGULAR_FLAG);
        if(regularFlag != null){
          const oktaAuth = new OktaAuth(GeneralOktaAuth);
          setCustomOktaAuthWithClient(oktaAuth);
        } else{
          setCustomOktaAuthWithClient(new OktaAuth(FederatedOktaAuth));
        }
      }
  });
  },[propagateLogin, propagateLogout]);

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.push(originalUri);
  };

  // Store some Okta Sign In Attributes in Local Storage before redirecting to Okta
  //  Regular Login denotes a regular Okta Login, as opposed to Federated Okta Login
  //  Support Realm and Selected User are only relevant for support Users
  const updateOktaSignInAttributes = (regularLogin: boolean, supportRealm: string, selectedUser: string) =>{
    if(!regularLogin){
      localStorage.removeItem(LOCAL_STORAGE_OKTA_REGULAR_FLAG);
      localStorage.removeItem(LOCAL_STORAGE_OKTA_SUPPORT_REALM);
      localStorage.removeItem(LOCAL_STORAGE_OKTA_SELECTED_USER);
      const oktaAuth = new OktaAuth(FederatedOktaAuth);
      setCustomOktaAuthWithClient(oktaAuth);
      oktaAuth.signInWithRedirect({originalUri: OKTA_RESTORE_URI })
    } else {
      localStorage.setItem(LOCAL_STORAGE_OKTA_REGULAR_FLAG, regularLogin.toString());
      if(supportRealm != null){
        localStorage.setItem(LOCAL_STORAGE_OKTA_SUPPORT_REALM, supportRealm);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_OKTA_SUPPORT_REALM);
      }
      if(selectedUser){
        localStorage.setItem(LOCAL_STORAGE_OKTA_SELECTED_USER, selectedUser);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_OKTA_SELECTED_USER);
      }
      const oktaAuth = new OktaAuth(GeneralOktaAuth);
      setCustomOktaAuthWithClient(oktaAuth);
      const restoreUri = (supportRealm == null ? "" : "/internal") + OKTA_RESTORE_URI;
      oktaAuth.signInWithRedirect({originalUri: restoreUri })
    }
  }

  return (
    <>
    <Security oktaAuth={customOktaAuthWithClient} restoreOriginalUri={restoreOriginalUri}>
    { !isLoggedIn ? (
      <Switch>
        <Route exact path={OKTA_CALLBACK_PATH} component={CustomLoginCallback}/>
        <Route exact path={OKTA_RESTORE_URI}>
          <Login oktaReturn={true} setOktaSignInAttributes={updateOktaSignInAttributes} />
        </Route>
        <Route exact path={"/internal" + OKTA_RESTORE_URI}>
          <Login oktaReturn={true} setOktaSignInAttributes={updateOktaSignInAttributes} />
        </Route>
        <Route exact path={OKTA_LOGOUT_URI}>
          <Login oktaReturn={false} logoutOkta={true} setOktaSignInAttributes={updateOktaSignInAttributes} />
        </Route>
        <Route>
          <Login oktaReturn={false} setOktaSignInAttributes={updateOktaSignInAttributes} />
        </Route> 
      </Switch>
      ) : (
      <Layout style={{ minHeight: "100vh" }}>
        <IdleTimeout />
        <Messages />
        <Sidebar 
          refreshFunction={triggerRefresh}
        />
        <Layout style={{ height: "100vh" }}>
          <Header 
            setSearchText={setSearchText}
            refreshFunction={triggerRefresh}
            refreshNotificationsCount={refreshNotificationsCount}
          />
          <Content
            id="app-main-content-pane"
            className="main-content"
            style={{ position: "relative"}}
          >
            <Switch>
              <Redirect exact={true} from="/" to="/home" />
              <Redirect exact={true} from="/internal" to="/home" />
              <Redirect exact={true} from={OKTA_RESTORE_URI} to="/home" />
              <Redirect exact={true} from={"/internal" +  OKTA_RESTORE_URI} to="/home" />
              <Route
                exact
                path="/home"
                render={(props) => <HomePage {...props} key={"homepage" + pageLoad}/>}
              />
              <Route 
                exact 
                path="/overview"
                render={(props) => <MainDashboard {...props} embeddedPath={mainDashboardPath} key={"overview" + pageLoad}/>}
              />
              {/*<Route 
                exact 
                path="/moveTests"
                render={(props) => <MoveTests {...props} key={Math.random()}/>}
              />*/}
              <Route
                exact
                path="/reports/:id+"
                render={(props: any) => <Reports {...props} key={"reports" + pageLoad} />}
              />
              {
                isAuthorized(roleMapping, Permission.VIEW_EXPLORE_TAB) &&
                <Route 
                  exact 
                  path="/explore"
                  render={(props: any) => <Explore {...props} key={"explore" + ( window?.location?.search ? window.location.search : '' ) + pageLoad}/>}
                />
              }
              <Route 
                exact 
                path="/explore/look/:lookId"
                render={(props: any) => <Explore {...props} key={"explorelook" + pageLoad}/>}
              />
              <Route 
                exact 
                path="/looks/:lookId"
                render={(props: any) => <Look {...props} key={"look" + pageLoad}/>}
              />
              <Route 
                exact 
                path="/dashboards/:dashboardId"
                render={(props: any) => <Dashboard {...props} key={"dashboard" + pageLoad}/>}
              />
              {
                isAuthorized(roleMapping, Permission.VIEW_MY_REPORTS_TAB) &&
                <Route 
                  exact 
                  path="/myReports"
                        render={(props) => <GroupedReports {...props} group={IReportGroupType.MY} key={"myReports" + pageLoad} />}
                />
              }
              <Route 
                exact 
                path="/sharedReports"
                      render={(props) => <GroupedReports {...props} group={IReportGroupType.TEAM} key={"sharedReports" + pageLoad} />}
              />
              <Route 
                exact 
                path="/reportHistory"
                      render={(props) => <ReportHistory {...props} key={"reportHistory" + pageLoad} />}
              />
              <Route 
                exact 
                path="/search"
                      render={(props) => <SearchResults {...props} searchText={searchText} key={"search" + pageLoad} />}
              />
              <Route
                exact
                path="/favorites"
                render={(props) => <Favorites {...props} key={"favorites" + pageLoad}/>}
              />
              <Route 
                exact 
                path="/schedules"
                render={(props) => <Schedules {...props} key={"schedules" + pageLoad} />}
              />
              { isAuthorized(roleMapping, Permission.VIEW_ALERTS_TAB) &&
                <Route
                  exact
                  path="/alerts"
                  render={(props) => <AlertsPage {...props} key={"alerts" + pageLoad}/>}/>
              }
              <Route
                exact
                path="/documentation"
                      render={(props) => <Documentation {...props} key={"documentation" + pageLoad} />}
              />
              { isAuthorized(roleMapping, Permission.VIEW_ADMIN_TAB) &&
                <Route 
                  exact 
                  path="/admin/groups"
                      render={(props) => <EditGroups {...props} key={"admingroups" + pageLoad} />}
                />
              }
              { isAuthorized(roleMapping, Permission.VIEW_ADMIN_TAB) &&
                <Route
                  exact
                  path="/admin/users"
                    render={(props) => <EditUsers {...props} key={"adminusers" + pageLoad} />}
                />
              }
              { isAuthorized(roleMapping, Permission.VIEW_NOTIFICATION_MANAGEMENT) && isRealmAuthorized(realm, Permission.VIEW_NOTIFICATION_MANAGEMENT) &&
                <Route
                  exact
                  path="/admin/notificationManagement"
                    render={(props) => <NotificationManagement {...props} key={"notificationmanage" + pageLoad} />}
                />
              }
              { cpsPremiumEnabled && isAuthorized(roleMapping, Permission.VIEW_APS_CUSTOM_LISTS) && hasDataMart(dataMarts, DATA_MART.APS) &&
                <Route
                  exact
                  path="/customAdherenceDrugLists"
                    render={(props) => <CustomDrugList {...props} key={"customadherence" + pageLoad} />}
                />
              }
              <Route component={InvalidRoute}/>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    )}
    </Security>
   </>
  );
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    isLoggedIn: state.userAuth.isLoggedIn,
    roleMapping: state.userAuth.roleMapping,
    realm: state.userAuth.realm
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    propagateLogout: () => dispatch(propagateLogout()),
    propagateLogin: (userInfo: LocalStorageUserInfo) =>
      dispatch(propagateLogin(userInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
