import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { LookerContentType, IPrintDesignerDataSource, IStandardReportsGroupItem, IStandardReportsDashboardItem, ICustomerSettings } from "../../AppModels";
import { ISetLookerContentInfo, ISettingsState, SettingsActions, SettingsActionsTypes, ISetPrintDesignerDataAction, IGetStandardReportsAction, IGetCustomerSettingsAction } from "./SettingsActionsTypes";
import { getAndParseStandardReports } from "../../services/SettingsManagement/StandardReportsService";
import { API } from "../../services/LookerAPI/LookerAPI";

const getStandardReportsInfo = async(): Promise<IGetStandardReportsAction> =>{
  let standardReports: IStandardReportsGroupItem[] = null;
  let standardReportsMap: Map<string, IStandardReportsDashboardItem> = null;
  let standardReportsError: string = null;
  const setStandardReports = (data: IStandardReportsGroupItem[])=>{
    standardReports = data;
  }
  const setStandardReportsMap = (data: Map<string, IStandardReportsDashboardItem>)=>{
    standardReportsMap = data;
  }
  const setError = (error: Error)=>{
    standardReportsError = "There was an unexpected error loading Standard Reports.";
  }
  await getAndParseStandardReports(setStandardReports, setStandardReportsMap, setError);
  const action: SettingsActions = {
    type: SettingsActionsTypes.GET_STANDARD_REPORTS,
    standardReports: standardReports,
    standardReportsMap: standardReportsMap,
    standardReportsError: standardReportsError
  }
  return action;
}

const getCustomerSettingsInfo = async(): Promise<IGetCustomerSettingsAction> =>{
  let customerSettings: ICustomerSettings = null;
  let customerSettingsError: string = null;
  const setCustomerSettings = (data: any)=>{
    customerSettings = {} as ICustomerSettings;
    customerSettings.realTimeAll = data.realTimeAll;
    if(data.realTimeExplores && data.realTimeExplores.length > 0){
      customerSettings.realTimeExplores = new Set(data.realTimeExplores);
    }
  }
  const setError = (error: Error)=>{
    customerSettingsError = "There was an unexpected error loading CustomerSettings.";
  }
  await API.getCustomerSettings(setCustomerSettings, setError);
  const action: SettingsActions = {
    type: SettingsActionsTypes.GET_CUSTOMER_SETTINGS,
    customerSettings: customerSettings,
    customerSettingsError: customerSettingsError
  }
  return action;
}

export const setLookerPageInfo: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  ISettingsState,
  null,
  ISetLookerContentInfo
>> = (type: LookerContentType, lookerId: string, embedUrl: string) => {
  return async (dispatch: Dispatch) => {
    const action: SettingsActions = 
    {
      type: SettingsActionsTypes.SET_LOOKER_CONTENT_INFO,
      contentType: type,
      lookerId: lookerId,
      embedUrl: embedUrl,
    }
    return dispatch(action);
  };
};

export const setPrintDesignerData: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  ISettingsState,
  null,
  ISetPrintDesignerDataAction
>> = (printDesignerData: IPrintDesignerDataSource) => {
  return async (dispatch: Dispatch) => {
    const action: SettingsActions = 
    {
      type: SettingsActionsTypes.SET_PRINT_DESIGNER_DATA,
      printDesignerData: printDesignerData
    }
    return dispatch(action);
  };
};

export const getStandardReports: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  ISettingsState,
  null,
  IGetStandardReportsAction
>> = () => {
  return async (dispatch: Dispatch) => {
    const action = await getStandardReportsInfo();
    return dispatch(action);
  };
};

export const getCustomerSettings: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  ISettingsState,
  null,
  IGetCustomerSettingsAction
>> = () => {
  return async (dispatch: Dispatch) => {
    const action = await getCustomerSettingsInfo();
    return dispatch(action);
  };
};

export const setLastExploreTab: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  ISettingsState,
  null,
  ISetLookerContentInfo
>> = (selectedExploreTab: string) => {
  return async (dispatch: Dispatch) => {
    const action: SettingsActions = 
    {
      type: SettingsActionsTypes.SET_LAST_EXPLORE_TAB,
      selectedExploreTab: selectedExploreTab,
    }
    return dispatch(action);
  };
}
