
import { API } from "../../LookerAPI/LookerAPI";
import { ILicenseInfo } from "./EditUsersModels"

export const setDownloadPermission = async (
    username: string,
    downloadAllowed: boolean,
    setResult: (result: any) => void,
    setError: (error: Error) => void
) => {
    await API.setDownloadPermission(username, downloadAllowed, setResult, setError);
};

export const setPrintDesignerRole = async (
    username: string,
    printDesignerRole,
    setResult: (result: any) => void,
    setError: (error: Error) => void
) => {
    await API.setPrintDesignerRole(username, printDesignerRole, setResult, setError);
}

export const getLicensedUsersInfo = async (
    setResult: (result: ILicenseInfo) => void,
    setError: (error: Error) => void
) => {
    await API.getLicenseInfo(setResult, setError);
};