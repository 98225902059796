import { Alert, Col, Layout, Row, Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";
import { IAlertInfo } from "../../AppModels";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { API } from "../../services/LookerAPI/LookerAPI";
import { IApplicationState } from "../../store/Store";
import OneTimeSends from "./OneTimeSends/OneTimeSends";
import RecurringSchedules from "./RecurringSchedules/RecurringSchedules";
import "./Schedules.scss";
import { IScheduleInfo } from "../../services/Schedules/ScheduleModels";

// props only needed when getting user schedules as an admin
interface IProps {
  admin?: boolean;
  username?: string;
}

const Schedules: React.FC<IProps> = (props: IProps) => {
  const [alert, setAlert] = React.useState<IAlertInfo>(null); 
  const [access, setAccess] = React.useState<boolean>(false); 

  React.useEffect(() => {
    if (!props.admin) {
      const setAccessResult = (result: any) =>{
        if(result && result["scheduleAllowed"] === false){
          setAlert({
            type: "error",
            message: "You do not have access to manage schedules. Please contact your EnterpriseRx Data Insights Administrator for schedule functionality."
          });
        } else {
          setAccess(true);
        }
      }
      const setAccessError = () => {
        setAlert({
          type: "error",
          message: "There was an unexpected error verifying schedule access."
        });
      }
      document.title = 'Schedules';
      API.getScheduleAccess(setAccessResult, setAccessError)
    } else {
      setAccess(true);
    }
  }, [props.admin]);

  /**
   * Sends a schedule immediately.
   *
   * @param {IScheduleInfo} schedule - The schedule to be sent.
   * @returns {Promise<IScheduleInfo>} - A Promise that resolves with the sent schedule if the send was successful, or rejects with an error if the send failed.
   */
  const sendNow = (schedule: IScheduleInfo): Promise<IScheduleInfo> => {
    // Return a new Promise
    return new Promise((resolve, reject) => {
      /**
       * setResult is a callback function that is called when the schedule is successfully sent.
       * It sets a success alert and resolves the Promise with the sent schedule.
       */
      const setResult = () => {
        // Set a success alert
        setAlert({
          type: "success",
          message: `Schedule '${schedule.name}' (ID: ${schedule.id}) has been sent.`,
          description: `Check status in 'One-Time Sends'. Note:  After a 'Completed Successfully' status, email delivery may be delayed due to scheduled report volume.`,
        });

        // Resolve the Promise with the sent schedule
        resolve(schedule);
      };

      /**
       * setError is a callback function that is called when there is an error sending the schedule.
       * It logs the error, sets an error alert, and rejects the Promise with the error.
       *
       * @param {Error} error - The error that occurred.
       */
      const setError = (error) => {
        // Log the error
        console.error(
          `There was an error sending schedule ${schedule.id}. ${error.message}`
        );

        // Set an error alert
        setAlert({
          type: "error",
          message: `There was an issue sending schedule '${schedule.name}' (ID: ${schedule.id}). Please try again later or contact support if the problem persists.`,
        });

        // Reject the Promise with the error
        reject(error);
      };

      // Call the API to send the schedule immediately, passing in the setResult and setError callback functions
      API.runScheduleOnceById(
        schedule.id,
        setResult,
        setError,
        schedule.user_id
      );
    });
  };

  const clearAlert = () =>{
    setAlert(null);
  }

  React.useEffect(() =>{
    if(alert!=null){
      document.getElementById('app-main-content-pane').scrollTop = 0;
    }
  }, [alert]);

  return (
    <>
      <Layout>
        <div className="schedule-area">
        {
          (access || alert != null) ?
          <Layout.Content 
            style = { !props.admin ? 
            {
              padding: "8px 64px 4px 64px"
            } 
            : null
          }
          > 
            { !props.admin &&
              <>
              <br />
              <Row>
                <Col span={24}>
                  <h3>Schedules</h3>
                </Col>
              </Row>    
              <br />
              </>
            }
            { alert &&
              <Alert 
                style={{marginBottom: "24px"}}
                type={alert.type}
                message={alert.message}  
                description={alert?.description}
                showIcon={alert?.description ? true : false}
                closable 
                afterClose={clearAlert} 
              />
            }
            {
              access && !props.admin && 
              <Tabs type="card" destroyInactiveTabPane={true} >
                <Tabs.TabPane tab="Recurring" key="recurring">
                  <RecurringSchedules
                    admin={props.admin}
                    username={props.username}
                    sendNow={sendNow}
                    clearParentAlert={clearAlert}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="One-Time Sends" key="onetime">
                  <OneTimeSends
                    admin={props.admin}
                    username={props.username}
                  />
                </Tabs.TabPane>
              </Tabs>
            }
            {
              access && props.admin && 
              <RecurringSchedules
                admin={props.admin}
                username={props.username}
                sendNow={sendNow}
                clearParentAlert={clearAlert}
              />
            }
          </Layout.Content>
          : 
          <Layout.Content
            style={{ height: "85hv" , width: "100%"}}
          >
            <CustomSpinner />
          </Layout.Content>
        }
      </div>
    </Layout>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);
