import { Card, Col, Row, Tooltip } from "antd";
import React from "react";
import { IDisplayFolderItemType, IDashboardInfo, ILookInfo, IDisplayFolderItem, IRootFolderIds, ReportsAction, IFolderInfo, IContentDisplayTypes, IEmbedUsername } from "../../../services/FolderManagement/FolderModels";
import ReportsFolderItemOptionsMenu from "../ReportsFolderItemOptionsMenu/ReportsFolderItemOptionsMenu";
import "./ReportsCardContainer.scss";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store/Store";
import { Role, Permission} from "../../../services/RoleManagement/RoleModels";
import { isAuthorized } from "../../../services/RoleManagement/RoleManagement";
import { IEditFolderPermissions } from "../../../services/AdminManagement/FolderPermissionsManagement/FolderPermissionsModels";
import FavoritesItemOptionsMenu from "../../Favorites/FavoritesItemOptionsMenu/FavoritesItemOptionsMenu";

interface IProps {
    type: IDisplayFolderItemType;
    data: IDashboardInfo[] | ILookInfo[];
    display: IContentDisplayTypes;
    rootFolders: IRootFolderIds;
    folder: IFolderInfo;
    groupMemberNameMap: Map<string, IEmbedUsername>;
    roleMapping: Map<Role, boolean>;
    setAction: (action: ReportsAction) => void;
    goToItem: (item: IDisplayFolderItem) => void;
    reloadFavorites: () => void;
    editFolderPermissions: IEditFolderPermissions;
}

const ReportsCardContainer: React.FC<IProps> = ( props: IProps ) => {

  const serverHost = process.env.REACT_APP_DATA_INSIGHTS_API_BASE_URL;

  const responsiveColumnSpans = { xs: 24, sm: 12, md: 12, lg: 8, xl: 6 };

  const getCardDescription = (element: IDashboardInfo | ILookInfo) =>{
    switch(props.display){
      case IContentDisplayTypes.HISTORY:
        return 'Last Viewed ' + element.last_viewed;
      case IContentDisplayTypes.SEARCH:
        return 'Location: ' + element.folder_name;
      default: 
        return (
          (
            element.user_id && props.groupMemberNameMap && props.groupMemberNameMap.has(element.user_id) ?
            ('Created by ' + props.groupMemberNameMap.get(element.user_id).display_name) : 
            ("\u00A0")
          )
        );
    }
  }

  return (
    <>
      <div className="site-card-wrapper folder-content-cards">
        <Row gutter={[32,32]}>
          {(props.data.map((element, index) =>
          {
            let thisItem: IDisplayFolderItem = {
              type: props.type,
              name: element.title,
              id: element.id,
              parent_id: element.folder_id ? element.folder_id : null,
              parent_name: element.folder_name ? element.folder_name : null,
              favorite_id: element.favorite_id ? element.favorite_id : null
            };
            return (<Col key={'cold' + index} {...responsiveColumnSpans} >
                <Card
                  hoverable
                  cover={
                    <div className="card-cover-container">
                      {  props.folder && props.setAction && props.rootFolders && isAuthorized(props.roleMapping, Permission.EDIT_GROUPED_REPORTS) &&
                         props.editFolderPermissions.userCanEditContent &&
                        <ReportsFolderItemOptionsMenu 
                          item={thisItem}
                          folderInfo={props.folder}
                          setAction={props.setAction}
                          rootFolders={props.rootFolders}
                          roleMapping={props.roleMapping}
                          userCanDeleteFolder={props.editFolderPermissions.userCanDeleteFolder}
                        />
                      }
                      {
                        props.display === IContentDisplayTypes.FAVORITES &&
                        <FavoritesItemOptionsMenu favoriteId={thisItem.favorite_id} reloadFavorites={props.reloadFavorites}/>
                      }
                      {
                        element.folder_id === '0' ? 
                        <Tooltip title='Preview Not Available'>
                          <div style={{textAlign: "center"}}>---</div>
                        </Tooltip>
                        :
                        <img alt="Dashboard" src={serverHost + "/looker/content/content_thumbnail/" +
                        (props.type===IDisplayFolderItemType.LOOK ? "look/" : "dashboard/") + element.id} />
                      }
                    </div>
                  }
                  onClick={() => props.goToItem(thisItem)}
                >
                  <Card.Meta 
                    title={element.title}
                    description={getCardDescription(element)}
                  />
                </Card>
              </Col>)
          }))}
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    roleMapping: state.userAuth.roleMapping,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsCardContainer);
