import { CalendarOutlined, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Tooltip, Badge, Col } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ICustomerSettings } from "../../../AppModels";
import { IExploreTile } from "../../../services/DocumentationManagement/DocumentationModels";
import { IApplicationState } from "../../../store/Store";
import '../ExploreTiles.scss';

interface IProps {
    exploreTile: IExploreTile;
    tooltip: string;
    tooltipLoading: boolean;
}

const ExploreTile: React.FC<IProps> = ({exploreTile, tooltip, tooltipLoading}) => {

    const responsiveColumnSpans = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 8 };

    const customerSettings: ICustomerSettings = useSelector<IApplicationState>(state => state.settings.customerSettings) as ICustomerSettings;

    const ExploreTileWrapper = ({ realTime, children }) => (
        realTime || (exploreTile.category === "EnterpriseRx" || exploreTile.category === "Legacy") ?
        <Badge.Ribbon 
            style={{boxShadow: '2px 2px 2px gray'}}
            color="#FFFFFF" 
            text={<span style={{fontSize: "16px", fontWeight: 500, color: '#005A8C' }}>
                &nbsp;<CalendarOutlined/> DAILY&nbsp;&nbsp;
                </span>}>
            {children}
        </Badge.Ribbon>
        : children
      );

  return (
    <Col key={'cole' + exploreTile.embedUrl} {...responsiveColumnSpans} >
        <ExploreTileWrapper realTime={customerSettings && !customerSettings.realTimeAll && customerSettings.realTimeExplores && customerSettings.realTimeExplores.has(exploreTile.exploreName)}>
            <Card 
                title={
                <div>
                    {
                        exploreTile.exploreLabel
                    }
                    &ensp;
                    {
                        <Tooltip overlayInnerStyle={{textAlign: 'center'}} placement='top' title={tooltipLoading ? <LoadingOutlined/> : tooltip ? tooltip : 'TBD'} >
                            <InfoCircleOutlined style={{ fontSize: '20px'}}/>
                        </Tooltip> 
                    }
                </div>
                }
                className={'explore-tile'}
                hoverable
                actions={[
                    <Link to={`/documentation?explore-name=${exploreTile.exploreName.replaceAll("_", "-")}`}>
                        <Button type='link'>Documentation</Button>
                    </Link>,
                    <Link to={`/explore?name=${exploreTile.exploreName.replaceAll("_", "-")}`}>
                        <Button className='explore-tile-explore-btn' type='primary'>Explore</Button>
                    </Link>
                ]}>
                    <div className='explore-tile-content'>
                        <p className='explore-tile-paragraph'>{exploreTile.description}</p>
                        <br/>
                        <h5>Example Data Types</h5>
                        <p className='explore-tile-example-types'>{exploreTile.exampleDataTypes}</p>
                        <br/>
                        <h5>{exploreTile.reportsUsingDetail}</h5>
                        <ul className='explore-tile-list'>
                                {exploreTile.reportsUsingNames.map((value, index) => {
                                        return (<li key={index} className='explore-tile-list-item'>{value}</li>)
                                    }
                                )}
                        </ul>
                    </div>
            </Card>    
        </ExploreTileWrapper>
      </Col>
  );
};

export default ExploreTile;