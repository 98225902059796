import { Modal } from "antd";
import React from "react";
import { IAlertInfo } from "../../../AppModels";
import { API } from "../../../services/LookerAPI/LookerAPI";
import { ILookerAlertInfo } from "../../../services/Schedules/AlertsModels";

interface IDeleteAlertsItemProps {
    alert: ILookerAlertInfo;
    triggerAlertsRefresh: () => void;
    showAlert: (alertInfo: IAlertInfo) => void;
    exit: () => void;
}

const DeleteAlertsItem: React.FC<IDeleteAlertsItemProps> = (props: IDeleteAlertsItemProps) => {

    const closeDeleteModal = () => {
        props.exit();
    }
        
    const deleteComplete = () => {     
        props.triggerAlertsRefresh();   
        props.showAlert({
            type: "success",
            message:  'Alert successfully deleted.',
            visible: true
        });
    }

    const deleteError = () => {        
      props.showAlert({
          type: "error",
          message: 'An unexpected error occured attempting to delete alert.',
          visible: true
      });
    }

    const deleteAlert = () => {
        API.deleteAlert(props.alert.alert_id, deleteComplete, deleteError);
        props.exit();
    }


  return (
    <>
    <Modal
        title={'Delete "' + props.alert.name + '"?'}
        visible={true}
        onOk={deleteAlert}
        okText={'Delete Alert'}
        onCancel={closeDeleteModal}
        width={650}
    >      
    <>
        <h4>Are you sure you want to delete the alert<br/>"{props.alert.name}"?</h4>
        <br />
        This <b>CANNOT</b> be undone.
    </>
    </Modal>
    </>
  );
};
 
export default DeleteAlertsItem;
  