import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IDataInsightsMessage } from "../../containers/Messages/MessageList";
import { IClearFirstMessage, IDisplayMessage, ILargeLookMessage, IMessageState, MessageActions, MessageActionsTypes } from "./MessageActionsTypes";

/*const checkIfGreaterThan50FieldsInLook = async(
  url: string
): Promise<IDisplayMessage>=>{
  let lookGreaterThan50Fields = false;
  if(url.startsWith("/embed/looks/")){
    const lookIdFromUrl = url.substring(13, url.indexOf("?"));
    
    const setResult = (result:any) =>{
      if(result && result.query && result.query.fields && result.query.fields.length > 50){
        lookGreaterThan50Fields = true;
      }
    }
    const setError = (error:any) => {
      //Do nothing, but no warning message will display
    }

    await API.getLook(Number(lookIdFromUrl), "id,query(fields),model", setResult, setError);

  } else if(url.startsWith("/embed/explore/")){
      if(url.indexOf("qid=") !== -1){
        let qid = url.substr(url.indexOf("qid=") + 4);
        if(qid.indexOf("&") !== -1){
          qid = qid.substring(0, qid.indexOf("&"));
        }

        const setResult = (result:any) =>{
          if(result && result.fields && result.fields.length > 50){
            lookGreaterThan50Fields = true;
          }
        }
        const setError = (error:any) => {
          //Do nothing, but no warning message will display
        }

        await API.getQueryForSlug(qid, "fields", setResult, setError);
      }
  }

  console.log('checkIfGreaterThan50FieldsInLook ', lookGreaterThan50Fields);
  if(lookGreaterThan50Fields){
    return {
      type: MessageActionsTypes.DISPLAY_MESSAGE,
      message: MESSAGE_LARGE_LOOK_SAVE_WARNING
    }
  } 
  return {
    type: MessageActionsTypes.DISPLAY_MESSAGE,
    message: null
  };
}

export const checkSaveLookUrlAndDisplayMessage: ActionCreator<ThunkAction<
  void,
  IMessageState,
  null,
  IDisplayMessage
>> = (url: string) => {
  return async (dispatch: Dispatch) => {
    const action = await checkIfGreaterThan50FieldsInLook(url);
    return dispatch(action);
  };
}; */

export const displayMessage: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IMessageState,
  null,
  IDisplayMessage
>> = (message: IDataInsightsMessage) => {
  return async (dispatch: Dispatch) => {
    const action: MessageActions = 
    {
      type: MessageActionsTypes.DISPLAY_MESSAGE,
      message: message
    }
    return dispatch(action);
  };
};

export const clearFirstMessage: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IMessageState,
  null,
  IClearFirstMessage
>> = () => {
  return async (dispatch: Dispatch) => {
    const action: MessageActions = 
    {
      type: MessageActionsTypes.CLEAR_FIRST_MESSAGE
    }
    return dispatch(action);
  };
};

export const displayLargeLookMessageForLook: ActionCreator<ThunkAction<
  void,
  IMessageState,
  null,
  ILargeLookMessage
>> = (message: IDataInsightsMessage, lookId: string) => {
  return async (dispatch: Dispatch) => {
    const action: MessageActions = 
    {
      type: MessageActionsTypes.LARGE_LOOK_MESSAGE_FOR_LOOK,
      message: message,
      lookId: lookId
    }
    dispatch(action);
  };
};

