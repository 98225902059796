import React from "react";
import { Select,} from "antd";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import { setPrintDesignerRole } from "../../../services/AdminManagement/EditUsersManagement/EditUsersManagement"
import { IAlertInfo } from "../../../AppModels";

const { Option } = Select;

interface IProps {
    userInfo: IUserInfo,
    setLoading(isLoading: boolean): void,
    setAlertInfo(alertInfo: IAlertInfo),
    loading: boolean,
    currentUsername: string
}

const PrintDesignerRoleAssignment: React.FC<IProps> = (props: (IProps & IAlertInfo)) => {

    const handleValueChange = async (value, option) => {

        const setResult = (result: any) => {
            props.setAlertInfo({
                message: props.currentUsername === props.userInfo.username ?
                "Your Print Designer role has been successfully updated! You will need to log out and log in again for this change to take effect.":
                "Print Designer user role has been successfully updated!",
                type: "success",
                visible: true
            })
        }

        const setError = (error: Error) => {
            props.setAlertInfo({
                message: "There was a problem updating the Print Designer user role",
                type: "error",
                visible: true
            })
        }

        props.setLoading(true);
        await setPrintDesignerRole(props.userInfo.username, value.toUpperCase(), setResult, setError);
        props.setLoading(false);
    }

    return (
        <>
            <Select defaultValue={props.userInfo.printDesignerRole} style={{width: "100px"}} onChange={handleValueChange}>
                <Option value="designer">Designer</Option>
                <Option value="viewer">Viewer</Option>
                <Option value="none">None</Option>
            </Select>
        </>
    );
};

export default PrintDesignerRoleAssignment;