import React, { useState } from "react";
import { Checkbox } from "antd";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import { setDownloadPermission } from "../../../services/AdminManagement/EditUsersManagement/EditUsersManagement"
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IAlertInfo } from "../../../AppModels";

interface IProps {
    userInfo: IUserInfo,
    setLoading(isLoading: boolean): void,
    setAlertInfo(alertInfo: IAlertInfo),
    loading: boolean,
    disabled: boolean
}

const DownloadPermissions: React.FC<IProps> = (props: (IProps & IAlertInfo)) => {

    const [checked, setChecked] = useState<boolean>(props.userInfo.downloadAllowed)

    const handleSetDownloadPermission = async (username: string, value: boolean) => {
        const setResult = (result: any) => {
            props.setAlertInfo({
                visible: true,
                type: 'success',
                message: `Successfully ${value ? 'enabled' : 'disabled'} download permissions for '${props.userInfo.displayName}'`,
                description: ''
            });

            if (checked) {
                setChecked(false);
            } else {
                setChecked(true);
            }
        }

        const setError = (error: Error) => {
            props.setAlertInfo({
                visible: true,
                type: 'error',
                message: `There was an unexpected error when ${value ? 'enabling' : 'disabling'} download permissions for '${props.userInfo.displayName}'`,
                description: ''
            });
        }

        props.setLoading(true);
        await setDownloadPermission(username, value, setResult, setError);
        props.setLoading(false);
    }

    const handleCheckboxChange = (event: CheckboxChangeEvent) => {
        handleSetDownloadPermission(props.userInfo.username, event.target.checked);
    }

    return (
        <>
            <Checkbox
                checked={checked}
                onChange={handleCheckboxChange}
                disabled={props.disabled}/>
        </>
    );
};

export default DownloadPermissions;