import { Alert, AlertProps, Button, Col, Layout, Row, Space, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect } from 'react'
import { deleteApsDrugList, getAllApsDrugLists, getApsDrugListDetails, saveAndVerifyDrugList } from '../../services/CustomAdherenceDrugListManagement/CustomAdherenceDrugListManagement';
import { ICustomDrugList, IDrugListHeader, IDrugListSaveResult, LIST_TYPE } from '../../services/CustomAdherenceDrugListManagement/CustomAdherenceDrugListModels';
import './CustomDrugList.scss'
import DeleteListModal from './DeleteListModal/DeleteListModal';
import DrugListTable from './DrugListTable/DrugListTable';
import ListDetailsModal from './ListDetailsModal/ListDetailsModal';

const { Text } = Typography;
interface ICustomDrugListProps {

}

const CustomDrugList : React.FC<ICustomDrugListProps> = (props: ICustomDrugListProps) => {

    const [showModal, setShowModal] = React.useState(false);

    const [creating, setCreating] = React.useState<boolean>(false);
    const [editing, setEditing] = React.useState<{isEditing: boolean, list: IDrugListHeader}>({isEditing: false, list: null});
    const [deleting, setDeleting] = React.useState<{isDeleting: boolean, list: IDrugListHeader}>({isDeleting: false, list: null});

    const [allLists, setAllLists] = React.useState<IDrugListHeader[]>([]);
    const [maxLists, setMaxLists] = React.useState<number>(-1);

    const [loadingLists, setLoadingLists] = React.useState<boolean>(true);
    const [loadingDetails, setLoadingDetails] = React.useState<boolean>(false);

    const [listDetails, setListDetails] = React.useState<ICustomDrugList>({
        list_type: LIST_TYPE.NDC,
        drug_list_name: "",
        drug_list_description: "",
        items: []
    })

    const [alertInfo, setAlertInfo] = React.useState<AlertProps & {visible: boolean}>({
        visible: false,
        message: "",
        type: "success",
        description: ""
      })


    const getAllLists = (): void => {
        if (!loadingLists) setLoadingLists(true)
        getAllApsDrugLists((drug_list) => {
            setAllLists(drug_list.drug_lists)
            setMaxLists(drug_list.max_drug_lists)
            setLoadingLists(false)

        }, (error) => {
            setAllLists([])
            setMaxLists(0)
            setLoadingLists(false)
            setAlertInfo({
                visible: true,
                message: `An unexpected error occurred while attempting to retrieve all Lists`,
                type: "error",
                description: ""
            })
        })
    }

    const getListDetails = (drugListName: string): void => {
        setLoadingDetails(true)
        getApsDrugListDetails(drugListName, (drug_list) => {
            setLoadingDetails(false)
            setListDetails(drug_list);
        }, (error) => {
            setLoadingDetails(false)
            setShowModal(false)
            setAlertInfo({
                visible: true,
                message: `An unexpected error occurred while attempting open List "${drugListName}"`,
                type: "error",
                description: ""
            })
        })
    }

    const verifyAndSaveList = async (drugList: ICustomDrugList): Promise<IDrugListSaveResult> => {
        console.log(`create/edit list attempted`)

        let promise = new Promise<IDrugListSaveResult>((resolve, reject) => {
            saveAndVerifyDrugList(drugList, (result) => {
                if (result.all_valid) {
                    setAlertInfo({
                        visible: true,
                        message: `"${drugList.drug_list_name}" ${editing.isEditing ? "was successfully saved!" : "was successfully created!"}`,
                        description: '',
                        type: 'success'
                    })
                    showDetailsModal(false)
                    getAllLists()
                } 
                
                return resolve(result)
            }, (error) => {

                showDetailsModal(false)
                setAlertInfo({
                    visible: true,
                    message: `An unexpected error occurred while attempting to save List "${drugList.drug_list_name}"`,
                    type: "error",
                    description: ""
                })

                return reject({
                    drug_list_name: "",
                    drug_list_description: "",
                    list_type: LIST_TYPE.NDC,
                    items: [],
                    all_valid: false
                })
            })
        })
        return promise
    }

    const deleteList = (drugListName: string): string => {
        console.log(`delete list attempted - ${drugListName}`)
        setLoadingLists(true)
        deleteApsDrugList(drugListName, (status) => {

            if (status === "OK") {
                getAllLists();
                setAlertInfo({
                    visible: true,
                    message: `"${drugListName}" was deleted successfully!`,
                    description: '',
                    type: 'success'
                })
            } else {
                setLoadingLists(false)
            }
            showDeleteModal(false, null, false)
            return status


        }, (error) => {
            setAlertInfo({
                visible: true,
                message: `An unexpected error occurred while attempting to delete List "${drugListName}"`,
                type: "error",
                description: ""
            })
        })
        return "error"
    }
    // ----------------------------------------------

    const handleCreateModal = (isCreating) => {
        setCreating(isCreating)
        if (isCreating) setEditing({isEditing: false, list: null})
        showDetailsModal(isCreating);
    }

    const handleEditingModal = (isEditing: boolean, list: IDrugListHeader) => {
        setEditing({isEditing: isEditing, list: list})
        if (isEditing) setCreating(false)
        showDetailsModal(isEditing);
    } 

    const showDetailsModal = (showModal: boolean) => {
        setShowModal(showModal);
        if (showModal === false) {
            setCreating(false)
            setEditing({isEditing: false, list: null})
            setListDetails({
                list_type: LIST_TYPE.NDC,
                drug_list_name: "",
                drug_list_description: "",
                items: []
            })
            setLoadingDetails(false)
        } 
    }

    const showDeleteModal = (showModal: boolean, list: IDrugListHeader, confirmation: boolean) => {
        setDeleting({isDeleting: showModal, list: list})
        if (!showModal && list && confirmation) {
            deleteList(list.drug_list_name)
        }
    } 

    useEffect(() => {
        getAllLists()
        document.title = 'Admin - Custom Adherence Drug Lists'
    // eslint-disable-next-line
    }, [])

    return (
        <>
        <Layout>
        <div className="lists-content-area">
            <Layout.Content>
            <br />
            <Row style={{height:35.5}}>
                <Col span={16}>
                    <h3 style={{float:'left', margin:0}}>Custom Adherence Drug Lists</h3>
                </Col>
                <Col span={8}>
                    <Space size={20} style={{float: 'right'}}>
                        <Tooltip popupVisible={maxLists > -1} title={`A maximum of ${maxLists} lists are allowed`}>
                            <Text>{maxLists > -1 ? `${allLists.length} out of ${maxLists} lists`: <Spin/>}</Text>
                        </Tooltip>
                        <Button className='btn-new-list' type='primary' disabled={loadingLists} onClick={() => {
                            if (allLists.length === maxLists) {
                                setAlertInfo({
                                    visible: true,
                                    message: "Maximum List limit has been reached. Delete a List to create a new one!",
                                    description: "",
                                    type: "warning"
                                })
                            } else {
                                handleCreateModal(true)
                            }
                        }}>
                            New List
                        </Button>
                    </Space>
                </Col>
            </Row>
            <br />
            <div className='flex-container' style={{overflow: 'auto'}}>
                { alertInfo.visible && 
                    <>
                    <Alert {...alertInfo} onClose={() => setAlertInfo(prev => {return{...prev, visible: false}})} closable/> 
                    <br />
                    </>
                }
                <DrugListTable 
                    dataSource={allLists} 
                    getListDetails={getListDetails}
                    setEditing={handleEditingModal}
                    setDeleting={showDeleteModal}
                    loading={loadingLists}/>
            </div>
            </Layout.Content>
        </div>
        </Layout>
        { showModal &&
            <ListDetailsModal
            listDetails={listDetails}
            isCreating={creating}
            isEditing={editing.isEditing}
            showModal={showModal}
            loading={loadingDetails}
            allLists={allLists}
            setLoading={setLoadingDetails}
            closeModal={showDetailsModal}
            saveList={verifyAndSaveList}/>
        }
        { deleting.isDeleting && deleting.list &&
            <DeleteListModal 
                list={deleting.list}
                deleteList={deleteList}
                setDeleting={showDeleteModal}/>
        }
        </>
    )
}

export default CustomDrugList