import React from "react";
import { Alert, Table } from "antd";
import { ColumnsType } from 'antd/es/table'
import EditGroupButton from './EditGroupButton/EditGroupButton'
import DeleteGroupButton from './DeleteGroupButton/DeleteGroupButton'
import { IGroupInfo } from '../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels'
import { IAlertInfo } from "../../../AppModels";

interface IProps {
    groups: IGroupInfo[],
    setLoading(isLoading: boolean): void,
    setAlertInfo(alertInfo: IAlertInfo),
    loading: boolean,
}

const GroupsView: React.FC<IProps> = (props: (IProps & IAlertInfo)) => {

    const itemsPerPage = 15;

    const onAlertClose = () => {
        props.setAlertInfo({
            visible: false,
            type: 'warning',
            message: '',
            description: ''
        })
    }

    /* search functionality if we want it */

    // const [searchText, setSearchText] = useState('')
    // const [searchedColumn, setSearchedColumn] = useState('');

    // const getColumnSearchProps = dataIndex => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    //       <div style={{ padding: 8 }}>
    //         <Input
    //           placeholder={`Search ${dataIndex}`}
    //           value={selectedKeys[0]}
    //           onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //           style={{ width: 188, marginBottom: 8, display: 'block' }}
    //         />
    //         <Space>
    //           <Button
    //             type="primary"
    //             onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //             icon={<SearchOutlined />}
    //             size="small"
    //             style={{ width: 90 }}
    //           >
    //             Search
    //           </Button>
    //           <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
    //             Reset
    //           </Button>
    //           <Button
    //             type="link"
    //             size="small"
    //             onClick={() => {
    //               confirm({ closeDropdown: false });
    //               setSearchText(selectedKeys[0]);
    //               setSearchedColumn(dataIndex);
    //             }}
    //           >
    //             Filter
    //           </Button>
    //         </Space>
    //       </div>
    //     ),
    //     filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    //     onFilter: (value, record) =>
    //       record[dataIndex]
    //         ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //         : '',
    //     onFilterDropdownVisibleChange: visible => {
    //       if (visible) {
    //       }
    //     },
    //     render: text =>
    //       searchedColumn === dataIndex ? ( text
    //       ) : (
    //         text
    //       ),
    //   });
    
    //   const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm();
    //     setSearchText(selectedKeys[0]);
    //     setSearchedColumn(dataIndex);
    //   };
    
    //    const handleReset = clearFilters => {
    //     clearFilters();
    //     setSearchText('');
    //   };

    const columns: ColumnsType<IGroupInfo> = [
        {
            title: 'Name', 
            dataIndex: 'groupName', 
            key:'groupName',
            sortDirections: ['descend', 'ascend'],
            width: '87%',
            sorter: (a, b) => a.groupName.localeCompare(b.groupName),
        },
        {
            title: 'Members',
            dataIndex: 'totalUniqueUsers',
            key: 'totalUniqueUsers',
            align: 'center',
            width: '5%',
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.totalUniqueUsers - b.totalUniqueUsers,
        },
        {
            title: 'Actions', 
            dataIndex: '', 
            width: '8%',
            align: 'center',
            key: 'actions',
            render: (text, record, index) => 
                <div className='table-action-cell'>
                    <EditGroupButton 
                        text={text} 
                        record={record} 
                        index={index}
                    />
                    <DeleteGroupButton 
                        text={text} 
                        record={record} 
                        index={index}
                        setLoading={props.setLoading}
                        setAlertInfo={props.setAlertInfo}
                    />
                </div>
        }
    ]

    return (
        <>
            {
            props.visible &&
            <Alert
                style={{ marginBottom: "24px"  }}
                type={props.type}
                message={props.message}  
                description={props.description}
                closable 
                onClose={onAlertClose}/>
            }
            <Table<IGroupInfo>
                columns={columns}
                dataSource={props.groups}
                rowKey='groupName'
                loading={props.loading}
                pagination={{
                    size:"small",
                    defaultPageSize: itemsPerPage,
                    hideOnSinglePage: true,
                    position: ["bottomRight"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} groups`
                }}
            />
        </>
    );
};

export default GroupsView;