import { Alert, Button, Layout, Popconfirm, Space, Table } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IAlertInfo } from "../../../AppModels";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import { API } from "../../../services/LookerAPI/LookerAPI";
import { IScheduleInfo } from "../../../services/Schedules/ScheduleModels";
import {
  getBulkScheduleLastRunStatus,
  getScheduledPlans,
  getScheduledPlansAdmin,
} from "../../../services/Schedules/Schedules";
import { IApplicationState } from "../../../store/Store";
import DeleteScheduleItem from "../../DeleteScheduleItem/DeleteScheduleItem";
import LastRunStatus from "../LastRunStatus/LastRunStatus";
import "../Schedules.scss";
import SendNowButton from "./SendNowButton/SendNowButton";

// props only needed when getting user schedules as an admin
interface IProps {
  admin?: boolean;
  username?: string;
  sendNow: (schedule: IScheduleInfo) => Promise<IScheduleInfo>;
  clearParentAlert?: () => void;
}

const RecurringSchedules: React.FC<IProps> = (props: IProps) => {
  const [schedules, setSchedules] = React.useState<IScheduleInfo[]>(null);

  const [scheduleItemToDelete, setScheduleItemToDelete] =
    React.useState<IScheduleInfo>(null);
  const [alert, setAlert] = React.useState<IAlertInfo>(null);

  const [refreshCount, setRefreshCount] = React.useState<number>(0);

  const [scheduleSelectedLastRunModal, setScheduleSelectedLastRunModal] =
    React.useState<IScheduleInfo>(null);

  const [lastRunStatuses, setLastRunStatuses] =
    React.useState<Map<string, string>>(null);

  const [sending, setSending] = React.useState(false);

  const [globalCountdown, setGlobalCountdown] = React.useState<boolean>(false);

  const [countdowns, setCountdowns] = React.useState(() => {
    // Get the countdowns from local storage when the component mounts
    const storedCountdowns = localStorage.getItem("countdowns");
    return storedCountdowns
      ? new Map(Object.entries(JSON.parse(storedCountdowns)))
      : new Map();
  });

  React.useEffect(() => {
    // Function to handle the storage event
    const handleStorage = () => {
      // Get the updated countdowns from local storage
      const storedCountdowns = localStorage.getItem("countdowns");
      setCountdowns(
        storedCountdowns
          ? new Map(Object.entries(JSON.parse(storedCountdowns)))
          : new Map()
      );
    };

    // Listen for the storage event
    window.addEventListener("storage", handleStorage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, []);

  // To add a new countdown
  const addCountdown = (scheduleId, countdownMillis) => {
    // Get the current time and add the countdown
    const endTime = Date.now() + countdownMillis;

    // Add the new countdown to the state
    setCountdowns(
      (prevCountdowns) => new Map(prevCountdowns.set(scheduleId, endTime))
    );

    // Also update the countdowns in local storage
    const countdowns = JSON.parse(localStorage.getItem("countdowns") || "{}");
    countdowns[scheduleId] = endTime;
    localStorage.setItem("countdowns", JSON.stringify(countdowns));
  };

  // To remove a countdown
  const removeCountdown = (scheduleId) => {
    // Remove the countdown from the state
    setCountdowns(
      (prevCountdowns) =>
        new Map(prevCountdowns.delete(scheduleId) && prevCountdowns)
    );

    // Also update the countdowns in local storage
    const countdowns = JSON.parse(localStorage.getItem("countdowns") || "{}");
    delete countdowns[scheduleId];
    localStorage.setItem("countdowns", JSON.stringify(countdowns));
  };

  const maxRecipientsDisplay = 3;
  const itemsPerPage = 20;

  const refreshSchedules = () => {
    setRefreshCount(refreshCount + 1);
  };

  const setError = (error: Error) => {
    setAlert({
      type: "error",
      message: "An unexpected error occurred loading Schedules.",
    });
  };

  const setResults = (results: any) => {
    setSchedules(results);
  };

  React.useEffect(() => {
    if (!props.admin) {
      getScheduledPlans(setResults, setError);
    } else {
      getScheduledPlansAdmin(props.username, setResults, setError);
    }
  }, [refreshCount, props.admin, props.username]);

  const deleteSchedule = (schedule) => {
    props.clearParentAlert();
    setScheduleItemToDelete(schedule);
  };

  const cancelDelete = () => {
    setScheduleItemToDelete(null);
  };

  const clearAlert = () => {
    setAlert(null);
  };

  React.useEffect(() => {
    if (alert != null) {
      document.getElementById("app-main-content-pane").scrollTop = 0;
    }
  }, [alert]);

  React.useEffect(() => {
    if (schedules && schedules.length > 0) {
      let scheduleIds: string[] = [];
      schedules.forEach((schedule) => {
        scheduleIds.push(schedule.id);
      });

      if (scheduleIds.length > 5000) {
        setAlert({
          type: "warning",
          message: "Only retrieving last run status for first 5000 schedules.",
        });
        scheduleIds = scheduleIds.slice(0, 5000);
      }

      const setError = () => {
        setLastRunStatuses(new Map<string, string>());
        setAlert({
          type: "error",
          message:
            "An unexpected error occurred loading Last Run Status for all schedules.",
        });
      };

      getBulkScheduleLastRunStatus(scheduleIds, setLastRunStatuses, setError);
    }
  }, [schedules]);

  /**
   * DeleteButton component
   * This component renders a delete button for a schedule.
   * If the user is an admin, it shows a confirmation pop-up before deleting.
   *
   * @param {Object} props - The component props
   * @param {boolean} props.admin - Indicates if the user is an admin
   * @param {Object} props.schedule - The schedule to be deleted
   */
  const DeleteButton = ({ admin, schedule }) => {
    /**
     * deleteComplete function
     * This function is called when a schedule is successfully deleted.
     * It refreshes the schedules and shows a success alert.
     */
    const deleteComplete = () => {
      refreshSchedules();
      setAlert({
        type: "success",
        message: "Schedule successfully deleted.",
      });
    };

    /**
     * deleteError function
     * This function is called when there's an error deleting a schedule.
     * It shows an error alert.
     */
    const deleteError = () => {
      setAlert({
        type: "error",
        message: "An unexpected error occured attempting to delete schedule.",
      });
    };

    // If the user is an admin, show a confirmation pop-up before deleting
    return admin ? (
      <Popconfirm
        placement="top"
        title="Are you sure you want to delete this schedule?"
        onConfirm={() =>
          API.deleteScheduledPlanAdmin(
            schedule.id,
            props.username,
            deleteComplete,
            deleteError
          )
        }
        okText="Yes"
        cancelText="No"
        icon={null}
        style={{ width: "20px" }}
        overlayClassName="remove-btn-container"
      >
        <Button
          type="primary"
          danger
          disabled={sending}
          style={{ width: "110px", display: "flex", justifyContent: "center" }}
        >
          Delete
        </Button>
      </Popconfirm>
    ) : (
      // If the user is not an admin, just show the delete button
      <Button
        type="primary"
        danger
        onClick={() => deleteSchedule(schedule)}
        disabled={sending}
        style={{ width: "110px", display: "flex", justifyContent: "center" }}
      >
        Delete
      </Button>
    );
  };

  const sendNow = (schedule: IScheduleInfo): Promise<IScheduleInfo> => {
    setSending(true);

    return props
      .sendNow(schedule)
      .then((result: IScheduleInfo) => {
        // Handle successful send
        return result;
      })
      .catch((error) => {
        // Handle failed send
        throw error;
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <>
      {schedules != null || alert != null ? (
        <Layout.Content style={{ overflow: "auto" }}>
          {alert && (
            <Alert
              style={{ marginBottom: "24px" }}
              type={alert.type}
              message={alert.message}
              description={alert.description}
              closable
              afterClose={clearAlert}
            />
          )}
          {schedules && (
            <Table
              dataSource={schedules}
              rowKey={(schedule) => schedule.id}
              pagination={{
                size: "small",
                defaultPageSize: itemsPerPage,
                hideOnSinglePage: true,
                position: ["bottomRight"],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} schedules`,
              }}
            >
              <Column
                title="ID"
                key="id"
                dataIndex="id"
                width="3%"
                sorter={(a: IScheduleInfo, b: IScheduleInfo) =>
                  a.id.localeCompare(b.id)
                }
              />
              <Column
                title="Name"
                key="name"
                width="20%"
                sorter={(a: IScheduleInfo, b: IScheduleInfo) =>
                  a.name.localeCompare(b.name)
                }
                render={(schedule: IScheduleInfo) => {
                  if (props.admin) {
                    return <span>{schedule.name}</span>;
                  } else if (schedule.dashboard_id) {
                    return (
                      <Link to={"/dashboards/" + schedule.dashboard_id}>
                        <span>
                          <u>{schedule.name}</u>
                        </span>
                      </Link>
                    );
                  } else if (schedule.look_id) {
                    return (
                      <Link to={"/looks/" + schedule.look_id}>
                        <span>
                          <u>{schedule.name}</u>
                        </span>
                      </Link>
                    );
                  } else {
                    return <span>{schedule.name}</span>;
                  }
                }}
              />
              <Column
                title="Scheduled Times"
                key="scheduledTimes"
                width="14%"
                dataIndex="crontab_description"
              />
              <Column
                title="Recipients"
                key="recipients"
                width="18%"
                render={(schedule) => {
                  let recipientArray = [],
                    recipients = "",
                    i;
                  if (
                    schedule.scheduled_plan_destination[0] &&
                    schedule.scheduled_plan_destination[0].type.indexOf(
                      "print_designer_scheduler"
                    ) > 0
                  ) {
                    recipientArray = JSON.parse(
                      schedule.scheduled_plan_destination[0].parameters
                    )["email_addresses"].split(",");
                  } else if (
                    schedule.scheduled_plan_destination[0] &&
                    schedule.scheduled_plan_destination[0].type.indexOf(
                      "custom_delimiter_email"
                    ) > 0
                  ) {
                    recipientArray = JSON.parse(
                      schedule.scheduled_plan_destination[0].parameters
                    )["email_address"].split(",");
                  } else if (schedule.scheduled_plan_destination) {
                    recipientArray = schedule.scheduled_plan_destination.map(
                      (destination) => destination.address
                    );
                  }
                  for (
                    i = 0;
                    i < recipientArray.length && i < maxRecipientsDisplay;
                    i++
                  ) {
                    recipients += recipientArray[i] + ", ";
                  }
                  if (recipientArray.length > maxRecipientsDisplay) {
                    recipients +=
                      "& " +
                      (recipientArray.length - maxRecipientsDisplay) +
                      " more";
                  } else if (recipients.length > 0) {
                    recipients = recipients.substring(0, recipients.length - 2);
                  }
                  return <span>{recipients}</span>;
                }}
              />
              <Column
                title="Type"
                key="type"
                width="7%"
                sorter={(a: IScheduleInfo, b: IScheduleInfo) =>
                  (a.dashboard_id ? "Dashboard" : "Look").localeCompare(
                    b.dashboard_id ? "Dashboard" : "Look"
                  )
                }
                render={(schedule) => {
                  if (schedule.dashboard_id) {
                    return <span>Dashboard</span>;
                  } else if (schedule.look_id) {
                    return <span>Look</span>;
                  } else {
                    return <span></span>;
                  }
                }}
              />
              <Column
                title="Updated"
                key="updated"
                dataIndex="updated_at"
                width="8%"
                render={(text) => (text == null ? "" : text.substring(0, 10))}
                sorter={(a: IScheduleInfo, b: IScheduleInfo) =>
                  a.updated_at.localeCompare(b.updated_at)
                }
              />
              <Column
                title="Last Ran"
                key="lastran"
                dataIndex="last_run_at"
                width="8%"
                render={(text, schedule) =>
                  text == null ? "" : text.substring(0, 10)
                }
                sorter={(a: IScheduleInfo, b: IScheduleInfo) =>
                  (a.last_run_at + "").localeCompare(b.last_run_at + "")
                }
              />
              <Column
                title="Last Run Status"
                key="last_run_status"
                width="8%"
                render={(schedule) => {
                  const lastRunStatus = lastRunStatuses
                    ? lastRunStatuses.has(schedule.id)
                      ? lastRunStatuses.get(schedule.id)
                      : null
                    : "Loading...";
                  return (
                    <Button
                      type="link"
                      onClick={() => {
                        setScheduleSelectedLastRunModal(schedule);
                      }}
                    >
                      {lastRunStatus ? (
                        <span style={{ textDecoration: "underline" }}>
                          {lastRunStatus.charAt(0).toUpperCase() +
                            lastRunStatus.slice(1).replaceAll("_", " ")}
                        </span>
                      ) : (
                        <span>&emsp;&emsp;</span>
                      )}
                    </Button>
                  );
                }}
              />
              <Column
                title="Actions"
                key="actions"
                width="14%"
                align="center"
                render={(schedule: IScheduleInfo) => (
                  <Space direction="horizontal" size={"small"}>
                    <SendNowButton
                      schedule={schedule}
                      schedules={schedules}
                      sendNow={sendNow}
                      sending={sending}
                      globalCountdown={globalCountdown}
                      setGlobalCountdown={setGlobalCountdown}
                      countdowns={countdowns}
                      addCountdown={addCountdown}
                      removeCountdown={removeCountdown}
                    />
                    <DeleteButton admin={props.admin} schedule={schedule} />
                  </Space>
                )}
              />
            </Table>
          )}
        </Layout.Content>
      ) : (
        <Layout.Content style={{ height: "85hv", width: "100%" }}>
          <CustomSpinner />
        </Layout.Content>
      )}
      {scheduleItemToDelete && (
        <DeleteScheduleItem
          schedule={scheduleItemToDelete}
          showAlert={setAlert}
          triggerFolderRefresh={refreshSchedules}
          exit={cancelDelete}
        />
      )}
      {scheduleSelectedLastRunModal && (
        <LastRunStatus
          clearModal={() => setScheduleSelectedLastRunModal(null)}
          scheduleInfo={scheduleSelectedLastRunModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RecurringSchedules);
