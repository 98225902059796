import { Button, Col, Row, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import { ICustomDrugList, IDrugListHeader, IDrugListItem } from '../../../services/CustomAdherenceDrugListManagement/CustomAdherenceDrugListModels'
import './DrugListTable.scss'
import { API } from '../../../services/LookerAPI/LookerAPI'
import { mkConfig, generateCsv, download, ConfigOptions } from 'export-to-csv'

interface IDrugListTableProps {
    dataSource: IDrugListHeader[]
    loading: boolean;
    getListDetails: (listName: string) => void
    setEditing: (isEditing: boolean, list: IDrugListHeader) => void
    setDeleting: (isDeleting: boolean, list: IDrugListHeader, confirmation: boolean) => void
}

const DrugListTable: React.FC<IDrugListTableProps> = (props: IDrugListTableProps) => {

    const options: ConfigOptions = { 
        fieldSeparator: ',',
        quoteStrings: true,
        quoteCharacter: '"',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
      };

    const csvConfig = mkConfig(options);

    const getListDetails = async (list_name: string): Promise<IDrugListItem[]> => {

        return new Promise((resolve, reject) => {
            // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
            API.getApsCustomDrugListDetails(list_name, (result: ICustomDrugList) => {
                resolve(result.items)
            }, () => {
                reject(null)
            })
        })
    }

    return (
        <div className="tbl-drug-list-wrapper">
            <Table
                dataSource={props.dataSource}
                rowKey='drug_list_name'
                className='tbl-drug-list'
                loading={props.loading}
                pagination={false}
                // footer={() => <Text>{props.dataSource.length} out of 5 lists used</Text>}
            >
            <Column
                title='Name'
                dataIndex='drug_list_name'
                key='drug_list_name'
                width='255px'
            />
            <Column
                title='Description'
                dataIndex='drug_list_description'
                key='drug_list_description'
                width='685px'
            />
            <Column
                title='List Type'
                dataIndex='list_type'
                key='list_type'
                align='center'
                width='175px'
            />
            <Column
                title='Status'
                dataIndex='status'
                key='status'
                align='center'
                width='170px'
            />
            <Column
                title='Last Modified'
                dataIndex='last_modified'
                key='last_modified'
                align='center'
                width='175px'
            />
            <Column
                title='Actions'
                width='255px'
                align='center'
                render={(text: string, record: IDrugListHeader, index: number) => {
                    return(
                        <Row gutter={4}>
                            <Col span={8}>
                                <Button id={`btn-list-edit-${index}`} className='btn-list-action'
                                onClick={() => {
                                    props.getListDetails(record.drug_list_name)
                                    props.setEditing(true, record)
                                }}>
                                    <span className='btn-list-action-text'>Edit</span>
                                </Button>
                            </Col>
                            <Col span={8}>
                                <Button id={`btn-list-export-${index}`} onClick={() => {

                                    getListDetails(record.drug_list_name).then((data) => {
                                        csvConfig.title = record.drug_list_name;
                                        csvConfig.filename = record.drug_list_name;

                                        const csv = generateCsv(csvConfig)(data as unknown as { [k: string]: unknown; [k: number]: unknown; }[]);
                                        download(csvConfig)(csv)
                                    })


                                }} className='btn-list-action'>
                                    <span className='btn-list-action-text'>Export</span>
                                </Button>
                            </Col>
                            <Col span={8}>
                                <Button 
                                id={`btn-list-delete-${index}`}
                                className='btn-list-action' 
                                type="primary" 
                                danger
                                onClick={() => {
                                    props.setDeleting(true, record, false)
                                }}
                                >
                                    <span className='btn-list-action-text'>Delete</span>
                                </Button>
                            </Col>
                        </Row>
                    )
                }}
            />
            </Table>
        </div>
    )
}

export default DrugListTable