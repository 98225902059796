import { Modal } from "antd";
import React from "react";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import "./ScheduleRemoveConfirm.scss";
import { IAlertInfo } from "../../../AppModels";
import { API } from "../../../services/LookerAPI/LookerAPI";

interface IScheduleRemoveConfirmContainerProps {
    scheduleRemoveUser: IUserInfo;
    refreshData: () => void;
    setAlertInfo: (alertInfo: IAlertInfo) => any;
    setLoading(isLoading: boolean): void;
    exit: () => void;
}

const ScheduleRemoveConfirm: React.FC<IScheduleRemoveConfirmContainerProps> = (props: IScheduleRemoveConfirmContainerProps) => {

    const closeModal = () =>{
        props.exit();
    }

    const removeSchedule = () => {
        props.setLoading(true);
        API.setSchedulePermission(props.scheduleRemoveUser.username, false, scheduleRemoveComplete, scheduleRemoveError);
        props.exit();
      }
    
    const scheduleRemoveComplete = () => {    
        props.setAlertInfo({
            visible: true,
            type: "success",
            message:  `Successfully disabled schedule permissions for '${props.scheduleRemoveUser.displayName}'`,
        });    
        //refreshData will set loading true again, and back to false when complete
        props.refreshData();
    }

    const scheduleRemoveError = () => {   
      props.setLoading(false);     
      props.setAlertInfo({
          visible: true,
          type: "error",
          message: `There was an unexpected error when disabling schedule permissions for '${props.scheduleRemoveUser.displayName}'`,
      });
  }

  return (
    <>
    <Modal
        title={'Remove Scheduling Access from ' + props.scheduleRemoveUser.displayName + '?'}
        visible={true}
        onOk={removeSchedule}
        okText={'Remove Schedule Access'}
        onCancel={closeModal}
        width={650}
    >      
    <>
        <h4>Are you sure you want to remove scheduling permissions from the user "{props.scheduleRemoveUser.displayName}"?</h4>
        <br />
            <div className="modal-user-delete-warning">
                This change will take effect next time the user logs into Data Insights. <br />
                Subsequent runs of any schedules owned by this user will fail. <br />
                This will also remove the user's ability to perform one-time sends of looks/dashboards.
            </div>

    </>
    </Modal>
    </>
  );
};
 
export default ScheduleRemoveConfirm;
  