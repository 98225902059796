// Import necessary dependencies from react and ant design libraries
import React, { useEffect, useState } from "react";
import { BellOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Badge, Popover, Button, Switch, Space, Typography, notification as antdNotification, } from "antd";

// Import other components and styles
import NotificationsMenu from "./Menu/NotificationsMenu";
import "./NotificationsView.scss";
import { INotification } from "../../services/NotificationsManagement/NotificationsModels";
import NotificationDetails from "./Modal/NotificationDetails";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotifications } from "../../store/actions/NotificationActions";
import { IApplicationState } from "../../store/Store";

import { NotificationActionTypes } from "../../store/actions/NotificationActionTypes";
import { NOTIFICATIONS_REFRESH } from "../../Constants";

const { Text } = Typography;

// Define interface for component props
interface IProps {
    className?: string
    refreshNotificationsCount: number;
}

// Define the NotificationsView component as a functional component
const NotificationsView: React.FC<IProps> = ({className, refreshNotificationsCount}) => {

    const dispatch = useDispatch();

    const { username } = useSelector((state: IApplicationState) => state.userAuth);
    const { notifications, error: notificationsError } = useSelector((state: IApplicationState) => state.notifications);
    const [searchText, setSearchText] = React.useState<string>('');
    const [ filteredNotifications, setFilteredNotifications ] = React.useState<INotification[]>([]);

    // State to control modal visibility
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    
    // State to hold currently selected notification for detailed view
    const [selectedNotification, setSelectedNotification] = useState<INotification | null>(null);

    // State to control popover visibility
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    
    const [unreadSwitch, setUnreadSwitch] = useState<boolean>(false);
    const [unreadCount, setUnreadCount] = useState<number>(0);

    // Function to handle opening the modal and update selectedNotification state
    const handleModalOpen = (notification: INotification) => {
        setSelectedNotification(notification);
        setIsModalVisible(true);
        //setIsPopoverOpen(false); // Close popover when modal opens - not desirable
    };
      
    // Function to handle closing the modal and reset selectedNotification state
    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedNotification(null);
    };

    useEffect(() => {
      setUnreadCount(notifications.filter((notifications) => {
        return !notifications.readHistory.some((entry) => {
          return (entry.isRead === true) 
        })
      }).length)
    }, [notifications, username])

    useEffect(() => {
      dispatch(getAllNotifications());
      let idleInterval = window.setInterval(function(){
        dispatch(getAllNotifications());
      }, NOTIFICATIONS_REFRESH);  
      return () =>{
        clearInterval(idleInterval);
      }
    }, [dispatch, refreshNotificationsCount])

    useEffect(() => {
      // Handle window's blur event
      const handleWindowBlur = () => {
          // Get iframe with ID 'looker'
          const lookerIframe = document.getElementById("looker");
          // If 'looker' iframe is the active element, close the popover
          if (document.activeElement === lookerIframe) {
              setIsPopoverOpen(false);
          }
      };
      // Attach blur event listener
      window.addEventListener('blur', handleWindowBlur);
      // Cleanup: remove event listener on unmount or re-run
      return () => {
          window.removeEventListener('blur', handleWindowBlur);
      };
      // Run this useEffect only once
  }, []);

    useEffect(() => {
      if(notifications){
        const notificationsTemp = notifications.filter((notification) => {
          return !unreadSwitch || !notification.readHistory.some((entry) => {
            return (entry.isRead === true) 
          })
        })

        if(searchText.trim().length === 0){
          setFilteredNotifications(notificationsTemp);
        } else {
          setFilteredNotifications(
            notificationsTemp.filter(notification => 
              notification.title.toLowerCase().includes(searchText.toLowerCase()) ||
              notification.summary.toLowerCase().includes(searchText.toLowerCase())
            )
          );
       }
      } else {
        setFilteredNotifications([]);
      }
    }, [notifications, searchText, unreadSwitch]);
 
    useEffect(() => {
      if(notificationsError !== null) {
        switch (notificationsError.type) {
          case NotificationActionTypes.GET_ALL_NOTIFICATIONS: {
            antdNotification.error({
              message: "Error",
              description:
                "There was an error fetching notifications.",
              placement: "topRight",
            })
            break;
          }
        }
      }

    }, [notificationsError, dispatch]);

  return (
    <>
      {/* Popover component for displaying notifications */}
      <Popover
        id='notifications-popover'
        placement="bottomRight"
        title={
          <Space 
          style={{width: '100%', display: 'flex'}}
          direction="horizontal"
          size={0}
          className="notifications-window-header">
            <h2 style={{paddingTop: 10}}>Notifications</h2>
            <div>
              <Space direction="horizontal">
                <Text >Only show unread</Text>
                <Switch 
                checkedChildren={<CheckOutlined />} 
                unCheckedChildren={<CloseOutlined />} 
                onChange={(checked: boolean) => {
                  setUnreadSwitch(checked);
                }}
                />
              </Space>
            </div>
          </Space>
        }
        content={<NotificationsMenu 
                    unreadCount={unreadCount} 
                    notifications={filteredNotifications}
                    handleModalOpen={handleModalOpen}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    setIsPopoverOpen={setIsPopoverOpen}
                />
        }
        trigger="click"
        overlayClassName="popover-content"
        onOpenChange={isModalVisible ? ()=>{} : setIsPopoverOpen}
        open={isPopoverOpen}
      >
        {/* Badge component to display if there are unread notifications */}
        <Badge
          className="notifications-badge"
          dot
          count={unreadCount}
          size="default"
          offset={[-7, 25]}
          overflowCount={10}
        >
          {/* Button component with bell icon */}
          <Button
            className={`${className} notifications-view-button`}
            type="primary"
            shape="circle"
            icon={
              <BellOutlined
                style={{ fontSize: "22px", verticalAlign: "middle" }}
              />
            }
          />
        </Badge>
      </Popover>

      {/* Modal component for displaying notification details */}
      {selectedNotification && (
        <NotificationDetails
            visible={isModalVisible}
            onClose={handleModalClose}
            notification={selectedNotification}
        />
      )}
    </>
  );
};

export default NotificationsView;
