import { ReloadOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Layout, Row, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IAlertInfo, ICustomerSettings } from "../../AppModels";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { getExploreDescriptions, getExploreTileInfo } from "../../services/DocumentationManagement/DocumentationManagement";
import { IExploreTile, ILookerExplore } from "../../services/DocumentationManagement/DocumentationModels";
import { getCustomerSettings, setLastExploreTab } from "../../store/actions/SettingsActions";
import { IApplicationState } from "../../store/Store";
import Looker from "../Looker/Looker";
import ExploreTile from "./ExploreTile/ExploreTile";
import "./ExploreTiles.scss";
import ExploreTileSearch from "./ExploreTileSearch/ExploreTileSearch";

const { TabPane } = Tabs;

interface IProps {
    
}

const ExploreTiles: React.FC<IProps> = (props: IProps) => {

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    const [loading, setLoading] = useState(false);
    const [tooltipLoading, setTooltipLoading] = useState(false);
    const [alertInfo, setAlertInfo] = useState<IAlertInfo>({ visible: false, type: 'warning', message: '', description: '' });
    const [tiles, setTiles] = useState<IExploreTile[]>([]);

    const [tileSearchText, setTileSearchText] = useState<string>('');
    const prevTileSearchText = usePrevious(tileSearchText);

    const [exploreUrl, setExploreUrl] = useState<string>('');
    const [exploreName, setExploreName] = useState<string>('');
    const [exploreDescriptions, setExploreDescriptions] = useState<ILookerExplore[]>([]);
    const [exploreCategories, setExploreCategories] = useState<string[]>([]);
    const [dataMartTabs, setDataMartTabs] = useState<JSX.Element>();

    const customerSettings: ICustomerSettings = useSelector<IApplicationState>(state => state.settings.customerSettings) as ICustomerSettings;
    const customerSettingsError: string = useSelector<IApplicationState>(state => state.settings.customerSettingsError) as string;

    const dataMarts: string[] = useSelector<IApplicationState>(state => state.userAuth.models) as string[];

    const selectedExploreTab: string = useSelector<IApplicationState>(state => state.settings.selectedExploreTab) as string;

    // const responsiveColumnSpans = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 8 }

    const dispatch = useDispatch();

    const handleExploreTiles = async () => {

        const setResult = (result: IExploreTile[]) => {
            setTiles(result)
        }

        const setError = (error: Error) => {
            setAlertInfo({
                visible: true,
                type: 'error',
                message: 'There was an error retrieving the Explores!',
                description: '',
            })
            console.log(error);
        }

        setLoading(true);
        await getExploreTileInfo(setResult, setError);
        setLoading(false);
    }

    const handleLookerDescriptions = async () => {
        const setResult = (result: ILookerExplore[]) => {
            setExploreDescriptions(result)
        }

        const setError = (error: Error) => {
            setAlertInfo({
                visible: true,
                type: 'error',
                message: 'There was an error retrieving the tooltip descriptions!',
                description: '',
            })
            console.log(error);
        }

        setTooltipLoading(true);
        await getExploreDescriptions(setResult, setError);
        setTooltipLoading(false);
    }

    useEffect(() => {
        document.title = "Explore"
        handleExploreTiles();
        handleLookerDescriptions();
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('name') && tiles.length > 0) {
            if (tiles.find(value => value.exploreName === urlParams.get('name').replaceAll("-", "_"))) {

                let exploreName = urlParams.get('name').replaceAll("-", "_");
                let exploreTile = tiles.find(value => value.exploreName === exploreName);
                if (customerSettings && (customerSettings.realTimeAll || ( customerSettings.realTimeExplores && customerSettings.realTimeExplores.has(exploreName)))) {
                    setExploreUrl(exploreTile.rtEmbedUrl);
                } else {
                    setExploreUrl(exploreTile.embedUrl);
                }

                setExploreName(exploreName);

            } else {
                window.history.replaceState({}, null, '/explore');
            }

        }
    }, [tiles, customerSettings])

    useEffect(()=>{
        if(customerSettings==null && customerSettingsError!=null && !alertInfo.visible){
            setAlertInfo({
                visible: true,
                message: <div 
                            onClick={() => {
                                dispatch(getCustomerSettings());
                                setAlertInfo({visible: false, message: '', type: 'error', description: ''}); 
                            }} 
                            className="clickable">
                            There was an error loading Twice Daily configuration and Daily flags might be missing. <u>Click to Retry <ReloadOutlined /></u>
                        </div>,
                type: 'warning',
                description: ''
            })
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerSettings, customerSettingsError, dispatch])

    useEffect(() => {
        if (exploreCategories.length === 0) {
            let categories: string[] = [];
            tiles.forEach(value => {
                if (!categories.includes(value.category)) {
                    categories.push(value.category)
                }
            })
            setExploreCategories(categories);
        }
    }, [tiles, exploreCategories.length])

    useEffect(() => {


        if (exploreCategories.length > 0 && (!dataMartTabs || prevTileSearchText !== tileSearchText) && !tooltipLoading) {
            setDataMartTabs(
                <Tabs defaultActiveKey={selectedExploreTab} onChange={(activeKey) => {
                    setTileSearchText("")
                    dispatch(setLastExploreTab(activeKey))
                }} type="card">
                    {
                        exploreCategories.map((value, index) => {

                            return <TabPane tab={value} key={value}>
                                {   
                                    <>
                                        <Row>
                                            <Col span={24}>
                                                <ExploreTileSearch value={tileSearchText} setSearchText={setTileSearchText}/>
                                            </Col>
                                        </Row>
                                        <Row gutter={[32,32]}>{
                                            tiles.map((val, index) => {
                                                let desc = exploreDescriptions.find(descr => {
                                                    return descr.name === val.exploreName;
                                                });
                                                if (value === val.category && val.exploreLabel.toLowerCase().includes(tileSearchText.toLowerCase())) {
                                                    return <ExploreTile key={index+1} exploreTile={val} tooltip={desc ? desc.description : null} tooltipLoading={tooltipLoading}/>
                                                } else {
                                                    return null
                                                }
                                        })}
                                        </Row>
                                    </>
                                }
                            </TabPane>
                        })
                    }
                </Tabs>
            )
        }
    }, [dispatch, dataMartTabs, tileSearchText, tooltipLoading, exploreDescriptions, prevTileSearchText, tiles, exploreCategories, dataMarts, selectedExploreTab])

    const onAlertClose = () => {
        setAlertInfo({
            visible: false,
            type: 'warning',
            message: '',
            description: ''
        })
    }

    return (
        <>
            { exploreUrl.length > 0 ?
                <>
                    <Row>
                        <Col span={24}>
                            <Link to={'/explore'}>
                                <Button style={{ float: 'left' }} type='link'>{`< Return to Explores`}</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Looker
                                embeddedPath={exploreUrl}
                                windowTitle={`Explore - ${tiles.length > 0 ? tiles.find(value => value.exploreName === exploreName).exploreLabel : ''}`}
                                cssClassOverride='full-height-minus-header-and-explore-dropdown'
                            />
                        </Col>
                    </Row>
                </> :
                <Layout>
                    {loading ? <CustomSpinner /> :
                        <div className="explores-content-area">
                            <Layout.Content>
                                <br />
                                <Row style={{ height: 35.5 }}>
                                    <Col span={24}>
                                        <h3 style={{ float: 'left', margin: 0 }}>Explore</h3>
                                    </Col>
                                </Row>
                                {
                                    alertInfo.visible &&
                                    <Alert
                                        style={{ marginBottom: "24px", width: '100%' }}
                                        type={alertInfo.type}
                                        message={alertInfo.message}
                                        description={alertInfo.description}
                                        closable
                                        onClose={onAlertClose}
                                    />
                                }
                                {dataMartTabs}
                            </Layout.Content>
                        </div>
                    }
                </Layout>
            }
        </>
    );
};

export default ExploreTiles;