import { Alert, Button, Col, Layout, Popconfirm, Row, Space } from "antd";
import React, { useEffect } from "react";
import "./NotificationManagement.scss";
import NotificationManagementModal from "./NotificationDetailsModal/NotificationManagementModal";
import NotificationsTable from "./NotificationTable/NotificationsTable";
import { INotification } from "../../services/NotificationsManagement/NotificationsModels";
import { getAllNotifications } from "../../services/NotificationsManagement/NotificationsManagement";
import { IAlertInfo } from "../../AppModels";
import { API, IApiError } from "../../services/LookerAPI/LookerAPI";
import { ColumnType } from "antd/lib/table";

interface IProps {

}

const NotificationManagement: React.FC<IProps> = (props: IProps) => {

  const [creating, setCreating] = React.useState<boolean>(false);
  const [editor, setEditor] = React.useState<{isEditing: boolean, notification: INotification}>({isEditing: false, notification: null});
  const [notifications, setNotifications] = React.useState<INotification[]>([]);
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const [alertInfo, setAlertInfo] = React.useState<IAlertInfo>({
    type: "error",
    message: "",
    description: "",
    visible: false
  });

  const getAllCurrentNotifications = () => {
    setTableLoading(true);
    getAllNotifications().then(
      (result) => {
        setNotifications(result);
      },
      (error) => {
        setAlertInfo({
          type: "error",
          message: "An unexpected error occurred getting the notifications",
          description: "",
          visible: true
        })
      }
    ).finally(() => setTableLoading(false))
  }

  const toggleModal = (toggle: boolean) => {
    setCreating(false);
    setEditor({
      isEditing: false,
      notification: null
    });
  }

  const confirm = (notificationId: string) => {
    return new Promise((resolve, reject) => {

      const setResult = (result: INotification) => {
        resolve(result);
        getAllCurrentNotifications();
        setAlertInfo({
          type: "success",
          message: "Notification successfully deleted!",
          description: "",
          visible: true
        });
      }

      const setError = (error: IApiError) => {
        reject(error);
        console.log(error)
        setAlertInfo({
          type: "error",
          message: error.errorCode === 403 ? `${error.errorCode} - ${error.status}: ${error.message}` : "An unexpected error occurred deleting the notification",
          description: "",
          visible: true
        });
      }

      API.deleteNotification(notificationId, setResult, setError);
    })
  }

  useEffect(() => {
    getAllCurrentNotifications();
  }, [])

  const columns: ColumnType<INotification>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
      className: 'max-width-text',
      ellipsis: true,
    },
    {
      title: 'Additional Details',
      dataIndex: 'modalInfo',
      key: 'modalInfo',
      className: 'max-width-text',
      ellipsis: true,
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      ellipsis: true,
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity',
      ellipsis: true,
    },
    {
      title: 'Realm',
      dataIndex: 'realm',
      key: 'realm',
      ellipsis: true,
    },
    {
      title: 'User',
      dataIndex: 'realmUsername',
      key: 'realmUsername',
      ellipsis: true,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      ellipsis: true,
    },
    {
      title: 'Modified By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      ellipsis: true,
    },
    {
      title: 'Modified Date',
      dataIndex: 'updatedOn',
      key: 'updatedOn',
      ellipsis: true,
    },
    {
      title: "Actions",
      fixed: 'right',
      render: (text, record) => {
        return (
          <Space direction="horizontal">
            <Button onClick={() => setEditor({
              isEditing: true,
              notification: record
            })}>Edit</Button>
            <Popconfirm
              title="Are you sure? This cannot be undone and Read history will be lost"
              onOpenChange={() => {console.log(record)}}
              onConfirm={() => confirm(record.notificationId as string)}
              >
              <Button type="primary" danger>Delete</Button>
            </Popconfirm>
          </Space>
        )
      },
    }
  ]

  return (
    <>
      <Layout>
        <div className="notif-mgmt-content-area">
          <Layout.Content>
            <br />
            <Space direction="vertical" style={{width: '100%'}}>
              <Row style={{ height: 35.5 }}>
                <Col span={24}>
                  <div className="header">
                    <h3 style={{ float: "left", margin: 0 }}>
                      Notification Management
                    </h3>
                    <Button type="primary" onClick={() => setCreating(true)}>Send Notification</Button>
                  </div>
                </Col>
              </Row>
              { alertInfo.visible &&
                <Row>
                  <Col span={24}>
                    <Alert {...alertInfo} closable onClose={() => setAlertInfo({ ...alertInfo, visible: false })} />
                  </Col>
                </Row>
              }
              <Row>
                <Col span={24}>
                  <NotificationsTable 
                    refreshNotifications={getAllCurrentNotifications}
                    dataSource={notifications}
                    setAlertInfo={setAlertInfo}
                    rowKey={(record) => record.notificationId as string}
                    columns={columns}
                    scroll={{ x: "max-content", y: "calc(100vh - 400px)"}}
                    loading={tableLoading}
                  />
                </Col>
              </Row>
            </Space>
          </Layout.Content>
        </div>
      </Layout>
      { (creating || editor.isEditing) &&
        <NotificationManagementModal 
          open={true}
          title={editor.isEditing ? "Edit Notification" : "Send Notification"}
          width={825}
          onCancel={() => toggleModal(false)}
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
          toggleModal={toggleModal}
          record={editor.notification}
          refreshNotifications={getAllCurrentNotifications}
        />
      }
    </>
  );
};

export default NotificationManagement;
