// Importing necessary modules and types
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { API } from "../../services/LookerAPI/LookerAPI";
import { IClearNotificationError, IGetAllNotifications, INotificationsState, ISetNotificationStatus, ISetNotificationStatusBulk, NotificationActionTypes } from '../actions/NotificationActionTypes';
import { INotification, IReadLogEntry } from "../../services/NotificationsManagement/NotificationsModels";
import { REFRESH_NOTIFICATIONS } from "../../Constants";

/**
 * Function to get the current timezone
 * @returns {string} The current timezone
 */
const getTimezone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Function to get all notifications
 * @returns {Promise<INotification[]>} A promise that resolves with an array of notifications
 */
const getNotifications = (): Promise<INotification[]> => {
    return new Promise((resolve, reject) => {
        try {
            // Regular expression to match the date in the title
            const regexToMatchTitleDate = /- \w+, \w+ \d+, \d{4} \d{2}:\d{2} (AM|PM) \w{3}/;
            const timezone = getTimezone();
            const language = navigator.language;

            // Function to handle the result from the API
            const setResult = (result: INotification[]) => {
                const convertedResult = result?.map(notification => {
                    // Create a Date object from the startDate string
                    const date = new Date(notification.startDate);

                    // Convert the UTC time to the desired timezone
                    const startDateOptions: Intl.DateTimeFormatOptions = { 
                        timeZone: timezone, 
                        year: 'numeric', 
                        month: '2-digit', 
                        day: '2-digit', 
                        hour: '2-digit', 
                        minute: '2-digit', 
                        second: '2-digit', 
                        hourCycle: 'h23',
                    };

                    const titleDateOptions: Intl.DateTimeFormatOptions = { 
                        timeZone: timezone,
                        dateStyle: 'full',
                        timeStyle: 'short'
                    }
                    notification.startDate = date.toLocaleString(language, startDateOptions);
                    notification.title = notification.title.match(regexToMatchTitleDate) != null ? notification.title.replace(regexToMatchTitleDate, " - " + date.toLocaleString(language, titleDateOptions)) : notification.title + " - " + date.toLocaleString(language, titleDateOptions);
                    return notification;
                });
                resolve(convertedResult);
            }
        
            // Function to handle errors from the API
            const setError = (error: Error) => {
                reject(error)
            }
        
            // Call the API to get notifications
            API.getNotifications(setResult, setError);
        } catch (error: unknown) {
            if (error instanceof Error) {
                reject(error);
            } else {
                reject(new Error("An unknown error occurred while retrieving notifications"))
            }
        }
    })
}

/**
 * Function to set the read status of a single notification
 * @param {string} notificationId - The ID of the notification
 * @param {boolean} isRead - The read status to set
 * @returns {Promise<IReadLogEntry>} A promise that resolves with the read log entry
 */
const setNotificationReadStatus = (notificationId: string, isRead: boolean): Promise<IReadLogEntry> => {
    return new Promise((resolve, reject) => {
        try {
            // Function to handle the result from the API
            const setResult = (result: IReadLogEntry) => {
                resolve(result);
                // Store the notification ID and current time in local storage
                localStorage.setItem(REFRESH_NOTIFICATIONS, JSON.stringify({notificationIds: [notificationId], time: Date.now()}));
            }
        
            // Function to handle errors from the API
            const setError = (error: Error) => {
                reject(error)
            }
        
            // Call the API to set the notification status
            API.setNotificationStatus(notificationId, isRead, setResult, setError);
        } catch (error: unknown) {
            if (error instanceof Error) {
                reject(error);
            } else {
                reject(new Error("An unknown error occurred while toggling notification"))
            }
        }
    })
}

/**
 * Function to set the read status of multiple notifications
 * @param {string[]} notificationIds - The IDs of the notifications
 * @param {boolean} isRead - The read status to set
 * @returns {Promise<IReadLogEntry[]>} A promise that resolves with an array of read log entries
 */
const setNotificationReadStatusBulk = (notificationIds: string[], isRead: boolean): Promise<IReadLogEntry[]> => {
    return new Promise((resolve, reject) => {
        try {
            // Function to handle the result from the API
            const setResult = (results: IReadLogEntry[]) => {
                resolve(results);
                // Store the notification IDs and current time in local storage
                localStorage.setItem(REFRESH_NOTIFICATIONS, JSON.stringify({notificationIds: notificationIds, time: Date.now()}));
            }
        
            // Function to handle errors from the API
            const setError = (error: Error) => {
                reject(error);
            }
        
            // Call the API to set the notification statuses
            API.setNotificationStatusBulk(notificationIds, isRead, setResult, setError);
        } catch (error: unknown) {
            if (error instanceof Error) {
                reject(error);
            } else {
                reject(new Error("An unknown error occurred while toggling notifications"));
            }
        }
    });
}

// Exporting the action creators for getting all notifications, setting notification status, setting bulk notification status, and clearing notifications error

/**
 * Action creator for getting all notifications
 * @returns {ThunkAction} A redux thunk action
 */
export const getAllNotifications: ActionCreator<ThunkAction<
    Promise<AnyAction>,
    INotificationsState,
    null,
    IGetAllNotifications
>> = () => {

    return async (dispatch: Dispatch) => {

        let action: IGetAllNotifications = {
            type: NotificationActionTypes.GET_ALL_NOTIFICATIONS,
            notifications: [],
            error: null
        }

        // Call the getNotifications function and dispatch the result
        await getNotifications().then(
            (result: INotification[]) => {
                action = {
                    type: NotificationActionTypes.GET_ALL_NOTIFICATIONS,
                    notifications: result,
                    error: null
                }
            },
            (error: Error) => {
                action = {
                    type: NotificationActionTypes.GET_ALL_NOTIFICATIONS,
                    notifications: [],
                    error: {
                        type: NotificationActionTypes.GET_ALL_NOTIFICATIONS,
                        error: error
                    }
                }
            }
        )
        return dispatch(action);
    };
};

/**
 * Action creator for setting the read status of a single notification
 * @param {string} notificationId - The ID of the notification
 * @param {boolean} isRead - The read status to set
 * @returns {ThunkAction} A redux thunk action
 */
export const setNotificationStatus: ActionCreator<ThunkAction<
    Promise<AnyAction>,
    INotificationsState,
    null,
    ISetNotificationStatus
>> = (notificationId: string, isRead: boolean) => {

    return async (dispatch: Dispatch) => {

        let action: ISetNotificationStatus = {
            type: NotificationActionTypes.SET_NOTIFICATION_STATUS,
            readEntry: null,
            error: null
        }

        // Call the setNotificationReadStatus function and dispatch the result
        await setNotificationReadStatus(notificationId, isRead).then(
            (result: IReadLogEntry) => {
                action = {
                    type: NotificationActionTypes.SET_NOTIFICATION_STATUS,
                    readEntry: result,
                    error: null
                }
            },
            (error: Error) => {
                action = {
                    type: NotificationActionTypes.SET_NOTIFICATION_STATUS,
                    readEntry: null,
                    error: {
                        type: NotificationActionTypes.SET_NOTIFICATION_STATUS,
                        error: error
                    }
                }
            }
        )
        return dispatch(action);
    };
};

/**
 * Action creator for setting the read status of multiple notifications
 * @param {string[]} notificationIds - The IDs of the notifications
 * @param {boolean} isRead - The read status to set
 * @returns {ThunkAction} A redux thunk action
 */
export const setNotificationStatusBulk: ActionCreator<ThunkAction<
    Promise<AnyAction>,
    INotificationsState,
    null,
    ISetNotificationStatus
>> = (notificationIds: string[], isRead: boolean) => {

    return async (dispatch: Dispatch) => {

        let action: ISetNotificationStatusBulk = {
            type: NotificationActionTypes.SET_NOTIFICATION_STATUS_BULK,
            readEntries: [],  // Updated to be an array
            error: null
        }

        // Call the setNotificationReadStatusBulk function and dispatch the result
        await setNotificationReadStatusBulk(notificationIds, isRead).then(
            (results: IReadLogEntry[]) => {  // Handling array of results
                action = {
                    type: NotificationActionTypes.SET_NOTIFICATION_STATUS_BULK,
                    readEntries: results,  // Updated to handle array of results
                    error: null
                }
            },
            (error: Error) => {
                action = {
                    type: NotificationActionTypes.SET_NOTIFICATION_STATUS_BULK,
                    readEntries: [],  // No results in case of error
                    error: {
                        type: NotificationActionTypes.SET_NOTIFICATION_STATUS_BULK,
                        error: error
                    }
                }
            }
        )
        return dispatch(action);
    };
};

/**
 * Action creator for clearing notifications error
 * @returns {ThunkAction} A redux thunk action
 */
export const clearNotificationsError: ActionCreator<ThunkAction<
    Promise<AnyAction>,
    INotificationsState,
    null,
    IClearNotificationError
>> = () => {

    return async (dispatch: Dispatch) => {

        let action: IClearNotificationError = {
            type: NotificationActionTypes.CLEAR_ERROR,
        }
        return dispatch(action);
    };
};