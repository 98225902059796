import { AuthState } from "@okta/okta-auth-js";
import axios from "axios";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { LocalStorageUserInfo } from "../../AppModels";
import { IDLE_LOGOUT_TIME, LOCAL_STORAGE_FACILITY_SELECTIONS, LOCAL_STORAGE_LAST_ACTIVITY, LOCAL_STORAGE_USER, LOCAL_STORAGE_OKTA_REGULAR_FLAG, LOCAL_STORAGE_OKTA_SUPPORT_REALM, LOCAL_STORAGE_OKTA_SELECTED_USER } from "../../Constants";
import { getLicensedUsersInfo } from "../../services/AdminManagement/EditUsersManagement/EditUsersManagement";
import { ILicenseInfo } from "../../services/AdminManagement/EditUsersManagement/EditUsersModels";
import { Role } from "../../services/RoleManagement/RoleModels";
import { IApplicationState } from "../Store";
import { IFederatedPromptCredentials, IFederatedPromptState, ISetOktaErrorMessage, IUserAuthState, IUserCpsPremiumAction, IUserLoginAction, IUserLoginCredentials, IUserLogoutAction, IUserLookupAction, IUserOktaLoginAction, IUserPropagateLoginAction, IUserPropagateLogoutAction, UserAuthActionTypes } from "./UserAuthTypes";


const serverHost = process.env.REACT_APP_DATA_INSIGHTS_API_BASE_URL;

const loginPath = "/login";
const logoutPath = "/logout";
const oktaLoginPath = "/okta_login";
const lookupPath = "/current_user";
const loginUrl = serverHost + loginPath;
const logoutUrl = serverHost + logoutPath;
const lookupUrl = serverHost + lookupPath;
const oktaLoginUrl = serverHost + oktaLoginPath;


const postLoginForm = async (
  credentials: IUserLoginCredentials
): Promise<IUserLoginAction> => {
  let headers = {
    "X-OpenAM-Username": credentials.username,
    "X-OpenAM-Password": credentials.password,
    "Authentication-Realm": credentials.realm,
    "Support-User-Login-Flag": credentials.support,
    "Support-User-SOC-Only": credentials.scopeOfControlOnly,
    "Support-User-Force": credentials.forceFlag
  };
  if(credentials.selectedUser){
    headers["Support-User-Selected-Id"] = credentials.selectedUser;
    console.log(credentials.selectedUser)
  }
  let authorizedForApplication = false;
  let errorMessage: string = null;
  let isLoggedIn = false;
  let isSupport = false;
  let expiration: number = null;
  let username: string = null;
  let realm: string = null;
  let roleMapping: Map<Role, boolean> = null;
  let models: string[] = null;
  let sudoUser: string = null;
  let scopeOfControlUser: string = null;
  let printDesignerRole: string = null;
  let oktaLogin: boolean = false;
  let federatedLogin: boolean = false;
  let loginId: string = null;

  await axios
    .post<any>(loginUrl, null, { headers: headers, withCredentials: true })
    .then((response) => {
      console.log("response: ", response);
      if (response.data.success) {
        isLoggedIn = true;
        username = response.data.userId;
        realm = response.data.realm;
        isSupport = response.data.support;
        expiration = response.data.expiration;
        sudoUser = response.data.sudoUser;
        scopeOfControlUser = response.data.scopeOfControlUser;
        roleMapping = transformRoleArrayToRoleMap(response.data.roles);
        roleMapping.forEach((value, key) => { if(value){authorizedForApplication = true;} });
        models = response.data.models;
        printDesignerRole = response.data.printDesignerRole;
        oktaLogin = response.data.oktaLogin;
        federatedLogin = response.data.federatedLogin;
        loginId = response.data.loginId;
      } else {
        errorMessage = "Unexpected error from Login Request.";
      }
      if(!authorizedForApplication){
        isLoggedIn = false;
        isSupport = false;
        username = null;
        realm = null;
        errorMessage = "User has not been assigned roles for this application.";
      } else { 
        const userInfo : LocalStorageUserInfo = {
          loggedIn: true,
          username: username,
          realm: realm,
          isSupport: isSupport,
          roles: response.data.roles,
          models: response.data.models,
          expiration: expiration,
          updated: new Date(),
          sudoUser: sudoUser,
          scopeOfControlUser: scopeOfControlUser,
          printDesignerRole: printDesignerRole,
          oktaLogin: oktaLogin,
          federatedLogin: federatedLogin,
          loginId: loginId,
        }
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(userInfo));
      }
    })
    .catch((ex) => {
      if(ex && ex.response && ex.response.status === 401){
        if(ex.response.data && ex.response.data.message){
          errorMessage = ex.response.data.message;
        } else {
          errorMessage = "Login request received Unauthorized response.";
        }
      } else if(credentials.support && ex && ex.response && ex.response.status === 400
        && ex.response.data && ex.response.data.message && ex.response.data.message === 'NEW_CLIENT_WARNING'){
          errorMessage = 'Warning: New Realm';
      } else {
        errorMessage = "An unexpected error occurred.";
      }
      console.log("errorMessage: ", ex?.response?.data?.message);
    });
  return {
    type: UserAuthActionTypes.LOGIN,
    credentials: credentials,
    errorMessage: errorMessage,
    isLoggedIn: isLoggedIn,
    roleMapping: roleMapping,
    models: models,
    username: username,
    realm: realm,
    isSupport: isSupport,
    expiration: expiration,
    sudoUser: sudoUser,
    scopeOfControlUser: scopeOfControlUser,
    printDesignerRole: printDesignerRole,
    oktaLogin: oktaLogin,
    federatedLogin: federatedLogin,
    loginId: loginId
  };
};

const processOktaLogin = async(
  authState: AuthState,
  additionalInfoForm?: IFederatedPromptCredentials
): Promise<IUserOktaLoginAction>=>{
  let headers = {
    "X-Okta-Access-Token": authState.accessToken.accessToken
  };
  if(additionalInfoForm){
    headers["Federated-Additional-Info"] = true;
    headers["Federated-User-Existing-User"] = additionalInfoForm.existingUser === "yes";
    headers["X-OpenAM-Username"] = additionalInfoForm.username;
    headers["X-OpenAM-Password"] = additionalInfoForm.password;
  }
  const inputSupportFlag = (localStorage.getItem(LOCAL_STORAGE_OKTA_SUPPORT_REALM) != null);
  if(inputSupportFlag){
    headers["Support-User-Login-Realm"] = localStorage.getItem(LOCAL_STORAGE_OKTA_SUPPORT_REALM);
  }
  const inputSelectedUser = localStorage.getItem(LOCAL_STORAGE_OKTA_SELECTED_USER);
  headers["Support-User-Login-Flag"] = inputSupportFlag;
  if(inputSelectedUser){
    headers["Support-User-Selected-Id"] = inputSelectedUser;
  }
  let authorizedForApplication = false;
  let errorMessage: string = null;
  let isLoggedIn = false;
  let isSupport = false;
  let expiration: number = null;
  let username: string = null;
  let realm: string = null;
  let roleMapping: Map<Role, boolean> = null;
  let models: string[] = null;
  let sudoUser: string = null;
  let scopeOfControlUser: string = null;
  let printDesignerRole: string = null;
  let oktaLogin: boolean = false;
  let federatedLogin: boolean = false;
  let federatedPrompt: IFederatedPromptState = null;
  let loginId: string = null;

  await axios
  .post<any>(oktaLoginUrl, null, { headers: headers, withCredentials: true })
  .then((response) => {
    console.log("response: ", response);
    if(response.data.requireMoreInformation){
        federatedPrompt = {
          existingCustomer: response.data.existingCustomer,
          erxOpenAm: response.data.erxOpenAm,
          errorMessage: response.data.authResponse ? response.data.authResponse.message : null,
          realm: response.data.realm
        };
    } else {
      //In Okta Login case, Our regular Auth Response is nested in some federated metadata
      const authResponse = response.data.authResponse;
      if (authResponse.success) {
        isLoggedIn = true;
        username = authResponse.userId;
        realm = authResponse.realm;
        isSupport = authResponse.support;
        expiration = authResponse.expiration;
        sudoUser = authResponse.sudoUser;
        scopeOfControlUser = authResponse.scopeOfControlUser;
        roleMapping = transformRoleArrayToRoleMap(authResponse.roles);
        roleMapping.forEach((value, key) => { if(value){authorizedForApplication = true;} });
        models = authResponse.models;
        printDesignerRole = authResponse.printDesignerRole;
        oktaLogin = authResponse.oktaLogin;
        federatedLogin = authResponse.federatedLogin;
        loginId = authResponse.loginId;
      } else {
        errorMessage = "Unexpected error from Login Request.";
      }
      if(!authorizedForApplication){
        isLoggedIn = false;
        isSupport = false;
        username = null;
        realm = null;
        errorMessage = "User has not been assigned roles for this application.";
      } else { 
        const userInfo : LocalStorageUserInfo = {
          loggedIn: true,
          username: username,
          realm: realm,
          isSupport: isSupport,
          roles: authResponse.roles,
          models: models,
          expiration: expiration,
          updated: new Date(),
          sudoUser: sudoUser,
          scopeOfControlUser: scopeOfControlUser,
          printDesignerRole: printDesignerRole,
          oktaLogin: oktaLogin,
          federatedLogin: federatedLogin,
          loginId: loginId,
        }
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(userInfo));
      }
    }
  })
  .catch((ex) => {
    if(ex && ex.response && ex.response.status === 401){
      if(ex.response.data && ex.response.data.authResponse && ex.response.data.authResponse.message){
        errorMessage = ex.response.data.authResponse.message;
      } else {
        errorMessage = "Login request received Unauthorized response.";
      }
    } else {
      errorMessage = "An unexpected error occurred.";
    }
    console.log("errorMessage: ", ex?.response?.data?.message);
  });
  return {
    type: UserAuthActionTypes.OKTA_LOGIN,
    errorMessage: errorMessage,
    isLoggedIn: isLoggedIn,
    roleMapping: roleMapping,
    models: models,
    username: username,
    realm: realm,
    isSupport: isSupport,
    expiration: expiration,
    sudoUser: sudoUser,
    scopeOfControlUser: scopeOfControlUser,
    printDesignerRole: printDesignerRole,
    oktaLogin: oktaLogin,
    federatedLogin: federatedLogin,
    federatedPrompt: federatedPrompt,
    loginId: loginId,
  };
}

const lookupUserRequest = async (): Promise<IUserLookupAction> => {

  let authorizedForApplication = false;
  let errorMessage: string = null;
  let isLoggedIn = false;
  let isSupport = false;
  let username: string = null;
  let realm: string = null;
  let roleMapping: Map<Role, boolean> = null;
  let models: string[] = null;
  let expiration: number = null;
  let sudoUser: string = null;
  let scopeOfControlUser: string = null;
  let printDesignerRole: string = null;
  let oktaLogin: boolean = false;
  let federatedLogin: boolean = false;
  let loginId: string = null;

  await axios
    .get<any>(lookupUrl, { withCredentials: true })
    .then((response) => {
      console.log("response: ", response);
      if (response.data.success) {
        const lastActivity = localStorage.getItem(LOCAL_STORAGE_LAST_ACTIVITY);
        if(lastActivity !== null && ( (Date.now() - Number(lastActivity)) > IDLE_LOGOUT_TIME ) ){         
            //If there is a valid security cookie, but the user has been idle long enough for timeout, 
            // peform logout instead. 
            console.log('Idle Logout Detected')
            postLogout(response.data.oktaLogin); 
        } else {
          console.log("Current User Found");
          isLoggedIn = true;
          username = response.data.userId;
          realm = response.data.realm;
          isSupport = response.data.support;
          expiration = response.data.expiration;
          sudoUser = response.data.sudoUser;
          scopeOfControlUser = response.data.scopeOfControlUser;
          roleMapping = transformRoleArrayToRoleMap(response.data.roles);
          roleMapping.forEach((value, key) => { if(value){authorizedForApplication = true;} });
          if(!authorizedForApplication){
            isLoggedIn = false;
            username = null;
            errorMessage = "User has not been assigned roles for this application.";
          }
          models = response.data.models;
          printDesignerRole = response.data.printDesignerRole;
          oktaLogin = response.data.oktaLogin;
          federatedLogin = response.data.federatedLogin;
          loginId = response.data.loginId;
        }
      } 
    })
    .catch((ex) => {
      if(ex && ex.response && ex.response.status === 401){
        localStorage.removeItem(LOCAL_STORAGE_FACILITY_SELECTIONS);
        localStorage.removeItem(LOCAL_STORAGE_LAST_ACTIVITY);
        localStorage.removeItem(LOCAL_STORAGE_OKTA_REGULAR_FLAG);
        localStorage.removeItem(LOCAL_STORAGE_OKTA_SUPPORT_REALM);
        localStorage.removeItem(LOCAL_STORAGE_OKTA_SELECTED_USER);
      }
      console.log("Lookup User Failed");
    });
  return {
    type: UserAuthActionTypes.LOOKUP,
    errorMessage: errorMessage,
    isLoggedIn: isLoggedIn,
    roleMapping: roleMapping,
    models: models,
    username: username,
    realm: realm,
    isSupport: isSupport,
    expiration: expiration,
    checkForUserComplete: true,
    sudoUser: sudoUser,
    scopeOfControlUser: scopeOfControlUser,
    printDesignerRole: printDesignerRole,
    oktaLogin: oktaLogin,
    federatedLogin: federatedLogin,
    loginId: loginId,
  };
};

const postLogout = async (oktaLogin: boolean): Promise<IUserLogoutAction> => {
  await axios
   .post<any>(logoutUrl, null, { withCredentials: true })
    .then((response) => {
      console.log("logout response: ", response);
    })
    .catch((ex) => {
      console.log("logout errorMessage: ", ex?.response?.data?.message);
    });
  return {
    type: UserAuthActionTypes.LOGOUT,
    oktaLogout: oktaLogin
  };
 };

const transformRoleArrayToRoleMap = (roles: string[]) => {
  let roleMapping: Map<Role, boolean> = new Map([
    [ Role.ERX_DI_VIEW_ROLE, false ],
    [ Role.ERX_DI_EXPLORE_ROLE, false ],
    [ Role.ERX_DI_ADMIN_ROLE, false ],
    [ Role.ERX_DI_SUPPORT_ROLE, false ],
    [ Role.APS_CUSTOMER_ADMIN, false ],
  ]);
  let role;
  if(roles){
    for(role of roles){
      if(roleMapping.has(role)){
        roleMapping.set(role, true);
      }
    }
  }
  return roleMapping;
}

export const postLogin: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IUserAuthState,
  null,
  IUserLoginAction
>> = (credentials: IUserLoginCredentials) => {
  return async (dispatch: Dispatch) => {
    const action = await postLoginForm(credentials);
    return dispatch(action);
  };
};

export const postOktaLogin: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IUserAuthState,
  null,
  IUserOktaLoginAction
>> = (authState: AuthState, formValues?: IFederatedPromptCredentials) => {
  return async (dispatch: Dispatch) => {
    const action = await processOktaLogin(authState, formValues);
    return dispatch(action);
  };
};

export const logout: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IApplicationState,
  null,
  IUserLogoutAction
>> = () => {
  return async (dispatch: Dispatch, getState: () => IApplicationState) => {
    const oktaLogin = getState().userAuth.oktaLogin;
    const action = await postLogout(oktaLogin);
    return dispatch(action);
  };
};

export const lookupUser: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IUserAuthState,
  null,
  IUserLookupAction
>> = () => {
  return async (dispatch: Dispatch) => {
    const action = await lookupUserRequest();
    return dispatch(action);
  };
};

export const propagateLogout: ActionCreator<ThunkAction<
    Promise<AnyAction>,
    IUserAuthState,
    null,
   IUserPropagateLogoutAction
>> = () => {
  return async (dispatch: Dispatch) =>
    dispatch({
     type: UserAuthActionTypes.PROPAGATE_LOGOUT,
  });
};

export const propagateLogin: ActionCreator<ThunkAction<
    Promise<AnyAction>,
    IUserAuthState,
    null,
    IUserPropagateLoginAction
>> = (userInfo: LocalStorageUserInfo) => {
  console.log(userInfo.roles)
  return async (dispatch: Dispatch) =>
    dispatch({
        type: UserAuthActionTypes.PROPAGATE_LOGIN,
        isLoggedIn: userInfo.loggedIn,
        errorMessage: null,
        roleMapping:  transformRoleArrayToRoleMap(userInfo.roles),
        models: userInfo.models,
        username: userInfo.username,
        realm: userInfo.realm,
        isSupport: userInfo.isSupport,
        expiration: userInfo.expiration,
        sudoUser: userInfo.sudoUser,
        scopeOfControlUser: userInfo.scopeOfControlUser,
        printDesignerRole: userInfo.printDesignerRole,
        oktaLogin: userInfo.oktaLogin,
        federatedLogin: userInfo.federatedLogin,
        loginId: userInfo.loginId,
  });
};

export const setErrorMessageFromOkta: ActionCreator<ThunkAction<
    Promise<AnyAction>,
    IUserAuthState,
    null,
    ISetOktaErrorMessage
>> = (errorMessage: string) => {
  console.log('redux action ' + errorMessage);
  return async (dispatch: Dispatch) =>
    dispatch({
        type: UserAuthActionTypes.SET_ERROR_FROM_OKTA,
        errorMessage: errorMessage
  });
};

export const cpsPremiumStatus: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IUserAuthState,
  null,
  IUserCpsPremiumAction
>> = () => {
  return async (dispatch: Dispatch) => {

    let cpsPremiumEnabled: boolean = false;
    const setResult = (result: ILicenseInfo) => {
      cpsPremiumEnabled = result.contractInfo.cpsPremiumActive
    }
  
    const setError = (error: Error) => {
      console.log(error);
    }
  
    await getLicensedUsersInfo(setResult, setError);

    return dispatch({
      type: UserAuthActionTypes.CPS_PREMIUM,
      cpsPremiumEnabled: cpsPremiumEnabled
    });
  }
};