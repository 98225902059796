import { Alert, Col, Divider, Layout, Row, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { IAlertInfo } from "../../AppModels";
import { IChildInfo, IContentDisplayTypes, IDashboardInfo, IDisplayFolderItem, IDisplayFolderItemType, IFolderInfo, ILookInfo, IRootFolderIds, ReportsAction } from "../../services/FolderManagement/FolderModels";
import { IApplicationState } from "../../store/Store";
import ReportsFolderContainer from "./ReportsCardContainer/ReportsFolderContainer/ReportsFolderContainer";
import ReportsContentContainer from "./ReportsContentContainer/ReportsContentContainer";
import ReportsFolderItemOptionsMenu from "./ReportsFolderItemOptionsMenu/ReportsFolderItemOptionsMenu";
import ReportsFolderNewButton from "./ReportsFolderNewButton/ReportsFolderNewButton";
import { Role, Permission } from "../../services/RoleManagement/RoleModels";
import { isAuthorized } from "../../services/RoleManagement/RoleManagement";
import { getFolderEditPermissions } from "../../services/AdminManagement/FolderPermissionsManagement/FolderPermissionsManagement"
import { IEditFolderPermissions } from "../../services/AdminManagement/FolderPermissionsManagement/FolderPermissionsModels";
import { API } from "../../services/LookerAPI/LookerAPI";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IProps {
  item: IDisplayFolderItem;
  rootFolders: IRootFolderIds;
  folderRefreshCount: number;
  alert: IAlertInfo;
  clearAlert: () => void;
  showAlert: (alert: IAlertInfo) => void;
  
  roleMapping: Map<Role, boolean>;
  setAction: (action: ReportsAction) => void;
  goToItem: (item: IDisplayFolderItem) => void;
}

const ReportsFolder: React.FC<IProps> = ({ item, goToItem, alert, clearAlert, showAlert,
  setAction, rootFolders, folderRefreshCount, roleMapping }) => {
  const [folder, setFolder] = React.useState<IFolderInfo>(null);
  const [children, setChildren] = React.useState<IChildInfo[]>(null);
  const [looks, setLooks] = React.useState<ILookInfo[]>(null);
  const [dashboards, setDashboards] = React.useState<IDashboardInfo[]>(null);
  const [showFolderAdd, setShowFolderAdd] = React.useState<boolean>(false);
  const [showDashboardAdd, setShowDashboardAdd] = React.useState<boolean>(false);
  const [showFolderOptions, setShowFolderOptions] = React.useState<boolean>(false);
  const [showMove, setShowMove] = React.useState<boolean>(false);
  const [editFolderPermissions, setEditFolderPermissions] = React.useState<IEditFolderPermissions>({userCanDeleteFolder: false, userCanEditContent: false});
  const [loadingStates, setLoadingStates] = React.useState<{loadingChildren: boolean, loadingDashboards: boolean, loadingLooks: boolean}>({
    loadingChildren: false,
    loadingDashboards: false,
    loadingLooks: false
  })
  const serverHost = process.env.REACT_APP_DATA_INSIGHTS_API_BASE_URL;
  const imageUrlPrefix = serverHost + "/looker/content/image?imagePath=";

  const resetContent = () => {
    setChildren(null);
    setDashboards(null);
    setLooks(null);
  }
 
  const fetchData = React.useCallback(async () => {

    const setError = (error: Error) => {
      if(error.name === 'WARN'){
        showAlert({
          type: "error",
          message: error.message
        }) 
      } else {
          showAlert({
            type: "error",
            message: "An unexpected error occurred loading folder"
          })
        }
    }

    try {
      setLoadingStates({loadingChildren: true, loadingDashboards: true, loadingLooks: true});
      
      await API.getFolder(item.id, "id,name,parent_id", (folderData) => {
        setFolder(prevFolder => {
          return {
            ...prevFolder,
            id: folderData["id"],
            name: item.name,
            parent_id: folderData["parent_id"],
          };
        });
      }, setError);
  
      const fetchChildren = API.getFolderChildren(item.id, "id,name,created_at,parent_id", (childrenResult) => {
        let newChildren: IChildInfo[] = [];
        childrenResult.forEach((x: object) => {
          const child: IChildInfo = {
            id: x["id"],
            name: (x["name"].indexOf('@TeamShared') !== -1) ? 'Shared Reports' : x["name"],
            created_at: x["created_at"],
          };
          newChildren.push(child);
        });
        setChildren(newChildren);
      }, setError);
  
      const fetchDashboards = API.getFolderDashboards(item.id, "id,title,embed_url,view_count,favorite_count,user_id,created_at,updated_at,image_embed_url", (dashboardsResult) => {
        let newDashboards: IDashboardInfo[] = [];
        dashboardsResult.forEach((x: object) => {
          let imagePath = null;
          if (x["image_embed_url"]) {
            imagePath = imageUrlPrefix + x["image_embed_url"];
          }
          const dashboard: IDashboardInfo = {
            id: x["id"],
            title: x["title"],
            embed_url: x["embed_url"],
            image_embed_url: imagePath,
            view_count: x["view_count"],
            favorite_count: x["favorite_count"],
            user_id: x["user_id"],
            created_at: x["created_at"],
          };
          newDashboards.push(dashboard);
        });
        setDashboards(newDashboards);
      }, setError);
  
      const fetchLooks = API.getFolderLooks(item.id, "id,title,embed_url,view_count,favorite_count,user_id,created_at,updated_at,image_embed_url", (looksResult) => {
        let newLooks: ILookInfo[] = [];
        looksResult.forEach((x: object) => {
          let imagePath = null;
          if (x["image_embed_url"]) {
            imagePath = imageUrlPrefix + x["image_embed_url"];
          }
          const look: ILookInfo = {
            id: x["id"],
            title: x["title"],
            embed_url: x["embed_url"],
            image_embed_url: imagePath,
            view_count: x["view_count"],
            favorite_count: x["favorite_count"],
            user_id: x["user_id"],
            updated_at: x["updated_at"],
          };
          newLooks.push(look);
        });
        setLooks(newLooks);
      }, setError);
  
      await Promise.all([fetchChildren, fetchDashboards, fetchLooks]);
  
      setLoadingStates(prevState => ({...prevState, loadingChildren: false, loadingDashboards: false, loadingLooks: false}));
      
    } catch(error) {
      setError(error)
    }
  }, [item.id, imageUrlPrefix, item.name, showAlert]);
  
  React.useEffect(() => {
    fetchData();
  }, [fetchData, folderRefreshCount]);
  



  React.useEffect(() => {
    setFolder(prev => {
        return {
          ...prev,
          children: children,
          dashboards: dashboards,
          looks: looks
        }
      })
  }, [children, dashboards, looks, item.name])

  React.useEffect(() => {

    const setResult = (result: IEditFolderPermissions) => {
      setEditFolderPermissions(result);
    }

    const setError = (error: Error) => {
      showAlert({
        type: 'error',
        message: 'An unexpected error occurred loading folder actions'
      });
    }

    if (folder?.id) {
      getFolderEditPermissions(folder.id, folder.parent_id, setResult, setError);
    }

  }, [folder?.id, folder?.parent_id, showAlert])
  
  // Production code should allow the user to name the folder, but not allow duplicate
  // names within the parent folder, and the folder name "TeamShared" is reserved for 
  // system use.

  React.useEffect(()=>{
    if(folder && folder.id){
      //Show New Folder and Dashboard buttons?
      if(folder.id === rootFolders.teamRootId){
        setShowFolderAdd(isAuthorized(roleMapping, Permission.MANAGE_FOLDER_PERMISSIONS));
        setShowDashboardAdd(false);
      } else {
        setShowFolderAdd(isAuthorized(roleMapping, Permission.EDIT_GROUPED_REPORTS));
        setShowDashboardAdd(isAuthorized(roleMapping, Permission.EDIT_GROUPED_REPORTS));
      }
      //Show Folder Options button?
      if(folder.id === rootFolders.teamRootId || folder.id === rootFolders.teamSharedRootId || folder.id === rootFolders.personalRootId ){
        setShowFolderOptions(false);
        setShowMove(false);
      } else if(folder.parent_id === rootFolders.teamRootId){
        setShowFolderOptions(isAuthorized(roleMapping, Permission.MANAGE_FOLDER_PERMISSIONS));
        setShowMove(false);
      } else {
        setShowFolderOptions(isAuthorized(roleMapping, Permission.EDIT_GROUPED_REPORTS));
        setShowMove(isAuthorized(roleMapping, Permission.EDIT_GROUPED_REPORTS));
      }
    }
  }, [folder, roleMapping, rootFolders]);

  useEffect(() => {
    resetContent()
  }, [item])



  return (
    <>
      {
        (showFolderAdd || showDashboardAdd || showFolderOptions) && 
        <div 
          className="invert-colors-on-dropdown" 
          style={{ display: "inline", 
            position: "absolute", 
            right: "20px",
            top: "5px",}}>
          { (folder) && item.id === folder.id && editFolderPermissions.userCanEditContent &&
            <>
              <ReportsFolderNewButton 
                setAction={setAction}
                folderInfo={folder}
                showNewDashboardButton={showDashboardAdd}
                showNewFolderButton={showFolderAdd}
              />
              {folder && showFolderOptions &&
                <ReportsFolderItemOptionsMenu 
                  item={{
                    type: IDisplayFolderItemType.FOLDER,
                    name: folder.name,
                    id: folder.id }}
                  folderInfo={folder}
                  setAction={setAction}
                  rootFolders={rootFolders}
                  roleMapping={roleMapping}
                  showMove={showMove}
                  userCanDeleteFolder={editFolderPermissions.userCanDeleteFolder}
                /> 
              }
            </>   
          }
        </div>
      }
      <Divider />
      <Layout.Content 
        style = {{
          padding: "8px 64px 4px 64px"
        }}
      > 
        { alert &&
          <Alert 
            style={{ marginBottom: "24px"  }}
            type={alert.type}
            message={alert.message}  
            description={alert.description}
            closable 
            afterClose={clearAlert} 
          />
        }
        {(folder) && item.id === folder.id
        && 
          
            <>
            <Row>
              { (loadingStates.loadingChildren || (folder.children && folder.children.length > 0)) &&
                <Col span={14}>
                  <h3>Folders</h3>
                </Col>
              } 
              {(folder.children && folder.children.length > 0) ?
                (
                    <ReportsFolderContainer 
                      type={IDisplayFolderItemType.FOLDER}
                      data={folder.children}
                      goToItem={goToItem}
                      resetContent={resetContent}
                    />
                ) :  (loadingStates.loadingChildren === true) &&
                (
                  <Row>
                    <Col span={24}>                    
                      <Spin indicator={antIcon}>
                        <div style={{height: '200px'}}/>
                      </Spin>
                    </Col>
                  </Row>
                )
              }
            </Row>
            <Row>
              { (loadingStates.loadingDashboards || (folder.dashboards && folder.dashboards.length > 0)) &&
                <Col span={14}>
                  <h3>Dashboards</h3>
                </Col>
              } 
              {(folder.dashboards && folder.dashboards.length > 0) ?
                (
                  <ReportsContentContainer 
                    type={IDisplayFolderItemType.DASHBOARD}
                    data={folder.dashboards}
                    display={IContentDisplayTypes.GROUP}
                    rootFolders={rootFolders}
                    folder={folder}
                    setAction={setAction}
                    goToItem={goToItem}
                    editFolderPermissions={editFolderPermissions}
                  />
                ) :  (loadingStates.loadingDashboards === true) &&
                (
                  <Row>
                    <Col span={24}>                    
                      <Spin indicator={antIcon}>
                        <div style={{height: '200px'}}/>
                      </Spin>
                    </Col>
                  </Row>
                )
              }
            </Row>
            <Row>
              { (loadingStates.loadingLooks || (folder.looks && folder.looks.length > 0)) &&
                <Col span={14}>
                  <h3>Looks</h3>
                </Col>
              } 
              {(folder.looks && folder.looks.length > 0) ?
                (
                  <ReportsContentContainer 
                    type={IDisplayFolderItemType.LOOK}
                    data={folder.looks}
                    display={IContentDisplayTypes.GROUP}
                    rootFolders={rootFolders}
                    folder={folder}
                    setAction={setAction}
                    goToItem={goToItem}
                    editFolderPermissions={editFolderPermissions}
                  />
                ) : (loadingStates.loadingLooks === true) &&
                (
                  <Row>
                    <Col span={24}>                    
                      <Spin indicator={antIcon} >
                        <div style={{height: '200px'}}/>
                      </Spin>
                    </Col>
                  </Row>
                )
              }
            </Row>
            {
              (folder.children && folder.children.length === 0 && folder.looks && folder.looks.length === 0 && folder.dashboards && folder.dashboards.length === 0) &&
              <Row>
                <Col span={21}>
                  <h4 className="text-center">Add Looks or Dashboards by saving them to this folder. </h4>
                </Col>
                <Col span={3}>
                </Col>
              </Row>  
            }
          </>}
      </Layout.Content>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    rootFolders: state.folders.rootFolders,
    roleMapping: state.userAuth.roleMapping,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFolder);
