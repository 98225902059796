import { SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Input, Space, Table } from "antd";
import { ColumnsType } from 'antd/es/table';
import React from "react";
import { useSelector } from "react-redux";
import { IAlertInfo } from "../../../AppModels";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import { ILicenseChangeAction, ILicenseInfo } from "../../../services/AdminManagement/EditUsersManagement/EditUsersModels";
import { IApplicationState } from "../../../store/Store";
import ChangeLicenseModal from "../ChangeLicenseMdoal/ChangeLicenseModal";
import DeleteUser from "../DeleteUser/DeleteUser";
import DeleteUserConfirm from "../DeleteUserConfirm/DeleteUserConfirm";
import DownloadPermissions from "../DownloadPermissions/DownloadPermissions";
import LicenseAction from "../LicenseAction/LicenseAction";
import LicenseAssignment from "../LicenseAssignment/LicenseAssignment";
import PrintDesignerRoleAssignment from "../PrintDesignerRoleAssignment/PrintDesignerRoleAssignment";
import ViewSchedulesModal from "../ViewSchedulesModal/ViewSchedulesModal";
import SchedulePermissions from "../SchedulePermissions/SchedulePermissions";
import ScheduleRemoveConfirm from "../ScheduleRemoveConfirm/ScheduleRemoveConfirm";
import ViewAlertsModal from "../ViewAlertsModal/ViewAlertsModal";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Permission, Role } from "../../../services/RoleManagement/RoleModels";
import { isAuthorized } from "../../../services/RoleManagement/RoleManagement";

interface IProps {
    licenseInfo: ILicenseInfo;
    setLoading(isLoading: boolean): void;
    setAlertInfo(alertInfo: IAlertInfo);
    loading: boolean;
    refreshData: () => void;
}

const UsersView: React.FC<IProps> = (props: (IProps & IAlertInfo)) => {

    const username: string = useSelector<IApplicationState>(state => state.userAuth.username) as string; 
    const sudoUsername: string = useSelector<IApplicationState>(state => state.userAuth.sudoUser) as string; 
    const isSupport: boolean = useSelector<IApplicationState>(state => state.userAuth.isSupport) as boolean;
    const roleMapping: Map<Role, boolean> = useSelector<IApplicationState>(state => state.userAuth.roleMapping) as Map<Role, boolean>;
    const [deleteUser, setDeleteUser] = React.useState<IUserInfo>(null);
    const [scheduleRemoveUser, setScheduleRemoveUser] = React.useState<IUserInfo>(null);
    const [viewSchedules, setViewSchedules] = React.useState<IUserInfo>(null);
    const [viewAlerts, setViewAlerts] = React.useState<IUserInfo>(null);
    const [changeLicense, setChangeLicense] = React.useState<ILicenseChangeAction>(null);
    const [viewLicensesAvailable, setViewLicensesAvailable] = React.useState<boolean>(false);
    const [exploreLicensesAvailable, setExploreLicensesAvailable] = React.useState<boolean>(false);
    const [currentUsername, setCurrentUsername] = React.useState<string>(null);

    const itemsPerPage = 15;

    React.useEffect(() => {
        if(props.licenseInfo && props.licenseInfo.contractInfo){
            if(props.licenseInfo.contractInfo.exploreLicenseCount - props.licenseInfo.contractInfo.exploreLicensesInUse > 0){
                setExploreLicensesAvailable(true);
            } else {
                setExploreLicensesAvailable(false);
            }
            if(props.licenseInfo.contractInfo.viewLicenseCount - props.licenseInfo.contractInfo.viewLicensesInUse > 0){
                setViewLicensesAvailable(true);
            } else {
                setViewLicensesAvailable(false);
            }
        }
    }, [props.licenseInfo]);

    React.useEffect(()=>{
        setCurrentUsername(isSupport ? sudoUsername : username);
    }, [username, sudoUsername, isSupport]);

    const onAlertClose = () => {
        props.setAlertInfo({
            visible: false,
            type: 'warning',
            message: '',
            description: ''
        })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0] ? selectedKeys[0].toString() : ""}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
          }
        },
      });
    
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
      };
    
       const handleReset = clearFilters => {
        clearFilters();
      };

    const columns: ColumnsType<IUserInfo> = [
        {
            title: 'Display Name', 
            dataIndex: 'displayName', 
            key:'displayName',
            sortDirections: ['descend', 'ascend'],
            width: '13.5%',
            sorter: (a, b) => a.displayName.localeCompare(b.displayName),
            render: (text: string, record, index) => 
            <span>
                { text + (record.username === currentUsername ? " (self)" : "") }
            </span>,
            ...getColumnSearchProps('displayName')
        },
        {
            title: 'Username',
            dataIndex: 'loginUsername',
            key: 'loginUsername',
            width: '13.5%',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.loginUsername.localeCompare(b.loginUsername),
            ...getColumnSearchProps('loginUsername')
        },
        {
            title: 'Backend/Looker Username',
            dataIndex: 'username',
            key: 'username',
            width: '13.5%'
        },
        {
            title: "User Role",
            dataIndex: 'group',
            key: 'group',
            width: '9%',
            align: 'center',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.group.localeCompare(b.group),
            render: (text: string, record, index) => 
            <span>
                { text && text.indexOf("Users") && text.substring(0,text.indexOf("Users"))}
            </span>
        },
        {
            title: "Print Designer Role",
            dataIndex: 'printDesignerRole',
            key: 'printDesignerRole',
            width: '10%',
            align: 'center',
            sortDirections: ['descend', 'ascend'],
            render: (text: string, record, index) => 
            <span>
                <PrintDesignerRoleAssignment userInfo={record} setLoading={props.setLoading} setAlertInfo={props.setAlertInfo} loading={props.loading} currentUsername={currentUsername} />
            </span>
        },
        {
            title: 'Download Access',
            dataIndex: 'downloadAllowed' ,
            key: 'downloadAllowed',
            width: '7%',
            align: 'center',
            render: (text, record, index) => 
            <div className='table-action-cell'>
                <DownloadPermissions userInfo={record} setLoading={props.setLoading} loading={props.loading} setAlertInfo={props.setAlertInfo} disabled={record.group === 'AdminUsers'}/>
            </div>
        },
        {
            title: 'Schedule Access',
            dataIndex: 'scheduleAllowed' ,
            key: 'scheduleAllowed',
            width: '7%',
            align: 'center',
            render: (text, record, index) => 
            <div className='table-action-cell'>
                <SchedulePermissions 
                    userInfo={record} 
                    setLoading={props.setLoading} 
                    loading={props.loading} 
                    setAlertInfo={props.setAlertInfo} 
                    disabled={record.group === 'AdminUsers'}
                    setScheduleRemoveUser={setScheduleRemoveUser}/>
            </div>
        },
        {
            title: 'Schedules',
            dataIndex: 'viewSchedules',
            width: '9%',
            align: 'center',
            render: (text, record, index) => 
                <div className='table-action-cell'>
                    <Button type='link' onClick={() => setViewSchedules(record)}><u>View Schedules</u></Button>
                </div>
        },
        {
            title: 'Alerts',
            dataIndex: 'viewAlerts',
            width: '9%',
            align: 'center',
            render: (text, record, index) => 
                {   return record.group !== 'ViewUsers' ?
                    <div className='table-action-cell'>
                        <Button type='link' onClick={() => setViewAlerts(record)}><u>View Alerts</u></Button>
                    </div>
                    : 
                    "N/A";
                }
        },
        {
            title: "License Type",
            dataIndex: 'license',
            key: 'license',
            align: 'center',
            width: '9%',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.license.localeCompare(b.license),
        },
        {
            title: "License Assigned",
            dataIndex: 'holdingLicense',
            key: 'holdingLicense',
            width: '6%',
            align: 'center',
            render: (text, record, index) => 
            <div className='table-action-cell'>
                <LicenseAssignment userInfo={record} />
            </div>
        },
        {
            title: "License Action",
            key: 'licenseAction',
            width: '7%',
            align: 'center',
            render: (text, record, index) => 
            <div className='table-action-cell'>
                {  record.username !== currentUsername &&
                    <LicenseAction userInfo={record} setChangeLicense={setChangeLicense} exploreLicensesAvailable={exploreLicensesAvailable} viewLicensesAvailable={viewLicensesAvailable} />
                }
            </div>
        },
        {
            title: 'Delete',
            key: 'deleteUser',
            width: '9%',
            align: 'center',
            render: (text, record, index) => 
            <div className='table-action-cell'>
                {  record.username !== currentUsername &&
                    <DeleteUser userInfo={record} setDeleteUser={setDeleteUser} />
                }
            </div>
        },
    ]

    return (
        <>
            {
            props.visible &&
            <Alert
                style={{ marginBottom: "24px"  }}
                type={props.type}
                message={props.message}  
                description={props.description}
                closable 
                onClose={onAlertClose}/>
            }
            <Table<IUserInfo>
                columns={columns.filter(col => (col.title !== 'Backend/Looker Username' || isAuthorized(roleMapping, Permission.VIEW_LOOKER_USERNAMES) ))}
                dataSource={props.licenseInfo ? props.licenseInfo.userStatusList.filter(user =>
                    (user.group !== 'Internal')) : null}
                rowKey='username'
                loading={props.loading}
                pagination={{
                    size:"small",
                    defaultPageSize: itemsPerPage,
                    hideOnSinglePage: true,
                    position: ["bottomRight"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} groups`
                }}
            />
            { deleteUser &&
                <DeleteUserConfirm 
                    deleteUser={deleteUser}
                    refreshData={props.refreshData} 
                    setAlertInfo={props.setAlertInfo}
                    setLoading={props.setLoading} 
                    exit={() => setDeleteUser(null)}
                />
            }
            {   viewSchedules &&
                <ViewSchedulesModal
                    user={viewSchedules}
                    exit={() => setViewSchedules(null)}
                />
            }
            {   viewAlerts &&
                <ViewAlertsModal
                    user={viewAlerts}
                    exit={() => setViewAlerts(null)}
                />
            }
            {   changeLicense &&
                <ChangeLicenseModal
                    licenseChange={changeLicense} 
                    refreshData={props.refreshData} 
                    setAlertInfo={props.setAlertInfo}
                    setLoading={props.setLoading} 
                    exit={() => setChangeLicense(null)}
                />
            }
            {
                scheduleRemoveUser &&
                <ScheduleRemoveConfirm 
                    scheduleRemoveUser={scheduleRemoveUser}
                    refreshData={props.refreshData}
                    setAlertInfo={props.setAlertInfo}
                    setLoading={props.setLoading} 
                    exit={() => { setScheduleRemoveUser(null)}}
                />

            }
        </>
    );
};

export default UsersView;