import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { getFolderPermissionStatus, setFolderPermission } from '../../services/AdminManagement/FolderPermissionsManagement/FolderPermissionsManagement'
import { 
    IFolderPermissions,
 } from '../../services/AdminManagement/FolderPermissionsManagement/FolderPermissionsModels'
import { EditFolderPermissionsActions, EditFolderPermissionsActionTypes, IClearState, IEditFolderPermissionsState, IGetFolderPermissionStatus, ISetFolderPermission, ISetModifyError, ISetStatusError } from './EditFolderPermissionsTypes'

const getPermissionStatus = async (folder: string): Promise<IGetFolderPermissionStatus | ISetStatusError> => {
  let folderPermissionStatus: IFolderPermissions = null;
  let getFolderPermissionsError: Error = null;

  const setResult = (permissions: IFolderPermissions) => {
      folderPermissionStatus = permissions;
  }

  const setError = (error: Error) => {
    getFolderPermissionsError = error;
  }

  await getFolderPermissionStatus(folder, setResult, setError);
  let action: EditFolderPermissionsActions
  if (!getFolderPermissionsError) {
    action =
    {
        type: EditFolderPermissionsActionTypes.GET_FOLDER_PERMISSION_STATUS,
        folderPermissionStatus: folderPermissionStatus,
        getFolderPermissionsError: 'success',
    };
  } else {
    action =
    {
        type: EditFolderPermissionsActionTypes.SET_STATUS_ERROR,
        getFolderPermissionsError: 'error',
    };
  }
  return action;
};

const setPermission = async (folder: string, groupName: string, access: string): Promise<ISetFolderPermission | ISetModifyError> => {
  let folderPermissionStatus: IFolderPermissions = null;
  let setFolderPermissionError: Error = null;

  const setResult = (permissions: IFolderPermissions) => {
    folderPermissionStatus = permissions;
  }

  const setError = (error: Error) => {
    setFolderPermissionError = error;
  }

  await setFolderPermission(folder, groupName, access, setResult, setError);
  let action: EditFolderPermissionsActions;
  if (!setFolderPermissionError) {
    action =
    {
        type: EditFolderPermissionsActionTypes.SET_FOLDER_PERMISSION,
        folderPermissionStatus: folderPermissionStatus,
        setFolderPermissionError: 'success',
    };
  } else {
    action =
    {
        type: EditFolderPermissionsActionTypes.SET_MODIFY_ERROR,
        setFolderPermissionError: 'error',
    };
  }
  return action;
};

export const getFolderPermissions: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IEditFolderPermissionsState,
  null,
  IGetFolderPermissionStatus | ISetStatusError
>> = (folder: string) => {
  return async (dispatch: Dispatch) => {
    const action = await getPermissionStatus(folder);
    return dispatch(action);
  };
};

export const modifyFolderPermission: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IEditFolderPermissionsState,
  null,
  ISetFolderPermission | ISetModifyError
>> = (folder: string, groupName: string, access: string) => {
  return async (dispatch: Dispatch) => {
    const action = await setPermission(folder, groupName, access);
    return dispatch(action);
  }
};

export const clearState: ActionCreator<ThunkAction<
  Promise<AnyAction>,
  IEditFolderPermissionsState,
  null,
  IClearState
>> = () => {
  return async (dispatch: Dispatch) => {
    const action: EditFolderPermissionsActions = 
    {
      type: EditFolderPermissionsActionTypes.CLEAR_STATE,
      folderPermissionStatus: null,
      getFolderPermissionsError: null,
      setFolderPermissionError: null
    }
    return dispatch(action);
  };
};