import { Alert, Layout, Table, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";
import { connect } from "react-redux";
import { IAlertInfo } from "../../../AppModels";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import { IRunOnceScheduleInfo } from "../../../services/Schedules/ScheduleModels";
import { getRunOnceScheduledPlans } from "../../../services/Schedules/Schedules";
import { IApplicationState } from "../../../store/Store";
import "../Schedules.scss";

// included to mirror recurring schedules, but not used at this time
interface IProps {
  admin?: boolean;
  username?: string;
}

const OneTimeSends: React.FC<IProps> = (props: IProps) => {
  const [oneTimeSchedules, setOneTimeSchedules] = React.useState<IRunOnceScheduleInfo[]>(null);
  const [alert, setAlert] = React.useState<IAlertInfo>(null); 
  const maxRecipientsDisplay = 3;

  const setError = (error: Error) => {
    setAlert({
      type: "error",
      message: "An unexpected error occurred loading One-Time Send Information."
    })
  }

  const setResults = (results: any) =>{
    setOneTimeSchedules(results);
  }

  React.useEffect(() => {
    if (props.admin) {
      console.log('INVALID ADMIN CONFIGURATION FOR ONE TIME SENDS');
    } else {
      getRunOnceScheduledPlans(setResults, setError);
    }
  }, [props.admin]); 

  const clearAlert = () =>{
    setAlert(null);
  }

  React.useEffect(() =>{
    if(alert!=null){
      document.getElementById('app-main-content-pane').scrollTop = 0;
    }
  }, [alert]);

  return (
    <>

        {
          (oneTimeSchedules != null || alert != null) ?
          <Layout.Content style={{overflow: "auto"}}> 
            { alert &&
              <Alert 
                style={{ marginBottom: "24px"  }}
                type={alert.type}
                message={alert.message}  
                description={alert.description}
                closable 
                afterClose={clearAlert} 
              />
            }
            {
              oneTimeSchedules && 
              <Table 
                dataSource={oneTimeSchedules}
                rowKey={(schedule) => schedule.id}
                pagination={false}
              >
                <Column
                  title="ID"
                  key="id"
                  dataIndex="id"
                  width="3%"
                  sorter={(a: IRunOnceScheduleInfo, b: IRunOnceScheduleInfo) => a.id.localeCompare(b.id)}
                />
                <Column
                  title="Name"
                  key="name"
                  dataIndex="name"
                  width="23%"
                  sorter={(a: IRunOnceScheduleInfo, b: IRunOnceScheduleInfo) => a.name.localeCompare(b.name)}
                />
                <Column
                  title="Recipients"
                  key="recipients"
                  width="21%"
                  render={(schedule: IRunOnceScheduleInfo) => {
                    let recipientArray = [], recipients = '', i;
                    if(schedule.schedule_destination_type.indexOf("print_designer_scheduler") > 0 ){
                      if(schedule.schedule_destination_parameters){
                        try{
                          
                          const parsedParams = JSON.parse(schedule.schedule_destination_parameters);
                          if(parsedParams["email_addresses"]){
                            recipientArray = parsedParams["email_addresses"].split(",");
                          }
                        } catch(error){
                          //do nothing
                        }
                      }
                    } else if (schedule.schedule_destination_type.indexOf("custom_delimiter_email") > 0) {
                      if(schedule.schedule_destination_parameters){
                        try{
                          const parsedParams = JSON.parse(schedule.schedule_destination_parameters);
                          if(parsedParams["email_address"]){
                            recipientArray = parsedParams["email_address"].split(",");
                          }
                        } catch(error){
                          //do nothing
                        }
                      }
                    } else if(schedule.addresses) {
                      recipientArray = schedule.addresses.split(",");
                    }
                    for(i = 0; i < recipientArray.length && i<maxRecipientsDisplay; i++){
                      recipients += recipientArray[i] + ', ';
                    }
                    if(recipientArray.length > maxRecipientsDisplay){
                      recipients += '& ' + (recipientArray.length - maxRecipientsDisplay) + ' more';
                    }
                    else if(recipients.length > 0){
                      recipients = recipients.substring(0,recipients.length-2);
                    }
                    return <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{recipients}</span>
                  }}
                />
                <Column
                  title="Type"
                  key="type"
                  width="7%"
                  render={(schedule) => {
                    if(schedule.dashboard_id){
                      return <span>Dashboard</span>
                    } else if(schedule.look_id){
                      return <span>Look</span>
                    } else {
                      return <span></span>
                    }
                  }}
                />
                <Column 
                  title={<Tooltip title="Status (if ran in last 60 days)">Status</Tooltip>}
                  key="status"
                  dataIndex="schedule_status"
                  width="8%"
                  render={(lastRunStatus) => (lastRunStatus ? lastRunStatus.charAt(0).toUpperCase() + lastRunStatus.slice(1).replaceAll('_', ' ') : "")}
                />          
                <Column 
                  title={<Tooltip title="Status Details (if ran in last 60 days)">Details</Tooltip>}
                  key="schedule_status_detail"
                  dataIndex="schedule_status_detail"
                  width="25%"
                  render={(value, record) => {
                    let jsonRecord = record['schedule_status_detail'] && record['schedule_status_detail'].includes(`"status": `) ? JSON.parse(record['schedule_status_detail']) : null;
                    if (record['schedule_status'] && record['schedule_status'].toLowerCase() === 'in_progress') {
                      return "In Progress.";
                    }
                    if (jsonRecord && jsonRecord['status']) {
                      return `runId: ${jsonRecord['runId']} locationUrl: ${jsonRecord['locationUrl']}`;
                    } else {
                      if (record['schedule_status'] && record['schedule_status'].toLowerCase() === 'success') {
                        return "Completed Successfully.";
                      }
                      else return value ? value : "";
                    }
                    
                  }}
                />        
                <Column
                  title="Submitted Time"
                  key="submitted_time"
                  dataIndex="submitted_time"
                  width="13%"
                  sorter={(a: IRunOnceScheduleInfo, b: IRunOnceScheduleInfo) => a.submitted_time.localeCompare(b.submitted_time)}
                  defaultSortOrder="descend"
                  render={(value) => {
                    var date = new Date(value + " UTC");
                    return date.toLocaleString()
                  }}
                />                             
              </Table>
            }
          </Layout.Content>
          : 
          <Layout.Content
            style={{ height: "85hv" , width: "100%"}}
          >
            <CustomSpinner />
          </Layout.Content>
        }
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneTimeSends);
