import { Button, Modal } from "antd";
import React from "react";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import Schedules from "../../Schedules/Schedules"
import "../EditUsers.scss";

interface IAdminSchedulesContainerProps {
    user: IUserInfo;
    exit: () => void;
}

const ViewSchedulesModal: React.FC<IAdminSchedulesContainerProps> = (props: IAdminSchedulesContainerProps) => {

    const closeModal = () =>{
        props.exit();
    }

  return (
    <>
    <Modal
        title={`Schedules - ${props.user.displayName} (${props.user.loginUsername})`}
        visible={true}
        onCancel={closeModal}
        width={1500}
        footer={
            <Button type='primary' onClick={closeModal}>
            Close
            </Button>
        }
    >    <div className="flex-container" style={{overflow: 'auto'}}>
            <Schedules admin={true} username={props.user.username} />
        </div>
    </Modal>
    </>
  );
};
 
export default ViewSchedulesModal;