import { Button, Modal } from "antd";
import React from "react";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import AlertsPage from "../../AlertsPage/AlertsPage";

interface IAdminAlertsContainerProps {
    user: IUserInfo;
    exit: () => void;
}

const ViewAlertsModal: React.FC<IAdminAlertsContainerProps> = (props: IAdminAlertsContainerProps) => {

    const closeModal = () =>{
        props.exit();
    }

  return (
    <>
        <Modal
            title={`Alerts - ${props.user.displayName} (${props.user.loginUsername})`}
            visible={true}
            onCancel={closeModal}
            width={1500}
            footer={
                <Button type='primary' onClick={closeModal}>
                    Close
                </Button>
            }
        >      
            <AlertsPage admin={true} username={props.user.username} />
        </Modal>
    </>
  );
};
 
export default ViewAlertsModal;