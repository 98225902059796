import { Reducer } from 'redux'
import {
    IEditFolderPermissionsState,
    EditFolderPermissionsActions,
    EditFolderPermissionsActionTypes,
} from '../actions/EditFolderPermissionsTypes'

const initialEditFolderPermissionsState: IEditFolderPermissionsState = {
    folderPermissionStatus: null,
    getFolderPermissionsError: null,
    setFolderPermissionError: null,
};

export const editFolderPermissionsReducer: Reducer<
    IEditFolderPermissionsState,
    EditFolderPermissionsActions
> = (state = initialEditFolderPermissionsState, action): IEditFolderPermissionsState => {
    switch (action.type) {
        case EditFolderPermissionsActionTypes.GET_FOLDER_PERMISSION_STATUS: {
            return {
                ...state,
                folderPermissionStatus: action.folderPermissionStatus,
                getFolderPermissionsError: action.getFolderPermissionsError
            }
        }
        case EditFolderPermissionsActionTypes.SET_FOLDER_PERMISSION: {
            return {
                ...state,
                folderPermissionStatus: action.folderPermissionStatus,
                setFolderPermissionError: action.setFolderPermissionError
            }
        }
        case EditFolderPermissionsActionTypes.SET_MODIFY_ERROR: {
            return {
                ...state,
                setFolderPermissionError: action.setFolderPermissionError
            }
        }
        case EditFolderPermissionsActionTypes.SET_STATUS_ERROR: {
            return {
                ...state,
                getFolderPermissionsError: action.getFolderPermissionsError
            }
        }
        case EditFolderPermissionsActionTypes.CLEAR_STATE: {
            return {
                ...state,
                folderPermissionStatus: action.folderPermissionStatus,
                getFolderPermissionsError: action.getFolderPermissionsError,
                setFolderPermissionError: action.setFolderPermissionError
            }
        }
        default: {
            return state;
        }
    }
};