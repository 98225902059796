import React, { useState } from "react";
import { useDispatch } from 'react-redux'
import { Modal, Button, Form, Input } from "antd";
import { IGroupInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import { getCustomGroups } from '../../../store/actions/EditGroupsActions'
import { createGroup } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsManagement";
import { IAlertInfo } from "../../../AppModels";

interface IProps {
    groups: IGroupInfo[];
    setLoading(isLoading: boolean): void;
    loading: boolean;
    setAlertInfo(alertInfo: IAlertInfo): void;
}

const AddGroupButton: React.FC<IProps> = (props: IProps) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const handleCreateGroup = async (group) => {
        props.setLoading(true);

        const setResult = (results: any) => {
            console.log('Results for creatGroup: ', results);
            props.setAlertInfo({
                visible: true, 
                type: 'success', 
                message: `Group ${group} was successfully created!`, 
                description:''
            })
        }

        const setError = (error: Error) => {
            console.log('createGroupError: ', error);
            props.setAlertInfo({
                visible: true, 
                type: 'error', 
                message: `Failed to create group ${group}!`, 
                description: ''
            })
        }

        await createGroup(group, setResult, setError);
        await dispatch(getCustomGroups());
        props.setLoading(false);
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 24 },
    };

    const toggleModal = () => {
        if(isModalVisible) {
            setIsModalVisible(false);
            form.resetFields()
        }
        else {
            form.resetFields()
            setIsModalVisible(true);
        }
    }

    const onFinish = (values: any) => {
        handleCreateGroup(values.groupName.trim())
        toggleModal();
    }

    const onFinishFailed = (values: any) => {
        console.log('failed')
    }

    return (
        <>
            <Button 
                disabled={props.loading}
                className="group-btn-add" 
                onClick={toggleModal}>
                Add Group
            </Button>
            <Modal 
            title="Add New Group" 
            visible={isModalVisible} 
            onCancel={toggleModal}
            footer={[
                <Button form='addGroup' type='default' onClick={toggleModal}>
                    Cancel
                </Button>,
                <Button key='submit' form='addGroup' type='primary' htmlType='submit'>
                    Create
                </Button>
            ]}>
                <Form
                {...layout}
                form={form}
                id="addGroup"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Name"
                        name="groupName"
                        rules={[
                            {required: true, message: "Please enter a group name!"},
                            () => ({
                                validator(_, value: string) {
                                    return new Promise<void>((resolve, reject) => {

                                        if(value && value.includes('@')){
                                            reject('A group name cannot contain an \'@\' character!')
                                        }

                                        if(value && value.trim().includes(' ')){
                                            reject('A group name cannot contain spaces!')
                                        }

                                        for (let group of props.groups) {
                                            if (value && group.groupName.toLowerCase() === value.toLowerCase()) {
                                                reject("A group with that name already exists!")
                                            }
                                        }

                                        resolve()
                                    })
                                }
                            })
                        ]}
                    >
                        <Input autoFocus/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddGroupButton;