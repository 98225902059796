import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
import { setErrorMessageFromOkta } from '../../../store/actions/UserAuthActions';
import { useHistory } from 'react-router-dom';
import { LOCAL_STORAGE_OKTA_SUPPORT_REALM, OKTA_RESTORE_URI, WEBAPP_VERSION } from '../../../Constants';
import { Layout, Row } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import CustomSpinner from '../../../components/CustomSpinner/CustomSpinner';

const LoginLoadingElement = <Layout className="login-blue-background" style={{ minHeight: "100vh" }}>
    <Header
      className="login-header">
      <img className="logo" src="/McKesson_Logo_White.png" alt="McKesson Logo"/>  
    </Header>
        <Content>
          <Row style={{ height:'75vh'}}>    
            <CustomSpinner />
          </Row>
        </Content>
  <Footer
    className="login-footer"
    style={ { textAlign: 'right'} }>
     EnterpriseRx Data Insights&trade; {WEBAPP_VERSION}
  </Footer>
  </Layout>;


const ErrorComponent = (props) => {
    const { error } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(()=>{
        const { errorSummary } = error;
        dispatch(setErrorMessageFromOkta('Okta Error: ' + errorSummary));
        const supportFlag = (localStorage.getItem(LOCAL_STORAGE_OKTA_SUPPORT_REALM) != null);
        const restoreUri = (!supportFlag ? "" : "/internal") + OKTA_RESTORE_URI;
        history.push(restoreUri);
    }, [error, dispatch, history])

    return <></>;
};

const CustomLoginCallback = () => {

    
    return <LoginCallback errorComponent={ErrorComponent} loadingElement={LoginLoadingElement}/>;
};

export default CustomLoginCallback;