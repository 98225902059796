import { 
    IFolderPermissions,
 } from "../../services/AdminManagement/FolderPermissionsManagement/FolderPermissionsModels";

export enum EditFolderPermissionsActionTypes {
    GET_FOLDER_PERMISSION_STATUS = "EDIT_FOLDER_PERMISSIONS/GET_FOLDER_PERMISSION_STATUS",
    SET_FOLDER_PERMISSION = "EDIT_FOLDER_PERMISSIONS/SET_FOLDER_PERMISSION",
    SET_MODIFY_ERROR = "EDIT_FOLDER_PERMISSIONS/SET_MODIFY_ERROR",
    SET_STATUS_ERROR = "EDIT_FOLDER_PERMISSIONS/SET_STATUS_ERROR",
    CLEAR_STATE = "EDIT_FOLDER_PERMISSIONS/CLEAR_STATE",
}

export type EditFolderPermissionsActions =
    | IGetFolderPermissionStatus
    | ISetFolderPermission
    | ISetModifyError
    | ISetStatusError
    | IClearState;

export interface IGetFolderPermissionStatus {
    type: EditFolderPermissionsActionTypes.GET_FOLDER_PERMISSION_STATUS;
    folderPermissionStatus: IFolderPermissions;
    getFolderPermissionsError: string;
}

export interface ISetFolderPermission {
    type: EditFolderPermissionsActionTypes.SET_FOLDER_PERMISSION;
    folderPermissionStatus: IFolderPermissions;
    setFolderPermissionError: string;
}

export interface ISetModifyError {
    type: EditFolderPermissionsActionTypes.SET_MODIFY_ERROR;
    setFolderPermissionError: string;
}

export interface ISetStatusError {
    type: EditFolderPermissionsActionTypes.SET_STATUS_ERROR;
    getFolderPermissionsError: string;
}
export interface IClearState {
    type: EditFolderPermissionsActionTypes.CLEAR_STATE;
    folderPermissionStatus: IFolderPermissions,
    getFolderPermissionsError: string,
    setFolderPermissionError: string,
}

export interface IEditFolderPermissionsState {
    readonly folderPermissionStatus: IFolderPermissions;
    readonly getFolderPermissionsError: string;
    readonly setFolderPermissionError: string;
}