export enum Role {
    ERX_DI_VIEW_ROLE = "erx-di-view",
    ERX_DI_EXPLORE_ROLE = "erx-di-explore",
    ERX_DI_ADMIN_ROLE = "erx-di-customer-admin",
    ERX_DI_SUPPORT_ROLE = "erx-di-support",
    APS_CUSTOMER_ADMIN = "aps-customer-admin"
}

export enum PrintDesignerRole {
    DESIGNER = "designer",
    VIEWER = "viewer",
    NONE = "none"
}

export enum Permission{
    VIEW_MY_REPORTS_TAB = "VIEW_MY_REPORTS_TAB",
    VIEW_EXPLORE_TAB = "VIEW_EXPLORE_TAB",
    EDIT_GROUPED_REPORTS = "EDIT_GROUPED_REPORTS",
    VIEW_ADMIN_TAB = "VIEW_ADMIN_TAB",
    VIEW_APS_CUSTOM_LISTS = "VIEW_APS_CUSTOM_LISTS",
    VIEW_ALERTS_TAB = "VIEW_ALERTS_TAB",
    MANAGE_FOLDER_PERMISSIONS = "MANAGE_FOLDER_PERMISSIONS",
    VIEW_NEAR_TIME_STATUS = "VIEW_NEAR_TIME_STATUS",
    VIEW_NOTIFICATION_MANAGEMENT = "VIEW_NOTIFICATION_MANAGEMENT",
    VIEW_LOOKER_USERNAMES = "VIEW_LOOKER_USERNAMES"
}

export const permissionMapping: Map<Permission, Role[]> = new Map([
    [   Permission.VIEW_MY_REPORTS_TAB,
        [
            Role.ERX_DI_EXPLORE_ROLE,
            Role.ERX_DI_ADMIN_ROLE,
            Role.ERX_DI_SUPPORT_ROLE
        ]
    ],
    [
        Permission.VIEW_EXPLORE_TAB,
        [
            Role.ERX_DI_EXPLORE_ROLE,
            Role.ERX_DI_ADMIN_ROLE,
            Role.ERX_DI_SUPPORT_ROLE
        ]
    ],
    [
        Permission.VIEW_ALERTS_TAB,
        [
            Role.ERX_DI_EXPLORE_ROLE,
            Role.ERX_DI_ADMIN_ROLE,
            Role.ERX_DI_SUPPORT_ROLE
        ]
    ],
    [
        Permission.EDIT_GROUPED_REPORTS,
        [
            Role.ERX_DI_EXPLORE_ROLE,
            Role.ERX_DI_ADMIN_ROLE,
            Role.ERX_DI_SUPPORT_ROLE
        ]
    ],
    [
        Permission.VIEW_ADMIN_TAB,
        [
            Role.ERX_DI_ADMIN_ROLE,
            Role.ERX_DI_SUPPORT_ROLE
        ]
    ],
    [
        Permission.VIEW_APS_CUSTOM_LISTS,
        [
            Role.ERX_DI_SUPPORT_ROLE,
            Role.APS_CUSTOMER_ADMIN
        ]

    ],
    [
        Permission.MANAGE_FOLDER_PERMISSIONS,
        [
            Role.ERX_DI_ADMIN_ROLE,
            Role.ERX_DI_SUPPORT_ROLE          
        ]
    ],
    [
        Permission.VIEW_NEAR_TIME_STATUS,
        [
            Role.ERX_DI_SUPPORT_ROLE          
        ]
    ],
    [
        Permission.VIEW_NOTIFICATION_MANAGEMENT,
        [
            Role.ERX_DI_SUPPORT_ROLE
        ]
    ],
    [
        Permission.VIEW_LOOKER_USERNAMES,
        [
            Role.ERX_DI_SUPPORT_ROLE
        ]
    ]
]);

export enum Realm {
    INTERNAL_DEV = "us-qa-admin",
    INTERNAL_STAGE = "internal-other-admin",
    INTERNAL_PROD = "internal-admin"
}

export const realmPermissionMapping: Map<Permission, Realm[]> = new Map([
    [
        Permission.VIEW_NOTIFICATION_MANAGEMENT,
        [
            Realm.INTERNAL_DEV,
            Realm.INTERNAL_STAGE,
            Realm.INTERNAL_PROD
        ]
    ]
]);
