import { IDemoFilter } from "../../containers/LookerFilterDemo/LookerFilters";
import { LookerContentType } from "../../AppModels";
import { ICorporateHierarchyNode } from "../../services/GroupManagement/GroupModels";

export type LookerFilterActionTypes = ISetFilterAction | ISetLookerContentInfoAction | ISetFacilityFilterListAction | IGetCorporateHierarchyAction;

export enum LookerFilterActions {
  SET_FILTER = "FILTER_ACTION/SET",
  SET_LOOKER_CONTENT_INFO = "FILTER_ACTION/SET_LOOKER_CONTENT_INFO",
  SET_FACILITY_FILTER_LIST = "FILTER_ACTION/SET_FACILITY_FILTER_LIST",
  GET_CORPORATE_HIERARCHY = "FILTER_ACTION/GET_CORPORATE_HIERARCHY",
}

export interface ISetFilterAction {
  type: LookerFilterActions.SET_FILTER;
  filter: IDemoFilter;
}

export interface ISetLookerContentInfoAction {
  type: LookerFilterActions.SET_LOOKER_CONTENT_INFO;
  lookerContentType: LookerContentType;
  facilityFilterPresent: boolean;
  lookGreaterThan50Fields?: boolean;
}

export interface ISetFacilityFilterListAction {
  type: LookerFilterActions.SET_FACILITY_FILTER_LIST;
  facilityFilterList: string;
}

export interface IGetCorporateHierarchyAction {
  type: LookerFilterActions.GET_CORPORATE_HIERARCHY;
  corporateHierarchy: ICorporateHierarchyNode[];
  corporateHierarchyError: string;
}

export interface ILookerFilterState {
  readonly filterType: IDemoFilter;
  readonly lookerContentType: LookerContentType;
  readonly facilityFilterPresent: boolean;
  readonly facilityFilterList: string;
  readonly corporateHierarchy: ICorporateHierarchyNode[];
  readonly corporateHierarchyError: string;
}
