import { IDataInsightsMessage } from "../../containers/Messages/MessageList";

export interface IMessageState {
    readonly messages: IDataInsightsMessage[];
    readonly looksForLargeLookMessage: string[];
}

export enum MessageActionsTypes {
    DISPLAY_MESSAGE = "MESSAGES/DISPLAY_MESSAGE",
    LARGE_LOOK_MESSAGE_FOR_LOOK = "MESSAGES/LARGE_LOOK_MESSAGE_FOR_LOOK",
    CLEAR_FIRST_MESSAGE = "MESSAGES/CLEAR_FIRST_MESSAGE"
}

export type MessageActions =
    | IDisplayMessage
    | ILargeLookMessage
    | IClearFirstMessage;

export interface IDisplayMessage {
    type: MessageActionsTypes.DISPLAY_MESSAGE;
    message: IDataInsightsMessage;
}

export interface ILargeLookMessage {
    type: MessageActionsTypes.LARGE_LOOK_MESSAGE_FOR_LOOK;
    message: IDataInsightsMessage;
    lookId: string;
}

export interface IClearFirstMessage {
    type: MessageActionsTypes.CLEAR_FIRST_MESSAGE;
}
