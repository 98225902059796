import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";
import { IAlertInfo } from "../../../AppModels";
import { IUserInfo } from "../../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import { ILicenseChangeAction } from "../../../services/AdminManagement/EditUsersManagement/EditUsersModels";

interface IProps {
    userInfo: IUserInfo,
    setChangeLicense: (changeLicense: ILicenseChangeAction) => void;
    exploreLicensesAvailable: boolean;
    viewLicensesAvailable: boolean;
}

const LicenseAction: React.FC<IProps> = (props: (IProps & IAlertInfo)) => {
    const [licenseAvailable, setLicenseAvailable] = React.useState<boolean>(false);

    React.useEffect(()=>{
        if(props.userInfo){
            if(props.userInfo.license === 'Explore'){
                setLicenseAvailable(props.exploreLicensesAvailable);
            } else if(props.userInfo.license === 'View'){
                setLicenseAvailable(props.viewLicensesAvailable);
            }
        }
    }, [props.userInfo, props.exploreLicensesAvailable, props.viewLicensesAvailable]);

    return (
        <>
            { props.userInfo.holdingLicense ?
                <Tooltip title="Remove License">
                <Button
                    shape="circle"
                    size="small"
                    onClick={() => { 
                        props.setChangeLicense({ user: props.userInfo, newLicenseStatus: false});
                    }}
                >
                    <MinusOutlined style={{ fontSize: "12px" }}/>
                </Button>
                </Tooltip>
                :
                <>
                {
                    licenseAvailable ?
                    <Tooltip title="Assign License">
                    <Button
                        shape="circle"
                        size="small"
                        onClick={() => { 
                            props.setChangeLicense({ user: props.userInfo, newLicenseStatus: true});
                        }}
                    >
                        <PlusOutlined style={{ fontSize: "12px" }}/>
                    </Button>
                    </Tooltip>
                    :
                    <Tooltip title="No Licenses Available">
                        <div>&nbsp;---&nbsp;</div>
                    </Tooltip>
                }
                </>

            }
        </>
    );
};

export default LicenseAction;