import { API } from "../LookerAPI/LookerAPI";

import {
    IDocument,
    IDataModelField,
    IExploreTile,
    ILookerExplore
} from './DocumentationModels'

export const getReportsDocumentation = async (
    setResult: (documents: IDocument[]) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: any) => {
        const documents: IDocument[] = [];
        for (let doc of result['documents']) {
            documents.push({
                category: doc['category'],
                title: doc['filename'],
                version: doc['version'],
                updated: doc['dateUpdated'],
                pdfUrl: doc['url'],
            });
        };
        setResult(documents);
    };
    await API.getReportsDocumentation(getResult, setError);
};

export const getDataModelDocumentation = async (
    setResult: (fields: any) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: any) => {
        const fields: IDataModelField[] = [];
        for (let field of result) {
            if(field['inLooker'] === true && field['exploreHidden'] === false && field['isHidden'] === false) {
                fields.push({
                    fieldName: field['fieldName'],
                    viewName: field['viewName'],
                    fieldType: field['fieldType'],
                    dataFormat: field['dataFormat'],
                    fieldDescription: field['fieldDescription'],
                    exploreName: field['exploreName'],
                    exploreLabel: field['exploreLabel'],
                    dataSource: field['snowflakeView'] && field['snowflakeFieldName'] ? `${field['snowflakeView']}.${field['snowflakeFieldName']}` : ""
                });
            }
        };
        setResult(fields);
    };
    await API.getDataModelDocumentation(getResult, setError);
};

export const getExploreTileInfo = async (
    setResult: (tiles: IExploreTile[]) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: any) => {
        const tiles: IExploreTile[] = [];
        for (let tile of result) {
            tiles.push({
                exploreName: tile['exploreName'],
                exploreLabel: tile['exploreLabel'],
                description: tile['description'],
                exampleDataTypes: tile['exampleDatatypes'],
                reportsUsingDetail: tile['reportsUsingDetail'],
                reportsUsingNames: tile['reportsUsingNames'].split(','),
                embedUrl: tile['embedUrl'],
                rtEmbedUrl: tile['rtEmbedUrl'],
                documentationUrl: tile['documentationLink'],
                isRealtime: tile['isRealtime'],
                isMarketplace: tile['isMarketplace'],
                isMarketplaceEnabled: tile['isMarketplaceEnabled'],
                category: tile['category'],
                version: tile['version'],
            });
        };
        setResult(tiles);
    };
    await API.getExploreTilesConfig(getResult, setError);
}

export const getExploreDescriptions = async (
    setResult: (tiles: ILookerExplore[]) => void,
    setError: (error: Error) => void
) => {
    const getResult = (result: any) => {
        const descriptions: ILookerExplore[] = [];
        for (let description of result) {
            descriptions.push({
                name: description['exploreName'],
                description: description['exploreDescription']
            });
        };
        setResult(descriptions);
    };
    await API.getExploreDescriptions(getResult, setError);
}