import { API } from "../LookerAPI/LookerAPI";
import { ICorporateHierarchyNode } from "./GroupModels";

export const EMBED_USER_ROOT_FOLDER_NAME = "Embed Users";
export const TEAM_SHARED_ROOT_FOLDER_NAME = "TeamShared";

export const getCorporateHierarchyForUser = async (
  setHierarchyInfo: (root: ICorporateHierarchyNode[]) => void,
  setError: (error: Error) => void
) => {
  let rawHierarchyArray: object[] = null;
  let hierarchyError: Error = null;
  const setHierarchyArray = (dataArray: object[]) => {
    rawHierarchyArray = dataArray;
  };
  const setHierarchyError = (error: Error)=>{
    hierarchyError = error;
  }
  await API.getCorporateHierarchy(setHierarchyArray, setHierarchyError);
  if(hierarchyError != null || rawHierarchyArray == null){
    setError({
      name: "ERROR",
      message: "There was an error retrieving facility groupings."
    });
    return;
  }
  if(rawHierarchyArray.length === 0){
    setError({
      name: "NO RESULTS",
      message: "No facility groupings were found."
    });
    return;
  }
  let hierarchyList : ICorporateHierarchyNode[] = [];
  let hierarchyMap = {}; //This will map a node's id to its index in hierarchyList to aid in assembling nested structure
  let roots: ICorporateHierarchyNode[] = []; //First level of nodes to be returned
  let index = 0;
  rawHierarchyArray.forEach((element) =>{
    const hierarchyNode: ICorporateHierarchyNode = {
      key: element["scope_of_control.nodetype"] + ';' + element["scope_of_control.child"] + ';' + element["scope_of_control.fd_facility_id"],
      title: element["scope_of_control.child_name"],
      id: element["scope_of_control.child"],
      name: element["scope_of_control.child_name"],
      facilityId: element["scope_of_control.fd_facility_id"],
      hierarchyLevel: element["scope_of_control.hierarchy_level"],
      nodeType: element["scope_of_control.nodetype"],
      parent: element["scope_of_control.parent"],
      children: null
    };
    if(hierarchyNode.nodeType === 'R' || (hierarchyNode.nodeType === 'F' && hierarchyNode.facilityId === null)){
      //Do not display corporate root, that's just a level that would need expanded 
      //Do not display facilities without facility ID, facility id is how they are applied to filter, so would not have effect
    } else {
      hierarchyList.push(hierarchyNode);
      hierarchyMap[hierarchyNode.id] = index++;
    }
  });
  //API Returns in order or hierarchy level, processing in reverse order allows us to know if intermediate node is childless before adding it
  for(let i = hierarchyList.length - 1; i >= 0; i--){
    let element = hierarchyList[i];
    if(element.nodeType === "I" && element.children == null){
      //Do not add empty intermediate nodes
    } else {
      if(element.parent && hierarchyMap[element.parent] != null){
        if(hierarchyList[hierarchyMap[element.parent]].children == null){
          hierarchyList[hierarchyMap[element.parent]].children = [];
        }
        hierarchyList[hierarchyMap[element.parent]].children.push(element);
      } else {
        roots.push(element);
      }
    }
  }
  //Sort roots and children of all nodes
  hierarchyList.forEach(node => {
    if(node.children != null && node.children.length > 0){
      node.children.sort(sortHierarchyNodes);
    }
  });
  roots.sort(sortHierarchyNodes);
  setHierarchyInfo(roots);
};

const sortHierarchyNodes = (a: ICorporateHierarchyNode, b: ICorporateHierarchyNode) =>{
  //Intermediate notes before facilities (nodeType='I' before nodeType='F')
  //Then by name
  let _compare = b.nodeType.localeCompare(a.nodeType);
  if(_compare !== 0){
    return _compare;
  }
  _compare = a.name.localeCompare(b.name);
  return _compare;

}