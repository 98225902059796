import {
  InfoCircleOutlined,
  LoadingOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Layout,
  Menu,
  notification,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  IAlertInfo,
  IPrintDesignerDataSource,
  LookerContentType,
} from "../../AppModels";
import { isAuthorized } from "../../services/RoleManagement/RoleManagement";
import {
  Permission,
  PrintDesignerRole,
  Role,
} from "../../services/RoleManagement/RoleModels";
import { ILookerContentInfo } from "../../store/actions/SettingsActionsTypes";
import { IApplicationState } from "../../store/Store";
import FacilitySelector from "../FacilitySelector/FacilitySelector";
import SetGroupHomePage from "../HomePage/SetHomePage/SetGroupHomePage";
import SetRealmHomePage from "../HomePage/SetHomePage/SetRealmHomePage";
import SetUserHomePage from "../HomePage/SetHomePage/SetUserHomePage";
import { logout as logoutUser } from "./../../store/actions/UserAuthActions";
import "./Header.scss";
import {
  IDisplayFolderItemType,
  IMoveSource,
  IRootFolderIds,
} from "../../services/FolderManagement/FolderModels";
import { getRootFolders } from "../../store/actions/FolderActions";
import AboutModal from "../AboutModal/AboutModal";
import { API } from "../../services/LookerAPI/LookerAPI";
import MoveToFolderContainer from "../MoveToFolder/MoveToFolderContainer";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { getRootFolderIds } from "../../services/FolderManagement/FolderManagement";
import { useWindowDimensions } from "../../services/CustomHooks";
import NotificationsView from "../Notifications/NotificationsView";
import {
  getLastDataRefreshDateTime,
  getLastEtlCompletedDateTime,
} from "../../services/NotificationsManagement/NotificationsManagement";

const { Text } = Typography;

interface IHeaderProps {
  username: string;
  loginId: string;
  isSupport: boolean;
  realm: string;
  sudoUser: string;
  scopeOfControlUser: string;
  roleMapping: Map<Role, boolean>;
  lookerContentType: LookerContentType;
  printDesignerRole: string;
  logout: typeof logoutUser;
  setSearchText: (searchText: string) => void;
  refreshFunction: () => void;
  refreshNotificationsCount: number;
}

const Header: React.FC<IHeaderProps> = ({
  username,
  loginId,
  isSupport,
  realm,
  sudoUser,
  scopeOfControlUser,
  roleMapping,
  lookerContentType,
  printDesignerRole,
  logout,
  setSearchText,
  refreshFunction,
  refreshNotificationsCount,
}) => {
  const [menu, setMenu] = React.useState<ReactElement>(null);
  const [settingsMenu, setSettingsMenu] = React.useState<ReactElement>(null);
  const [enteredText, setEnteredText] = React.useState<string>("");
  const [usernameLine1, setUsernameLine1] = React.useState<string>("");
  const [usernameLine2, setUsernameLine2] = React.useState<string>("");
  const [itemToCopy, setItemToCopy] = React.useState<IMoveSource>(null);
  const [copyLoading, setCopyLoading] = React.useState<boolean>(false);
  const { width } = useWindowDimensions(); //can be used to get page width and height
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [userHomeModalVisible, setUserHomeModalVisible] =
    useState<boolean>(false);
  const [realmHomeModalVisible, setRealmHomeModalVisible] =
    useState<boolean>(false);
  const [groupHomeModalVisible, setGroupHomeModalVisible] =
    useState<boolean>(false);

  const [aboutModalVisible, setAboutModalVisible] = useState<boolean>(false);

  const [copyModalVisible, setCopyModalVisible] = useState<boolean>(false);

  const [dataLastRefreshDateTime, setDataLastRefreshDateTime] =
    React.useState<string>(null);
  const [lastETLRunCompletedDateTime, setLastETLRunCompletedDateTime] =
    React.useState<string>(null);

  const lookerContentInfo = useSelector<IApplicationState>(
    (state) => state.settings.lookerContentInfo
  ) as ILookerContentInfo;
  const printDesignerData = useSelector<IApplicationState>(
    (state) => state.settings.printDesignerData
  ) as IPrintDesignerDataSource;
  const rootFolders: IRootFolderIds = useSelector<IApplicationState>(
    (state) => state.folders.rootFolders
  ) as IRootFolderIds;

  const printDesignerUrl = process.env.REACT_APP_PRINT_DESIGNER_FE_BASE_URL;

  const updateSearchText = (event) => {
    setEnteredText(event.target.value);
  };

  const openNotification = (
    message: string,
    description: string,
    type: string
  ) => {
    notification[type]({
      message,
      description,
      placement: "topRight",
      duration: 3,
    });
  };

  const showCopyAlert = (info: IAlertInfo) => {
    notification[info.type]({
      message:
        info.type.charAt(0).toUpperCase() +
        info.type.substring(1, info.type.length),
      description: info.message,
      placement: "topRight",
      duration: 3,
    });
  };

  const startSearch = (searchText: string) => {
    if (searchText.trim().length === 0) {
      setEnteredText("");
      return;
    }
    setSearchText(searchText.trim());
    refreshFunction();
    history.push("/search");
    setEnteredText("");
  };

  const exitUserHomeModal = () => {
    setUserHomeModalVisible(false);
  };

  const exitRealmHomeModal = () => {
    setRealmHomeModalVisible(false);
  };

  const exitGroupHomeModal = () => {
    setGroupHomeModalVisible(false);
  };

  const exitAboutModal = () => {
    setAboutModalVisible(false);
  };

  useEffect(() => {
    if (isSupport) {
      setUsernameLine1(loginId + " (" + realm + ")");
      if (sudoUser != null) {
        setUsernameLine2("sudoing as: " + sudoUser);
      } else if (scopeOfControlUser != null) {
        setUsernameLine2("scope of control for: " + scopeOfControlUser);
      } else {
        setUsernameLine2(null);
      }
    } else {
      setUsernameLine1(loginId);
      setUsernameLine2(null);
    }
  }, [loginId, realm, isSupport, sudoUser, scopeOfControlUser]);

  React.useEffect(() => {
    if (rootFolders == null && !isSupport) {
      dispatch(getRootFolders(realm, true));
    }
  }, [rootFolders, realm, isSupport, dispatch]);

  useEffect(() => {
    setMenu(
      <Menu>
        <Menu.ItemGroup
          title={
            <div>
              <UserOutlined /> {usernameLine1}
              {usernameLine2 && (
                <>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{usernameLine2}
                </>
              )}
            </div>
          }
        >
          <Menu.Divider />
          <Menu.Item key="1" onClick={logout}>
            Logout
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  }, [logout, usernameLine1, usernameLine2]);

  const getLookerCopyInfo = React.useCallback(
    async (id: string, type: LookerContentType) => {
      // currently only used when a support user tries to copy a standard dashboard immediately after logging in, before rootFolders is populated
      const getRootFoldersInfo = (realm, ignoreError) => {
        return new Promise((resolve, reject) => {
          const setResult = (ids: IRootFolderIds) => {
            resolve(ids);
          };
          const setError = (error: Error) => {
            if (!ignoreError) {
              openNotification(
                "Error",
                "There was an error loading root folder information.",
                "error"
              );
              reject();
            }
            reject();
          };
          getRootFolderIds(realm).then(setResult, setError);
        });
      };

      // rootfoldersids only need to be grabbed here if the user is support
      let supportRootFolders: IRootFolderIds = null;
      if (rootFolders == null && isSupport) {
        dispatch(getRootFolders(realm, true));
        setCopyLoading(true);
        await getRootFoldersInfo(realm, true).then(
          (value: IRootFolderIds) => {
            console.log(value);
            supportRootFolders = value;
          },
          () => {}
        );
      }

      const setResult = (result: any) => {
        setItemToCopy({
          moveType:
            type === LookerContentType.DASHBOARD
              ? IDisplayFolderItemType.DASHBOARD
              : IDisplayFolderItemType.LOOK,
          moveItemId: result["id"],
          moveItemName: result["title"],
          moveFromFolderId: result["folder_id"],
          startFolderId: rootFolders
            ? rootFolders.teamRootId
            : supportRootFolders.teamRootId,
          startFolderName: "Shared Root Folder",
        });

        // copyLoading only changes if the user is support so no need to modify if they are not
        if (isSupport) setCopyLoading(false);

        setCopyModalVisible(true);
      };

      const setError = (error: Error) => {
        console.log(error);
        openNotification(
          "Error",
          "There was an unexpected error trying to copy.",
          "error"
        );
      };

      switch (type) {
        case LookerContentType.DASHBOARD: {
          API.getDashboard(id, "id,title,folder_id", setResult, setError);
          break;
        }
        case LookerContentType.LOOK: {
          API.getLook(id, "id,title,folder_id", setResult, setError);
          break;
        }
        default: {
          openNotification(
            "Error",
            "Only Looks or Dashboards can be copied.",
            "error"
          );
        }
      }
    },
    [rootFolders, realm, dispatch, isSupport]
  );

  const closeCopyModal = () => {
    setCopyModalVisible(false);
  };

  const addFavorite = React.useCallback(() => {
    const setResult = (result: any) => {
      openNotification(
        "Success",
        lookerContentInfo.type === LookerContentType.LOOK
          ? "Look successfully added to your favorites"
          : "Dashboard successfully added to your favorites",
        "success"
      );
    };

    const setError = (error: Error) => {
      openNotification(
        "Error",
        "An unexpected error occurred adding to favorites",
        "error"
      );
    };
    API.addFavorite(
      lookerContentInfo.type,
      lookerContentInfo.lookerId,
      setResult,
      setError
    );
  }, [lookerContentInfo]);

  useEffect(() => {
    setSettingsMenu(
      <Menu>
        <Menu.Item
          key="4"
          onClick={() => addFavorite()}
          disabled={
            !lookerContentType ||
            lookerContentType === LookerContentType.EXPLORE
          }
        >
          Add to Favorites
        </Menu.Item>
        {isAuthorized(roleMapping, Permission.VIEW_EXPLORE_TAB) && (
          <Menu.Item
            key="5"
            onClick={() => {
              getLookerCopyInfo(lookerContentInfo.lookerId, lookerContentType);
            }}
            disabled={
              !lookerContentType ||
              lookerContentType === LookerContentType.EXPLORE
            }
          >
            Copy{" "}
            {lookerContentType !== LookerContentType.EXPLORE
              ? lookerContentType
              : ""}
          </Menu.Item>
        )}
        <Menu.Item
          key="1"
          onClick={() => setUserHomeModalVisible(true)}
          disabled={!lookerContentType}
        >
          {location.pathname !== "/home"
            ? "Set as your Home Page"
            : "Reset your Home Page"}
        </Menu.Item>
        {isAuthorized(roleMapping, Permission.VIEW_ADMIN_TAB) && (
          <Menu.Item
            key="2"
            onClick={() => setGroupHomeModalVisible(true)}
            disabled={!lookerContentType}
          >
            {location.pathname !== "/home"
              ? "Set as a Group Home Page"
              : "Reset a Group Home Page"}
          </Menu.Item>
        )}
        {isAuthorized(roleMapping, Permission.VIEW_ADMIN_TAB) && (
          <Menu.Item
            key="3"
            onClick={() => setRealmHomeModalVisible(true)}
            disabled={!lookerContentType}
          >
            {location.pathname !== "/home"
              ? "Set as Global Home Page"
              : "Reset the Global Home Page"}
          </Menu.Item>
        )}
      </Menu>
    );
  }, [
    lookerContentType,
    roleMapping,
    location.pathname,
    addFavorite,
    lookerContentInfo,
    getLookerCopyInfo,
  ]);

  useEffect(() => {
    refreshDataTooltipInformation();
  }, []);

  const refreshDataTooltipInformation = () => {
    setDataLastRefreshDateTime(null);
    setLastETLRunCompletedDateTime(null);

    getLastDataRefreshDateTime().then(
      (result) => {
        setDataLastRefreshDateTime(result);
      },
      (error) => {
        setDataLastRefreshDateTime(error);
      }
    );

    getLastEtlCompletedDateTime().then(
      (result) => {
        setLastETLRunCompletedDateTime(result);
      },
      (error) => {
        setLastETLRunCompletedDateTime(error);
      }
    );
  };

  const openPrintDesignerWithData = () => {
    API.updatePrintDesignerAccessLog(
      "Open with Look",
      () => null,
      () => null
    );

    let url = printDesignerUrl + "/design?type=" + printDesignerData.type;
    if (printDesignerData.id) {
      url = url + "&id=" + printDesignerData.id;
    }
    if (printDesignerData.qid) {
      url = url + "&qid=" + printDesignerData.qid;
    }
    if (printDesignerData.filters) {
      url = url + "&filters=" + btoa(JSON.stringify(printDesignerData.filters));
    }
    window.open(url, "_blank");
  };

  const openPrintDesigner = () => {
    API.updatePrintDesignerAccessLog(
      "Open",
      (result: any) => console.log("test"),
      (error: Error) => console.log(error)
    );
    window.open(printDesignerUrl, "_blank");
  };

  const handleDataTooltipOpenChange = (open: boolean) => {
    if (open) {
      refreshDataTooltipInformation();
    }
  };

  return (
    <>
      {userHomeModalVisible && (
        <SetUserHomePage
          openNotification={openNotification}
          lookerContentInfo={lookerContentInfo}
          realm={realm}
          username={username}
          exit={exitUserHomeModal}
          resetMode={location.pathname === "/home"}
        />
      )}
      {realmHomeModalVisible && (
        <SetRealmHomePage
          openNotification={openNotification}
          lookerContentInfo={lookerContentInfo}
          realm={realm}
          exit={exitRealmHomeModal}
          resetMode={location.pathname === "/home"}
        />
      )}
      {groupHomeModalVisible &&
        !realmHomeModalVisible &&
        !userHomeModalVisible && (
          <SetGroupHomePage
            openNotification={openNotification}
            lookerContentInfo={lookerContentInfo}
            realm={realm}
            exit={exitGroupHomeModal}
            resetMode={location.pathname === "/home"}
          />
        )}
      {aboutModalVisible && <AboutModal closeModal={exitAboutModal} />}
      {copyLoading && (
        <div className="copy-loading-div">
          <CustomSpinner />
        </div>
      )}
      {copyModalVisible && !copyLoading && (
        <MoveToFolderContainer
          moveFrom={itemToCopy}
          abortMove={closeCopyModal}
          showAlert={showCopyAlert}
          moveFolderNavigation={null}
          triggerFolderRefresh={null}
          copy={true}
        />
      )}
      <Layout.Header
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
        className="header-height"
      >
        <Row>
          <Col span={8}>
            <h3 style={{ paddingTop: "20px" }}>
              <Space>
                EnterpriseRx Data Insights&trade;
                <Tooltip
                  onOpenChange={handleDataTooltipOpenChange}
                  overlayInnerStyle={{ width: "500px" }}
                  placement="bottom"
                  title={
                    <Space direction="vertical">
                      <Text style={{ color: "white" }}>
                        Last update completed:{" "}
                        {lastETLRunCompletedDateTime ?? <LoadingOutlined />}
                      </Text>
                      <Text style={{ color: "white" }}>
                        Data valid as of:{" "}
                        {dataLastRefreshDateTime ?? <LoadingOutlined />}
                      </Text>
                    </Space>
                  }
                >
                  <InfoCircleOutlined
                    style={{ fontSize: "20px", color: "white" }}
                    twoToneColor="#004870"
                  />
                </Tooltip>
              </Space>
            </h3>
          </Col>
          <Col span={16} style={{ textAlign: "right" }}>
            <Row justify="end">
              <Col xs={24} sm={24} md={24} lg={13} xl={15} xxl={17}>
                <Input.Search
                  className="header-search"
                  placeholder="Search Looks and Dashboards"
                  value={enteredText}
                  onChange={updateSearchText}
                  onSearch={startSearch}
                  width="100%"
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={11} xl={9} xxl={7}>
                <Row justify="end">
                  <Col>
                    <Tooltip
                      placement="bottomRight"
                      title="Notifications"
                      overlayClassName="behind-dropdown"
                    >
                      <NotificationsView
                        className="icon-vertical-alignment"
                        refreshNotificationsCount={refreshNotificationsCount}
                      />
                    </Tooltip>
                  </Col>
                  &ensp;&ensp;
                  <Col>
                    <FacilitySelector />
                  </Col>
                  &ensp;&ensp;
                  <Col>
                    <Tooltip
                      placement="bottomRight"
                      title={
                        printDesignerRole === PrintDesignerRole.NONE
                          ? "Print Designer - Disabled"
                          : "Print Designer"
                      }
                      overlayClassName="behind-dropdown"
                    >
                      <div className="print-designer-button">
                        <Dropdown
                          disabled={
                            printDesignerRole === PrintDesignerRole.NONE ||
                            width < 1000
                          }
                          overlay={
                            <Menu>
                              <Menu.Item key="1" onClick={openPrintDesigner}>
                                Open Print Designer
                              </Menu.Item>
                              <Menu.Item
                                key="2"
                                onClick={openPrintDesignerWithData}
                                disabled={
                                  printDesignerRole !==
                                    PrintDesignerRole.DESIGNER ||
                                  printDesignerData == null
                                }
                              >
                                Design with Look...
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            shape="circle"
                            className="icon-vertical-alignment"
                            icon={
                              <PrinterOutlined
                                style={{
                                  fontSize: "22px",
                                  verticalAlign: "middle",
                                }}
                              />
                            }
                          />
                        </Dropdown>
                      </div>
                    </Tooltip>
                  </Col>
                  &ensp;&ensp;
                  <Col>
                    <Tooltip
                      placement="bottomRight"
                      title="Settings"
                      overlayClassName="behind-dropdown"
                    >
                      <Dropdown overlay={settingsMenu} trigger={["click"]}>
                        <Button
                          type="primary"
                          shape="circle"
                          className="icon-vertical-alignment"
                          icon={
                            <SettingOutlined
                              style={{
                                fontSize: "22px",
                                verticalAlign: "middle",
                              }}
                            />
                          }
                        />
                      </Dropdown>
                    </Tooltip>
                  </Col>
                  &ensp;&ensp;
                  <Col>
                    <Tooltip
                      placement="bottomRight"
                      title="User Profile"
                      overlayClassName="behind-dropdown"
                    >
                      <Dropdown overlay={menu} trigger={["click"]}>
                        <Button
                          type="primary"
                          shape="circle"
                          className="icon-vertical-alignment"
                          icon={
                            <UserOutlined
                              style={{
                                fontSize: "22px",
                                verticalAlign: "middle",
                              }}
                            />
                          }
                        />
                      </Dropdown>
                    </Tooltip>
                  </Col>
                  &ensp;&ensp;
                  <Col>
                    <Tooltip
                      placement="bottomRight"
                      title="About"
                      overlayClassName="behind-dropdown"
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        className="icon-vertical-alignment"
                        onClick={() => setAboutModalVisible(true)}
                        icon={
                          <QuestionCircleOutlined
                            style={{
                              fontSize: "22px",
                              verticalAlign: "middle",
                            }}
                          />
                        }
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout.Header>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    username: state.userAuth.username,
    loginId: state.userAuth.loginId,
    isSupport: state.userAuth.isSupport,
    realm: state.userAuth.realm,
    sudoUser: state.userAuth.sudoUser,
    scopeOfControlUser: state.userAuth.scopeOfControlUser,
    roleMapping: state.userAuth.roleMapping,
    lookerContentType: state.filter.lookerContentType,
    printDesignerRole: state.userAuth.printDesignerRole,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
