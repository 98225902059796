import { Table, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";
import { IDashboardInfo, IDisplayFolderItem, IDisplayFolderItemType, IFolderInfo, ILookInfo, IRootFolderIds, ReportsAction, IContentDisplayTypes, IEmbedUsername } from "../../../services/FolderManagement/FolderModels";
import ReportsFolderItemOptionsMenu from "../ReportsFolderItemOptionsMenu/ReportsFolderItemOptionsMenu";
import "./ReportsListContainer.scss";
import { Link } from "react-router-dom";
import { Role, Permission } from "../../../services/RoleManagement/RoleModels";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store/Store";
import { isAuthorized } from "../../../services/RoleManagement/RoleManagement";
import { IEditFolderPermissions } from "../../../services/AdminManagement/FolderPermissionsManagement/FolderPermissionsModels";
import FavoritesItemOptionsMenu from "../../Favorites/FavoritesItemOptionsMenu/FavoritesItemOptionsMenu";

interface IProps {
    type: IDisplayFolderItemType;
    data: IDashboardInfo[] | ILookInfo[];
    display: IContentDisplayTypes;
    rootFolders: IRootFolderIds;
    folder: IFolderInfo;
    groupMemberNameMap: Map<string, IEmbedUsername>;
    roleMapping: Map<Role, boolean>;
    setAction: (action: ReportsAction) => void;
    goToItem: (item: IDisplayFolderItem) => void;
    reloadFavorites: () => void;
    editFolderPermissions: IEditFolderPermissions;
}

const ReportsListContainer: React.FC<IProps> = ( props: IProps ) => {

  const serverHost = process.env.REACT_APP_DATA_INSIGHTS_API_BASE_URL;

  return (
    <div className="folder-content-list-area">    
      <Table 
        pagination={false}
        dataSource={props.data}
        rowKey={(element) => (props.type + element.id)}
        onRow={(element) => {
          return {
            onClick: () => props.goToItem({
              type: props.type,
              name: element.title,
              id: element.id,
              parent_id: element.folder_id ? element.folder_id : null,
              parent_name: element.folder_name ? element.folder_name: null,
            })
          };
        }}
        >
        <Column
          title=""
          key="thumbnail"
          width={100}
          render={(element)=> ( 
            element.folder_id === '0' ? 
            <Tooltip title='Preview Not Available'>
              <div className="element-thumbnail" style={{textAlign: "center"}}>---</div>
            </Tooltip>
            :
            <img alt="Dashboard" src={serverHost + "/looker/content/content_thumbnail/" +
             (props.type===IDisplayFolderItemType.LOOK ? "look/" : "dashboard/") + element.id}
              className="element-thumbnail"
            />) 
          }
        />
        <Column
          title="Name"
          key="name"
          width={300}
          render={(element) => (
            <>
            <p className="element-title">{element.title}</p>
            { props.display === IContentDisplayTypes.GROUP && 
              <p className="element-description">
              {
                (
                  element.user_id && props.groupMemberNameMap && props.groupMemberNameMap.has(element.user_id) ?
                  ('Created by ' + props.groupMemberNameMap.get(element.user_id).display_name) : 
                  ("\u00A0")
                )
              }
              </p>
            }
            </>
          )}
        />
        {
          props.display === IContentDisplayTypes.HISTORY &&
          <Column
            title="Last Viewed"
            key="lastViewed"
            width={100}
            dataIndex="last_viewed"
          />
        }
        {
          props.display === IContentDisplayTypes.SEARCH &&
          <Column
            title="Location"
            key="location"
            width={100}
            dataIndex="folder_name"
          />
        }
        {
          props.display === IContentDisplayTypes.GROUP && 
          isAuthorized(props.roleMapping, Permission.VIEW_EXPLORE_TAB) &&
          ( props.type === IDisplayFolderItemType.LOOK ?
            <Column
              title=""
              key="exploreFromHere"
              width={100}
              render={(element) => 
                <Link className="custom-link" to={'/explore/look/' + element.id}>
                  <span>Explore From Here</span>
                </Link>}
            /> 
            :
            <Column
              title=""
              key=""
              width={100}
            />
          )
        }
        {
          props.display === IContentDisplayTypes.GROUP && props.type === IDisplayFolderItemType.DASHBOARD &&
          <Column
            title="Created"
            key="created"
            width={100}
            dataIndex="created_at"
            render={(value) => <span>{ value.length > 10 ? value.substring(0,10) : value}</span>}
          />
        }
        {
          props.display === IContentDisplayTypes.GROUP && props.type === IDisplayFolderItemType.LOOK &&
          <Column
            title="Last Updated"
            key="lastupdated"
            width={100}
            dataIndex="updated_at"
            render={(value) => <span>{ value.length > 10 ? value.substring(0,10) : value}</span>}
          />
        }
        { props.folder && props.setAction && props.rootFolders && isAuthorized(props.roleMapping, Permission.EDIT_GROUPED_REPORTS) &&
          props.editFolderPermissions.userCanEditContent &&
          <Column
            title="Options"
            key="options"
            width={50}
            render={(element) =>  (
              <ReportsFolderItemOptionsMenu 
              item={{
                type: props.type,
                name: element.title,
                id: element.id
              }}
              folderInfo={props.folder}
              setAction={props.setAction}
              rootFolders={props.rootFolders}
              roleMapping={props.roleMapping}
              userCanDeleteFolder={props.editFolderPermissions.userCanDeleteFolder}
              />
            )}
          />    
        }
        { props.display === IContentDisplayTypes.FAVORITES &&
          <Column
          title="Options"
          key="options"
          width={50}
          render={(element) =>  (
            <FavoritesItemOptionsMenu favoriteId={element.favorite_id} reloadFavorites={props.reloadFavorites}/>
          )}
        /> 
        }
      </Table>
      <br />
    </div>   
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    roleMapping: state.userAuth.roleMapping,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsListContainer);
