import { CloseOutlined, TeamOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, List, Modal, Row, Select, Space, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IAlertInfo } from "../../AppModels";
import { IGroupInfo, IUserInfo } from "../../services/AdminManagement/EditGroupsManagement/EditGroupsModels";
import { IFolderPermission, IFolderPermissions } from "../../services/AdminManagement/FolderPermissionsManagement/FolderPermissionsModels";
import { IDisplayFolderItem } from "../../services/FolderManagement/FolderModels";
import { clearState, modifyFolderPermission } from "../../store/actions/EditFolderPermissionsActions";
import { getCustomGroups, getLicensedUsers } from "../../store/actions/EditGroupsActions";
import { IApplicationState } from '../../store/Store';
import "./EditFolderPermissions.scss";

interface IProps {
  isVisible: boolean;
  toggleModal(): void;
  item: IDisplayFolderItem;
  loading: boolean;
  setLoading(isLoading: boolean): void;
}

const { Option } = Select;
const { Text, Paragraph } = Typography;

const EditFolderPermissions: React.FC<IProps> = (props: IProps) => {

  const dispatch = useDispatch();

  const folderInfo: IFolderPermissions = useSelector<IApplicationState>(state => state.editFolderPermissions.folderPermissionStatus) as IFolderPermissions;
  const customGroups: IGroupInfo[] = useSelector<IApplicationState>(state => state.editGroups.customGroupDetails) as IGroupInfo[];
  const licensedUsers: IUserInfo[] = useSelector<IApplicationState>(state => state.editGroups.licensedUsers) as IUserInfo[];
  const modifyPermissionError: string = useSelector<IApplicationState>(state => state.editFolderPermissions.setFolderPermissionError) as string;
  const getPermissionStatusError: string = useSelector<IApplicationState>(state => state.editFolderPermissions.getFolderPermissionsError) as string;

  const [currentPermissions, setCurrentPermissions] = useState<IFolderPermission[]>([]);
  const [candidateGroups, setCandidateGroups] = useState<IGroupInfo[]>([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState('view');
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({ visible: false, type: 'warning', message: '', description: '' });

  useEffect(() => {

    let permissions: IFolderPermission[] = [];
    let groups = [];

    // object to store every group along with user counts. initialized with ExploreUsers and ViewUsers
    let memberCounts = {
      'ViewUsers': licensedUsers.filter(user => user.holdingLicense && user.license === 'View').length,
      'ExploreUsers': licensedUsers.filter(user => user.holdingLicense && user.license === 'Explore').length
    }

    if (props.isVisible && folderInfo) {

      // adds each custom group along with user count to memberCounts object
      if (customGroups) {
        for (let group of customGroups) {
          memberCounts[group.groupName] = group.totalUniqueUsers;
        }
      }

      // loads currentPermissions of the current folder
      for (let candidate of folderInfo.candidateGroups) {
        for (let item of folderInfo.currentPermissions) {
          if (item.groupname === candidate) {
            permissions.push({ ...item, users: memberCounts[candidate] });
          }
        }

        // adds candidate groups to the dropdown if they aren't already in the currentPermissions
        if (folderInfo.currentPermissions.filter(item => item.groupname === candidate).length === 0) {
          groups.push(
            <Option
              value={candidate}
              key={candidate}
            >
              <Row gutter={[15, 0]}>
                <Col span={2}>
                  <TeamOutlined />
                </Col>
                <Col span={14}>
                  <Paragraph ellipsis={{ tooltip: true }} style={{ width: '100%' }}>
                    {candidate}
                  </Paragraph>
                </Col>
                <Col span={6}>
                  <Text>
                    {memberCounts[candidate]} {memberCounts[candidate] === 1 ? 'user' : 'users'}
                  </Text>
                </Col>
              </Row>
            </Option>
          );
        }
      }
    }
    setCurrentPermissions(permissions);
    setCandidateGroups(groups);
  }, [props.isVisible, folderInfo, customGroups, licensedUsers])

  useEffect(() => {
    if (props.isVisible) {
      dispatch(getCustomGroups());
      dispatch(getLicensedUsers());
    } else if (!props.isVisible) {
      dispatch(clearState());
      setAlertInfo({
        visible: false,
        type: 'success',
        message: '',
        description: ''
      })
    }
  }, [props.isVisible, dispatch])

  useEffect(() => {
    if (getPermissionStatusError === 'error') {
      setAlertInfo({
        visible: true,
        type: 'error',
        message: 'There was an unexpected error loading the permissions!',
        description: ''
      })
    }

    if (modifyPermissionError === 'error') {
      setAlertInfo({
        visible: true,
        type: 'error',
        message: 'There was an unexpected error modifying the permissions!',
        description: ''
      })
    } else if (modifyPermissionError === 'success') {
      setAlertInfo({
        visible: true,
        type: 'success',
        message: 'Permissions were updated successfully!',
        description: ''
      })
    }
  }, [getPermissionStatusError, modifyPermissionError])

  const handleGroupSelectChange = (options: []) => {
    setSelectedGroups(options);
  }

  const handlePermissionSelectChange = (value) => {
    setSelectedPermission(value);
  }

  const onAlertClose = () => {
    setAlertInfo({
      visible: false,
      type: 'success',
      message: '',
      description: '',
    })
  }

  const removePermission = async (item) => {
    props.setLoading(true);
    await dispatch(modifyFolderPermission(props.item.id, item.groupname, 'none'));
    props.setLoading(false);

  }

  const addPermission = async () => {
    props.setLoading(true);
    for (let group of selectedGroups) {
      await dispatch(modifyFolderPermission(props.item.id, group, selectedPermission));
      setSelectedGroups([]);
    }
    props.setLoading(false);

  }

  const editPermission = async (item, value) => {
    props.setLoading(true);
    await dispatch(modifyFolderPermission(props.item.id, item.groupname, value));
    props.setLoading(false);
  }

  return (
    <>
      <Modal
        width={650}
        title={`Manage Access - ${props.item.name}`}
        visible={props.isVisible}
        onCancel={props.toggleModal}
        footer={
          <Button
            type='primary'
            onClick={props.toggleModal}
          >
            Close
          </Button>
        }>
        <Text className='perm-modal-header'>Who can access this folder?</Text>
        <Divider style={{ marginBottom: 0, marginTop: 10 }} />
        {
          alertInfo.visible &&
          <Alert
            type={alertInfo.type}
            message={alertInfo.message}
            description={alertInfo.description}
            closable
            onClose={onAlertClose}
          />
        }
        <List
          loading={props.loading}
          itemLayout='horizontal'
          dataSource={currentPermissions}
          renderItem={item => (
            <List.Item>
              <Row style={{ width: '100%' }} gutter={24}>
                <Col span={2}>
                  <div className='centered'>
                    <TeamOutlined />
                  </div>
                </Col>
                <Col span={12}>
                  <div className='centered-group-name'>
                    <Space
                      direction='vertical'
                      size={0}
                    >
                      <Text>{item.groupname}</Text>
                      <Text style={{ fontSize: '12px', color: 'gray' }}>{item.users} {item.users === 1 ? 'user' : 'users'}</Text>
                    </Space>
                  </div>
                </Col>
                <Col span={8}>
                  <div className='centered'>
                    <Select
                      className='perm-select'
                      defaultValue={item.permission}
                      onChange={(value) => editPermission(item, value)}
                    >
                      <Option value="view">View</Option>
                      <Option value="edit">Edit</Option>
                    </Select>
                  </div>
                </Col>
                <Col span={2}>
                  <div className='centered icon-btn' onClick={() => removePermission(item)}>
                    <Tooltip title='Remove'>
                      <CloseOutlined style={{ fontSize: 14 }} />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            </List.Item>
          )} />
        <List>
          <List.Item style={{ backgroundColor: "#f6f6f7" }}>
            <Row style={{ width: '100%', margin: 0 }} gutter={24}>
              <Col span={12}>
                <div className='centered'>
                  <Select
                    mode='multiple'
                    allowClear
                    disabled={props.loading}
                    style={{ width: '100%' }}
                    placeholder='Add group...'
                    onChange={handleGroupSelectChange}
                    optionLabelProp="label"
                    value={selectedGroups}
                  >
                    {candidateGroups}
                  </Select>
                </div>
              </Col>
              <Col span={8}>
                <div className='centered'>
                  <Select
                    disabled={props.loading}
                    className='perm-select'
                    defaultValue={"view"}
                    onChange={handlePermissionSelectChange}
                  >
                    <Option value="view">View</Option>
                    <Option value="edit">Edit</Option>
                  </Select>
                </div>
              </Col>
              <Col span={4}>
                <div className='centered'>
                  <Button type='primary' onClick={addPermission} disabled={selectedGroups.length === 0}>Add</Button>
                </div>
              </Col>
            </Row>
          </List.Item>
        </List>
      </Modal>
    </>
  );
};

export default EditFolderPermissions;