import { Col, Layout, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { IAlertInfo } from "../../AppModels";
import { IDataModelField, IDocument, IExploreTile } from "../../services/DocumentationManagement/DocumentationModels";
import { getReportsDocumentation, getDataModelDocumentation, getExploreTileInfo } from "../../services/DocumentationManagement/DocumentationManagement";
import "./Documentation.scss";
import DocumentationView from "./DocumentationView/DocumentationView";
import DataDocumentationView from "./DataDocumentationView/DataDocumentationView";
import MiscDocumentationView from "./MiscDocumentationView/MiscDocumentationView";
import LinksDocumentationView from "./LinksDocumentationView/LinksDocumentationView";

const { TabPane } = Tabs;

interface IProps { }

const Documentation: React.FC<IProps> = (props: IProps) => {

  const [loading, setLoading] = useState(false);
  const [loadingDataModel, setLoadingDataModel] = useState(false);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({ visible: false, type: 'warning', message: '', description: '' });
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [fields, setFields] = useState<IDataModelField[]>([]);
  const [explores, setExplores] = useState<IExploreTile[]>([]);
  const [exploreSelection, setExploreSelection] = useState("");
  const [tab, setTab] = useState("1");

  const handleReportsDocumentation = async () => {
    const setResult = (result: IDocument[]) => {
      setDocuments(result);
      setLoading(false);
    }

    const setError = (error: Error) => {
      setAlertInfo({
        visible: true,
        type: 'error',
        message: 'There was an error retrieving the reports documentation!',
        description: '',
      })
      console.log(error);
    }

    setLoading(true);
    await getReportsDocumentation(setResult, setError);
    
  }

  const handleDataModelDocumentation = async () => {
    const setDataModelResult = (result: IDataModelField[]) => {
      setFields(result)
    }

    const setExploresResult = (result: IExploreTile[]) => {
      setExplores(result)
    }

    const setError = (error: Error) => {
      setAlertInfo({
        visible: true,
        type: 'error',
        message: 'There was an error retrieving the data model documentation!',
        description: '',
      })
      console.log(error);

      setFields([]);
      setExplores([]);
    }

    setLoadingDataModel(true);
    await getDataModelDocumentation(setDataModelResult, setError);
    await getExploreTileInfo(setExploresResult, setError);
    setLoadingDataModel(false);
  }

  useEffect(() => {
    document.title = "Documentation";
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('explore-name')) {
      setExploreSelection(urlParams.get('explore-name').replaceAll('-', '_'));
      setTab("2");
    }
    handleDataModelDocumentation();
    handleReportsDocumentation();

  }, []);

  const handleTabChange = (activeKey) => {
    if (activeKey === 1) {
      setTab(activeKey);
    } else {
      setTab(activeKey);
    }
  }

  return (
    <>
      <Layout>
        <div className="docs-content-area">
          <Layout.Content>
            <br />
            <Row style={{ height: 35.5 }}>
              <Col span={24}>
                <h3 style={{ float: 'left', margin: 0 }}>Documentation</h3>
              </Col>
            </Row>
            <br />
            <Tabs type="card" onChange={handleTabChange} activeKey={tab}>
              <TabPane tab="Standard Reports" key="1">
                <DocumentationView
                  documents={documents}
                  setLoading={setLoading}
                  loading={loading}
                  setAlertInfo={setAlertInfo}
                  {...alertInfo} />
              </TabPane>
              <TabPane tab="Data Model" key="2">
                <DataDocumentationView
                  fields={fields}
                  exploresInfo={explores}
                  setLoading={setLoadingDataModel}
                  loading={loadingDataModel}
                  setAlertInfo={setAlertInfo}
                  defaultExpanded={exploreSelection}
                  {...alertInfo} />
              </TabPane>
              <TabPane tab="Misc. Documents" key="3">
                <MiscDocumentationView
                  documents={documents}
                  setLoading={setLoading}
                  loading={loading}
                  setAlertInfo={setAlertInfo}
                  {...alertInfo} />
              </TabPane>
              <TabPane tab="Links" key="4">
                <LinksDocumentationView/>
              </TabPane>
            </Tabs>

          </Layout.Content>
        </div>
      </Layout>
    </>
  );
};

export default Documentation;