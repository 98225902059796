import React, { useEffect, useState } from "react";
import "./NotificationManagementModal.scss";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  ModalProps,
  Row,
  Select,
  Space,
} from "antd";
import {
  INotification,
  INotificationForm,
  IRealmUser,
} from "../../../services/NotificationsManagement/NotificationsModels";
import moment from "moment-timezone";
import {
  getAllRealms,
  getUsersInRealms,
  sendNotifications,
  editNotification
} from "../../../services/NotificationsManagement/NotificationsManagement";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IAlertInfo } from "../../../AppModels";
import { IApiError } from "../../../services/LookerAPI/LookerAPI";

interface INotificationManagementModalProps extends ModalProps {
  alertInfo: IAlertInfo;
  setAlertInfo: React.Dispatch<React.SetStateAction<IAlertInfo>>;
  toggleModal: (toggle: boolean) => void;
  refreshNotifications: () => void;
  record?: INotification;
}

const NotificationManagementModal: React.FC<
  INotificationManagementModalProps
> = ({ alertInfo, setAlertInfo, toggleModal, refreshNotifications, record, ...modalProps }) => {

  
  const [form] = Form.useForm();
  const [isRealmBoxChecked, setShowRealm] = useState(false);
  const [isRealmUsernameBoxChecked, setShowRealmUsername] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);

  const [realmOptions, setRealmOptions] = useState<{label, value}[]>([]);
  const [selectedRealms, setSelectedRealms] = useState<string[]>([]);
  const [userOptions, setUserOptions] = useState<{label, value}[]>([]);
  const [selectedUsernames, setSelectedUsernames] = useState<string[]>([]);
  const [userMap, setUserMap] = useState<Map<string, IRealmUser>>(new Map<string, IRealmUser>())

  const [modalAlertInfo, setModalAlertInfo] = React.useState<IAlertInfo>({
    type: "error",
    message: "",
    description: "",
    visible: false
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        title: record.title,
        summary: record.summary,
        severity: record.severity,
        dateTime: moment(record.startDate),
        link: record.link,
        modalInfo: record.modalInfo,
        realms: record.realm ? [record.realm] : [],
        realmUsernames: record.realmUsername ? [record.realmUsername] : [],
      });
      if (record.realm) {
        getRealms().then(
          () => {
            setShowRealm(true);
            setSelectedRealms([record.realm]);
          },
          (error: Error) => {
            console.log(error);
          }
        );
      }
      if (record.realmUsername) {
        getUsers([record.realm]).then(
          () => {
            setShowRealmUsername(true);
            setSelectedUsernames([record.realmUsername]);
          },
          (error: Error) => {
            console.log(error);
          }
        );
      }
      if (record.link) {
        setShowLink(true);
      }
      if (record.modalInfo) {
        setShowModalInfo(true);
      }
    }
  }, [record, form])


  const getRealms = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      getAllRealms().then(
        (realms: string[]) => {
          setRealmOptions([...realms.map(realm => ({label: realm, value: realm}))]);
          resolve();
        },
        (error: Error) => {
          console.log(error);
          reject(error);
        }
      );
    });
  }

  const getUsers = (realms: string[]): Promise<void> => {
    return new Promise((resolve, reject) => {
      getUsersInRealms(realms).then(
        (users: IRealmUser[]) => {
          const seenRealmUsernames = new Set();
          const filteredUsers = users.filter(user => {
            if (!seenRealmUsernames.has(user.realmUsername)) {
              seenRealmUsernames.add(user.realmUsername);
              return true;
            }
            return false;
          });
          setUserMap(users.reduce((map, user) => {
            map.set(user.realmUsername, user);
            return map;
          }, new Map<string, IRealmUser>()));
          setUserOptions([...filteredUsers.map(user => ({label: user.realmUsername, value: user.realmUsername}))]);
          resolve();
        },
        (error: Error) => {
          console.log(error);
          reject(error);
        }
      )
    });
  }
  
  const resetUserSelection = () => {
    setUserOptions([]);
    setSelectedUsernames([])
    form.setFieldValue('realmUsernames', [])
  }

  const resetRealmSelection = () => {
    setRealmOptions([]);
    setSelectedRealms([])
    form.setFieldValue('realms', [])
  }


  const handleRealmsCheckboxChange = (e: CheckboxChangeEvent) => {
    if (isRealmUsernameBoxChecked) {
      setShowRealmUsername(false);
      resetUserSelection();
    }
    if (e.target.checked) {
      getRealms()
    } else {
      resetRealmSelection();
    }
    setShowRealm(e.target.checked);
  };

  const handleUsersCheckboxChange = (e: CheckboxChangeEvent) => {
    getUsers(selectedRealms);
    if (!e.target.checked) {
      resetUserSelection();
    }
    setShowRealmUsername(e.target.checked);
  };

  useEffect(() => {
    if (isRealmUsernameBoxChecked){
      getUsers(selectedRealms)

      const newSelections = selectedUsernames?.filter((username) => {
        if (userMap.has(username)) {
          return selectedRealms.includes(userMap.get(username).realm)
        } else {
          return false
        }
      })
      form.setFieldValue('realmUsernames', newSelections)
      setSelectedUsernames(newSelections);
    }
  // eslint-disable-next-line
  }, [selectedRealms])

  const onFinish = async (values: INotificationForm): Promise<void> => {
    const { timezone, dateTime, realms, realmUsernames, ...otherValues } =
      values;

    const dateTimeAsMoment = moment(dateTime);

    let newNotifications: INotification[] = [];
    let notificationBuilder: INotification = {
      startDate: dateTimeAsMoment.format("YYYY-MM-DDTHH:mm:ss.000Z"),
      ...otherValues,
    };

    if (realms) {
      realms.forEach((realm) => {
        notificationBuilder = {
          ...notificationBuilder,
          realm,
        };

        if (realmUsernames) {
          realmUsernames.forEach((realmUsername) => {
            if (userMap.get(realmUsername).realm === realm) {
              newNotifications.push({
                ...notificationBuilder,
                realmUsername,
              });
            }
          });
        } else {
          newNotifications.push(notificationBuilder);
        }
      });
    } else {
      newNotifications.push(notificationBuilder);
    }

    return new Promise<void>((resolve, reject) => {
      sendNotifications(newNotifications).then(
        () => {
          setAlertInfo({
            message: "Notification(s) sent successfully!",
            type: "success",
            visible: true,
          });
          toggleModal(false);
          resolve();
        },
        (error: IApiError) => {
          setModalAlertInfo({
            message: error.errorCode === 403 ? `${error.errorCode} - ${error.status}: ${error.message}` : "Error sending notification(s)",
            type: "error",
            visible: true,
          });
          console.log(error);
          reject(error);
        }
      ).finally(refreshNotifications);
    });
  };

  const onSave = async (values: INotificationForm): Promise<void> => {

    console.log(values)
    const { timezone, dateTime, realms, realmUsernames, ...otherValues } = values;
    const realmsArray = Array.isArray(realms) ? realms : (realms ? [realms] : []);
    const realmUsernamesArray = Array.isArray(realmUsernames) ? realmUsernames : (realmUsernames ? [realmUsernames] : []);

    const dateTimeAsMoment = moment(dateTime);

    let newNotification: INotification = null;
    let notificationBuilder: INotification = {
      notificationId: record.notificationId,
      startDate: dateTimeAsMoment.format("YYYY-MM-DDTHH:mm:ss.000Z"),
      ...otherValues,
    };

    if (realms) {
      realmsArray.forEach((realm) => {
        notificationBuilder = {
          ...notificationBuilder,
          realm,
        };

        if (realmUsernames) {
          realmUsernamesArray.forEach((realmUsername) => {
            if (userMap.get(realmUsername).realm === realm) {
              newNotification = {
                ...notificationBuilder,
                realmUsername,
              }
            }
          });
        } else {
          newNotification = notificationBuilder;
        }
      });
    } else {
      newNotification = notificationBuilder;
    }

    return new Promise<void>((resolve, reject) => {
      editNotification(newNotification).then(
        () => {
          setAlertInfo({
            message: "Notification saved successfully!",
            type: "success",
            visible: true,
          });
          toggleModal(false);
          resolve();
        },
        (error: IApiError) => {
          setModalAlertInfo({
            message: error.errorCode === 403 ? `${error.errorCode} - ${error.status}: ${error.message}` : "Error saving notification",
            type: "error",
            visible: true,
          });
          console.log(error);
          reject(error);
        }
      ).finally(refreshNotifications);
    });
  };


  return (
    <Modal
      {...modalProps}
      footer={[
        <Button key="close" onClick={(e) => modalProps.onCancel(e)}>
          Cancel
        </Button>,
        record ? 
        <Button
        key="edit"
        type="primary"
        loading={isSubmitting}
        onClick={(e) => {

          setIsSubmitting(true)

          form
            .validateFields()
            .then(async (values) => {
              await onSave(values);
            })
            .catch((info) => {
              console.log("Validate Failed: ", info);
            }).finally(() => {
              setIsSubmitting(false)
            });
        }}
      >
        Save
      </Button>
      :
      <Button
        key="send"
        type="primary"
        loading={isSubmitting}
        onClick={(e) => {

          setIsSubmitting(true)

          form
            .validateFields()
            .then(async (values) => {
              await onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed: ", info);
            }).finally(() => {
              setIsSubmitting(false)
            });
        }}
        >
          Send
        </Button>
      ]}
    >
      <Layout>
        <Layout.Content>
          <Space direction="vertical" style={{ width: "100%" }}>
            {modalAlertInfo.visible && (
              <Row style={{ marginBottom: "10px" }}>
                <Col span={24}>
                  <Alert {...modalAlertInfo} />
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <div className="notif-details-content-area">
                  <Form
                    form={form}
                    requiredMark={true}
                    layout="vertical"
                    scrollToFirstError
                    onSubmitCapture={(event) => {
                      event.preventDefault();
                    }}
                    onFinishFailed={(errorInfo) => {
                      console.log(errorInfo);
                    }}
                  >
                    {/* Text Inputs */}
                    <Form.Item
                      style={{width: 300}}
                      label="Title"
                      name="title"
                      rules={[{ required: true, message: "Please input the title!" }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Summary"
                      name="summary"
                      rules={[
                        { required: true, message: "Please input the summary!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    {/* Dropdowns */}
                    <Form.Item
                      style={{width: 110}}
                      label="Severity"
                      name="severity"
                      initialValue={"INFO"}
                      rules={[
                        { required: true, message: "Please select a severity!" },
                      ]}
                    >
                      <Select placeholder="Select a severity">
                        <Select.Option value="INFO">INFO</Select.Option>
                        <Select.Option value="CRITICAL">CRITICAL</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Start Date/Time"
                      tooltip="When to push the notification"
                      required
                      style={{ marginBottom: 0 }}
                    >
                      <Row gutter={20}>
                        <Col>
                          <Form.Item
                            name="dateTime"
                            rules={[
                              {
                                required: true,
                                message: "Please pick a date and time!",
                              },
                            ]}
                          >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            name="timezone"
                            initialValue={"Etc/UTC"}
                            rules={[
                              {
                                required: true,
                                message: "Please select a timezone!",
                              },
                            ]}
                          >
                            <Select placeholder="Select a timezone" disabled={true}>
                              {/* <Select.Option value="America/Los_Angeles">
                                America/Los_Angeles
                              </Select.Option>
                              <Select.Option value="America/Chicago">
                                America/Chicago
                              </Select.Option>
                              <Select.Option value="America/New_York">
                                America/New_York
                              </Select.Option> */}
                              <Select.Option value="America/New_York">
                                Etc/UTC
                              </Select.Option>
                              {/* ... other timezones */}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>

                    <Checkbox
                      checked={showLink}
                      onChange={(e) => setShowLink(e.target.checked)}
                    >
                      Add Link
                    </Checkbox>

                    {showLink && (
                      <Form.Item
                        label="Link"
                        name="link"
                        rules={[
                          { required: true, message: "Please input the link!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}

                    <Checkbox
                      checked={showModalInfo}
                      onChange={(e) => setShowModalInfo(e.target.checked)}
                    >
                      Add Details
                    </Checkbox>

                    {/* Text Area */}
                    {showModalInfo && (
                      <Form.Item
                        label="Extra Details"
                        name="modalInfo"
                        rules={[
                          { required: true, message: "Please input the modal info!" },
                        ]}
                      >
                        <Input.TextArea rows={4} />
                      </Form.Item>
                    )}

                    <Checkbox
                      checked={isRealmBoxChecked}
                      onChange={handleRealmsCheckboxChange}
                    >
                      Specific Realms
                    </Checkbox>

                    {/* Conditionally rendered dropdowns */}
                    {isRealmBoxChecked && (
                      <Form.Item
                        label="Realms"
                        name="realms"
                        rules={[
                          { required: true, message: "Please select at least one realm!" },
                        ]}
                        
                      >
                        <Select 
                        options={realmOptions}
                        {...(record ? {} : { mode: "multiple" })}
                        disabled={record ? true : false}
                        placeholder="Select realm(s)"
                        value={selectedRealms}
                        onChange={setSelectedRealms}/>
                      </Form.Item>
                    )}

                    {/* Checkboxes for optional fields */}

                    {isRealmBoxChecked && (
                      <Checkbox
                        checked={isRealmUsernameBoxChecked}
                        onChange={handleUsersCheckboxChange}
                      >
                        Specific Users
                      </Checkbox>
                    )}

                    {isRealmUsernameBoxChecked && (
                      <Form.Item
                        label="Realm Usernames"
                        name="realmUsernames"
                        rules={[
                          {
                            required: true,
                            message: "Please select at least one realm username!",
                          },
                        ]}
                      >
                        <Select 
                        options={userOptions}
                        {...(record ? {} : { mode: "multiple" })}
                        disabled={record ? true : false}
                        value={selectedUsernames}
                        placeholder="Select username(s)"
                        onChange={setSelectedUsernames}/>
                      </Form.Item>
                    )}
                  </Form>
                </div>
              </Col>
            </Row>
          </Space>
        </Layout.Content>
      </Layout>
    </Modal>
  );
};

export default NotificationManagementModal;
