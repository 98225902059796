import { Alert, Layout } from "antd";
import React from "react";

interface IProps {

}

const InvalidRoute: React.FC<IProps> = () => {

  return (
    <Layout.Content 
      style = {{
        padding: "32px 64px"
      }}
    > 
      <Alert 
        style={{ marginBottom: "24px"  }}
        type="error"
        message="The current URL does not exist or you do not have access to it."  
      />
    </Layout.Content>
  );
};

export default InvalidRoute;
