import { Button, message, Modal, Space, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import React from 'react'
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv"
import { IDataModelField, IDataSearchState } from '../../../../services/DocumentationManagement/DocumentationModels';
import { SearchStateDispatch } from '../DataDocumentationView';
import './DataDocumentationSearchModal.scss'

interface IProps {
    searchState: IDataSearchState;
}

interface ITableFilters {
    explore: string[],
    fieldName: string[],
    csvData: IDataModelField[]
}

// used to validate only current state variables are being set in the onChange event in the Table component
// even if new filters are added to the table without adding them here
enum FilterTypes {
    explore="explore",
    fieldName="fieldName"
}

const { Text } = Typography;

const DataDocumentationSearchModal: React.FC<IProps> = ({searchState}) => {

    const setSearchState = React.useContext<React.Dispatch<Partial<IDataSearchState>>>(SearchStateDispatch);

    const [filters, setFilters] = React.useReducer((prev: ITableFilters, next: Partial<ITableFilters>) => {

        let newData = searchState.results

        newData = newData.filter(value => {
                if (next.explore === null) {
                    return true
                } else {
                    return next.explore.includes(value.exploreLabel)
                }
            }
        )

        newData = newData.filter(value => {
                if (next.fieldName === null) {
                    return true
                } else {
                    return  next.fieldName.includes(value.fieldName)
                }
            }
        )

        // added to support empty sets of data when exporting to csv
        if (newData.length === 0) {
            newData.push({
                fieldName: null,
                viewName: null,
                fieldType: null,
                dataFormat: null,
                fieldDescription: null,
                exploreName: null,
                exploreLabel: null,
                dataSource: null
            })
        }

        return {
            ...prev,
            ...next,
            csvData: newData
        }
    }, {explore: null, fieldName: null, csvData: searchState.results})

    const columns: ColumnsType<IDataModelField> = [
        { 
            title: 'Field Name', 
            dataIndex: 'fieldName', 
            key: 'fieldName', 
            width: '15%',
            filters: searchState.results.reduce<ColumnFilterItem[]>((previousValue, currentValue, index) => {

                const newFilter = {
                    text: currentValue.fieldName,
                    value: currentValue.fieldName
                }

                if (!previousValue.find((filter) => filter.value === currentValue.fieldName)) {
                    previousValue.push(newFilter)
                    
                }
                    
                return previousValue
                
            }, []),
            sortDirections: ['descend', 'ascend'],
            onFilter: (value: string, record) => record.fieldName === value,
            sorter: (a, b) => a.fieldName.localeCompare(b.fieldName),
        },
        {
            title: 'Explore Name',
            dataIndex: 'exploreLabel',
            key: 'explore',
            width: '15%',
            filters: searchState.results.reduce<ColumnFilterItem[]>((previousValue, currentValue, index) => {

                const newFilter = {
                    text: currentValue.exploreLabel,
                    value: currentValue.exploreLabel
                }

                if (!previousValue.find((filter) => filter.value === currentValue.exploreLabel)) {
                    previousValue.push(newFilter)
                    
                }
                    
                return previousValue
                
            }, []),
            sortDirections: ['descend', 'ascend'],
            onFilter: (value: string, record) => record.exploreLabel === value,
            sorter: (a, b) => a.exploreLabel.localeCompare(b.exploreLabel),
            render: (value, record, index) => {
                return (
                    <Link to={`/explore?name=${record.exploreName.replaceAll("_", "-")}`}>
                        <Text underline>{value}</Text>
                    </Link>
                )
            }
        },
        {
            title: 'View name',
            dataIndex: 'viewName',
            key: 'viewName',
            width: '15%',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.viewName.localeCompare(b.viewName),
        },
        {
            title: 'Data Format',
            dataIndex: 'dataFormat',
            key: 'dataFormat',
            width: '10%',
            align: 'center',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.exploreLabel.localeCompare(b.exploreLabel),
        },
        {
            title: 
            <Space className="data-doc-title">
                <Text>Description</Text>
                <Button type="primary" style={{float: 'right'}}>
                    <CSVLink
                    filename={"search_results.csv"}
                    data={filters.csvData.map((value) => {
                        return {
                            field_name: value.fieldName,
                            explore_name: value.exploreLabel,
                            view_name: value.viewName,
                            data_format: value.dataFormat,
                            description: value.fieldDescription
                        }
                    })}
                    onClick={() => {
                        message.success("The file is downloading")
                    }}
                    >
                    Export to CSV
                    </CSVLink>
                </Button>
            </Space>,
            dataIndex: 'fieldDescription',
            key: 'fieldDescription',
            width: '45%',
        },
    ];
 
    return (
        <Modal 
        width={1350}
        title={`Search Results for '${searchState.text}'`}
        visible={searchState.visible}
        onCancel={() => setSearchState({visible: false})}
        footer={
            <Button type="primary" onClick={() => setSearchState({visible: false})}>Close</Button>
        }
        >
            <Table
            size='small'
            className="tbl-data-search"
            dataSource={searchState.results}
            columns={columns}
            onChange={(pagination, filters) => {
                let newFilters = filters
                // validates in case new filters are added but not supported by the current filters state variable
                for (const filter in newFilters) {
                    if (filter in FilterTypes === false) {
                        delete newFilters[filter]
                    }
                }
                setFilters(newFilters)
            }}
            rowKey={(row) => `${row.fieldName}-${row.exploreName}-${row.viewName}-${row.fieldDescription}`}
            pagination={{
                pageSizeOptions: ["10", "20", "50", "100"],
                defaultPageSize: 50,
                showTotal: (total, range) => {
                    return (
                    <span style={{ left: 0, position: "absolute" }}>
                        {range[0]}-{range[1]} of {total} results
                    </span>
                    )
                }
            }}
            scroll={{
                y: 500,
              }}
            />
        </Modal>
    )
}

export default DataDocumentationSearchModal