import { ReloadOutlined } from "@ant-design/icons";
import { Alert, Col, Layout, Row } from "antd";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IAlertInfo, ICustomerSettings, IStandardReportsDashboardItem } from "../../AppModels";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { getFolderAncestors, search } from "../../services/FolderManagement/FolderManagement";
import { IAncestorInfo, IContentDisplayTypes, IDashboardInfo, IDisplayFolderItem, IDisplayFolderItemType, ILookInfo, IRootFolderIds } from "../../services/FolderManagement/FolderModels";
import { getRootFolders, setCurrentItemAndAncestors } from "../../store/actions/FolderActions";
import { getCustomerSettings, getStandardReports } from "../../store/actions/SettingsActions";
import { IApplicationState } from "../../store/Store";
import ReportsContentContainer from "../ReportsFolder/ReportsContentContainer/ReportsContentContainer";

interface IProps {
  realm: string;
  searchText: string;
  rootFolders: IRootFolderIds;
  getRootFolders: typeof getRootFolders;
  setCurrentItemAndAncestors: typeof setCurrentItemAndAncestors;
}

const SearchResults: React.FC<IProps> = ({ searchText, rootFolders, getRootFolders, setCurrentItemAndAncestors, realm }) => {
  const [dashboards, setDashboards] = React.useState<IDashboardInfo[]>(null);
  const [looks, setLooks] = React.useState<ILookInfo[]>(null);
  const [message, setMessage] = React.useState<string>('');
  const [alert, setAlert] = React.useState<IAlertInfo>(null); 

  const standardReportsMap: Map<string, IStandardReportsDashboardItem> = useSelector<IApplicationState>(state => state.settings.standardReportsMap) as Map<string, IStandardReportsDashboardItem>;
  const customerSettingsError: string = useSelector<IApplicationState>(state => state.settings.customerSettingsError) as string;
  const customerSettings: ICustomerSettings = useSelector<IApplicationState>(state => state.settings.customerSettings) as ICustomerSettings;
  const standardReportsError: string = useSelector<IApplicationState>(state => state.settings.standardReportsError) as string;
  
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (rootFolders == null || rootFolders.personalRootId == null) {
      getRootFolders(realm);
    }
  }, [getRootFolders, rootFolders, realm]);

  const setError = (error: Error) => {
    setAlert({
      type: "error",
      message: "An unexpected error occurred loading Search Results."
    })
  }

  React.useEffect(() => {
    if(searchText.length < 3){
      setMessage('Cannot show results for "' + searchText + '". Please enter at least three characters in search box to view search results.');
      setLooks([]);
      setDashboards([]);
    } else {
      const setResults = (results: any) =>{
        let resultLooks = results.looks;
        let resultDashboards = results.dashboards;
        if(standardReportsMap != null && customerSettings != null){
          standardReportsMap.forEach(dashboard =>{
            if(dashboard.name && dashboard.name.toUpperCase().includes(searchText.toUpperCase())){
              const realTimeAllowed = customerSettings && (customerSettings.realTimeAll || dashboard.explores==null || 
                (customerSettings.realTimeExplores && dashboard.explores.some(explore => customerSettings.realTimeExplores.has(explore))));
              if(!realTimeAllowed && dashboard.embeddedPath == null) {
                //Hide Report  
              } else {
                resultDashboards.push({
                  id: dashboard.dashboardId,
                  title: dashboard.name,
                  folder_name: 'Standard Report',
                  folder_id: '0', //using folder_id 0 to force special logic for Standard Reports in goToItem method
                });
              }
            }
          });
        } else if (standardReportsError == null || customerSettingsError == null){
          setAlert({
            type: "warning",
            message: <div 
            onClick={() => {
              if(standardReportsError){
               dispatch(getStandardReports()); 
              }
              if(customerSettingsError){
                dispatch(getCustomerSettings());
              } 
              setAlert(null); 
              setLooks(null);
              setDashboards(null);
            }} 
            className="clickable">
            There was an error searching Standard Reports and related results may be missing. <u>Click to Retry <ReloadOutlined /></u>
          </div>
          });
        }
        if( (resultLooks && resultLooks.length > 0) ||
            (resultDashboards && resultDashboards.length>0) ){
          setMessage('Showing results for "' + searchText + '".');
        } else {
          setMessage('No results found for "' + searchText + '".');
        }
        setLooks(resultLooks);
        setDashboards(resultDashboards);
      }
      search(searchText, setResults, setError);
    }
    document.title = 'Search Results';
  }, [searchText, standardReportsMap, customerSettings, standardReportsError, customerSettingsError, dispatch]);

  const goToItem = (item: IDisplayFolderItem)=>{
    if(item.parent_id === '0'){
      history.push("/reports/" + item.id)
      return;
    }
    const processResult = (result: any) => {
      let ancestors: IAncestorInfo[] = result;
      ancestors.push({
        id: item.parent_id,
        name: (item.parent_name.indexOf('@TeamShared') !== -1) ? 'Shared Reports' : item.parent_name,
        parent_id: null
      })
      for(let i=0; i< ancestors.length; i++){
        let ancestor = ancestors[i];
        if(rootFolders && rootFolders.teamRootId && ancestor.id === rootFolders.teamRootId){
          setCurrentItemAndAncestors(item, ancestors.slice(i+1));
          history.push('/sharedReports');
          return;
        } else if(rootFolders && rootFolders.personalRootId && ancestor.id === rootFolders.personalRootId) {
          setCurrentItemAndAncestors(item,  ancestors.slice(i+1));
          history.push('/myReports');
          return;
        }
      }
      if(item.type === IDisplayFolderItemType.LOOK){
        history.push('/looks/' + item.id);
      } else if(item.type === IDisplayFolderItemType.DASHBOARD){
        history.push('/dashboards/' + item.id);
      }
    }
    const processError = (error: Error) =>{
      //If error occurs retrieving ancestors, just display without breadcrumbs
      if(item.type === IDisplayFolderItemType.LOOK){
        history.push('/looks/' + item.id);
      } else if(item.type === IDisplayFolderItemType.DASHBOARD){
        history.push('/dashboards/' + item.id);
      }
    }
    getFolderAncestors(item.parent_id.toString(), processResult, processError);
  };

  const clearAlert = () =>{
    setAlert(null);
  }

  return (
    <>
      <Layout>
        <div className="folder-content-area">
        {
          (dashboards != null || looks != null || alert != null) ?
          <Layout.Content 
            style = {{
              padding: "8px 64px 4px 64px"
            }}
          > 
            <br />
            <Row>
              <Col span={24}>
                <h3>Search Results</h3>
                <span>{message}</span>
              </Col>
            </Row>    
            <br />
            { alert &&
              <Alert 
                style={{ marginBottom: "24px"  }}
                type={alert.type}
                message={alert.message}  
                description={alert.description}
                closable 
                afterClose={clearAlert} 
              />
            }
            {(dashboards && dashboards.length > 0) &&
              (
                <ReportsContentContainer 
                  type={IDisplayFolderItemType.DASHBOARD}
                  data={dashboards}
                  display={IContentDisplayTypes.SEARCH}
                  rootFolders={null}
                  folder={null}
                  setAction={null}
                  goToItem={goToItem}
                />
              )
            }
            {(looks && looks.length > 0) &&
              (
                <ReportsContentContainer 
                  type={IDisplayFolderItemType.LOOK}
                  data={looks}
                  display={IContentDisplayTypes.SEARCH}
                  rootFolders={null}
                  folder={null}
                  setAction={null}
                  goToItem={goToItem}
                />
              )
            }
          </Layout.Content>
          : 
          <Layout.Content
            style={{ height: "85hv" , width: "100%"}}
          >
            <CustomSpinner />
          </Layout.Content>
        }
      </div>
    </Layout>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    rootFolders: state.folders.rootFolders,
    realm: state.userAuth.realm,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getRootFolders: (realm: string) =>
      dispatch(getRootFolders(realm)),
    setCurrentItemAndAncestors: (item: IDisplayFolderItem, ancestors: IAncestorInfo[]) =>
      dispatch(setCurrentItemAndAncestors(item, ancestors)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
